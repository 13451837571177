/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { Box } from '@mui/system';
import CourseDownload from "../../asset/image/Course/CourseDownload.svg";
import Project from "../../asset/image/Course/Project.svg";
import useStyles from '../MyCourse/MyCourseCss';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { usePost } from '../../utils/apiHelper';
import { base64toBlob } from '../../utils/Base64toblob';

interface FileData {
    fileName: string;
    fileContent: string; // Assuming file content is base64 encoded
}

interface ApiResponse {
    status: number;
    data: {
        fileContent: string;
    };
}


const DownloadCourse: React.FC = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [filesToDownload, setFilesToDownload] = useState<FileData[]>([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //Future Use
    // const handleDownloadCourse = async () => {
    //     try {
    //         const zip = new JSZip();
    //         const promises = filesToDownload.map(async (file) => {
    //             const response = await usePost("File/MultiplePdfFileDownload", file);
    //             if (response.status === 200) {
    //                 const blob = base64toBlob(baseImage + response.data);
    //                 zip.file(file.fileName, blob);
    //             }
    //         });
    //         await Promise.all(promises);

    //         zip.generateAsync({ type: "blob" }).then((content) => {
    //             saveAs(content, "course_files.zip");
    //         });
    //     } catch (error) {
    //         console.error("Error downloading files:", error);
    //     }
    // };

    const handleDownloadCourse = async () => {
        try {
            const zip = new JSZip();
            const promises = filesToDownload.map(async (file) => {
                // Make API call to fetch file content
                const response = await usePost("File/MultiplePdfFileDownload", file) as ApiResponse;
                if (response.status === 200 && response.data && response.data.fileContent) {
                    // Convert file content from base64 to blob
                    const blob = base64toBlob(response.data.fileContent);
                    // Add file to ZIP archive
                    zip.file(file.fileName, blob);
                }
            });
            await Promise.all(promises);

            // Generate ZIP file and download it
            zip.generateAsync({ type: "blob" }).then((content) => {
                saveAs(content, "course_files.zip");
            });
        } catch (error) {
            console.error("Error downloading files:", error);
        }
    };

    return (
        <React.Fragment>
            <IconButton size="small" onClick={handleClickOpen}>
                <DownloadIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose} PaperProps={{ style: { width: 500, height: 300 } }}>
                <DialogTitle borderBottom={"1px dashed lightgrey"}>
                    <b>File</b>
                    <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                        <HighlightOffIcon />
                    </IconButton></DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box p={1} m={1} border={"1px solid lightgrey"} height="140px" width="90%">
                                <img
                                    src={CourseDownload}
                                    alt={"course"}
                                    className={classes.iconstyle}
                                    onClick={handleDownloadCourse}
                                />
                                <Typography variant='body2' align='center' ml={'5px'} mr={1} p={1}>
                                    <b>COURSE</b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box p={1} m={1} border={"1px solid lightgrey"} height="140px" width="90%">
                                <img
                                    src={Project}
                                    alt={"Project"}
                                    className={classes.iconstyle}
                                // onClick={handleDownloadProject} 
                                />
                                <Typography variant='body2' align='center' ml={'5px'} mr={1} p={1}>
                                    <b>PROJECT</b>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ borderTop: "1px dashed lightgrey" }}>
                    <Button onClick={handleClose} color="error" variant="contained" size="small">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DownloadCourse;
