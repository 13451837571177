/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Divider, Grid, Typography } from '@mui/material';

import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import award from '../../../images/course/certificate/award.png';
import certificate1bg from '../../../images/course/certificate/certificate1-1.png';
import certificate1 from '../../../images/course/certificate/certificate1.png';
import certificate2bg from '../../../images/course/certificate/certificate2-1.png';
import certificate2 from '../../../images/course/certificate/certificate2.png';
import certificate3bg from '../../../images/course/certificate/certificate3-1.png';
import certificate3 from '../../../images/course/certificate/certificate3.png';
import certificate4bg from '../../../images/course/certificate/certificate4-1.png';
import certificate4 from '../../../images/course/certificate/certificate4.png';
import sign from '../../../images/course/certificate/sign.png';
import logo from '../../../images/logo/logo.png';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import { ICertificateName } from '../../../model/Course/ICertificateList';
import { usePost } from '../../../utils/apiHelper';
const certificateList = [
    {
        label: "Certificate 1",
        icon: certificate1,
        bgImage: certificate1bg
    },
    {
        label: "Certificate 2",
        icon: certificate2,
        bgImage: certificate2bg
    },
    {
        label: "Certificate 3",
        icon: certificate3,
        bgImage: certificate3bg
    },
    {
        label: "Certificate 4",
        icon: certificate4,
        bgImage: certificate4bg
    }
];

const ClaimCertificate = (props) => {
    // Future Work
    const CourseId = props?.match?.params?.courseId;
    const [selectedCertificate, setSelectedCertificate] = useState(certificateList[2]);
    const [loading, setloading] = useState(false);
    const [certificateTemplateList, setCertificateTemplateList] = useState<ICertificateName>(null);
    const [getCertificateName, setGetCertificateName] = useState<string>("");
    const [bgImageUrl, setBgImageUrl] = useState<string>("");

    useEffect(() => {
        (async() => {
            setloading(true);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            await usePost<any>(`UserCourse/GetCertificateTemplate?courseId=${64}`, {}).then((r) => {
                setCertificateTemplateList(r?.data);
                const receivedCertificateName = (r?.data?.certificateName);
                    setGetCertificateName(receivedCertificateName);
                    if(receivedCertificateName){
                        const selectedTemplate  = certificateList.find(r => r?.label === receivedCertificateName);
                        if(selectedTemplate) {
                            setBgImageUrl(selectedTemplate?.bgImage);
                        }
                        else {
                            console.error(`Certificate with name not found in the certificate list.`);
                        }
                    } else {
                        console.error(`Certificate name not received from the API.`);
                    }
            })
        })().finally(() => {
            setloading(false);
        })
    }, [])

    return (
        <React.Fragment>
            <Grid container spacing={0} justifyContent="center" alignItems="center">
                <Grid item xs={9}>
                    <Box m={0} p={3} >
                        <Grid container spacing={0} m={0} p={1}>
                            <Grid item xs={12} justifyContent={'center'} display={'inline-flex'} alignItems={'center'}>
                                <div className="outer-container">
                                    <div className="inner-container" style={{ backgroundImage: `url(${bgImageUrl})` }}>

                                        <Grid container spacing={0} m={0} p={"50px 200px"} >
                                            <Grid item xs={12} >
                                                <Typography variant='h4' mt={0} textAlign={'center'} fontFamily={'Open Sans, sans-serif'} >
                                                    <img src={logo} alt="logo" width={'150px'} height={'40px'} />
                                                </Typography>
                                                <Typography variant='h5' fontSize={30} fontWeight={600} mt={3} textAlign={'center'} fontFamily={'times new roman'} letterSpacing={1}>
                                                    <b>CERTIFICATE OF COMPLETION</b>
                                                </Typography>

                                                <Typography variant='h6' fontSize={17} fontWeight={200} mt={2} textAlign={'center'} fontFamily={'times new roman'} letterSpacing={1} color={'gray'}>
                                                    THIS CERTIFICATE IS PRESENTED TO
                                                </Typography>

                                                <Typography variant='h5' fontSize={42} fontWeight={600} mt={2} textAlign={'center'} color={'#032F66'} letterSpacing={4} fontFamily={'Dancing Script, cursive'} className='fontStyle'>
                                                    User Name
                                                </Typography>

                                                <Typography variant='h5' fontSize={15} fontWeight={200} mt={2} textAlign={'center'} letterSpacing={1} fontFamily={'times new roman'} >
                                                    has successfully completed the online Course
                                                </Typography>

                                                <Typography variant='h5' fontSize={22} fontWeight={600} mt={2} textAlign={'center'} letterSpacing={1}>
                                                    <b>React Native: Getting Started</b>
                                                </Typography>

                                                <Typography variant='h6' fontSize={15} fontWeight={200} mt={2} textAlign={'center'} letterSpacing={0} fontFamily={'times new roman'} >
                                                    This Professional has demonstrated initiative and a commitment to deepending their skills and advancing their career. Well done!
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5} >
                                                <Typography variant='h6' fontSize={15} fontWeight={400} ml={0} mt={2} textAlign={'center'} letterSpacing={0} fontFamily={'times new roman'} padding={"0px 50px 0px 50px"} >
                                                    <b>mm/dd/yy</b>
                                                </Typography>
                                                <Typography variant='h6' fontSize={15} fontWeight={400} ml={0} mt={1} textAlign={'center'} letterSpacing={0} fontFamily={'times new roman'} padding={"0px 0px 0px 20px"} >
                                                    <Divider />
                                                </Typography>
                                                <Typography variant='h6' fontSize={15} fontWeight={500} ml={0} mt={1} textAlign={'center'} letterSpacing={0} fontFamily={'times new roman'} padding={"0px 50px"} >
                                                    <b>DATE OF ISSUE</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} >
                                                <img src={award} alt="award" />
                                            </Grid>
                                            <Grid item xs={5} >
                                                <Typography variant='h6' fontSize={15} fontWeight={400} ml={0} mt={2} mr={2} textAlign={'center'} letterSpacing={0} fontFamily={'times new roman'} padding={"0px 50px 0px 50px"}>
                                                    <img src={sign} alt="sign" width={'80px'} height={'20px'} />
                                                </Typography>
                                                <Typography variant='h6' fontSize={15} fontWeight={400} ml={0} mt={1} textAlign={'center'} letterSpacing={0} fontFamily={'times new roman'} padding={"0px 20px 0px 0px"} >
                                                    <Divider />
                                                </Typography>
                                                <Typography variant='h6' fontSize={14} fontWeight={500} mt={1} textAlign={'left'} letterSpacing={0} fontFamily={'times new roman'} >
                                                    <b>DR. PETER KULANDAI RAJ K</b>
                                                </Typography>
                                                <Typography variant='h6' fontSize={12} fontWeight={500} mt={0} textAlign={'left'} letterSpacing={0} fontFamily={'times new roman'} >
                                                    <b>CEO, COGNULTSOFT</b>
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box >
                </Grid >
                <Grid item xs={3} container alignItems="center" justifyContent="center" >
                    
                    <Link to="/myCourse" style={{ textDecoration: 'none!important' }}>
                        <Button variant='outlined' size='large'>
                            Complete the Task & claim Your Certificate <MilitaryTechIcon fontSize='large' color='primary' />
                        </Button>
                    </Link>
                </Grid>
            </Grid >
        </React.Fragment >
    )
}

export default ClaimCertificate;