/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import AdsClickIcon from '@mui/icons-material/AdsClick';
import CallIcon from '@mui/icons-material/Call';
import DescriptionIcon from '@mui/icons-material/Description';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import DownloadIcon from '@mui/icons-material/Download';
import FactoryIcon from '@mui/icons-material/Factory';
import LanguageIcon from '@mui/icons-material/Language';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SchoolIcon from '@mui/icons-material/School';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TaskIcon from '@mui/icons-material/Task';
import WorkIcon from '@mui/icons-material/Work';
import { Avatar, Button, Grid, IconButton, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Box } from '@mui/system';
import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../../../../GlobalStore/globalStore';
import ColorlibConnector from '../../../../GlobalStyles/ColorlibConnector';
import MLearnTooltip from '../../../../GlobalStyles/ToolTip';
import profileIcon from '../../../../images/ProfileCard/avatar.svg';
import { IEducationalQualification, IExperience, IPrepareCV, IProject, ISkill,objective } from '../../../../model/Users/IUser';
import { usePost } from '../../../../utils/apiHelper';
import useStyles from '../ProfileCardCss';
import ColorlibStepIcon from './ColorlibStepIcon';
import { steps } from './steps';


const PreviewCV = (props) => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const history = useHistory();
    const [activeStep, setActiveStep] = useState(5);
    const profileInformation: IPrepareCV[] = props?.location?.state?.profileInformation;
    const educationInformations: IEducationalQualification[] = props?.location.state?.educationInformations;
    const skillInformation: ISkill[] = props?.location?.state?.skillInformation;
    const experienceInformation: IExperience[] = props?.location?.state?.experienceInformation;
    const projectInformations: IProject[] = props?.location?.state?.projectInformations;

    const handleSave = () => {
        (async () => {
            let request = {
                'userId': state?.userAccessContext?.userId,
                "prepareCVInformations": profileInformation,
                "educationalQualification": educationInformations,
                "experienceDetails": experienceInformation,
                "projectDetails": projectInformations,
                "skillDetails": skillInformation
            };
            await usePost("PrepareCV/ManagePersonalInformationDetails", request).then((response) => {

            });
        })().finally(() => {
        });
    }

    const handleBackStep = () => {
        history.push("/Project", {
            profileInformation: profileInformation, educationInformations: educationInformations,
            skillInformation: skillInformation, experienceInformation: experienceInformation,
            projectInformations: projectInformations,
        });
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={2} display={"flex"} justifyContent={"center"} alignItems={"center"} borderRight={"1px solid lightGray"} style={{ backgroundColor: "#7a64a7" }}>
                <Stepper
                    activeStep={activeStep}
                    orientation='vertical'
                    connector={<ColorlibConnector />}>
                    {steps.map((step, index) => (
                        <Step key={step.label} sx={{ color: "white" }}>
                            <StepLabel StepIconComponent={ColorlibStepIcon} icon={step.icon}>
                                <b style={{ color: activeStep === index ? "orange" : (index < activeStep ? "limegreen" : "#ccc"), fontSize: activeStep === index ? "16px" : "14px" }}>
                                    {step.label}
                                </b>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            <Grid item xs={9.7}>
                {activeStep === 5 && (
                    <Grid container spacing={1}>
                        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} borderBottom={'1px dashed blue'}>
                            <MLearnTooltip title="Add" placement="top">
                                <IconButton
                                    size='small'
                                    sx={{ mb: "2px" }}
                                    aria-label="Add"
                                    color="primary"
                                    style={{ backgroundColor: "#5e35b1", color: "white" }}>
                                    < DownloadIcon />
                                </IconButton>
                            </MLearnTooltip>
                        </Grid>
                        <Grid item xs={12} justifyContent={"center"}>
                            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.educationContainer}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={4} container direction="column" spacing={2} bgcolor="#303030" color="white" padding={2}>
                                        <Grid item container justifyContent="center">
                                            <Avatar
                                                alt="User Profile"
                                                src={profileIcon}
                                                sx={{ width: 180, height: 180, border: '2px solid #5e35b1' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6">
                                                <IconButton>
                                                    <CallIcon fontSize='small' />
                                                </IconButton>
                                                CONTACT
                                            </Typography>
                                            <Typography variant="subtitle1">Phone: {profileInformation[0]?.phoneNumber}</Typography>
                                            <Typography variant="subtitle1">Email: {profileInformation[0]?.emailInformation}</Typography>
                                            <Typography variant="subtitle1">Address: {profileInformation[0]?.address}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6">
                                                <IconButton>
                                                    <SchoolIcon fontSize='small' />
                                                </IconButton>
                                                EDUCATION
                                            </Typography>
                                            {educationInformations?.map((data, index) => (
                                                <div key={index}>
                                                    <Typography variant="subtitle1">Degree: {data?.degreeName}</Typography>
                                                    <Typography variant="subtitle1">Percentage: {data?.percentage}</Typography>
                                                    <Typography variant="subtitle1">Year Of Passing: {data?.yearOfPassing}</Typography>
                                                    <Typography variant="subtitle1">University: {data?.university}</Typography>
                                                </div>
                                            ))}
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6">
                                                <IconButton>
                                                    <LightbulbCircleIcon fontSize='small' />
                                                </IconButton>
                                                SKILLS
                                            </Typography>
                                            <Typography variant="subtitle1">Skill Name: {skillInformation[0]?.skillName}</Typography>
                                            <Typography variant="subtitle1">Hobbies: {skillInformation[0]?.hobbiesName}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6">
                                                <IconButton>
                                                    <TaskIcon fontSize='small' />
                                                </IconButton>
                                                PROJECTS
                                            </Typography>
                                            {projectInformations?.map((data, index) => (
                                                <div key={index}>
                                                    <Typography variant="subtitle1">Project Name: {data?.projectName}</Typography>
                                                    <Typography variant="subtitle1">Tech Stack: {data?.projectTechnology}</Typography>
                                                    <Typography variant="subtitle1">Description: {data?.projectDescription}</Typography>
                                                </div>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Box borderBottom="2px solid black" padding={2}>
                                            <Typography variant="h4">{profileInformation[0]?.firstName} {profileInformation[0]?.lastName}</Typography>
                                            <Typography variant="subtitle1">{profileInformation[0]?.address}</Typography>
                                            <Typography variant="subtitle1">Tirupattur-635601</Typography>
                                        </Box>
                                        <Box marginY={2}>
                                            <Typography variant="h6">
                                                <IconButton>
                                                    <AdsClickIcon fontSize='small' />
                                                </IconButton>
                                                CAREER OBJECTIVE
                                            </Typography>
                                            <Typography variant="subtitle1">{objective}</Typography>
                                        </Box>
                                        <Box borderBottom="2px solid black" padding={1}>
                                            <Typography variant="h6">
                                                <IconButton>
                                                    <WorkIcon fontSize='small' />
                                                </IconButton>
                                                EXPERIENCE
                                            </Typography>
                                            <Grid container spacing={1} direction="column">
                                                <Grid item>
                                                    <IconButton>
                                                        <Diversity2Icon fontSize='small' />
                                                    </IconButton>
                                                    Organization
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2">{experienceInformation[0]?.organization}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton>
                                                        <FactoryIcon fontSize='small' />
                                                    </IconButton>
                                                    Industry
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2">{experienceInformation[0]?.industry}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton>
                                                        <MyLocationIcon fontSize='small' />
                                                    </IconButton>
                                                    Company Address
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2">{experienceInformation[0]?.location}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton>
                                                        <LanguageIcon fontSize='small' />
                                                    </IconButton>
                                                    Website
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2">{experienceInformation[0]?.website}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton>
                                                        <SupervisedUserCircleIcon fontSize='small' />
                                                    </IconButton>
                                                    Role
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2">{experienceInformation[0]?.role}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton>
                                                        <DescriptionIcon fontSize='small' />
                                                    </IconButton>
                                                    Describe the Role
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2">{experienceInformation[0]?.descriptionOfExperience}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box marginY={2}>
                                            <Typography variant="h6">
                                                <IconButton>
                                                    <WorkIcon fontSize='small' />
                                                </IconButton>
                                                REFERENCE
                                            </Typography>
                                            <Grid container direction="column" spacing={1} pl={1} alignItems="flex-start">
                                                <Grid item>
                                                    <Typography variant="subtitle1">Name of Reporting Manager:</Typography>
                                                </Grid>
                                                <Grid item style={{ marginTop: '-15px' }}>
                                                    <Typography variant="subtitle1">{experienceInformation[0]?.nameOfReportingManager}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1">Designation:</Typography>
                                                </Grid>
                                                <Grid item style={{ marginTop: '-15px' }}>
                                                    <Typography variant="subtitle1">{experienceInformation[0]?.referenceDesignation}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1">Email:</Typography>
                                                </Grid>
                                                <Grid item style={{ marginTop: '-15px' }}>
                                                    <Typography variant="subtitle1">{experienceInformation[0]?.referenceMailId}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1">Phone:</Typography>
                                                </Grid>
                                                <Grid item style={{ marginTop: '-15px' }}>
                                                    <Typography variant="subtitle1">{experienceInformation[0]?.referencePhoneNo}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Scrollbars>
                        </Grid>
                        <Box pt={0.5} width={'100%'} display={'inline-flex'} justifyContent={'flex-end'} borderTop={'1px solid lightgray'}>
                            <Button variant="contained" size="small" color="success" onClick={handleBackStep} sx={{ marginRight: "20px" }}>
                                <b>Back</b>
                            </Button>
                            <Button variant="contained" size="small" color="success" onClick={handleSave} sx={{ marginRight: "20px" }}>
                                <b>Save</b>
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default PreviewCV;