
export const msalConfig = {
    auth: {
      authority: 'https://login.microsoftonline.com/3ba10f38-0314-4ab2-b6b4-516f9ca8dbe5',
      clientId: '0910b7ac-57bf-42fe-9ef7-86111b9275d6',
      redirectUri: window.document.location.origin,
      postLogoutRedirectUri: window.document.location.origin,
      scopes: ['https://cognultsoftb2c.onmicrosoft.com/465f7269-d011-4b84-805b-b13843885c60/MerryLearn.API.Read'],
    },
    system: {
      iframeHashTimeout: 10000,
    },
  }
  export const loginRequest = {
    scopes: msalConfig.auth.scopes,
  }

  export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  };