import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    gridContainer: {
      width: '80%',
      boxShadow: '0px 2px 4px -1px rgba(145, 158, 171, 0.2), 0px 4px 5px 0px rgba(145, 158, 171, 0.14), 0px 1px 10px 0px rgba(145, 158, 171, 0.12)',
      borderRadius: '15px',
      marginTop: '5%',
      overflow: 'hidden'
    },
    gridAddress: {
      backgroundColor: '#2b284f',
      transform: 'scale(1.1)',
      borderRadius: '5px',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
    },
    textFiled: {
      width: '100%',
      padding: '5px',
      input: {
        fontSize: '5px',
      },
    },
  
    MuiTypographybody2: {
      fontfamily: 'Roboto",serif',
    },
    typography:{
      color: 'white', 
      marginTop: '10px' 
    },
    color:{
      color: 'white' 
    },
    marginTop:{
      marginTop: '20px' 
    },
    margin:{
      marginTop: '10px'
    },
    textField:{
      width: '98%', 
      marginTop: '20px'
    }
  }));