/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Badge, Box, ClickAwayListener, Grid, IconButton, Popper, Typography } from '@mui/material';
import React, { useState } from 'react';
import useStyles from './NotificationCss';
import profileIcon from '../../../asset/image/Profile/user2.svg';import DoneAllIcon from '@mui/icons-material/DoneAll';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import MLearnTooltip from '../../../GlobalStyles/ToolTip';
import { mockNotificationDetails } from '../../../_mock/ProfileJob';


const Notification = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);


  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <IconButton size='small' className={classes.iconButtons} onClick={handleToggle} aria-label="Notifications Icon">
        <Badge badgeContent={mockNotificationDetails?.length} color="primary">
        <MLearnTooltip title={ "Notifications"} placement="top">
          <NotificationsActiveTwoToneIcon fontSize='small' />
          </MLearnTooltip>
        </Badge>
      </IconButton>
      <Popper open={isOpen} anchorEl={anchorEl}  placement="bottom-end" > 
       <ClickAwayListener onClickAway={handleClose}>
        <Box
          sx={{
            marginTop: 3,
            width: '40vh',
            height: "auto",
            backdropFilter: 'blur(20px)',
            background: 'rgba(250, 250, 250, 0.9)',
            color: '#000',
            boxShadow: 3,
            p: 2,
            borderRadius: 1,
          }}
        >
          <Grid container padding={0} columns={{ xs: 4, md: 12 }} >
            <Grid item xs={12} sm={12} display='flex' justifyContent='space-between' alignItems='center' borderBottom={"1px dotted grey"} mb={2}>
              <Grid item>
                <Typography fontWeight="bold" p={1}>Notifications</Typography>
                <Typography variant='body1' color={'grey'} gutterBottom>You have 2 unread messages</Typography>
              </Grid>
              <Grid item>
                <MLearnTooltip title={"Marked As Read"} placement="top">
                  <IconButton onClick={handleToggle} size="small">
                    <DoneAllIcon color='primary' />
                  </IconButton>
                </MLearnTooltip>
              </Grid>
            </Grid>
            {mockNotificationDetails.map((notification) => (
              <Grid item xs={12} sm={12} key={notification.createdBy} >
                <Grid container p={0} spacing={1} className={classes.NotificationHover}>
                  <Grid item xs={3}>
                    <img alt={`Avatar ${notification.createdBy}`} src={profileIcon} />
                  </Grid>
                  <Grid item xs={9} >
                    <Typography variant='body2'>{notification.notificationMessage}</Typography>
                    <Typography variant='body1' color={"grey"}>{notification.createdDate.toLocaleTimeString()}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
          </ClickAwayListener >
      </Popper>
    </React.Fragment>
  );
};

export default Notification;
