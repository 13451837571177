/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* Imports */
import {
    Backdrop, Box, Breadcrumbs, Button, CircularProgress, Collapse, FormControl, Grid, IconButton,
    LinearProgress, List, ListItem, OutlinedInput,
    Tab, Tabs, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import ReactPlayer from 'react-player';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { useGlobalState } from '../../GlobalStore/globalStore';
import MLearnTooltip from '../../GlobalStyles/ToolTip';
/* Images */
import noVideo from "../../asset/image/Course/NoVideo.svg";
import facebook from "../../asset/image/Course/facebook.svg";
import google from "../../asset/image/Course/google.svg";
import linkedin from "../../asset/image/Course/linkedin.svg";
import microsoft from "../../asset/image/Course/microsoft.svg";
import twitter from "../../asset/image/Course/twitter.svg";
/* Icons */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CommentSharpIcon from '@mui/icons-material/CommentSharp';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from '@mui/icons-material/Home';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LockIcon from '@mui/icons-material/Lock';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import ShareSharpIcon from '@mui/icons-material/ShareSharp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VideocamIcon from '@mui/icons-material/Videocam';
/* Models */
import { TopicDetailResult } from '../../model/ITopicId';
import {
    IGetUserCourseProgressResult, IGetUserCourseTopicProgressResult,
    IUserCourseProgressTracker
} from '../../model/UserCourse/IUserCourseContract';
import { ICourseFile, IFile, ISubTopicDetail, ITopicDetail, ITreeItem } from '../../model/course';
import { usePost } from '../../utils/apiHelper';
/* Components */
import { ThemeProvider } from '@material-ui/core/styles';
import { IFeedback } from '../../model/ICourse';
import { theme } from '../../themes';
import useStyles from "../MyCourse/MyCourseCss";
import UserFeedbackDetails from '../User Feedback/UserFeedback';
import UserCourseAssessment from '../UserAssessment/UserAssessment';
import LabView from './CourseLab/LabView';
import DownloadCourse from "./DownloadCourse";
import DiscussionUI from './Discussion';

const CourseView = (props) => {
    const { state } = useGlobalState();
    let InitailCourseProgress: IGetUserCourseProgressResult = {
        progressTrackingId: 0,
        screenTime: '',
        createdDate: '',
        isTopicCompleted: false,
        isVideoCompleted: false,
        isAssignmentCompleted: false
    }
    const courseId = props?.match?.params?.courseId;
    const courseTitle = props?.match?.params?.courseTitle;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    // Future Work
    const [data, setData] = useState<ITreeItem[]>([]);
    const [topicData, setTopicData] = useState<ITopicDetail[]>([]);
    const [subTopic, setSubTopic] = useState<ISubTopicDetail[]>([]);
    const [topicDetail, setTopicDetail] = useState<TopicDetailResult[]>([]);
    const [subTopicDetail, setSubtopicDetail] = useState<ISubTopicDetail[]>([]);
    const [progress, setProgress] = useState(10);
    const [topicprogress, setTopiProgress] = useState(10);
    const [value, setValue] = useState(0);
    const [timeSpentOnPage, setTimeSpentOnPage] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);
    const [selectedTopic, setSelectedTopic] = useState<number>(0);
    const [selectedSubTopic, setSelectedSubTopic] = useState<number>(0);
    const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
    const history = useHistory();
    const [overallProgress, setOverallProgress] = useState(0);
    //video tracking
    const [videoEnded, setVideoEnded] = useState(false);
    const [fileUrl, setFileUrl] = useState<IFile[]>([]);
    const [courseMediaList, setCourseMediaList] = useState<ICourseFile[]>([]);
    const [blobUrl, setBlobUrl] = useState<string>(null);
    const [frameProgress, setFrameProgress] = useState(0);
    const [courseProgress, setCourseProgress] = useState<IGetUserCourseProgressResult>(InitailCourseProgress);
    const [courseTopicProgress, setCourseTopicProgress] = useState<IGetUserCourseTopicProgressResult[]>([]);
    const [isCertificateButtonEnabled, setIsCertificateButtonEnabled] = useState(false);
    const [isFeedbackCompleted, setIsFeedbackCompleted] = useState(false);
    const [isPinned, setIsPinned] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [isVideoStop, setIsVideoStop] = useState<boolean>(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const handlePinToggle = () => {
        setIsPinned(true);
    };
    const handleUnPinToggle = () => {
        setIsPinned(false);
    };

    useEffect(() => {
        if (isVideoPlaying && value === 0) {
            setIsTimerRunning(true);
            setTimeSpentOnPage(0);
        } else {
            setIsTimerRunning(false);
            setTimeSpentOnPage(0);
        }
    }, [isVideoPlaying, value]);

    useEffect(() => {
        let intervalId;
        if (isTimerRunning && value === 0) {
            intervalId = setInterval(() => {
                setTimeSpentOnPage(prevTime => prevTime + 1000); // increment by 1 second
            }, 1000); // every 1 second
        }
        return () => clearInterval(intervalId); // cleanup
    }, [value, isTimerRunning]);

    useEffect(() => {
        if (value === 1) {
            handleCompleted();
        }
    }, [value]);

    const formatTime = (time: number): string => {
        const hours = Math.floor(time / 3600000);
        const minutes = Math.floor((time % 3600000) / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        return `${hours} : ${minutes} : ${seconds}`;
    };

    const screenTiming = formatTime(timeSpentOnPage);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleNextButtonClick = () => {
        setIsTimerRunning(false);
        const currentTopicIndex = data?.findIndex(node => node.isActive);
        const currentTopic = data[currentTopicIndex];
        // Check for subtopics and navigate accordingly.
        if (currentTopic?.nestedItems?.length > 0) {
            const currentSubtopicIndex = currentTopic?.nestedItems?.findIndex(subtopic => subtopic?.isSubMenuActive);
            // Check if there's an active subtopic and it's not the last one.
            if (currentSubtopicIndex !== -1 && currentSubtopicIndex < currentTopic.nestedItems.length - 1) {
                // Move to the next subtopic
                const nextSubtopic = currentTopic?.nestedItems[currentSubtopicIndex + 1];
                handleSelectedTopic(nextSubtopic?.courseSubTopicName, currentTopic?.courseTopicId, nextSubtopic?.courseSubTopicId, nextSubtopic?.stipulatedTime);
                setValue(0);
                setIsTimerRunning(true);
                return;
            } else if (currentSubtopicIndex === -1) {
                // No subtopic is active, move to the first subtopic
                const firstSubtopic = currentTopic?.nestedItems[0];
                handleSelectedTopic(firstSubtopic?.courseSubTopicName, currentTopic?.courseTopicId, firstSubtopic?.courseSubTopicId, firstSubtopic?.stipulatedTime);
                setValue(0);
                setIsTimerRunning(true);
                return;
            }
        }
        // Move to the next topic if no more subtopics or no subtopics at all
        const nextTopicIndex = currentTopicIndex + 1;
        const nextTopic = data && data[nextTopicIndex];
        if (nextTopic) {
            handleSelectedTopic(nextTopic?.courseTopicName, nextTopic?.courseTopicId, 0, null);
            const firstSubtopic = nextTopic?.nestedItems && nextTopic?.nestedItems[0];
            if (firstSubtopic) {
                handleSelectedTopic(firstSubtopic?.courseSubTopicName, nextTopic?.courseTopicId, firstSubtopic?.courseSubTopicId, firstSubtopic?.stipulatedTime);
            } else {
                // If there are no subtopics, reset subtopic related values
                handleSelectedTopic(nextTopic?.courseTopicName, nextTopic?.courseTopicId, 0, null);
            }
            setValue(0);
            setIsTimerRunning(true);
            setTimeSpentOnPage(0);
        }
    };

    const handleCompleted = () => {
        (async () => {
            let request = {
                progressTrackingId: courseProgress?.progressTrackingId,
                userId: state?.userAccessContext?.userId,
                courseId: courseId,
                courseTopicId: selectedTopic,
                courseSubTopicId: selectedSubTopic,
                screenTime: screenTiming,
                isTopicCompleted: true,
                isVideoCompleted: true,
                isAssignmentCompleted: false,
                isVideoFlag: true,
                isTopicFlag: false,
                isAssignmentFlag: false
            };
            await usePost<IUserCourseProgressTracker[]>(`UserCourse/ManageUserCourseProgressTracker`, request).finally(() => {
                setLoading(false);
                GetCourseProgressDetails(true, selectedTopic, selectedSubTopic);
                GetCourseTopicProgressDetails();
            })
        })()
    };

    const handleCompleteCourse = () => {
        history.push({
            pathname: "/completecourse",
            state: {
                courseId: courseId,
                courseTitle: courseTitle,
            }
        })
    };

    ///video player
    const fetchVideo = (uri) => {
        (async () => {
            let request = {
                pdfUri: uri,
            };
            await usePost<any>("File/FileDownload", request).then((response) => {
                const byteCharacters = atob(response.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'video/mp4' });
                const blobUrl = URL.createObjectURL(blob);
                setBlobUrl(blobUrl);
            })
        })()
    };

    //feature
    const fetchVedios = () => {
        let MediaResult = [];
        courseMediaList?.map((r) => {
            if (r.fileType === '.mp4') {
                let newResult: IFile = {
                    fileId: r?.fileId,
                    fileUrl: r?.fileURI,
                    fileName: r?.fileName
                }
                MediaResult?.push(newResult);
                fetchVideo(r.fileURI);
            }
            return null;
        })
        setFileUrl(MediaResult);
    };

    const handleProgress = (videoprogress) => {
        const { playedSeconds, loadedSeconds } = videoprogress;
        // Calculate the percentage of the video watched
        const progressPercentage = (playedSeconds / loadedSeconds) * 100;
        // Calculate number of frames watched based on the progress percentage
        const totalFrames = 1000; // Example: total frames in the video
        const watchedFrames = Math.floor((totalFrames * progressPercentage) / 100);
        setFrameProgress((watchedFrames / totalFrames) * 100);
    };

    const handleEnded = () => {
        setVideoEnded(true);
        handleProgressTracking();
    };

    const handleProgressTracking = () => {
        if (isProcessing) return;

        setIsProcessing(true);
        setLoading(true);
        (async () => {
            let request = {
              progressTrackingId: courseProgress?.progressTrackingId,
              userId: state?.userAccessContext?.userId,
              courseId: courseId,
              courseTopicId: selectedTopic,
              courseSubTopicId: selectedSubTopic,
              screenTime: screenTiming,
              isVideoCompleted: true,
              isTopicCompleted: true,
              isAssignmentCompleted: false,
              IsTopicFlag: false,
              IsVideoFlag: true,
              IsAssignmentFlag: false
            };
      
            try {
              await usePost<IUserCourseProgressTracker[]>(`UserCourse/ManageUserCourseProgressTracker`, request);
              setCompleted(true); // Show the tick after success
              setTimeout(() => {
                navigateToNextTopic();
                setCompleted(false); // Reset the completion state
              }, 2000); // Show tick for 2 seconds
            } catch (error) {
              console.error('Error updating progress:', error);
            } finally {
              setIsProcessing(false);
              setLoading(false);
              GetCourseProgressDetails(true, selectedTopic, selectedSubTopic);
              GetCourseTopicProgressDetails();
            }
          })();
        
    };

    const navigateToNextTopic = () => {
        setLoading(true);
        const currentSubTopic = subTopic?.find(topic => topic?.courseSubTopicId === selectedSubTopic);
        const currentTopic = topicData?.find(topic => topic?.courseTopicId === selectedTopic);

        if (currentSubTopic) {
            if (currentSubTopic?.isAssignmentRequired) {
                setValue(3); // Go to assignment tabs
            } else {
                handleNextButtonClick();
            }
        } else if (currentTopic && selectedSubTopic === 0 || subTopic.length === 0) {
            if (currentTopic?.isAssignmentRequired) {
                setValue(3); // There are no subtopics but an assignment is required
            } else {
                handleNextButtonClick();
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        handleUpdateCourseCompletion();
    }, [overallProgress]);

    const handleUpdateCourseCompletion = () => {
        (async () => {
            let request = {
                userId: state?.userAccessContext?.userId,
                courseId: courseId,
                courseCompletionPercentage: overallProgress
            }
            await usePost('Course/ManageCourseCompletionPercentage', request)
        })()
    };

    const handleChange = (event, newValue) => {
        setProgress(newValue);
    };

    useEffect(() => {
        GetCourseTopicProgressDetails();
        fetchData();
    }, [])

    const GetCourseProgressDetails = (flag, courseTopicId, childId) => {
        setLoading(true);
        let request = {
            "userId": state?.userAccessContext?.userId,
            "courseId": courseId,
            "courseTopicId": courseTopicId,
            "courseSubTopicId": selectedSubTopic,
        };
        (async () => {
            await usePost<IGetUserCourseProgressResult>("UserCourse/GetUserCourseProgressResult", request).then((response) => {
                setCourseProgress(response.data[0])
            }).finally(() => {
                setLoading(false);
            })
        })()
    };

    const GetCourseTopicProgressDetails = () => {
        setLoading(true);
        let request = {
            "userId": state?.userAccessContext?.userId,
            "courseId": courseId
        };
        (async () => {
            await usePost<IGetUserCourseTopicProgressResult[]>("UserCourse/GetUserCourseTopicProgressResult", request).then((response) => {
                setCourseTopicProgress(response.data)
            }).finally(() => {
                setLoading(false);
            })

        })()
    };

    const fetchData = () => {
        setLoading(true);
        let request = {
            'courseId': courseId
        };
        (async () => {
            await usePost<{ courseTopicDetailResults: ITopicDetail[], courseSubTopicDetailResults: ISubTopicDetail[] }>("/Course/GetCourseTopicDetail", request).then((response) => {
                setTopicData(response?.data?.courseTopicDetailResults);
                setSubTopic(response?.data?.courseSubTopicDetailResults);
                handleSelectedTopic(null, response?.data?.courseTopicDetailResults[0]?.courseTopicId, 0, null);
            }).finally(() => {
                setLoading(false);
            })
        })()
    };

    useEffect(() => {
        const newData: ITreeItem[] = topicData?.map((menu) => {
            const matchingSubmenus = subTopic?.filter(
                (submenu) => submenu?.courseTopicId === menu?.courseTopicId
            );
            return {
                courseTopicId: menu?.courseTopicId,
                courseTopicName: menu?.courseTopicName,
                isAssignmentRequired: menu?.isAssignmentRequired,
                nestedItems: matchingSubmenus?.map((submenu) => ({
                    courseSubTopicId: submenu?.courseSubTopicId,
                    courseSubTopicName: submenu?.courseSubTopicName,
                    stipulatedTime: submenu?.stipulatedTime,
                    isAssignmentRequired: submenu?.isAssignmentRequired
                })),
                isExpanded: !!matchingSubmenus?.length,
                isActive: false,
            };
        });
        setData(newData);
    }, [topicData, subTopic]);

    const handleToggleExpand = (itemId: number) => {
        const updatedData = data.map((node) =>
            node.courseTopicId === itemId
                ? { ...node, isExpanded: !node.isExpanded }
                : { ...node, isExpanded: false } // Close other topics
        );
        setData(updatedData);
    };

    const handleSelectedTopic = (label: string, courseTopicId: number, childId: number, stipulatedTime: string) => {
        setLoading(true);
        setSelectedTopic(courseTopicId);
        setSelectedSubTopic(childId);
        let flag = (childId > 0 ? false : true);
        GetCourseProgressDetails(flag, courseTopicId, childId);
        GetCourseMediaDetails(flag, courseTopicId, childId);
        (async () => {
            let request = {
                topicId: flag ? courseTopicId : childId,
                isTopicSelected: flag
            }
            await usePost<{ topicDetailResultContracts: TopicDetailResult[] }>("Course/GetTopicInformation", request).then((response) => {
                setTopicDetail(response?.data?.topicDetailResultContracts);
                setLoading(false);
                setData(prevData => {
                    const updatedData = prevData.map((node) => ({
                        ...node,
                        nestedItems: node?.nestedItems?.map(sub => ({
                            ...sub,
                            isSubMenuActive: sub?.courseSubTopicId === childId
                        })),
                        isActive: node?.courseTopicId === courseTopicId || node?.nestedItems.some(sub => sub?.isSubMenuActive)
                    }));
                    const topicIndex = updatedData.findIndex(item => item.isActive);
                    if (topicIndex !== -1) {
                        setValue(0);
                    }
                    return updatedData;
                });
            }).finally(() => {
                GetCourseProgressDetails(flag, courseTopicId, childId)
            })
        })()
    };

    const GetCourseMediaDetails = (flag, courseTopicId, childId) => {
        (async () => {
            let requests = {
                courseId: courseId,
                courseTopicId: flag ? courseTopicId : null,
                courseSubTopicId: flag ? null : childId,
                assignmentId: null,
            };
            await usePost<ICourseFile[]>("File/GetCourseMediaDetail", requests).then((result2) => { setCourseMediaList(result2.data); }
            );
        })()
    }

    //Learner Progress bar
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => {
                const newProgress = prevProgress + 10;
                if (newProgress >= 80) {
                    clearInterval(timer); // Stop the interval when progress reaches 200
                }
                return newProgress;
            });
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    // Topic progress bar
    useEffect(() => {
        const timer = setInterval(() => {
            setTopiProgress((prevProgress) => {
                const newProgress = prevProgress + 10;
                if (newProgress >= 70) {
                    clearInterval(timer); // Stop the interval when progress reaches 200
                }
                return newProgress;
            });
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    /* Future Use */
    const calculateTotalTime = (nestedItems: any[]): string => {
        let totalHours = 0;
        let totalMinutes = 0;
        let totalSeconds = 0;
        nestedItems?.forEach((nestedItem) => {
            if (nestedItem?.stipulatedTime) {
                const [hours, minutes, seconds] = nestedItem?.stipulatedTime.split(':').map(Number);
                totalHours += hours;
                totalMinutes += minutes;
                totalSeconds += seconds;
            }
        });
        totalMinutes += Math.floor(totalSeconds / 60);
        totalSeconds %= 60;
        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes %= 60;

        if (totalHours === 0 && totalMinutes === 0 && totalSeconds === 0) {
            return '';
        }
        let totalTimeString = '';
        if (totalHours > 0) {
            totalTimeString += `${totalHours}h `;
        }
        if (totalMinutes > 0) {
            totalTimeString += `${totalMinutes}m `;
        }
        if (totalSeconds > 0 || totalTimeString === '') {
            totalTimeString += `${totalSeconds}s`;
        }
        return totalTimeString?.trim();
    };

    /* Future Use */
    const formatTimeOutput = (timeString: string): string => {
        if (!timeString) {
            return '';
        }
        const parts = timeString?.split(':');
        const hours = parseInt(parts[0]);
        const minutes = parseInt(parts[1]);
        const seconds = parseInt(parts[2]?.split('.')[0]);
        let formattedTime = '';
        if (hours > 0) {
            formattedTime += `${hours}h `;
        }
        if (minutes > 0) {
            formattedTime += `${minutes}m `;
        }
        if (seconds > 0) {
            formattedTime += `${seconds}s `;
        }
        return formattedTime?.trim();
    };

    const calculateOverallProgress = () => {
        // Count completed topics with subtopics
        const completedTopicsWithSubtopicsCount = data?.filter(topic => {
            // If topic has no subtopics, check if the topic itself is completed
            if (!topic?.nestedItems || topic?.nestedItems?.length === 0) {
                const completionStatus = courseTopicProgress?.find(progress => progress?.courseTopicId === topic?.courseTopicId);
                if (topic?.isAssignmentRequired) {
                    return completionStatus && completionStatus?.isTopicCompleted && completionStatus?.isAssignmentCompleted;
                }
                else {
                    return completionStatus && completionStatus?.isTopicCompleted;
                }
            }
            // If topic has subtopics, check if all subtopics are completed
            return topic?.nestedItems?.every(subtopic => {
                const completionStatus = courseTopicProgress?.find(progress => progress?.courseSubTopicId === subtopic?.courseSubTopicId);
                if (subtopic?.isAssignmentRequired) {
                    // If assignment is required, check both isTopicCompleted and isAssignmentCompleted
                    return completionStatus && completionStatus?.isTopicCompleted && completionStatus?.isAssignmentCompleted;
                } else {
                    // If assignment is not required, check only isTopicCompleted
                    return completionStatus && completionStatus?.isTopicCompleted;
                }
            });
        })?.length;
        // Count total topics including those without subtopics
        const totalTopicsCount = data?.length;
        // Calculate overall progress percentage based on completed topics
        const overallProgressPercentage = totalTopicsCount === 0 ? 0 : (completedTopicsWithSubtopicsCount / totalTopicsCount) * 100;
        // Enable certificate button if progress is 100%
        if (overallProgressPercentage === 100) {
            setIsCertificateButtonEnabled(true);
            setIsVideoStop(false);
        } else {
            setIsCertificateButtonEnabled(false);
        }
        return overallProgressPercentage;
    };
    useEffect(() => {
        const progressPercentage = calculateOverallProgress();
        setOverallProgress(Math.round(progressPercentage));
        if (overallProgress === 100) {
            fetchFeedback();
        }
    }, [courseTopicProgress, data, overallProgress]);
    // const GetUserCourse=()=>{
    //     (async () => {
    //         let request = state?.userAccessContext?.userId;
    //         await usePost<IUserCourseDetail[]>(`UserCourse/GetUserCourse?userId=${request}`, {}).then((r) => {
    //             setUserCourseDetails(r?.data);
    //         });
    //     })().finally(() => {
    //         setLoading(false);
    //     })
    // }
    function fetchFeedback() {
        (async () => {
            const request = {
                userId: state?.userAccessContext?.userId,
                courseId: props.courseId
            };
            await usePost<IFeedback[]>("Course/GetFeedback", request).then((response) => {
                CheckFeedback(response.data.length);
            })
        })()
    };

    const CheckFeedback = (data) => {
        const progressPercentage = calculateOverallProgress();
        setOverallProgress(Math.round(progressPercentage));
        if (overallProgress === 100 && data === 0) {
            setIsFeedbackCompleted(true);
        }
    };

    function LinearProgressWithLabel(props) {
        return (

            <React.Fragment>
                <Grid item xs={12} >


                    {/* <Box minWidth={35}>
                                <Typography variant="body2" color="#5e35b1" className={classes.progressFontStyle} sx={{ mt: "-3px" }}>
                                    <b>{`${Math.round(overallProgress)}%`}</b>
                                </Typography>
                            </Box> */}

                </Grid>
            </React.Fragment>
        );
    }

    function CircularProgressWithLabel(props) {
        const isComplete = (props.value === 100);
        const color = isComplete ? 'green' : (props.value > 0 ? '#5e35b1' : 'lightgrey');
        return (
            <Box position="relative" display="inline-flex">
                {isComplete ? (
                    <CheckCircleOutlineIcon sx={{ color: 'green', fontSize: 26 }} />
                ) : (
                    <React.Fragment>
                        <CircularProgress variant="determinate" {...props} size={30} sx={{ color: color }} />
                        <Box top={0} width={30} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                            <Typography variant="caption" fontSize={'10px'} color="textSecondary"><b>{`${Math.round(props.value)}`}</b></Typography>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        );
    }



    const LinearColor = ({ value }) => {
        // Calculate the width of each color segment based on the value
        const redWidth = Math.min(value, 50);
        const blueWidth = Math.max(Math.min(value - 50, 40), 0);
        const greenWidth = Math.max(Math.min(value - 90, 10), 0);

        return (
            <Box p={0}
                width="100%"
                height={3}
                borderRadius={5}
                style={{
                    background: `linear-gradient(to right, 
                #f44336 ${redWidth}%, 
                #2196f3 ${redWidth}% ${redWidth + blueWidth}%, 
                #4caf50 ${redWidth + blueWidth}% ${redWidth + blueWidth + greenWidth}%, 
                #ffffff ${redWidth + blueWidth + greenWidth}% 100%)`,
                }}
            />
        );
    };





    function Icon() {
        return (
            <div>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <div className={classes.socialIcons}>
                            <span className={`${classes.socialIconStyle} ${classes.googleStyle}`}>
                                <img src={google} alt="google" className={classes.image} />
                            </span>
                            <span className={`${classes.socialIconStyle} ${classes.facebookStyle}`}>
                                <img src={facebook} alt="facebook" className={classes.image} />
                            </span>
                            <span className={`${classes.socialIconStyle} ${classes.microsoftStyle}`}>
                                <img src={microsoft} alt="microsoft" className={classes.image} />
                            </span>
                            <span className={`${classes.socialIconStyle} ${classes.twitterStyle}`}>
                                <img src={twitter} alt="twitter" className={classes.image} />
                            </span>
                            <span className={`${classes.socialIconStyle} ${classes.linkedinStyle}`}>
                                <img src={linkedin} alt="linkedIn" className={classes.image} />
                            </span>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }

    return (
        <React.Fragment>
            {loading && (
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
                </Backdrop>
            )}
            <ThemeProvider theme={theme}>
                <LinearColor value={overallProgress} />
            </ThemeProvider>
            <Grid container spacing={0} bgcolor={'white'} borderRadius={1}>
                <Grid item xs={isPinned ? 12 : 9}>
                    {topicDetail?.length > 0 && topicDetail?.map((r) => (
                        <Grid container padding={0}>
                            <Grid item xs={8} >
                                <Breadcrumbs>
                                    <RouterLink to={`/myCourse`} color="inherit">
                                        <Button variant='text' startIcon={<HomeIcon fontSize="small" htmlColor='#5e35b1' />}
                                            sx={{ '&:hover': { textDecoration: 'underline' }, textTransform: 'none', color: '#5e35b1' }}>My Courses</Button>
                                    </RouterLink>
                                    <RouterLink to={'/myCourse'} color="inherit">
                                        <Button variant="text" startIcon={<MenuBookTwoToneIcon fontSize="small" htmlColor='#5e35b1' />}
                                            sx={{ '&:hover': { textDecoration: 'underline' }, textTransform: 'none', color: '#5e35b1' }}>{courseTitle}</Button>
                                    </RouterLink>
                                    <Typography variant='button' sx={{ '&:hover': { textDecoration: 'underline' }, textTransform: 'none', color: '#5e35b1' }}>{topicDetail[0]?.topicName}</Typography>
                                </Breadcrumbs>

                            </Grid>

                            <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
                                <Box pt={0.5}>
                                    {isPinned ?
                                        <MLearnTooltip title={isPinned ? "Unpin" : "Pin"}>
                                            <IconButton onClick={handleUnPinToggle}
                                                sx={{
                                                    backgroundColor: '#5e35b1', borderRadius: '0px',
                                                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                                                    borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', '&:hover': { backgroundColor: '#5e35b1' }
                                                }}>
                                                <KeyboardDoubleArrowLeftIcon className={classes.pinIconStyle} htmlColor='#FFFFFF' />
                                            </IconButton>
                                        </MLearnTooltip>
                                        :
                                        <MLearnTooltip title={isPinned ? "Unpin" : "Pin"}>
                                            <IconButton onClick={handlePinToggle}
                                                sx={{
                                                    backgroundColor: '#5e35b1', borderRadius: '0px',
                                                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                                                    borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', '&:hover': { backgroundColor: '#5e35b1' }
                                                }}>
                                                <KeyboardDoubleArrowRightIcon className={classes.pinIconStyle} htmlColor='#FFFFFF' />
                                            </IconButton>
                                        </MLearnTooltip>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} padding={0} >
                                <Grid container spacing={0} >
                                    {value === 0 && (
                                        <React.Fragment>
                                            <Grid item xs={12} ml={1}>
                                                {topicDetail?.length > 0 && topicDetail?.map((r) => (
                                                    <React.Fragment>
                                                        {r?.topicVideoURL ? (
                                                            <Box m={0} display={'flex'} flexDirection={'row'}>
                                                                <Box m={0} width={'95%'}>
                                                                    <ReactPlayer
                                                                        url={r.topicVideoURL}
                                                                        controls={true}
                                                                        showRelatedVideos={false}
                                                                        allowFullScreen
                                                                        width='100%'
                                                                        height={"calc(100vh - 148px)"}
                                                                        volume={0.3}
                                                                        playing={isVideoStop}
                                                                        progressInterval={1000}
                                                                        playbackRate={1}
                                                                        onProgress={handleProgress}
                                                                        onEnded={handleEnded}
                                                                        onPlay={() => setIsVideoPlaying(true)}
                                                                    />
                                                                    <LinearProgress variant="determinate" value={frameProgress} className={classes.VideoProgress} color={videoEnded ? 'success' : 'primary'} />
                                                                </Box>
                                                                <Box width={'5%'} m={0} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                    <MLearnTooltip title="Mark as Completed" arrow>
                                                                    <IconButton
      onClick={handleProgressTracking}
      sx={{ backgroundColor: 'green', '&:hover': { backgroundColor: 'green' }, padding: '5px' }}
      disabled={isProcessing}
    >
      {completed ? <CheckCircleIcon fontSize="small" htmlColor="#FFFFFF" /> : <KeyboardDoubleArrowRightIcon fontSize="small" htmlColor="#FFFFFF" />}
    </IconButton>
                                                                    </MLearnTooltip>
                                                                </Box>
                                                            </Box>
                                                        ) : (
                                                            <Box m={0} display={'flex'} flexDirection={'row'}>
                                                                <Box m={0} width="95%">
                                                                    <img src={noVideo} alt="Video is not there" style={{ width: '95%', height: "calc(100vh - 253px)" }} />
                                                                </Box>
                                                                <Box width={'5%'} m={0} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                    <MLearnTooltip title="Mark as Completed" arrow>
                                                                        <IconButton onClick={handleProgressTracking} sx={{ backgroundColor: 'green', '&:hover': { backgroundColor: 'green' }, padding: '5px' }}>
                                                                            {completed ? <CheckCircleIcon fontSize="small" htmlColor="#FFFFFF" /> : <KeyboardDoubleArrowRightIcon fontSize="small" htmlColor="#FFFFFF" />}
                                                                        </IconButton>
                                                                    </MLearnTooltip>
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                    {value === 1 && (
                                        <Grid item xs={12} ml={1} mt={1} sx={{ minHeight: 'calc(100vh - 142px)' }}>
                                            <Typography variant='h6' fontWeight={600} color={'#5e35b1'} >
                                                <b>{topicDetail[0]?.topicName}</b>
                                            </Typography>
                                            <Typography variant='subtitle1' mt={1} mr={1} textAlign={'justify'}>
                                                {r?.topicDescrition}
                                            </Typography>
                                            <Typography variant='subtitle1' mt={1} mr={1} textAlign={'justify'}>
                                                {r?.topicObjective}
                                            </Typography>
                                        </Grid>
                                    )}
                                    {value === 2 && (
                                        <Grid item xs={12} ml={1}  sx={{ minHeight: 'calc(100vh - 135px)' }} >
                                            <DiscussionUI/>
                                        </Grid>
                                    )}
                                    {value === 3 && (
                                        <Grid item xs={12} ml={1} mt={1} sx={{ minHeight: 'calc(100vh - 142px)' }}>
                                            <UserCourseAssessment
                                                courseId={courseId}
                                                courseTitle={courseTitle}
                                                topicId={selectedTopic}
                                                topicName={topicDetail[0]?.topicName}
                                                subTopicId={selectedSubTopic}
                                                subTopicName={subTopicDetail[0]?.courseSubTopicName}
                                            />
                                        </Grid>
                                    )}
                                    {value === 4 && (
                                        <Grid item xs={12} ml={1} mt={1} sx={{ minHeight: 'calc(100vh - 190px)' }}>
                                            <LabView />
                                        </Grid>
                                    )}
                                    {value === 5 && (
                                        <Grid item xs={12} ml={1} mt={1} sx={{ minHeight: 'calc(100vh - 142px)' }} color={'lightgray'}>
                                            <b>Related Courses, Reference and Links...</b>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} borderTop={'1px solid lightgray'} display={'flex'}>
                                <Grid item xs={9}>
                                    <Tabs
                                        value={value}
                                        onChange={handleTabChange}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="Horizontal tabs example"
                                        sx={{
                                            minHeight: '40px',
                                            '& .MuiTabs-indicator': {
                                                top: 0,
                                                bottom: 'auto',
                                                height: '3px',
                                                backgroundColor: '#5e35b1'
                                            },
                                            '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
                                                color: '#5e35b1',
                                            },
                                        }}
                                    >
                                        <Tab label="Tutorial" className={classes.courseTabRecords} />
                                        <Tab label="Description" className={classes.courseTabRecords} />
                                        <Tab label="Discussion" className={classes.courseTabRecords} />
                                        <Tab label="Assessment" className={classes.courseTabRecords} />
                                        <Tab label="Lab" className={classes.courseTabRecords} />
                                        <Tab label="Related Courses" className={classes.courseTabRecords} />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={3} display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
                                    <ThumbUpIcon fontSize="small" htmlColor="gray" />
                                    <ThumbDownIcon fontSize="small" htmlColor="gray" />
                                    <DownloadCourse />
                                    <CommentSharpIcon fontSize="small" htmlColor="gray" />
                                    <ShareSharpIcon fontSize="small" htmlColor="gray" />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                {!isPinned ?
                    <Grid item xs={3} boxShadow={'-3px 0px 3px rgba(0, 0, 0, 0.16)'}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} padding={1} borderBottom={'1px solid lightgray'}>
                                <Typography variant='h6' textAlign={'center'} mt={"4px"}>Course Content</Typography>
                                {isFeedbackCompleted && <UserFeedbackDetails courseId={courseId} />}
                            </Grid>
                            <Grid item xs={12} padding={0} borderBottom={'1px solid lightgray'}>
                                <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.coureViewScrolbar}>
                                    <List sx={{ padding: 0 }}>
                                        {data?.map((node) => {
                                            if (node.nestedItems && node.nestedItems.length > 0) {
                                                // Topic has subtopics, calculate progress based on subtopics
                                                const completedSubtopics = node.nestedItems?.filter(nestedItem => {
                                                    const completionStatus = courseTopicProgress?.find(subtopic => subtopic?.courseSubTopicId === nestedItem?.courseSubTopicId);
                                                    // Check if assignment is required for the subtopic
                                                    if (nestedItem?.isAssignmentRequired) {
                                                        // Both video and assignment completion required
                                                        return completionStatus && completionStatus?.isTopicCompleted && completionStatus?.isAssignmentCompleted;
                                                    } else {
                                                        // Only video completion required
                                                        return completionStatus && completionStatus?.isTopicCompleted;
                                                    }
                                                })?.length;
                                                const totalSubtopics = node?.nestedItems?.length;
                                                const topicProgressPercentage = totalSubtopics === 0 ? 0 : (completedSubtopics / totalSubtopics) * 100;
                                                return (
                                                    <React.Fragment key={node?.courseTopicId}>
                                                        < ListItem className={node.isActive ? classes.listButtonActive : classes.listButtonInActive} sx={{ backgroundColor: 'lightcoral' }}>
                                                            <Grid container display={'flex'} alignItems={'center'} p={'3px 0px 3px 0px'}
                                                                onClick={() => handleSelectedTopic(node?.courseTopicName, node?.courseTopicId, 0, null)}>
                                                                <Grid item xs={12} display={'flex'}>
                                                                    <Grid item xs={2} display={'flex'} alignItems={'center'} >
                                                                        <CircularProgressWithLabel value={topicProgressPercentage} /* htmlColor='#5e35b1' */ />
                                                                    </Grid>
                                                                    <Grid item xs={9} display={'flex'}>
                                                                        <Typography variant='body2' fontSize={14} ml={1} mt={"3px"} fontWeight={node?.isActive ? 800 : 600}>
                                                                            {node.courseTopicName.length > 35 ? `${node.courseTopicName.slice(0, 35)}...` : node.courseTopicName}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {/* Future Use */}
                                                                    {/*  <Grid item xs={1} display={'flex'}>
                                                                        <Typography fontSize='10px'>
                                                                            <b>{calculateTotalTime(node?.nestedItems)}</b>
                                                                        </Typography>
                                                                    </Grid> */}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={1} className={classes.listItemIcon} onClick={() => handleToggleExpand(node?.courseTopicId)} >
                                                                {node?.nestedItems.length > 0 ? (
                                                                    node?.isExpanded ? (
                                                                        <ExpandLessIcon fontSize="small" />
                                                                    ) : (
                                                                        <ExpandMoreIcon fontSize="small" />
                                                                    )
                                                                ) : null}
                                                            </Grid>
                                                        </ListItem>
                                                        <Collapse in={node.isExpanded} timeout="auto" unmountOnExit>
                                                            <List disablePadding sx={{ padding: '0px 0px 0px 20px' }}>
                                                                {node?.nestedItems?.map((nestedItem) => {
                                                                    const subtopicProgress = courseTopicProgress?.find(subtopic => subtopic?.courseSubTopicId === nestedItem?.courseSubTopicId);
                                                                    let isSubtopicCompleted = false;
                                                                    if (nestedItem?.isAssignmentRequired) {
                                                                        isSubtopicCompleted = subtopicProgress ? subtopicProgress?.isTopicCompleted && subtopicProgress?.isAssignmentCompleted : false;
                                                                    } else {
                                                                        isSubtopicCompleted = subtopicProgress ? subtopicProgress?.isTopicCompleted : false;
                                                                    }
                                                                    return (
                                                                        <React.Fragment>
                                                                            <Grid container spacing={0} ml={0} mr={"14px"} key={nestedItem.courseSubTopicId} onClick={() => handleSelectedTopic(nestedItem?.courseSubTopicName, node?.courseTopicId, nestedItem?.courseSubTopicId, nestedItem?.stipulatedTime)} sx={{ borderLeft: nestedItem?.isSubMenuActive ? '3px solid #5e35b1' : '1px solid lightgray', padding: '3px 30px 3px 15px' }} >
                                                                                <Grid item xs={1} display={'flex'} alignItems={'center'}>
                                                                                    <IconButton size='small' sx={{ marginBottom: '10px' }}>
                                                                                        {/* <CheckCircleIcon color="success" className={classes.iconFontSize} /> */}
                                                                                        {isSubtopicCompleted ? <CheckCircleIcon color="success" className={classes.iconFontSize} /> : <VideocamIcon htmlColor='#5e35b1' className={classes.iconFontSize} />}
                                                                                    </IconButton>
                                                                                </Grid>
                                                                                {/* Future Use */}
                                                                                {/* <Grid item xs={1} >
                                                                                    <Box m={0} width={nestedItem?.isSubMenuActive ? '0.4rem' : '0.3rem'} ml={nestedItem?.isSubMenuActive ? 1 : 1} mt={1}
                                                                                        height={nestedItem?.isSubMenuActive ? '0.4rem' : '0.3rem'}
                                                                                        bgcolor={nestedItem?.isSubMenuActive ? '#5e35b1' : 'lightgrey'} borderRadius={5} >
                                                                                    </Box>
                                                                                </Grid> */}
                                                                                <Grid item xs={10} >
                                                                                    <Typography variant='body2' fontSize={14} ml={"5px"} sx={{ cursor: 'pointer' }} color={nestedItem?.isSubMenuActive ? '#5e35b1' : 'gray'} fontWeight={nestedItem?.isSubMenuActive ? 600 : 400}>
                                                                                        {nestedItem.courseSubTopicName.length > 28 ? `${nestedItem.courseSubTopicName.slice(0, 28)}...` :
                                                                                            nestedItem?.courseSubTopicName}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                {/* Future Use */}
                                                                                {/* <Grid item xs={2} >
                                                                                    <Typography fontSize='10px' >
                                                                                        <b>{formatTimeOutput(nestedItem?.stipulatedTime)}</b>
                                                                                    </Typography>
                                                                                </Grid> */}
                                                                            </Grid>
                                                                        </React.Fragment >
                                                                    );
                                                                })}
                                                            </List>
                                                        </Collapse >
                                                    </React.Fragment >
                                                );
                                            } else {
                                                const topiccompletionStatus = courseTopicProgress?.find(topic => topic?.courseTopicId === node?.courseTopicId);
                                                let progressPercentage = 0;
                                                if (node?.isAssignmentRequired) {
                                                    // Both video and assignment completion required
                                                    progressPercentage = (topiccompletionStatus?.isVideoCompleted && topiccompletionStatus?.isAssignmentCompleted) ? 100 : 0;
                                                } else {
                                                    // Only video completion required
                                                    progressPercentage = topiccompletionStatus?.isVideoCompleted ? 100 : 0;
                                                }
                                                return (
                                                    <React.Fragment key={node?.courseTopicId}>
                                                        <ListItem className={node.isActive ? classes.listButtonActive : classes.listButtonInActive}>
                                                            <Grid container display={'flex'} alignItems={'center'} p={'3px 0px 3px 0px'}
                                                                onClick={() => handleSelectedTopic(node?.courseTopicName, node?.courseTopicId, 0, null)}>
                                                                <Grid item xs={12} display={'flex'} alignItems={'center'}>
                                                                    <Grid item xs={2} display={'flex'} >
                                                                        <CircularProgressWithLabel value={progressPercentage} color="secondary" />
                                                                    </Grid>
                                                                    <Grid item xs={10} display={'flex'}>
                                                                        <Typography variant='body2' ml={1} fontWeight={node?.isActive ? 600 : 400}>
                                                                            {node?.courseTopicName?.length > 35 ? `${node?.courseTopicName.slice(0, 35)}...` : node?.courseTopicName}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {/* Future Use */}
                                                                    {/* <Grid item xs={2} display={'flex'}>
                                                                        <Typography fontSize='10px'>
                                                                            <b>{calculateTotalTime(node?.nestedItems)}</b>
                                                                        </Typography>
                                                                    </Grid> */}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={1} className={classes.listItemIcon} onClick={() => handleToggleExpand(node?.courseTopicId)} >
                                                                {node?.nestedItems.length > 0 ? (
                                                                    node?.isExpanded ? (
                                                                        <ExpandLessIcon fontSize="small" />
                                                                    ) : (
                                                                        <ExpandMoreIcon fontSize="small" />
                                                                    )
                                                                ) : null}
                                                            </Grid>
                                                        </ListItem>
                                                        <Collapse in={node.isExpanded} timeout="auto" unmountOnExit>
                                                            <List disablePadding sx={{ padding: '0px 0px 0px 20px' }}>
                                                                {node?.nestedItems?.map((nestedItem) => {
                                                                    const subtopicProgress = courseTopicProgress?.find(subtopic => subtopic?.courseSubTopicId === nestedItem?.courseSubTopicId);
                                                                    let isSubtopicCompleted = false;
                                                                    if (nestedItem?.isAssignmentRequired) {
                                                                        isSubtopicCompleted = subtopicProgress ? subtopicProgress?.isTopicCompleted && subtopicProgress?.isAssignmentCompleted : false;
                                                                    } else {
                                                                        isSubtopicCompleted = subtopicProgress ? subtopicProgress?.isTopicCompleted : false;
                                                                    }
                                                                    return (
                                                                        <React.Fragment>
                                                                            <Grid container spacing={0} ml={0} mr={"14px"} onClick={() => handleSelectedTopic(nestedItem?.courseSubTopicName, node?.courseTopicId, nestedItem?.courseSubTopicId, nestedItem?.stipulatedTime)} sx={{ borderLeft: nestedItem?.isSubMenuActive ? '3px solid #5e35b1' : '1px solid lightgray', padding: '3px 30px 3px 15px' }} >
                                                                                <Grid item xs={1} display={'flex'} alignItems={'center'}>
                                                                                    <IconButton size='small' sx={{ marginBottom: '10px' }}>
                                                                                        {isSubtopicCompleted ? <CheckCircleIcon color="success" className={classes.iconFontSize} /> : <VideocamIcon color="secondary" className={classes.iconFontSize} />}
                                                                                    </IconButton>
                                                                                </Grid>
                                                                                {/* Future Use */}
                                                                                {/* <Grid item xs={1} >
                                                                                    <Box m={0} width={nestedItem?.isSubMenuActive ? '0.4rem' : '0.3rem'} ml={nestedItem?.isSubMenuActive ? 1 : 1} mt={1}
                                                                                        height={nestedItem?.isSubMenuActive ? '0.4rem' : '0.3rem'}
                                                                                        bgcolor={nestedItem?.isSubMenuActive ? '#5e35b1' : 'lightgrey'} borderRadius={5} >
                                                                                    </Box>
                                                                                </Grid> */}
                                                                                <Grid item xs={10} >
                                                                                    <Typography variant='body2' fontSize={14} ml={0} sx={{ cursor: 'pointer' }} color={nestedItem?.isSubMenuActive ? '#5e35b1' : null} fontWeight={nestedItem?.isSubMenuActive ? 400 : 400}>
                                                                                        {nestedItem?.courseSubTopicName?.length > 28 ? `${nestedItem?.courseSubTopicName.slice(0, 28)}...` :
                                                                                            nestedItem?.courseSubTopicName}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                {/* <Grid item xs={2} >
                                                                                    <Typography fontSize='10px' >
                                                                                        <b>{formatTimeOutput(nestedItem?.stipulatedTime)}</b>
                                                                                    </Typography>
                                                                                </Grid> */}
                                                                            </Grid>
                                                                        </React.Fragment >
                                                                    );
                                                                })}
                                                            </List>
                                                        </Collapse >
                                                    </React.Fragment >
                                                );
                                            }
                                        })}
                                    </List >
                                </Scrollbars>
                            </Grid>
                            <Grid item xs={12} >
                                <Button
                                    variant="text"
                                    startIcon={<VerifiedUserIcon color='secondary' fontSize='small' />}
                                    endIcon={!isCertificateButtonEnabled && <LockIcon fontSize='small' style={{ color: "gray" }} />}
                                    className={classes.certificateButtonColor}
                                    onClick={handleCompleteCourse}
                                    disabled={!isCertificateButtonEnabled}
                                    fullWidth
                                >
                                    Claim your certificate
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    : null
                }
            </Grid>
        </React.Fragment>
    );
}

export default CourseView