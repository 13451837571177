/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-ComponentNo react-hooks/rules-of-hooks */
import React from "react";
import { SvgIconComponent } from "@mui/icons-material";
import AutoStoriesTwoToneIcon from "@mui/icons-material/AutoStoriesTwoTone";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FeedbackTwoToneIcon from "@mui/icons-material/FeedbackTwoTone";
import NotificationsNoneTwoToneIcon from "@mui/icons-material/NotificationsNoneTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import PermPhoneMsgTwoToneIcon from "@mui/icons-material/PermPhoneMsgTwoTone";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import ScienceTwoToneIcon from "@mui/icons-material/ScienceTwoTone";
import Dashboard from "./components/Dashboard/Dashboard";
import ClaimCertificate from "./components/ManageCourse/CertificateDetails/ClaimCertificate";
import UserCertificate from "./components/ManageCourse/CertificateDetails/UserCertificcate";
import Course from "./components/ManageCourse/CourseDetails";
import LabActivity from "./components/ManageLab/LabActivity";
import EducationalInformation from "./components/NavbarSection/Header/Profile/EducationalInformation";
import Experience from "./components/NavbarSection/Header/Profile/Experience";
import PersonalInformation from "./components/NavbarSection/Header/Profile/PersonalInformation";
import PreviewCV from "./components/NavbarSection/Header/Profile/PreviewCV";
import ProfileAccount from "./components/NavbarSection/Header/Profile/ProfileAccount";
import Project from "./components/NavbarSection/Header/Profile/Project";
import Skills from "./components/NavbarSection/Header/Profile/Skills";
import Manager from "./components/Organization/Manager";
import UserFeedbackDetails from "./components/User Feedback/UserFeedback";
import AssessmentReview from "./components/UserAssessment/Review";
import UserMultipleChoice from "./components/UserAssessment/UserMultipleChoice";
import CourseCompleted from "./components/UserCourseView/CourseCompleted";
import CourseView from "./components/UserCourseView/CourseView";
import UserCourseLayout from "./components/UserCourseView/UserCourseLayout";
import SubcribedUser from "./components/UsersDetails/SubcribedUser";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/ContactUs";
import FeedbackDetails from "./pages/FeedbackDetails";
import ManageNotification from "./pages/Managenotification";
export interface IRoute {
  path: string;
  title: string;
  component: React.ComponentType;
  groupName?: string;
  icon?: SvgIconComponent;
  noNavLink?: boolean;
  ComponentNo: number;
}

const AdminRoute: IRoute[] = [
  /* DASHBOARD */
  {
    path: "/dashboard",
    title: "Dashboard",
    component: Dashboard,
    groupName: "DASHBOARD",
    icon: DashboardIcon,
    noNavLink: true,
    ComponentNo: 1,
  },
  /* MANAGEMENT */
  {
    path: "/course",
    title: "Manage Course",
    component: Course,
    groupName: "MANAGEMENT",
    icon: AutoStoriesTwoToneIcon,
    noNavLink: true,
    ComponentNo: 2,
  },
  {
    path: "/Lab",
    title: "Lab Activity",
    component: LabActivity,
    groupName: "MANAGEMENT",
    icon: ScienceTwoToneIcon,
    noNavLink: true,
    ComponentNo: 17,
  },
  {
    path: "/Manager",
    title: "Manager",
    component: Manager,
    groupName: "MANAGEMENT",
    icon: BusinessIcon,
    noNavLink: true,
    ComponentNo: 3,
  },
  {
    path: "/user",
    title: "User List",
    component: SubcribedUser,
    groupName: "MANAGEMENT",
    icon: PeopleAltTwoToneIcon,
    noNavLink: true,
    ComponentNo: 4,
  },

  /* MY COURSE */
  // {
  //   path: "/ai204",
  //   title: "AI-204",
  //   component: CurrentCourse,
  //   groupName: "MY COURSE",
  //   icon: AssignmentTwoToneIcon,
  //   noNavLink: true,
  // },

  /* QUERY DETAILS */
  {
    path: "/feedback",
    title: "Manage Feedback",
    component: FeedbackDetails,
    groupName: "QUERY DETAILS",
    icon: FeedbackTwoToneIcon,
    noNavLink: true,
    ComponentNo: 7,
  },
  {
    path: "/userfeedback",
    title: "UserFeedbackDetails",
    component: UserFeedbackDetails,
    noNavLink: true,
    ComponentNo: 7,
  },
  {
    path: "/manage notification",
    title: "Manage Notification",
    component: ManageNotification,
    groupName: "QUERY DETAILS",
    icon: NotificationsNoneTwoToneIcon,
    noNavLink: true,
    ComponentNo: 8,
  },

  {
    path: "/about us",
    title: "About Us",
    component: AboutUs,
    groupName: "QUERY DETAILS",
    icon: PersonOutlineTwoToneIcon,
    noNavLink: true,
    ComponentNo: 5,
  },
  {
    path: "/contact us",
    title: "Contact Us",
    component: Contact,
    groupName: "QUERY DETAILS",
    icon: PermPhoneMsgTwoToneIcon,
    noNavLink: true,
    ComponentNo: 6,
  },
 
  /* User View */
  {
    path: "/myCourse",
    title: "My Course",
    component: UserCourseLayout,
    noNavLink: false,
    ComponentNo: 9,
  },
  {
    path: "/profileAccount",
    title: "Profile Account",
    component: ProfileAccount,
    noNavLink: false,
    ComponentNo: 10,
  },
  {
    path: "/courseView/:courseId/:courseTitle",
    title: "Course View",
    component: CourseView,
    noNavLink: false,
    ComponentNo: 11,
  },
  {
    path: "/claimCertificate",
    title: "Claim Certificate",
    component: ClaimCertificate,
    noNavLink: false,
    ComponentNo: 12,
  },
  {
    path: "/Certificate",
    title: "User Certificate",
    component: UserCertificate,
    noNavLink: false,
    ComponentNo: 12,
  },
  //user Assessment
  {
    path: "/multichoice",
    title: "Multichoice",
    component: UserMultipleChoice,
    noNavLink: false,
    ComponentNo: 14,
  },
  //Assessment Review
  {
    path: "/review",
    title: "Assessment Review",
    component: AssessmentReview,
    noNavLink: false,
    ComponentNo: 15,
  },
  //Complete Course
  {
    path: "/completecourse",
    title: "Complete Course",
    component: CourseCompleted,
    noNavLink: false,
    ComponentNo: 16,
  },
  {
    path: "/PersonalInformation",
    title: "Personal Information",
    component: PersonalInformation,
    noNavLink: false,
    ComponentNo: 16,
  },
  {
    path: "/EducationalInformation",
    title: "Education information",
    component: EducationalInformation,
    noNavLink: false,
    ComponentNo: 16,
  },
  {
    path: "/Skills",
    title: "Skills",
    component: Skills,
    noNavLink: false,
    ComponentNo: 16,
  },
  {
    path: "/Experience",
    title: "Experience",
    component: Experience,
    noNavLink: false,
    ComponentNo: 16,
  },
  {
    path: "/Project",
    title: "Project",
    component: Project,
    noNavLink: false,
    ComponentNo: 16,
  },
  {
    path: "/PreviewCV",
    title: "PreviewCV",
    component: PreviewCV,
    noNavLink: false,
    ComponentNo: 16,
  },
];

export default AdminRoute;
