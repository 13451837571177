/* eslint-disable react-hooks/rules-of-hooks */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";
import {
    Autocomplete, Button, Dialog, DialogActions, Grid,
    IconButton, TextField, Typography,
} from '@mui/material';
import { useState } from 'react';
import { Transition } from '../../../GlobalStyles/Transition';
import { characters, couponLength } from "../../../constant/Constant";
import { Months, Percent } from "../../../model/Coupon";
import { ICoupon } from '../../../model/course';
import { usePost } from '../../../utils/apiHelper';



function ManageCoupon({ coupon, courseId, Code, GetCoupon }) {
    const [open, setOpen] = useState(false);
    const initial: ICoupon = {
        couponId: 0,
        courseId: courseId,
        amount: 0,
        discountPercentage: 0,
        gstPercentage: null,
        couponCode: "",
        validityDays: 0,
        actualAmount: 32,
        isActive: null,
        createdBy: 0,
        modifiedBy: 0,
        createdDate: null,
        modifiedDate: null,
    }

    const [formData, setFormData] = useState<ICoupon>(coupon);



    const handleAmountChange = (event) => {
        const enteredAmount = parseFloat(event.target.value);
        const gstPercentage = (enteredAmount * formData.gstPercentage) / 100;

        setFormData({
            ...formData,
            amount: enteredAmount,
            gstPercentage,
            actualAmount: enteredAmount + gstPercentage,
        });
    };

    const handlePercentageChange = (event, value) => {

        const gstValue = (formData.amount * value) / 100;
        setFormData({
            ...formData,
            gstPercentage: gstValue,
            actualAmount: formData.amount + gstValue,
        });

    };


    const handleChangeValidity = (event, value) => {
        setFormData({
            ...formData,
            validityDays: value,
        });
    };

    const handleChangeDiscout = (event) => {
        setFormData({
            ...formData,
            discountPercentage: event.target.value,
        });
    };

    const handleGenerateCoupon = () => {
        const generatedCoupon = generateCoupon();
        setFormData({
            ...formData,
            couponCode: generatedCoupon,
        });
    };

    const generateCoupon = () => {

        let generatedCoupon = '';

        for (let i = 0; i < couponLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            generatedCoupon += characters.charAt(randomIndex);
        }

        return generatedCoupon;
    };

    const handleSave = () => {
        usePost("Course/ManageCourseCoupon", formData).finally(() => handleClose());
        setFormData(initial);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        GetCoupon();
    };


    return (
        <Grid>
            {coupon.couponId ? (
                <IconButton onClick={handleClickOpen} size='small' color="success">
                    <ModeTwoToneIcon fontSize='small' />
                </IconButton>
            ) : (
                <Button
                    variant='contained'
                    size='small'
                    onClick={handleClickOpen}
                    startIcon={<AddCircleIcon />}
                >
                    <b>Add Coupon</b>
                </Button>
            )}

            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} TransitionComponent={Transition} >
                <Grid container spacing={1} p={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" align="center">
                            <b> Manage Coupon</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2" align='left'><b>Course Code</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField fullWidth name='courseCode' size='small' value={Code} disabled />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2" align='left'><b>Amount</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            type='number'
                            size='small'
                            value={formData?.amount}
                            onChange={handleAmountChange} // Update the Amount and calculate GST
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="subtitle2" align='left'><b>GST Percentage</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            size='small'
                            fullWidth
                            id="combo-box-demo"
                            options={Percent}
                            onChange={(e, value) => handlePercentageChange(e, value)}
                            renderInput={(params) => <TextField {...params} fullWidth size='small' label="Choose the Percent Amount " />}
                            value={formData?.gstPercentage}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="subtitle2" align='left'><b>Discount Percentage</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField fullWidth size='small' value={formData?.discountPercentage} onChange={handleChangeDiscout} />
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'left' }}>
                        <Button size='small' variant='contained' onClick={handleGenerateCoupon}>
                            Generate Coupon
                        </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField fullWidth size='small' value={formData?.couponCode} disabled />

                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2" align='left'><b>Validity Plan</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            size='small'
                            fullWidth
                            id="combo-box-demo"
                            options={Months}
                            onChange={(e, value) => handleChangeValidity(e, value)}
                            renderInput={(params) => <TextField {...params} fullWidth size='small' label="Choose the Plan" />}
                            value={formData?.validityDays}
                        />

                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2" align='left'><b>Acutal cost</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField fullWidth size='small' value={formData?.actualAmount} disabled />
                    </Grid>

                </Grid>
                <DialogActions>
                    <Grid item xs={12} style={{ textAlign: 'center' }} paddingBottom={1}>
                        <Button size='small' variant='contained' onClick={handleClose}>
                            cancel
                        </Button>  <Button size='small' variant='contained' onClick={handleSave}>
                            Save
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default ManageCoupon;
