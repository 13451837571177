import { makeStyles } from "@material-ui/core";

const useColorlibStepIconStyles = makeStyles({
    stepperRoot: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 35,
      height: 35,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      backgroundColor: "#5e35b1",
    },
    completed: {
      backgroundColor: "#5e35b1",
    },
    pending: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(255,255,255,1) 0%, rgb(217,40,40,1) 35%, rgb(255,255,255,1) 100%)",
    },
  });

  export default useColorlibStepIconStyles;