import { makeStyles } from '@material-ui/core';
import AssistantTwoToneIcon from '@mui/icons-material/AssistantTwoTone';
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';
import RobotIcon from '@mui/icons-material/SmartToy';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import ThumbUpAltTwoToneIcon from '@mui/icons-material/ThumbUpAltTwoTone';
import TipsAndUpdatesTwoToneIcon from '@mui/icons-material/TipsAndUpdatesTwoTone';
import { Avatar, Box, Divider, Grid, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import image from "../../asset/image/Login/Logo/first.png";
const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        padding: theme.spacing(10),
    },
    logo: {
        maxWidth: 200,
    },
    cardsContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    card: {
        background: "#d9d9ed",//#f9f9f9
        color: "#5e35b1",
        borderRadius: 8,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        padding: theme.spacing(2),
        width: 200,
    },
    Chatcontainer: {
        bordeRadius: '10px',
        padding: '20px',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 300px)',
            minHeight: 'calc( 100vh - 300px)',
        }
    },
}));
export default function DiscussionUI() {
    const classes = useStyles();
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [sendButton, setSendButton] = useState(false);
    useEffect(() => {
        if (message.trim() !== '') {
            setSendButton(true);
        }
        else {
            setSendButton(false);
        }
    }, [message])
    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSendMessage = () => {
        if (message.trim() !== '') {
            const answer = getAnswer(message.trim());
            setMessages([...messages, { text: message, sender: 'User' }, { text: answer, sender: 'Merry-Learn-Bot' }]);
            setMessage('');
        }
    };

    const getAnswer = (question) => {
        const answers = {
            'How are you?': 'I am doing well, thank you!',
            'What is your name?': 'My name is Merry-Learn-ChatBot.',
            'what is react native': "React Native is an open-source mobile application framework created by Facebook. It allows developers to build mobile applications using JavaScript and React. Here are some key points about React Native:",
            'how to create a react app': "1. Install Node.js from [here](https://nodejs.org/).Open Terminal or Command Prompt.Run `npx create-react-app my-app` to create a new React app (replace `my-app` with your app's name).Move into the new app directory with `cd my-app`.Start the development server with `npm start`.Edit your app's code in the `src` directory.When ready to deploy, run `npm run build` to create a production build."
        };
        return answers[question] || 'I am sorry, I do not understand that question.';
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {/* <Typography variant="h6" gutterBottom>
                    Discussion
                </Typography> */}
                <Divider />
                <Grid sx={{ minHeight: 'calc(100vh - 185px)', padding: '10px' }}>
                    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.Chatcontainer}>
                        {messages.length > 0 ? messages.map((msg, index) => (
                            <React.Fragment key={index}>
                                {index === 0 && (
                                    <Grid container spacing={0} justifyContent="center">
                                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                            <Typography variant="subtitle2" color="textPrimary">
                                                {new Date().toLocaleString()}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                <ListItem key={index} sx={{ padding: '5px', marginBottom: '5px', borderRadius: '8px', backgroundColor: 'transparent' }}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            {msg.sender === 'User' ? <PersonIcon color='primary' /> : <RobotIcon color='secondary' />}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="subtitle2" component="span" color="textPrimary">
                                                {msg.sender}
                                            </Typography>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Box
                                                    sx={{
                                                        backgroundColor: msg.sender === 'User' ? '#e3f2fd' : '#b9f6ca',
                                                        border: msg.sender === 'User' ? '1px solid #90caf9' : '1px solid #00c853',
                                                        borderRadius: '8px',
                                                        padding: '5px',
                                                        marginTop: '4px',
                                                        display: 'inline-block',
                                                        maxWidth: '80%', // Adjust this value to control the maximum width
                                                        wordBreak: 'break-word'
                                                    }}
                                                >
                                                    <Typography variant="body1" component="span" color="textPrimary">
                                                        {msg.text}
                                                    </Typography>
                                                </Box>
                                                {msg.sender === 'Merry-Learn-Bot' && (
                                                    <Box sx={{ marginTop: '5px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                        <IconButton aria-label="like">
                                                            <ThumbUpAltTwoToneIcon />
                                                        </IconButton>
                                                        <IconButton aria-label="feedback">
                                                            <AssistantTwoToneIcon />
                                                        </IconButton>
                                                        <IconButton aria-label="share">
                                                            <ShareTwoToneIcon />
                                                        </IconButton>
                                                    </Box>
                                                )}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem></React.Fragment>
                        )) : <Grid>

                            <Grid container className={classes.container}>
                                <Grid item xs={12}>
                                    <Typography variant='h6'>Welcome to Merry Learn Discussion Portal</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <img src={image} alt="Logo" className={classes.logo} />
                                </Grid>
                                <Grid item xs={12} className={classes.cardsContainer}>
                                    <div className={classes.card}>
                                        <Typography variant="h6"><TipsAndUpdatesTwoToneIcon htmlColor='Yellow' fontSize='large' />Ask Questions</Typography>
                                        <Typography variant="body2">Get answers to your queries instantly.</Typography>
                                    </div>
                                    <div className={classes.card}>
                                        <Typography variant="h6"><HistoryEduTwoToneIcon fontSize='large' color='success' />Write a Story</Typography>
                                        <Typography variant="body2">Create a story in your favorite genre.</Typography>
                                    </div>
                                    <div className={classes.card}>
                                        <Typography variant="h6"><SmartToyTwoToneIcon fontSize='large' color='primary' />Like Our Chatbot</Typography>
                                        <Typography variant="body2">Experience the best of AI assistance.</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Scrollbars>
                </Grid>
                <Grid container spacing={2} alignItems="right">
                    <Grid item xs={12} >
                        <TextField
                            fullWidth
                           sx={{ width:"90%"}}
                            label="Type your message"
                            variant="outlined"
                            size="small"
                            value={message}
                            onChange={handleMessageChange}
                            onKeyPress={handleKeyPress}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleSendMessage} disabled={!sendButton} edge="end">
                                            <SendIcon color={sendButton ? 'primary' : 'inherit'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
