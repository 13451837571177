/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Backdrop, Box, Checkbox, Grid, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { MoonLoader } from 'react-spinners';
import { useGlobalState } from '../../GlobalStore/globalStore';
import { SubscribedHeadCells } from '../../_mock/Users/Users';
import { searchTermRegex } from '../../constant/Constant';
import { IUser } from '../../model/Users/IUser';
import { usePost } from '../../utils/apiHelper';
import AddUser from './AddUser';
import AssignUserDialog from './AssignUserDialog';
import { ManagerUserPermission } from './ManageUserPermission';
import useStyles from './UserCss';

const SubcribedUser = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [TotalCount, setTotalCount] = useState<any>();
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [searchDebtor, setSearchDebtor] = useState("");
    const [searchText, setSearchText] = useState<any>(null);
    const [tableData, setTableData] = useState<IUser[]>([]);
    const [startingRecordNumber, setStartingRecordNumber] = useState<number>(1);
    const [orderBy, setOrderBy] = useState('FirstName');
    const [sortOrder, setSortOrder] = useState({ column: 'FirstName', order: 'desc' });
    const [loading, setLoading] = useState(true);
    const [textBox, setTextBox] = useState(false);



    const { state } = useGlobalState();
    const handleSort = (column) => {
        setSortOrder({
            column,
            order: sortOrder.column === column && sortOrder.order === 'asc' ? 'desc' : 'asc',
        });
    };

    const isSortedColumn = (column) => {
        return column === sortOrder.column;
    };
    const GetSubscribedUser = () => {
        setLoading(true);

        (async () => {
            let request = {
                "searchText": searchText,
                "orderBy": sortOrder.column,
                "rowsPerPage": rowsPerPage,
                "recordNumber": page * rowsPerPage,
                "orderDirection": sortOrder.order
            };
            await usePost<{ usersResult: IUser[]; totalUsers: number }>("Users/GetUsersDetails", request).then((response) => {
                if (state.userAccessContext.userRole) {
                    let filterdata=response.data.usersResult.filter(user => user.userId !== state.userAccessContext.userId);
                    setTableData(filterdata);
                    setTotalCount(filterdata.length);
                }
                else {
                    let filterData=response.data.usersResult.filter(user => user.userRole === 0 && user.userId !== state.userAccessContext.userId);

                    setTableData(filterData);
                    setTotalCount(filterData.length);
                }
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            })
        })()

    };

    useEffect(() => {
        GetSubscribedUser();
    }, [rowsPerPage, page, orderBy, sortOrder]);

    const handleClear = () => {
        setSearchText("");
        GetClearSubscribedUser();
    };
    const GetClearSubscribedUser = () => {
        setLoading(true);
        (async () => {
            let request = {
                "searchText": null,
                "orderBy": "FirstName",
                "rowsPerPage": rowsPerPage,
                "recordNumber": startingRecordNumber,
                "orderDirection": "asc"
            };
            await usePost<{ usersResult: IUser[]; totalUsers: number }>("Users/GetUsersDetails", request).then((response) => {

                if (state.userAccessContext.userRole) {
                    setTableData(response.data.usersResult)
                }
                else {
                    setTableData(response.data.usersResult.filter(user => user.userRole === 1));
                }
            }).finally(() => setLoading(false))
        })()

        setTotalCount(tableData.length);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value));
    };

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        setLoading(true);
        GetSubscribedUser();
    }, []);

    const handleCallback = () => {
        GetSubscribedUser();
    };
    const handleDialogClose = () => {
        setTextBox(false);
        // setSelectedOptions([]);
    }

    //this function only active in Admin user
    const handleEdit = () => {
        setTextBox(true)
    };

    const highlightSearchTerm = (text: string | null, searchText: string | null) => {
        if (!text || !searchText) return text;

        const regex = searchTermRegex(searchText);
        const highlightedText = text.split(regex).map((part, index) => {
            return regex.test(part) ? (
                <mark key={index} style={{ backgroundColor: "#FFF44F", padding: "2px" }}>
                    {part}
                </mark>
            ) : (
                part
            );
        });

        return <>{highlightedText}</>;
    };

    function EnhancedTableHead() {
        return (
            <TableHead className={classes.tableHead}>
                <TableRow sx={{ color: "white" }} >
                    <TableCell className={classes.tableCellHeader}>
                        <Checkbox size='small' className={classes.checkBoxStyle} inputProps={{ 'aria-label': 'select all desserts' }} />
                    </TableCell >
                    {SubscribedHeadCells?.map((headCell) => (
                        <TableCell size="small" className={classes.tableCellHeader} key={headCell.id} onClick={() => handleSort(headCell.label)}>
                            {headCell.label || headCell.sortable}
                            {isSortedColumn(headCell.id) && (
                                <ArrowDropDownIcon style={{ transform: sortOrder.order === 'desc' ? 'rotate(180deg)' : 'none', }} />
                            )}
                        </TableCell>
                    ))}

                </TableRow>
            </TableHead >
        );
    }

    return (
        <div className={classes.div}>
            <Grid item xs={12} bgcolor={'white'} borderRadius={1} mb={2} boxShadow={1} p={1}>
                <Grid>
                    <Typography variant='h6' color={'black'} ml={1}><b>Subscribed Users</b></Typography>
                </Grid>
            </Grid>
            <Box p={0} borderRadius={2} bgcolor={'white'}>
                <Grid container spacing={0} padding={2}>
                    <Grid xs={12} sm={6} md={10}>
                        <TextField
                            id="input-with-icon-textfield"
                            placeholder='Search...'
                            size='small'
                            value={searchText}
                            onChange={(e: any) => setSearchText(e.target.value)}
                            type='text'
                            variant='outlined'
                            fullWidth
                            onKeyPress={(event) => {
                                if (event.key === "Enter" && searchDebtor !== "") {
                                    GetSubscribedUser();
                                    setShowSearchIcon(false);
                                } else if (event.key === "Enter" && searchDebtor === "") {
                                    setShowSearchIcon(true);
                                    GetSubscribedUser();
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        {showSearchIcon === true ?
                                            <IconButton size='small' onClick={e => { setShowSearchIcon(false); GetSubscribedUser(); }}>
                                                <SearchIcon fontSize='small' />
                                            </IconButton>
                                            :
                                            <IconButton size='small' onClick={(e) => { setShowSearchIcon(true); setSearchDebtor(""); }}>
                                                <CloseIcon fontSize='small' onClick={handleClear} />
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={2} display="flex" justifyContent={'end'}>
                        <AddUser onCreate={handleCallback} />
                    </Grid>
                </Grid>
                <TableContainer>
                    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.userContainer} >
                        {loading ? (
                            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                                <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
                            </Backdrop>
                        ) : (
                            < Table aria-label="customized table" size='medium'>
                                <EnhancedTableHead />
                                <TableBody>
                                    {tableData?.map((row) => (
                                        <TableRow>
                                            <TableCell className={classes.tableCellStyle}>
                                                <Checkbox className={classes.checkBoxStyle} inputProps={{ 'aria-label': 'select all desserts' }} />
                                            </TableCell>
                                            <TableCell className={classes.tableCellStyle}>{highlightSearchTerm(row?.firstName, searchText)}</TableCell>
                                            <TableCell className={classes.tableCellStyle}>{highlightSearchTerm(row?.lastName, searchText)}</TableCell>
                                            <TableCell className={classes.tableCellStyle}>{highlightSearchTerm(row?.userName, searchText)}</TableCell>
                                            <TableCell className={classes.tableCellStyle}>AC-903</TableCell>
                                            <TableCell className={classes.tableCellStyle}>{highlightSearchTerm(row.createdDate, searchText)}</TableCell>
                                         <TableCell className={classes.tableCellStyle}>
                                                <ManagerUserPermission UserId={row.userId} oncreate={GetSubscribedUser} />
                                                {/* <DeleteIcon color='error' fontSize='small' /> */}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </Scrollbars>
                    <TablePagination
                        component="div"
                        count={TotalCount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Box>
        </div >
    );
}

export default SubcribedUser;
