/* eslint-disable @typescript-eslint/no-unused-vars */
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";
import AddToQueueTwoToneIcon from "@mui/icons-material/AddToQueueTwoTone";
import AssignmentTwoToneIcon from "@mui/icons-material/AssignmentTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
import PaidTwoToneIcon from "@mui/icons-material/PaidTwoTone";
import WorkspacePremiumTwoToneIcon from "@mui/icons-material/WorkspacePremiumTwoTone";
import { AppBar, Box, Dialog, DialogContent, Grid, IconButton, Step, StepLabel, Stepper, Toolbar, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { Transition } from "../../GlobalStyles/Transition";
import ColorlibConnector from "../../GlobalStyles/ColorlibConnector";
import useColorlibStepIconStyles from "../../GlobalStyles/useColorlibStepIconStyles";
import { IGetCourseDetail } from '../../model/ICourse';
import AddEditCourseDetails from './CourseDetails/AddEditCourseDetails';
import TopicManagement from "./Topic/TopicManagement";
import PaymentDetail from "./PaymentDetails/PaymentDetail";
import EditCertificateDetails from "./CertificateDetails/EditCertificateDetails";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditAssesments from "./Topic/EditAssessment";
import { useGlobalState } from "../../GlobalStore/globalStore";
import MLearnTooltip from "../../GlobalStyles/ToolTip";

const MangeCourse: React.FC<{ row: IGetCourseDetail, getCourseList: () => void, onCourseAddedOrEdited: () => void }> = (props) => {
    const { state } = useGlobalState();
    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [courseInformation, setCourseInformation] = useState<IGetCourseDetail>(props?.row);
    const handleGetCourseDetails = (newPostId: IGetCourseDetail) => {
        setCourseInformation(newPostId);
    };

    const handleCourseAddedOrEdited = () => {
        props.onCourseAddedOrEdited();
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setActiveStep(0);
    };

    const CourseSteps = [
        {
            label: "Course Details",
            icon: <MenuBookTwoToneIcon />,
            component: <AddEditCourseDetails activeStep={activeStep} setActiveStep={setActiveStep} GetCourseInformation={handleGetCourseDetails} courseDetails={courseInformation} onCourseAddedOrEdited={handleCourseAddedOrEdited} />
        },
        {
            label: "Topic Details",
            icon: <AddToQueueTwoToneIcon />,
            component: <TopicManagement activeStep={activeStep} setActiveStep={setActiveStep} CourseId={courseInformation?.courseId} />

        },
        {
            label: "Assignment Details",
            icon: <AssignmentTwoToneIcon />,
            component: <EditAssesments activeStep={activeStep} setActiveStep={setActiveStep} CourseId={courseInformation?.courseId} />,
        },
        {
            label: "Edit Certificate Details",
            icon: <WorkspacePremiumTwoToneIcon />,
            component: <EditCertificateDetails activeStep={activeStep} setActiveStep={setActiveStep} CourseId={courseInformation?.courseId} CourseTitle={courseInformation?.courseTitle}/>
        },
        {
            label: "Coupon Details",
            icon: <PaidTwoToneIcon />,
            component: <PaymentDetail activeStep={activeStep} setActiveStep={setActiveStep} courseCode={courseInformation?.courseCode} courseId={courseInformation?.courseId} onCloseStepper={handleClose} />
        },
    ];

    function ColorlibStepIcon(props: ColorlibStepIconProps) {
        const classes = useColorlibStepIconStyles();
        const { active, completed, pending } = props;
        const activeStep = props?.index;
        const icons: Record<number, React.ReactNode> = {
            1: activeStep > 0 ? <DoneIcon /> : <MenuBookTwoToneIcon fontSize="small" />,
            2: activeStep > 1 ? <DoneIcon /> : <AddToQueueTwoToneIcon fontSize="small" />,
            3: activeStep > 2 ? <DoneIcon /> : <AssignmentTwoToneIcon fontSize="small" />,
            4: activeStep > 3 ? <DoneIcon /> : <WorkspacePremiumTwoToneIcon fontSize="small" />,
            5: activeStep > 4 ? <DoneIcon /> : <PaidTwoToneIcon fontSize="small" />,
        };
        return (
            <div className={clsx(classes.stepperRoot, { [classes.active]: active, [classes.completed]: completed, [classes.pending]: pending })} >
                {icons[(props.icon)]}
            </div>
        );
    }

    return (
        <React.Fragment>
            {props.row.courseId ?
                <IconButton onClick={handleClickOpen} size='small' disabled={!state?.userAccessContext?.isManageCourse} aria-label="Edit course" >
                    <ModeTwoToneIcon fontSize="small" color='success' />
                </IconButton> :
                <MLearnTooltip title="Add Course" placement="top">
                    <IconButton
                        aria-label="Add course"
                        color="primary"
                        onClick={handleClickOpen}
                        disabled={!state.userAccessContext.isManageCourse}
                        style={{ backgroundColor: "#5e35b1", color: "white" }}
                    >
                        <AddCircleIcon />
                    </IconButton>

                </MLearnTooltip>
            }
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} >
                <AppBar sx={{ position: "relative", minHeight: "50px !important", borderBottom: "1px solid lightgray" }} color="transparent" >
                    <Toolbar sx={{ minHeight: "50px !important", paddingLeft: 1, paddingRight: 1 }} >
                        <Typography sx={{ ml: 1, flex: 1 }} variant="h6" component="div">
                            <b>{props.row.courseId ? "Edit Course" : "Create Course"}</b>
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ padding: "0px" }}>
                    <Grid container spacing={0} borderRadius={3}>
                        <Grid item xs={12} padding={"10px 0px"} borderBottom={"1px solid lightgray"} >
                            <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />} sx={{ width: "100%" }} >
                                {CourseSteps.map((step, index) => (
                                    <Step key={index}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} >
                                                <Typography variant="body2" color={activeStep >= index ? "#5e35b1" : "gray"} >
                                                    <b>{step?.label}</b>
                                                </Typography>
                                            </Box>
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={12} >
                            {CourseSteps[activeStep].component && (
                                <Typography variant="body2" component="div">
                                    {CourseSteps[activeStep].component}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};
export default MangeCourse;