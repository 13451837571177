/* Sidebar Group Name */
export const Dashboard = "DASHBOARD";
export const Management = "MANAGEMENT";
export const MyCourse = "MY COURSE";
export const QueryDetails = "QUERY DETAILS";


export const emailRegex = /^([À-žA-Za-z0-9ñ]+(?:['._-][À-žA-Za-z0-9ñ]+)*)@([À-žA-Za-z0-9ñ]+(?:[.-][À-žA-Za-z0-9ñ]+)*\.[a-z]{2,6})$/;
export const NameRegex = /^(?!\s*$)[a-zA-Z0-9\s]+$/;

export const EmailRegex = /^([A-Za-z0-9]+(?:['+._-][A-Za-z0-9]+)*)@([A-Za-z0-9]+(?:[.-][A-Za-z0-9]+)*\.[a-z]{2,4})$/;
export const PhoneRegex = /^(\()?([0-9]{3})(\)|-| |\)-|\) )?([0-9]{3})(-| )?([0-9]{4}|[0-9]{4})$/;
//blob 
export const baseImage = "data:image/png,image/jpg,image/jpeg;base64";
//coupon
export const couponLength = 8;
export const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
//sample template
export const SampleUser=1;
export const sampleCourse=2;
export const searchTermRegex = (searchText: string | null) => {
    return new RegExp(`(${searchText?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`, "gi");
  };
  export const COLOR_STYLE_MAP = {
    red: {
      color: 'red',
    },
    blue: {
      color: 'blue',
    },
    green: {
      color: 'green',
    },
  };
  
  export const INLINE_STYLES = {
    bold: 'BOLD',
    italic: 'ITALIC',
    underline: 'UNDERLINE',
  };
  
  export const HEADER_STYLES = {
    h1: 'header-one',
    h2: 'header-two',
  };
  export const ALIGN_STYLES = {
    left: 'left',
    center: 'center',
    right: 'right',
    justify: 'justify',
  };
  