/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, InputAdornment, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Box } from '@mui/system';
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import ColorlibConnector from '../../../../GlobalStyles/ColorlibConnector';
import MLearnTooltip from '../../../../GlobalStyles/ToolTip';
import { FieldConfig } from '../../../../model/DynamicForms';
import { IEducationalQualification, IExperience, IPrepareCV, IProject, ISkill } from '../../../../model/Users/IUser';
import useStyles from '../ProfileCardCss';
import ColorlibStepIcon from './ColorlibStepIcon';
import { DynamicTextFieldGroup } from './DynamicForms';
import { steps } from './steps';

const Project = (props) => {
    const classes = useStyles();
    const [expandedIndex, setExpandedIndex] = useState<number>(0);
    const [projectInformation, setProjectInformation] = useState([]);
    function parseProjectData(data: any[]): IProject[] {
        return data?.map(item => ({
            projectId: 0, // Set the experienceId to an appropriate value or leave it blank if not necessary
            projectName: item?.projectName || "",
            projectTechnology: item?.projectTechnology || "",
            projectDescription: item?.projectDescription || "",
        }));
    }
    const projectInformations: IProject[] = parseProjectData(props?.location?.state?.projectInformations);
    const profileInformation: IPrepareCV[] = props?.location?.state?.profileInformation;
    const educationInformations: IEducationalQualification[] = props?.location.state?.educationInformations;
    const skillInformation: ISkill[] = props?.location?.state?.skillInformation;
    const experienceInformation: IExperience[] = props?.location?.state?.experienceInformation;

    const initializeFields = () => {
        if (Array.isArray(projectInformations) && projectInformations.length > 0) {
            return projectInformations.map((data, index) => [
                {
                    index: index,
                    id: 'projectName',
                    placeholder: 'Project Name',
                    startAdornment: <InputAdornment position="start"><DescriptionIcon fontSize='small' /></InputAdornment>,
                    value: data.projectName || ''
                },
                {
                    index: index,
                    id: 'projectTechnology',
                    placeholder: 'Tech Stack',
                    startAdornment: <InputAdornment position="start"><DescriptionIcon fontSize='small' /></InputAdornment>,
                    value: data.projectTechnology || ''
                },
                {
                    index: index,
                    id: 'projectDescription',
                    placeholder: 'Write a description of your Project....',
                    value: data.projectDescription || ''
                }
            ]);
        } else {
            return [
                [
                    {
                        index: 0,
                        id: 'projectName',
                        placeholder: 'Project Name',
                        startAdornment: <InputAdornment position="start"><DescriptionIcon fontSize='small' /></InputAdornment>,
                        value: ''
                    },
                    {
                        index: 0,
                        id: 'projectTechnology',
                        placeholder: 'Tech Stack',
                        startAdornment: <InputAdornment position="start"><DescriptionIcon fontSize='small' /></InputAdornment>,
                        value: ''
                    },
                    {
                        index: 0,
                        id: 'projectDescription',
                        placeholder: 'Write a description of your Project....',
                        value: ''
                    }
                ]];
        }
    };


    const initialFields = initializeFields();

    const [projectGroups, setProjectGroups] = useState<FieldConfig[][]>(initialFields);
    const [activeStep, setActiveStep] = useState(4);
    const history = useHistory();


    const addFieldGroup = () => {
        // Create an array of empty fields with the same structure as initialFields
        const emptyFields: FieldConfig[] = initialFields[0].map(field => ({
            ...field,
            value: '' // Set the value to an empty string
        }));
        setProjectGroups([emptyFields, ...projectGroups]);
    };

    const removeFieldGroup = (index: number) => {
        const newFieldGroups = [...projectGroups];
        newFieldGroups.splice(index, 1);
        setProjectGroups(newFieldGroups);
    };

    const toggleAccordion = (index: number) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const handleFieldValuesChange = (index, updatedFieldValues) => {
        const updatedProjectInformation = [...projectInformation];
        updatedProjectInformation[index] = updatedFieldValues;
        setProjectInformation(updatedProjectInformation);
    };

    const handleNextStep = () => {
        let value = projectInformations;
        history.push("/PreviewCV", {
            profileInformation: profileInformation, educationInformations: educationInformations,
            skillInformation: skillInformation, experienceInformation: experienceInformation,
            projectInformations: projectInformation.length > 0 ? parseProjectData(projectInformation) : value,
        });
    };

    const handleBackStep = () => {
        let value = projectInformations;
        history.push("/Experience", {
            profileInformation: profileInformation, educationInformations: educationInformations,
            experienceInformation: experienceInformation, skillInformation: skillInformation,
            projectInformations: projectInformation.length > 0 ? parseProjectData(projectInformation) : value,
        });
    };

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={2} display={"flex"} justifyContent={"center"} alignItems={"center"} borderRight={"1px solid lightgray"} style={{ backgroundColor: "#7a64a7" }}>
                    <Stepper
                        activeStep={activeStep}
                        orientation='vertical'
                        connector={<ColorlibConnector />}
                    >
                        {steps.map((step, index) => (
                            <Step key={step.label} sx={{ color: "white" }}>
                                <StepLabel StepIconComponent={ColorlibStepIcon} icon={step.icon}>
                                    <b style={{ color: activeStep === index ? "orange" : (index < activeStep ? "limegreen" : "#ccc"), fontSize: activeStep === index ? "18px" : "14px" }}>
                                        {step.label}
                                    </b>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid item xs={10}>
                    {activeStep === 4 && (
                        <Grid container spacing={1} >
                            <Grid item xs={8} pt={6}>
                                <Typography variant='body1' color="#5e35b1"><b>Project</b></Typography>
                            </Grid>
                            <Grid item xs={4} display={"flex"} justifyContent={"flex-end"}>
                                <MLearnTooltip title="Add" placement="top">
                                    <IconButton
                                        aria-label="Add"
                                        color="primary"
                                        onClick={addFieldGroup}
                                        style={{ backgroundColor: "#5e35b1", color: "white" }}>
                                        <AddCircleIcon />
                                    </IconButton>
                                </MLearnTooltip>
                            </Grid>
                            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.educationContainer}>
                                <Grid container spacing={2}>
                                    {projectGroups.map((fields, index) => (
                                        <Grid item xs={12} key={index} display={"flex"}>
                                            <Grid item xs={11.5}>
                                                <Accordion expanded={index === expandedIndex} onChange={() => toggleAccordion(index)}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon fontSize='small' />} className={index === expandedIndex ? classes.expandedAccordion : ''}>
                                                        <Typography variant='body2' color={"chocolate"}><b>Project Information {index + 1}</b></Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <DynamicTextFieldGroup key={index} index={index} fields={fields}
                                                            value={projectGroups[index]} onRemove={() => removeFieldGroup(index)} OnChange={handleFieldValuesChange} />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                            <Grid item xs={0.5} ml={2} mt={1}>
                                                <IconButton onClick={() => removeFieldGroup(index)} className={classes.JoinIconButton}>
                                                    <MLearnTooltip title="Remove" placement="top">
                                                        <DeleteOutlineIcon fontSize='small' color='error' />
                                                    </MLearnTooltip>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Scrollbars>
                            <Box pt={0.5} width={'100%'} display={'inline-flex'} justifyContent={'flex-end'} borderTop={'1px solid lightgray'}>
                                <Button variant="contained" size="small" color="success" sx={{ marginRight: "20px" }} onClick={handleBackStep}>
                                    <b>Back</b>
                                </Button>
                                <Button variant="contained" size="small" color="success" sx={{ marginRight: "20px" }} onClick={handleNextStep}>
                                    <b>Next</b>
                                </Button>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Project;