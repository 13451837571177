/* eslint-disable react-hooks/rules-of-hooks */
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Backdrop, Button } from '@mui/material';
import { useState } from 'react';
import { useDelete } from '../../utils/apiHelper';
import useStyles from './CourseCss';
import { MoonLoader } from 'react-spinners';
import MLearnTooltip from '../../GlobalStyles/ToolTip';
import { useGlobalState } from '../../GlobalStore/globalStore';

const DeleteCourse: React.FC<{ courseId: number, onDelete: () => void }> = (props) => {
  const classes = useStyles();
  const { courseId, onDelete } = props;
  const { state } = useGlobalState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteCourseDetails = () => {
    setLoading(true);
    (async () => {
      await useDelete("Course/DeleteCourseDetail?courseId=" + courseId);
    })().finally(() => {
      onDelete();
      handleClose();
      setLoading(false);
    })
  }

  return (
    <div>
      <MLearnTooltip title="delete Course" placement="top">
        <IconButton onClick={handleClickOpen} size='small'  disabled={!state?.userAccessContext?.isManageCourse} >
          <DeleteTwoToneIcon fontSize="small" color="error" />
        </IconButton>
      </MLearnTooltip>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
      </Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        classes={{ paper: classes.deleteDialogue }}
        PaperProps={{ style: { borderRadius: 15 } }}
        className={classes.dialogBackground}>
        <DialogTitle 
        id="alert-dialog-title" className={classes.dialogStyles}>
          <b>{"Delete Course"}</b>
          <CloseIcon onClick={handleClose} style={{ cursor: 'pointer', position: 'absolute', right: '14px', top: '14px' }} />
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete the course?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActionPadding}>
          <Button onClick={deleteCourseDetails} color="success" variant='contained' size='small' startIcon={<CheckCircleIcon />}>
            <b>Yes</b>
          </Button>
          <Button onClick={handleClose} color="error" variant='contained' size='small' startIcon={<CancelIcon />}>
            <b>NO</b>
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}

export default DeleteCourse;

