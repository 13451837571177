/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Backdrop, Button, Grid, IconButton, InputAdornment, Snackbar, TextField, Typography } from '@mui/material';
import { useGlobalState } from '../../../../GlobalStore/globalStore';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { IUserInformation } from '../../../../model/Users/IUser';
import { usePost } from '../../../../utils/apiHelper';
import PhoneIcon from '@mui/icons-material/Phone';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { MoonLoader } from 'react-spinners';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onUpdate: () => void,
    editType: 'PersonalDetails' | 'AboutMe';
    users: IUserInformation[];
}

const EditProfile: React.FC<Props> = ({ isOpen, onClose, editType, onUpdate, users }) => {
    const { state } = useGlobalState();
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [userInfo, setUserInfo] = useState<IUserInformation>({
        firstName: '',
        lastName: '',
        emailAddress: '',
        userRole: 0,
        isManager: false,
        phoneNumber: "",
        linkedInUserName: '',
        gitHubUserName: '',
        aboutMe: '',
        location:'',
        state:'',
    });

    useEffect(() => {
        if (users?.length > 0) {
            setUserInfo(users[0]); // Set initial values from props
        }
    }, [users]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserInfo(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleUserInformation = () => {
        const request = {
            userId: state?.userAccessContext?.userId,
            ...userInfo,
        };
        usePost("Users/ManageUser", request)
            .then(() => {
                setSnackbarMessage("User information updated successfully");
                setSnackbarOpen(true);
                onClose();
                onUpdate();

            });
    };

    return (
        <React.Fragment>
            <Drawer anchor="right" open={isOpen} onClose={onClose}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
                </Backdrop>
                <Grid container spacing={1} p={2} sx={{ width: '100%', maxWidth: 380 }}>
                    <Grid item xs={8}>
                        <Typography variant='subtitle1' textAlign={"left"} color="#5e35b1">
                            <b>Profile Information</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} display={"flex"} justifyContent={"flex-end"}>
                        <IconButton
                            onClick={onClose}
                        >
                            <HighlightOffIcon fontSize='small' />
                        </IconButton>
                    </Grid>
                    {editType === 'PersonalDetails' && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-basic-small"
                                    size="small"
                                    fullWidth
                                    placeholder='First Name'
                                    name='firstName'
                                    value={userInfo?.firstName}
                                    onChange={handleInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonOutlineIcon fontSize='small' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-basic-small"
                                    size="small"
                                    fullWidth
                                    placeholder='Last Name'
                                    name='lastName'
                                    value={userInfo?.lastName}
                                    onChange={handleInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonOutlineIcon fontSize='small' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-basic-small"
                                    size="small"
                                    fullWidth
                                    placeholder='Phone Number'
                                    name='phoneNumber'
                                    value={userInfo?.phoneNumber}
                                    onChange={handleInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneIcon fontSize='small' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-basic-small"
                                    size="small"
                                    fullWidth
                                    placeholder='LinkedIn UserName'
                                    name='linkedInUserName'
                                    value={userInfo?.linkedInUserName}
                                    onChange={handleInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LinkedInIcon fontSize='small' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-basic-small"
                                    size="small"
                                    fullWidth
                                    placeholder='GitHub UserName'
                                    value={userInfo?.gitHubUserName}
                                    name='gitHubUserName'
                                    onChange={handleInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <GitHubIcon fontSize='small' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-basic-small"
                                    size="small"
                                    fullWidth
                                    placeholder='Location'
                                    value={userInfo?.location}
                                    name='location'
                                    onChange={handleInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MyLocationIcon fontSize='small' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-basic-small"
                                    size="small"
                                    fullWidth
                                    placeholder='State'
                                    value={userInfo?.state}
                                    name='state'
                                    onChange={handleInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LocationOnIcon fontSize='small' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                    {editType === 'AboutMe' && (
                        <>
                            <Grid item xs={12} sx={{ borderTop: "1px dashed lightgrey !important" }}>
                                <TextField
                                    id="outlined-multiline-static"
                                    fullWidth
                                    placeholder='Write a Short description about yourself....'
                                    onChange={handleInputChange}
                                    name='aboutMe'
                                    value={userInfo?.aboutMe}
                                    multiline
                                    rows={8}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} borderTop={"1px dashed lighgrey"} display={"flex"} justifyContent={"flex-end"}>
                        <Button
                            variant="contained"
                            color='success'
                            size='small'
                            onClick={handleUserInformation}
                        >
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </Drawer >
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={1000} // Adjust the duration as needed
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackbarOpen(false)}>
                            <HighlightOffIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default EditProfile;