import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            display: 'flex',
            justifyContent: 'center'
        },
        deleteDialogue: {
            minWidth: "400px",
        },
        dialogBackground: {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(145,158,171,0.20) ! important"
        },
        dialogStyles: {
            display: "flex !important",
            justifyContent: "center !important",
            backgroundColor: "#5e35b1 !important",
            color: "white !important",
            padding: "9px 1px 2px 2px !important"
        },
        typographyvariant: {
            textAlign: 'center'
        },
        textbox: {
            marmarginTop: '10px !important',
            textAlign: 'center',
        },
        icons: {
            marginTop: '20px',
        },
        arrowicon: {
            cursor: 'pointer',
            borderRadius: '50%',
            padding: '5px',
            backgroundColor: 'black',
            color: 'white'
        },
        feedback: {
            marginTop: '10px !important',
            textAlign: 'center'
        },
        cardcontainer: {
            marginBottom: '10px',
            cursor: 'pointer',
            borderRadius: '10px',
            margin: '8px',
            backgroundColor: '#cecece !important    ',
            border: '2px solid #666',
            '&:hover': {
                backgroundColor: '#755e91 !important',
                color: 'white !important',
            },
        },
        selectedOption: {
            backgroundColor: '#755e91 !important',
            color: 'white !important',
        },
        typography: {
            color: 'gray !important',
            fontWeight: 'bold',  // Apply bold font weight 
            lineHeight: '1.5',  // Adjust the line height
            textAlign: 'center',  // Align text to the center 
        },

        progressbartext: {
            marginRight: '10px !important'
        },
        chart: {
            width: '50%',
        },
        closeicon: {
            cursor: 'pointer',
            position: 'absolute',
            right: '14px',
            top: '14px',
            color:"white"
        },
    })
);

export default useStyles 