/* eslint-disable react-hooks/rules-of-hooks */
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import {  Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { useGlobalState } from "../../GlobalStore/globalStore";
import MLearnTooltip from "../../GlobalStyles/ToolTip";
import { IUser } from "../../model/Users/IUser";
import useStyles from "./UserCss";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { usePost } from "../../utils/apiHelper";
const AddUser: React.FC<{ onCreate: () => void }> = (props) => {
  const classes = useStyles();
  const { state } = useGlobalState();
  const initialUser: IUser = {
    userId: 0,
    firstName: "",
    lastName: "",
    userName: "",
    userRole: 0,
    isSubscribed: false,
    isManager: false,
    password: "",
    confirmPassword: "",
    managerEmail: state.userAccessContext.emailAddress,
    phoneNumber: 0,
    createdDate: "",
    modifiedDate: "",
  };

  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<IUser>(initialUser);
  const [selectedManagerEmail, setSelectedManagerEmail] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  type Errors = {
    [key in keyof IUser]?: string;
  };

  const [errors, setErrors] = useState<Errors>({});

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleSaveUserInformation = async () => {
    //const userEmail = !user.confirmPassword ? user?.userName : user?.userName;
    // setShowProgressBar(true);
    try {
      const response = await fetch("https://merrylearnapi.azurewebsites.net/api/MSEntra/CheckEmailExist?userEmail=" + user.userName);
      const data = await response.json();
      if (data) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          userName: "Email already exists in Azure AD. Try with another email.",
        }));
        console.log("exist");
      }
      // Proceed with further operations since email does not exist

      // setShowProgressBar(false);
      console.log(data); // log the data to the console
    } catch (error) {

      /**
      * Calling the azure function to insert the Prospect Users Information
      * Need to write a logic to validate the account is exist in the system(DB) or not 
      */


      /**
        * Call the graph API to send an invitation to Signup user
        * 
        * Calling the graph API to Create a new user in the Azure AD
        */

      // setLoading(true);
      try {

        (async () => {
          let request = {
            "fullName": user?.firstName + " " + user?.lastName,
            "userEmail": user?.userName,
            "givenName": user?.firstName,
            "surname": user?.lastName,
            "password": user?.password
          }
          await fetch("https://merrylearnapi.azurewebsites.net/api/MSEntra/SendSignupInvitation", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
          }
          );

        })()
        let PostUsr = {
          "userId": 0,
          "firstName": user?.firstName,
          "lastName": user?.lastName,
          "emailAddress": user?.userName,
          "isSubscribed": false,
          "userRole": 0,
          "phoneNumber": user.phoneNumber,
          "isManager": user.isManager,
          "managerEmail": state.userAccessContext.emailAddress


        }
        await usePost('Users/ManageUser', PostUsr).finally(() => handleClose());
      } catch (error) {
        console.log(error); // log the data to the console

      }

    }
  }
  const handleClose = () => {
    setOpen(false);
    setUser(initialUser);
    setSelectedManagerEmail("");
    setErrors({});
  };

  const validate = (name: string, value: string) => {
    let error = "";

    switch (name) {
      case "firstName":
      case "lastName":
        if (!/^[A-Za-z]+$/.test(value)) error = "Only alphabets are allowed.";
        break;
      case "userName":
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))
          error = "Invalid email format.";
        break;
      case "phoneNumber":
        if (!/^[0-9- ]+$/.test(value)) error = "Invalid phone number.";
        break;
      case "password":
        const minLength = 8;
        const regexUpperCase = /[A-Z]/;
        const regexLowerCase = /[a-z]/;
        const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

        if (value.length < minLength) {
          error = "Password must be at least 8 characters long.";
        } else if (!regexUpperCase.test(value)) {
          error = "Password must contain at least one uppercase letter.";
        } else if (!regexLowerCase.test(value)) {
          error = "Password must contain at least one lowercase letter.";
        } else if (!regexSpecialChar.test(value)) {
          error = "Password must contain at least one special character.";
        }
        break;
      case "confirmPassword":
        if (value !== user.password) {
          error = "Passwords do not match.";
        }
        break;
      default:
        break;
    }
    return error;
  };


  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const newValue = name === "isManager" ? checked : value;

    const error = validate(name, newValue as string);

    setUser((prevUser) => ({
      ...prevUser,
      [name]: newValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isFormValid = () => {
    return (
      user.firstName &&
      user.lastName &&
      user.userName &&
      user.phoneNumber &&
      user.password &&
      user.confirmPassword &&
      Object.values(errors).every((error) => error === "")
    );
  };


  return (
    <React.Fragment>
      <MLearnTooltip title="Add User" placement="top">
        <IconButton
          aria-label="add user"
          color="secondary"
          onClick={handleClickOpen}
          size="small"
          style={{ backgroundColor: "#5e35b1", color: "white" }}
        >
          <PersonOutlineTwoToneIcon />
        </IconButton>
      </MLearnTooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"sm"}
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className={classes.dialogTitle}
        >
          <b>Add User</b>
          <CloseIcon onClick={handleClose} className={classes.closeButton} />
        </DialogTitle>
        <DialogContent
          className={classes.dialogContent}
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <Grid container spacing={1} justifyContent="center" mt={1}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="First Name"
                size="small"
                name="firstName"
                value={user.firstName}
                onChange={handleChange}
                fullWidth
                error={!!errors.firstName}
                helperText={errors.firstName}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Last Name"
                name="lastName"
                size="small"
                value={user.lastName}
                onChange={handleChange}
                fullWidth
                error={!!errors.lastName}
                helperText={errors.lastName}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Email Address"
                name="userName"
                size="small"
                value={user.userName}
                onChange={handleChange}
                fullWidth
                error={!!errors.userName}
                helperText={errors.userName}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Phone No"
                name="phoneNumber"
                size="small"
                value={user.phoneNumber as unknown as string}
                onChange={handleChange}
                fullWidth
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                inputProps={{ maxLength: 16 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                size="small"
                value={user.password}
                onChange={handleChange}
                fullWidth
                error={!!errors.password}
                helperText={errors.password}
                inputProps={{ maxLength: 50 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end" >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Confirm Password"
                name="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                size="small"
                value={user.confirmPassword}
                onChange={handleChange}
                fullWidth
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                inputProps={{ maxLength: 50 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle confirm password visibility" onClick={handleClickShowConfirmPassword} edge="end" >
                        {showConfirmPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="IsManager"
                  name="isManager"
                  checked={user.isManager}
                  onChange={handleChange}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" color="success" onClick={handleSaveUserInformation} disabled={!isFormValid()} >
            <b>Add</b>
          </Button>
          <Button onClick={handleClose} variant="contained" size="small" color="error" >
            <b>Cancel</b>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default AddUser;
