import * as React from "react";
import { useTheme } from "@mui/material/styles";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MLearnTooltip from "../../../../GlobalStyles/ToolTip";
import useStyles from "./TopicContentCss";
import { StyledToggleButtonGroup } from "./ToggleButton";

interface CustomizedDividersProps {
  onChange: (newContent: string) => void;
  value: string;
  minHeight?: string;
  minWidth?: number;
  className?: string;
}

type TextAlign =
  | "start"
  | "end"
  | "left"
  | "right"
  | "center"
  | "justify"
  | "match-parent";

export default function CustomizedDividers(props: CustomizedDividersProps) {
  const theme = useTheme();
  const classes = useStyles();
  const [alignment, setAlignment] = useState<TextAlign>("left");
  const [formats, setFormats] = useState(() => ["italic"]);
  const [color, setColor] = useState("#000000");
  const [text, setText] = useState("");

  const handleFormat = (event: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
    setFormats(newFormats);
  };

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: TextAlign) => {
    setAlignment(newAlignment);
  };

  const handleTextChange = (event: any) => {
    setText(event.target.value);
    props.onChange(event.target.value);
  };

  const handleColorChange = (newColor: string) => {
    setColor(newColor);
  };

  const colorOptions = ["#000000", "#0000FF", "#FF0000", "#008000", "#FFA500"];

  const getTextStyles = (): React.CSSProperties => {
    let styles: React.CSSProperties = {
      textAlign: "left",
      whiteSpace: "pre-line",
      textIndent:"5px !important",
      paddingTop:"10px !important",
     minHeight: props.minHeight,
    };

    if (formats.includes("bold")) {
      styles = { ...styles, fontWeight: "bold" };
    }

    if (formats.includes("italic")) {
      styles = { ...styles, fontStyle: "italic" };
    }

    if (formats.includes("underlined")) {
      styles = { ...styles, textDecoration: "underline" };
    }

    if (formats.includes("color")) {
      styles = { ...styles, color };
    }
    styles = { ...styles, textAlign: alignment };

    return styles;
  };

  useEffect(() => {
    // Update the text content when the value prop changes
    setText(props.value);
  }, [props.value]); // Example dynamic value
  const minWidthValue = `calc(${props.minWidth}px + 43vw)`;
  return (
    <Grid container>
      <Paper elevation={0} sx={{ display: "flex", flexWrap: "wrap" }}>
        <StyledToggleButtonGroup
          size="small"
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
        >
          <MLearnTooltip title="Align text Left" placement="bottom">
            <ToggleButton value="left" aria-label="left aligned">
              <FormatAlignLeftIcon />
            </ToggleButton>
          </MLearnTooltip>
          <MLearnTooltip title="Align Center" placement="bottom">
            <ToggleButton value="center" aria-label="centered">
              <FormatAlignCenterIcon />
            </ToggleButton>
          </MLearnTooltip>
          <MLearnTooltip title="Align text Right" placement="bottom">
            <ToggleButton value="right" aria-label="right aligned">
              <FormatAlignRightIcon />
            </ToggleButton>
          </MLearnTooltip>
          <MLearnTooltip title="justify" placement="bottom">
          <ToggleButton value="justify" aria-label="justified">
            <FormatAlignJustifyIcon />
          </ToggleButton>
          </MLearnTooltip>
        </StyledToggleButtonGroup>
        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
        <StyledToggleButtonGroup
          size="small"
          value={formats}
          onChange={handleFormat}
          aria-label="text formatting"
        >
          <MLearnTooltip title="Bold" placement="bottom">
          <ToggleButton value="bold" aria-label="bold">
            <FormatBoldIcon />
          </ToggleButton>
          </MLearnTooltip>
          <MLearnTooltip title="italic" placement="bottom">
          <ToggleButton value="italic" aria-label="italic">
            <FormatItalicIcon />
          </ToggleButton>
          </MLearnTooltip>
          <MLearnTooltip title="underlined" placement="bottom">
          <ToggleButton value="underlined" aria-label="underlined">
            <FormatUnderlinedIcon />
          </ToggleButton>
          </MLearnTooltip>
          <MLearnTooltip title="Fill Color" placement="bottom">
          <ToggleButton value="color" aria-label="color">
            <FormatColorFillIcon />
            <ArrowDropDownIcon />
          </ToggleButton>
          </MLearnTooltip>
          <div>
            {formats.includes("color") && (
              <Grid container spacing={1} justifyContent="center" mt={2}>
                {colorOptions.map((option) => (
                  <Grid item key={option}>
                    <div
                      onClick={() => handleColorChange(option)}
                      className={classes.richTextEditor}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        </StyledToggleButtonGroup>
        <Grid item xs={12}>
          <textarea 

            value={text}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleTextChange(event)
            }
            placeholder="Type Here..."
            style={{
              ...getTextStyles(),
              marginTop: theme.spacing(1),
              textIndent: "5px",
              paddingTop:"2px",
              minWidth: minWidthValue,
            }}
          />
        </Grid>
      </Paper>
    </Grid>
  );
}
