import { styled } from '@material-ui/core';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React, { useState, useEffect } from 'react';

interface CustomTooltipProps extends TooltipProps {
  displayDuration?: number; // duration in milliseconds
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ displayDuration = 2000, ...props }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (showTooltip) {
      timer = setTimeout(() => {
        setShowTooltip(false);
      }, displayDuration);
    }

    return () => clearTimeout(timer);
  }, [showTooltip, displayDuration]);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <Tooltip
      {...props}
      open={showTooltip}
      classes={{ popper: props.className }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

const MLearnTooltip = styled(CustomTooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // color: 'white',/* Future  Use*/
    boxShadow: theme.shadows[1],
    fontSize: 11,
    border:'2px solid white', /* Future Use */
    backgroundImage: "linear-gradient( 136deg, rgb(0, 15, 130) 0%, rgb(0, 15, 130) 50%)", /* Future Use */
    borderRadius:"20px"
  },
}));

export default MLearnTooltip;
