import React, { createContext, useContext, useReducer } from 'react';
import { IGlobalState } from "../utils/GlobalUtil";
import { IUser, IUserInfo } from "../model/Users/IUserContext";

export enum GlobalStateAction {
  Busy,
  Idle,
  Error,
  LoggedIn,
  LoggedOut,
  userAccessContext,
}

export type Action =
  | { type: GlobalStateAction.Busy }
  | { type: GlobalStateAction.Idle }
  | { type: GlobalStateAction.Error; error: any }
  | { type: GlobalStateAction.LoggedIn; user: IUser }
  | { type: GlobalStateAction.LoggedOut }
  | { type: GlobalStateAction.userAccessContext; userAccessContext: IUserInfo }


const initialUserState: IGlobalState = {
  hasError: false,
  busy: false,
}

const globalStore = createContext<{
  state: IGlobalState,
  dispatch: React.Dispatch<Action>
}>({
  state: initialUserState,
  dispatch: () => { },
})

const reducer: React.Reducer<IGlobalState, Action> = (state, action) => {
  switch (action.type) {
    case GlobalStateAction.Busy:
      return { ...state, busy: true }
    case GlobalStateAction.Idle:
      return { ...state, busy: false }
    case GlobalStateAction.Error:
      return { ...state, busy: false, error: action.error }
    case GlobalStateAction.LoggedIn:
      return { ...state, user: { ...action.user } }
    case GlobalStateAction.LoggedOut:
      return { ...state, user: undefined }
    case GlobalStateAction.userAccessContext:
      return { ...state, userAccessContext: action.userAccessContext }
    default:
      return state
  }
}

const GlobalStateProvider: React.FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer<React.Reducer<IGlobalState, Action>>(
    reducer,
    initialUserState
  )

  return (
    <globalStore.Provider value={{ state, dispatch }}>
      {children}
    </globalStore.Provider>
  )
}

const useGlobalState = () => useContext(globalStore)

export { GlobalStateProvider, useGlobalState };
