import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    //new design start
    iconButtons: {
      marginTop: '3px !important',
      color: '#5e35b1 !important',
      backgroundColor: "#E6E6FA !important",
      '&:hover': { color: 'white !important', backgroundColor: "#5e35b1 !important" },
      borderRadius: '10px'
    },
    borderBottom: {
      borderBottom: "1px dashed lightgray",
    }, searchBox: {
      // marginTop: '2px !important',
      borderRadius: "10px !important",
      border: '2px solid #5e35b1 !important',
      height: '35px !important',
      textIndent: '10px !important',
      fontSize: "12px !important",
      '& .MuiInputBase-root': {
        fontSize: "12px !important"
      },
      '& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root': {
        paddingRight: '2px'
      }
    }, outlinedInput: {
      fontSize: "12px !important",
      color: "black !important",
      marginTop: '-2px !important',
      '&$focused $notchedOutline': {
        color: 'white !important',
        border: '0px !important'
      },
    }, notchedOutline: {
      color: 'white !important',
      border: 'none !important',
      fontSize: "12px !important",
    },
    //new design end
    NotificationHover: {
      display: 'flex',
     alignItems: 'center',
   //   padding: '10px',
      borderRadius: '10px',
    //  color: 'linear-gradient(45deg, #6200ea 30%, #9c27b0 90%) !important',
     // background: "#d8d8e8 !important",
      '&:hover ': {
        background: " rgb(237 233 254)!important",
        '& .MuiTypography-root': {
      //    color: 'white !important'
        }
      }
    },
    UserFeedback: {
      maxHeight: "calc( 100vh - 350px) !important",
      minHeight: "calc( 100vh - 350px) !important",
    },
    container: {
      paddingBottom: '10px !important',
      justifyContent: 'space-between'
    },
    customizedDivider: {
      justifyContent: "flex-end",
    },
    textfield: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      paddingRight: '5px !important',
    },
    papercontainer: {
      width: '550px !important',
      height: '300px !important',
      overflowY: 'hidden'
    },

    control: {
      padding: '5px !important',
    },
    textEditor: {
      // textIndent: "px",
      border: "1px solid lightgray",
      height: "220px !important",
      // borderRadius: "10px !important"
    },
    courseContainer: {
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 195px) !important',
        minHeight: 'calc( 100vh - 195px) !important',
      },
    },
    notification: {
      padding: '10px !important',
    },
    description: {
      color: "#a7a7a7 !important",
    },
    message: {
      marginLeft: '8px !important',
    },
    admincard: {
      borderRadius: '10px',
      cursor: 'pointer',
      margin: '20px 10px',
      border: '2px solid gray',
    },
    checkBox: {
      backgroundColor: '#daf2ff !important'
    },
    checkBox1: {
      backgroundColor: 'white'
    },
    TimeLineContainer: {

      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 115px) !important",
        minHeight: "calc( 100vh - 115px) !important",
      },
    },
    noticationContainer: {
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 365px) !important",
        minHeight: "calc( 100vh - 365px) !important",
      },
    }
  }),
);

export default useStyles;