// Styled component for the TreeView
import { styled } from '@mui/material/styles';
import { TreeView } from '@mui/x-tree-view';

const StyledTreeView = styled(TreeView)({
    '& .MuiCollapse-vertical .MuiCollapse-wrapperInner': {
        borderLeft: '1px dashed lightgray'
    },
});

export default StyledTreeView