/* eslint-disable @typescript-eslint/no-unused-vars */
import { Hidden } from '@mui/material';
import { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useGlobalState } from '../GlobalStore/globalStore';
import routes from '../Routes';
import Content from '../components/NavbarSection/Content';
import NavbarHeader from '../components/NavbarSection/NavHeader';
import Sidebar from '../components/NavbarSection/Sidebar';

const AdminLayout = () => {

    const { state } = useGlobalState();
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [Admin, setAdmin] = useState(state.userAccessContext.isManager||state.userAccessContext.userRole as any);
    const changeRole = () => {
        setAdmin((prevSidebarVisible) => !prevSidebarVisible);
    }
    const toggleSidebar = () => {
        setSidebarVisible((prevSidebarVisible) => !prevSidebarVisible);
    };
    return (
        <div>
            {Admin ? (
                <Router>
                    <NavbarHeader toggleSidebar={toggleSidebar} admin={state.userAccessContext.userRole} emailAddress={state.userAccessContext.emailAddress} switchUser={changeRole} />
                    <Hidden smDown>
                        <Sidebar routes={routes} sidebarVisible={sidebarVisible} toggleSidebar={toggleSidebar} admin={state.userAccessContext.userRole} />
                    </Hidden>
                    <Hidden smUp></Hidden>
                </Router>) : (
                <Router>
                    <NavbarHeader toggleSidebar={toggleSidebar} admin={state.userAccessContext.userRole} emailAddress={state.userAccessContext.emailAddress} switchUser={changeRole} />
                    <Content />
                </Router>)
            }
        </div>
    )
};

export default AdminLayout;
