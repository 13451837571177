/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import AddCommentIcon from '@mui/icons-material/AddComment';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import {
  Backdrop, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid,
  Icon, IconButton,  ListItem, ListItemText, Paper, TextField, Tooltip, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { MoonLoader } from 'react-spinners';
import { useGlobalState } from '../GlobalStore/globalStore';
import useStyles from '../components/NavbarSection/Header/NotificationCss';
import { IGetCourseDetail, INotificationDetails } from '../model/ICourse';
import { bgcolor } from '@mui/system';
import { usePost } from '../utils/apiHelper';

const ManageNotification = () => {
  const { state } = useGlobalState();
  const classes = useStyles();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [courseDetail, setCourseDetail] = useState<IGetCourseDetail[]>([]);
  const [selectAllCourses, setSelectAllCourses] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationDetails, setNotificationDetails] = useState<INotificationDetails[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const handleSelectAllCourses = () => {
    setSelectAllCourses(prevSelectAllCourses => !prevSelectAllCourses);
    if (!selectAllCourses) {
      setSelectedCourses(courseDetail.map(notification => (notification?.courseId)));
    } else {
      setSelectedCourses([]);
    }
  };

  const handleCreateDialogOpen = () => {
    setCreateDialogOpen(true);
  };

  const handleCreateNotification = () => {
    (async () => {
      setLoading(true);
      let request = {
        notificationMessgae: notificationMessage,
        notificationDetailContract: selectedCourses?.map(courseId => ({ courseId: courseId })),
        createdBy: state?.userAccessContext?.userId,
        modifiedBy: state?.userAccessContext?.userId,
      }
      // eslint-disable-next-line react-hooks/rules-of-hooks
      //await usePost("Notification/CreateNotificationRemainder", request).then((r) => {

      //})
    })().finally(() => {
      handleGetNotifications();
      setLoading(false);
    })
  }

  const handleClose = () => {
    setSelectAllCourses(false);
    setSelectedCourses([]);
    setCreateDialogOpen(false);
  }

  const handleCourseToggle = (course: number) => () => {
    setSelectedCourses(selected =>
      selected.includes(course) ? selected.filter(item => item !== course) : [...selected, course]
    );
  };

  const GetCourseDetails = () => {
    (async () => {
      setLoading(true);
      let request = 0;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      await usePost<IGetCourseDetail[]>("/Course/GetCourse?courseId=" + request, request)
        .then((response) => {
          setCourseDetail(response.data);
        })
    })().finally(() => {
      setLoading(false);
    })
  }

  const handleGetNotifications = () => {
    (async () => {
      setLoading(true);
      let UserId = state?.userAccessContext?.userId;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      // await usePost<INotificationDetails[]>("Notification/GetNotificationRemainders?UserId=" + UserId, UserId).then((r) => [
      //   setNotificationDetails(r?.data)
      // ])
    })().finally(() => {
      setLoading(false);
    })
  }

  const handleToggleNotification = () => {
    setIsPinned(prevState => !prevState);
  };

  useEffect(() => {
    GetCourseDetails();
    handleGetNotifications();
  }, []);

  return (
    <React.Fragment>
      {loading ? (<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
      </Backdrop>) : (
        <Grid container >
          {tableLoading && (
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={tableLoading}>
              <MoonLoader size={50} color={"#5e35b1"} loading={tableLoading} />
            </Backdrop>
          )}
          <Grid item xs={12} className={classes.borderBottom}>
            <Grid container >
              <Grid item xs={12} md={6.8}>
                <Typography variant='h6' color={'black'} ><b>Manage Notification</b></Typography>
              </Grid>
              <Grid item xs={7} md={4.2} display={"flex"} justifyContent={"flex-end"}>
                <TextField
                  fullWidth
                  size="small"
                  id="standard-bare"
                  variant="outlined"
                  // value={searchText}
                  className={classes.searchBox}
                  type='text'
                  placeholder="Search Notification by Title..."
                  // onChange={(e: any) => setSearchText(e.target.value)}
                  inputProps={{ maxLength: 255 }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      notchedOutline: classes.notchedOutline,
                    },
                    // endAdornment: (
                    //   // <InputAdornment position="end">
                    //   //   {showSearchIcon === true ?
                    //   //     <IconButton size='small' onClick={undefined} className={classes.searchIcon}>
                    //   //       <SearchIcon fontSize='small' />
                    //   //     </IconButton>
                    //   //     :
                    //   //     <IconButton size='small' onClick={(e) => {
                    //   //       setShowSearchIcon(true);
                    //   //       setSearchLab("");
                    //   //     }} className={classes.closeIcon}>
                    //   //       <CloseIcon fontSize='small' onClick={undefined} />
                    //   //     </IconButton>
                    //   //   }
                    //   // </InputAdornment>
                    // )
                  }}
                />
              </Grid>
              <Grid item xs={4} md={1} display={'flex'} justifyContent={'space-evenly'}>
                <Tooltip title={" Create notification"}>
                  <IconButton onClick={handleCreateDialogOpen} >
                    <AddCommentIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.TimeLineContainer}>
            <Grid item display={'flex'}>
              <Grid item xs={isPinned ? 9 : 12}>
                <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.TimeLineContainer}>
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                  >
                    {mockTimelineData.map((item, index) => (
                      <TimelineItem key={index}>
                        <TimelineSeparator>
                          <TimelineDot sx={{ bgcolor: 'blueviolet' }} />
                          {index < mockTimelineData.length - 1 && (
                            <TimelineConnector
                              sx={{ bgcolor: 'blueviolet' }}
                            />
                          )}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography variant="button" component="span">
                            {item.dateTime}
                          </Typography>

                          <Grid component={Paper} elevation={3} p={2} borderLeft={"5px solid blueviolet"}>
                            {item.Message.map((message, idx) => (
                              <ListItem key={idx} sx={{ paddingLeft: 0 }} onClick={handleToggleNotification}>
                                <ListItemText
                                  primary={message.notificationMessage}
                                  secondary={`Status: ${message.status} - Email: ${message.emailAddress}`}
                                />
                              </ListItem>
                            ))}
                          </Grid>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </Scrollbars>
              </Grid>
              {isPinned ?
                <Grid item xs={3} borderLeft={"1px dotted grey"} p={1}  >
                  <Paper elevation={1} sx={{ p: 1 }}>
                    <Grid item xs={12} display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1">Notification Name:</Typography>
                      <Typography variant="subtitle1">{sampleData.notificationName}</Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="space-between">
                      <Typography variant="body1">Date:</Typography>
                      <Typography variant="body1">{sampleData.date}</Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <Typography variant="h6">Notifications</Typography>
                      <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.noticationContainer}>
                        {sampleData.notifications.map((notif, index) => (
                          <Grid item xs={12} display="flex" justifyContent="space-between" key={index} >
                            <Typography variant="body1">{notif.name}</Typography>
                            <Button size="small" color={notif.status === 'success' ? 'success' : 'error'}>
                              <Icon component={notif.status === 'success' ? CheckCircleTwoToneIcon : HighlightOffTwoToneIcon} fontSize='small' />
                              {notif.status}
                            </Button>
                          </Grid>
                        ))}
                      </Scrollbars>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: 1, borderTop: '1px dotted grey' }}>
                      <Grid item xs={12} display="flex" justifyContent="space-between">
                        <Typography variant="body1">Total Counts:</Typography>
                        <Typography variant="body1">{sampleData.totalCounts}</Typography>
                      </Grid>
                      <Grid item xs={12} display="flex" justifyContent="space-between">
                        <Typography variant="body1">Read Counts:</Typography>
                        <Typography variant="body1">{sampleData.readCounts}</Typography>
                      </Grid>
                      <Grid item xs={12} display="flex" justifyContent="space-between">
                        <Typography variant="body1">Unread Counts:</Typography>
                        <Typography variant="body1">{sampleData.unreadCounts}</Typography>
                      </Grid>
                      <Grid item xs={12} display="flex" justifyContent="space-between">
                        <Typography variant="body1">Success Counts:</Typography>
                        <Typography variant="body1">{sampleData.successCounts}</Typography>
                      </Grid>
                      <Grid item xs={12} display="flex" justifyContent="space-between">
                        <Typography variant="body1">Failed Counts:</Typography>
                        <Typography variant="body1">{sampleData.failedCounts}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid> : null}
            </Grid>
          </Scrollbars>
          <Dialog
            open={createDialogOpen}
            onClose={handleClose}
            fullWidth
            PaperProps={{ style: { borderRadius: 15 } }}>
            <DialogTitle
              id="alert-dialog-title" style={{ backgroundColor: '#1976d2', color: 'white', justifyContent: 'center', display: 'flex' }}>
              <b>Create Notification Reminder</b>
              <CloseIcon onClick={handleClose} style={{ cursor: 'pointer', position: 'absolute', right: '14px', top: '14px' }} />
            </DialogTitle>
            <DialogContent>
              <Grid item xs={12}>
                <TextField
                  label="Notification Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={notificationMessage}
                  onChange={(e) => setNotificationMessage(e.target.value)}
                  style={{ marginBottom: '16px', padding: '15px' }}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} justifyContent={'space-between'}>
                  <Grid display={'flex'} justifyContent={'space-between'}>
                    <Typography variant='subtitle1' gutterBottom><b>Courses</b></Typography>
                    <FormControlLabel
                      control={<Checkbox
                        size='small'
                        checked={selectAllCourses}
                        onChange={handleSelectAllCourses}
                        style={{ marginRight: '25px' }}
                      />}
                      label="Select All"
                    />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} justifyContent={'space-between'}>
                      <Paper className={classes.papercontainer} >
                        <Scrollbars
                          autoHide
                          autoHideTimeout={1000}
                          autoHideDuration={10}
                          className={classes.courseContainer}
                        >
                          {courseDetail.map(course => (
                            <Grid container alignItems="center" className={classes.control}>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={<Checkbox
                                    checked={selectedCourses.includes(course?.courseId)}
                                    onChange={handleCourseToggle(course?.courseId)}
                                    // className={selectedCourses?.length > 0 ? classes?.checkBox : classes?.checkBox1}
                                    size='small'
                                  />}
                                  label={course.courseTitle}
                                />
                              </Grid>
                            </Grid>
                          ))}
                        </Scrollbars>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <DialogActions>
                <Button onClick={handleCreateNotification} color="secondary" component="label"
                  variant="contained"
                  style={{ backgroundColor: 'green' }}
                  size="small" >Send</Button>
                <Button onClick={handleClose} color="secondary" component="label"
                  variant="contained"
                  style={{ backgroundColor: 'red' }}
                  size="small" >CANCEL</Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ManageNotification;
const mockTimelineData = [
  {
    dateTime: '2024-05-22T07:00:00',
    Message: [
      {
        notificationMessage: 'Good morning! Start your day with a new course: Introduction to Python',
        createdBy: 1,
        createdDate: new Date('2024-05-22T07:00:00'),
        status: 'unread',
        emailAddress: 'user1@example.com',
      },
    ],
  },
  {
    dateTime: '2024-05-22T09:30:00',
    Message: [
      {
        notificationMessage: 'New course available: Advanced JavaScript',
        createdBy: 1,
        createdDate: new Date('2024-05-22T09:30:00'),
        status: 'unread',
        emailAddress: 'user1@example.com',
      },
      {
        notificationMessage: "Your course 'React for Beginners' has been updated.",
        createdBy: 2,
        createdDate: new Date('2024-05-22T09:45:00'),
        status: 'read',
        emailAddress: 'user2@example.com',
      },
      {
        notificationMessage: 'Admin message: Scheduled maintenance on 2024-05-25',
        createdBy: 1,
        createdDate: new Date('2024-05-22T09:50:00'),
        status: 'unread',
        emailAddress: 'user3@example.com',
      },
    ],
  },
  {
    dateTime: '2024-05-22T11:00:00',
    Message: [
      {
        notificationMessage: 'Your assignment for the JavaScript course is due tomorrow',
        createdBy: 3,
        createdDate: new Date('2024-05-22T11:00:00'),
        status: 'unread',
        emailAddress: 'user4@example.com',
      },
    ],
  },
  {
    dateTime: '2024-05-22T13:00:00',
    Message: [
      {
        notificationMessage: 'Lunch break! Check out the new discussion in the Python course',
        createdBy: 4,
        createdDate: new Date('2024-05-22T13:00:00'),
        status: 'unread',
        emailAddress: 'user5@example.com',
      },
    ],
  },
  {
    dateTime: '2024-05-22T15:30:00',
    Message: [
      {
        notificationMessage: 'Reminder: Webinar on React starts at 4 pm',
        createdBy: 5,
        createdDate: new Date('2024-05-22T15:30:00'),
        status: 'unread',
        emailAddress: 'user6@example.com',
      },
    ],
  },
  {
    dateTime: '2024-05-22T17:00:00',
    Message: [
      {
        notificationMessage: 'Webinar on React has started. Join now!',
        createdBy: 5,
        createdDate: new Date('2024-05-22T17:00:00'),
        status: 'unread',
        emailAddress: 'user6@example.com',
      },
    ],
  },
  {
    dateTime: '2024-05-22T19:00:00',
    Message: [
      {
        notificationMessage: 'New course available: Data Structures in Python',
        createdBy: 1,
        createdDate: new Date('2024-05-22T19:00:00'),
        status: 'unread',
        emailAddress: 'user1@example.com',
      },
    ],
  },
  {
    dateTime: '2024-05-22T21:00:00',
    Message: [
      {
        notificationMessage: 'Your feedback on the JavaScript course is appreciated',
        createdBy: 2,
        createdDate: new Date('2024-05-22T21:00:00'),
        status: 'read',
        emailAddress: 'user2@example.com',
      },
    ],
  },
  {
    dateTime: '2024-05-22T23:00:00',
    Message: [
      {
        notificationMessage: 'Good night! Don\'t forget to review today\'s lessons.',
        createdBy: 1,
        createdDate: new Date('2024-05-22T23:00:00'),
        status: 'unread',
        emailAddress: 'user1@example.com',
      },
    ],
  },
];
const sampleData = {
  notificationName: 'CourseStart Date Information',
  date: '02:02:2024:07:07:00',
  notifications: [
    { name: 'Kishor', status: 'success' },
    { name: 'Rohsik', status: 'failed' },
    { name: 'Alice', status: 'success' },
    { name: 'Bob', status: 'failed' },
    { name: 'Charlie', status: 'success' },
    { name: 'Diana', status: 'failed' },
    { name: 'Eve', status: 'success' },
    { name: 'Frank', status: 'failed' },
    { name: 'Grace', status: 'success' },
    { name: 'Hank', status: 'failed' },
    { name: 'Ivy', status: 'success' },
    { name: 'Jack', status: 'failed' },
    { name: 'Eve', status: 'success' },
    { name: 'Frank', status: 'failed' },
    { name: 'Grace', status: 'success' },
    { name: 'Hank', status: 'failed' },
    { name: 'Ivy', status: 'success' },
    { name: 'Jack', status: 'failed' },
    { name: 'Frank', status: 'failed' },
    { name: 'Grace', status: 'success' },
    { name: 'Hank', status: 'failed' },
    { name: 'Ivy', status: 'success' },
    { name: 'Jack', status: 'failed' },
  ],
  totalCounts: 12,
  readCounts: 6,
  unreadCounts: 6,
  successCounts: 6,
  failedCounts: 6,
};