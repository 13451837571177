/* eslint-disable @typescript-eslint/no-unused-vars */
//MUI COMPONENTS
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
//COMPONENTS
import { useMsal } from "@azure/msal-react";
import CardMembershipTwoToneIcon from '@mui/icons-material/CardMembershipTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import HelpCenterTwoToneIcon from '@mui/icons-material/HelpCenterTwoTone';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MLearnTooltip from "../../../GlobalStyles/ToolTip";
import profileIcon from '../../../images/ProfileCard/avatar.svg';

const ProfileCard = (props) => {
    const { instance } = useMsal();
    const { emailAddress, userRole } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const signout = () => {
        instance.logoutRedirect({
             postLogoutRedirectUri: `${window.document.location.origin}/login`
        })
    }

    return (
        <React.Fragment>
            <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <MLearnTooltip title={"Profile"} placement="top">
                    <Avatar alt="user profile" src={profileIcon} sx={{ width: 28, height: 28 }} />
                </MLearnTooltip >
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose} sx={{ backgroundColor: 'rgb(145 158 171 / 12%)', ml: 1, mr: 1, borderRadius: 1.5 }}>
                    <Grid spacing={0} container >
                        <Grid item xs={2}>
                            <Avatar alt="user profile" src={profileIcon} sx={{ width: 24, height: 24 }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" fontWeight={600}>{emailAddress}</Typography>
                            <Typography variant="body2" fontSize={'12px'} color={'gray'}>
                                {userRole ? "Admin" : "Learner"}
                            </Typography>
                        </Grid>
                    </Grid>
                </MenuItem>
                <Divider />
                <List sx={{ pt: 0, pb: 0 }}>
                    {userRole ?
                        <ListItem disablePadding>
                            <ListItemButton sx={{ pt: 0.5, pb: 0.5 }} component={Link} to="/dashboard">
                                <ListItemIcon sx={{ minWidth: 42 }}>
                                    <IconButton size="small" >
                                        <DashboardTwoToneIcon fontSize="small" sx={{ color: 'rgb(95, 116, 141)' }} />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2" fontWeight={500} color={'rgb(95, 116, 141)'}>Dashboard</Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        :
                        <React.Fragment>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ pt: 0.5, pb: 0.5 }} component={Link} to="/myCourse">
                                    <ListItemIcon sx={{ minWidth: 42 }}>
                                        <IconButton size="small">
                                            <MenuBookTwoToneIcon fontSize="small" sx={{ color: 'rgb(95, 116, 141)' }} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="body2" fontWeight={500} color={'rgb(95, 116, 141)'}>My Course</Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ pt: 0.5, pb: 0.5 }} component={Link} to="/claimCertificate">
                                    <ListItemIcon sx={{ minWidth: 42 }}>
                                        <IconButton size="small" >
                                            <CardMembershipTwoToneIcon fontSize="small" sx={{ color: 'rgb(95, 116, 141)' }} />
                                        </IconButton>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="body2" fontWeight={500} color={'rgb(95, 116, 141)'}>Claim Your Certificate</Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </React.Fragment>
                    }
                    <ListItem disablePadding>
                        <ListItemButton sx={{ pt: 0.5, pb: 0.5 }} component={Link} to="/profileAccount">
                            <ListItemIcon sx={{ minWidth: 42 }}>
                                <IconButton size="small" >
                                    <ManageAccountsTwoToneIcon sx={{ color: 'rgb(95, 116, 141)' }} />
                                </IconButton>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" fontWeight={500} color={'rgb(95, 116, 141)'}>Profile</Typography>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ pt: 0.5, pb: 0.5 }}>
                            <ListItemIcon sx={{ minWidth: 42 }}>
                                <IconButton size="small" >
                                    <HelpCenterTwoToneIcon fontSize="small" sx={{ color: 'rgb(95, 116, 141)' }} />
                                </IconButton>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body2" fontWeight={500} color={'rgb(95, 116, 141)'}>Help</Typography>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton sx={{ pt: 0.5, pb: 0.5 }}>
                            <ListItemIcon sx={{ minWidth: 42 }}>
                                <IconButton size="small" >
                                    <LogoutIcon fontSize="small" sx={{ color: 'rgb(95, 116, 141)' }} />
                                </IconButton>
                            </ListItemIcon>
                            <ListItemText onClick={signout}>
                                <Typography variant="body2" fontWeight={500} color={'rgb(95, 116, 141)'}>Logout</Typography>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Menu>
        </React.Fragment >
    );
};

export default ProfileCard;
