/* eslint-disable react-hooks/rules-of-hooks */
import { Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from '@mui/material';
import { useState } from 'react';
import { useGlobalState } from '../../../GlobalStore/globalStore';
import { AddTopicDialogProps, ITopicDetail } from '../../../model/course';
import { usePost } from '../../../utils/apiHelper';
import useStyles from "../CourseCss";

const AddTopicDialog: React.FC<AddTopicDialogProps> = (props) => {

    const { state } = useGlobalState();
    const Initail: ITopicDetail = {
        courseTopicId: 0,
        courseId: props.courseId,
        courseTopicName: "",
        courseTopicDescrition: "",
        courseTopicObjective: "",
        courseTopicVideoURL: "",
        courseTopicSearchURL: "",
        courseTopicPrompt: "",
        createdDate: "",
        modifiedDate: "",
        createdBy: "",
        modifiedBy: "",
        isAssignmentRequired: false,
        isAssignmentExist: 0
    };

    const classes = useStyles();
    const [openTopicDialog, setOpenTopicDialog] = useState(false);
    const [topicData, setTopicData] = useState<ITopicDetail>(Initail);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleOpenDialog = () => {
        if (props?.TopicId) {
            const filteredTopic = props.selectedTopic.find(
                (topic) => topic?.courseTopicId === props?.TopicId
            );
            setTopicData(filteredTopic);
        }
        setOpenTopicDialog(true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTopicData((prevData) => ({
            ...prevData,
            createdBy: topicData.courseTopicId ? prevData.createdBy : state.userAccessContext.userId.toString(),
            modifiedBy: state.userAccessContext.userId.toString(),
        }));

        const { value } = e.target;
        setTopicData((prevData) => ({
            ...prevData,
            courseTopicName: value
        }));
    };

    const handleClose = () => {
        setTopicData(Initail)
        setOpenTopicDialog(false);
    };
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleSave = async () => {
        setOpenTopicDialog(false);
        let TopicData = {
            courseTopicId: topicData.courseTopicId,
            courseId: props.courseId,
            courseTopicName: topicData.courseTopicName,
            courseTopicDescrition: topicData.courseTopicDescrition,
            courseTopicObjective: topicData.courseTopicObjective,
            courseTopicVideoURL: topicData.courseTopicVideoURL,
            courseTopicSearchURL: topicData.courseTopicSearchURL,
            courseTopicPrompt: topicData.courseTopicPrompt,
            isAssignmentRequired: topicData.isAssignmentRequired,
            modifiedBy: topicData.modifiedBy,
        };
        (async () => {
            await usePost("Course/SaveCourseTopicDetail", TopicData).then(() => {
                props.onCallBack();
            }).finally(() => {
                handleClose();
                setSnackbarMessage('Topic saved successfully');
                setSnackbarOpen(true);
            });
        })()
    };

    return (
        <Grid>
            {!props?.TopicId ? (
                <Button size="small" variant="contained" className={classes.createButton} onClick={handleOpenDialog}>
                    <b>Add Topic</b>
                </Button>
            ) : (<ModeTwoToneIcon fontSize="small" color="primary" onClick={handleOpenDialog} />)}
            <Dialog
                open={openTopicDialog}
                onClose={handleClose}
                fullWidth
                classes={{ paper: classes.deleteDialogue }}
                PaperProps={{ style: { borderRadius: 15 } }}
                className={classes.dialogBackground}
            >
                <DialogTitle id="alert-dialog-title" className={classes.dialogStyles}>
                    <b>{!topicData?.courseTopicId ? 'Add Topic' : "Edit Topic"}</b>
                    <CloseIcon onClick={handleClose} className={classes.closeButton} />
                </DialogTitle>
                <DialogContent>
                    <TextField fullWidth autoFocus margin="dense" size="small" type="text" value={topicData?.courseTopicName} onChange={handleChange} sx={{ mt: "1" }} />
                </DialogContent>
                <DialogActions className={classes.dialogActionPadding}>
                    <Button onClick={handleSave} color="success" variant="contained" size="small" className={classes.AddbuttonStyle} startIcon={<CheckCircleIcon />}>
                        <b>Save</b>
                    </Button>
                    <Button onClick={handleClose} color="error" variant="contained" size="small" className={classes.ButtonStyle} startIcon={<CancelIcon />}>
                        <b>Cancel</b>
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000} // Optional: Set duration to auto-close the snackbar
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set position to top center
                color='success'
            >
                <Alert onClose={handleCloseSnackbar} severity="success" icon={<CheckCircleOutlineIcon fontSize="small" />}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default AddTopicDialog;
