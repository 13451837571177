/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { ISubTopicDetail, ITopicDetail } from "../../../model/course";
import { usePost } from "../../../utils/apiHelper";
import useStyles from "../CourseCss";
import AddTopic from "./AddTopic";
import TopicContent from "./TopicContent";

const TopicManagement = ({ CourseId, activeStep, setActiveStep }) => {
  const classes = useStyles();
  const [topicData, setTopicData] = useState<ITopicDetail[]>([]);
  const [subTopicData, setSubTopicData] = useState<ISubTopicDetail[]>([]);
  const [selectedTopicId, setSelectedTopicId] = useState<number>();
  const [selectedSubTopicId, setSelectedSubTopicId] = useState<number>();
  const [flag, setFlag] = useState<boolean>();

  function GetTopicData() {
    fetchData();
  }
  const fetchData = () => {
    (async () => {
      await usePost<{ courseTopicDetailResults: ITopicDetail[], courseSubTopicDetailResults: ISubTopicDetail[] }>("/Course/GetCourseTopicDetail", { CourseId }).then((response) => {
        setTopicData(response.data.courseTopicDetailResults);
        setSubTopicData(response.data.courseSubTopicDetailResults);
        OnselectTopic( response?.data?.courseTopicDetailResults[0]?.courseTopicId, 0);
      })

    })()
  };

  useEffect(() => {
    fetchData();
  }, []);

  const OnselectTopic = (TopicId:number, SubTopicId:number) => {
    setSelectedTopicId(TopicId);
    setSelectedSubTopicId(SubTopicId);
    setFlag(!(TopicId && SubTopicId));
  };

  return (
    <React.Fragment>
      <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.courseContainer}>
        <Grid container spacing={0} padding={0} mt={0}>
          <Grid item xs={12} sm={12} md={2} lg={3} borderRight={"1px solid lightgray"}>
            <AddTopic topicData={topicData} subTopicData={subTopicData} OnCallBack={GetTopicData} courseId={CourseId} OnSelectedTopic={OnselectTopic} />
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={9}>
            <TopicContent courseId={CourseId} flag={flag} topicId={selectedTopicId} SubTopicId={selectedSubTopicId} onCallBack={GetTopicData} />
          </Grid>
        </Grid>
      </Scrollbars>
      <Box m={0} p={0.5} width={'100%'} display={'inline-flex'} justifyContent={'flex-end'} borderTop={'1px solid lightgray'}>
        <Button component="label" variant="contained" size="small" color="success" disabled={activeStep === 0} onClick={() => setActiveStep(activeStep - 1)} sx={{ marginRight: 1, marginBottom: 1 }}>
          <b>Back</b>
        </Button>
        <Button component="label" variant="contained" size="small" color="success" onClick={() => setActiveStep(activeStep + 1)} sx={{ marginRight: 1, marginBottom: 1 }}>
          <b>Next</b>
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default TopicManagement;
