/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Button, Grid, Typography, } from "@mui/material";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { ISubTopicDetail, ITopicDetail } from "../../../model/course";
import { usePost } from "../../../utils/apiHelper";
import EditMultipleChoice from "../AssignmentDetails/EditMultipleChoice";
import EditTopicAssignment from "../AssignmentDetails/EditTopicAssignment";
import useStyles from "../CourseCss";

const EditAssesments = ({ CourseId, activeStep, setActiveStep }) => {
    const classes = useStyles();
    const [topicId, setTopicId] = useState<number>();
    const [subTopicId, setSubTopicId] = useState<number>();
    const [topicLabel, setTopicLable] = useState<string>('');
    const [isAssignmentRequired, setIsAssignmentRequired] = useState(false);
    const [isAssignment, setIsAssignment] = useState(null);
    const [topicData, setTopicData] = useState<ITopicDetail[]>([]);
    const [subTopicData, setSubTopicData] = useState<ISubTopicDetail[]>([]);
    const handleSelectedTopic = (topicName: string, topicId: number, subTopicId: number, isAssignmentRequired: boolean) => {
        setTopicId(topicId);
        setSubTopicId(subTopicId);
        setTopicLable(topicName);
        setIsAssignmentRequired(isAssignmentRequired);
    };
    const fetchData = () => {
        (async () => {
            await usePost<{ courseTopicDetailResults: ITopicDetail[], courseSubTopicDetailResults: ISubTopicDetail[] }>("/Course/GetCourseTopicDetail", { CourseId }).then((response) => {
                setTopicData(response.data.courseTopicDetailResults);
                setSubTopicData(response.data.courseSubTopicDetailResults);
                const enableButton1 = response.data.courseTopicDetailResults.every(item => !item.isAssignmentRequired || item.isAssignmentExist >= 1);
                const enableButton2 = response.data.courseSubTopicDetailResults.every(item => !item.isAssignmentRequired || item.isAssignmentExist >= 1);
                setIsAssignment(enableButton1 && enableButton2);
            });
        })()
    };

    useEffect(() => {
        fetchData();
    }, []);
    const CheckFlag = (flag, id) => {
        if (flag) {
            const index = topicData.findIndex(topic => topic.courseTopicId === id);
            const updatedTopicData = [...topicData];
            updatedTopicData[index].isAssignmentExist = 1;
        }
        else {
            const index = subTopicData.findIndex(topic => topic.courseSubTopicId === id);
            const updatedSubTopicData = [...subTopicData];
            updatedSubTopicData[index].isAssignmentExist = 1;
        }
        const enableButton1 = topicData.every(item => !item.isAssignmentRequired || item.isAssignmentExist >= 1);
        const enableButton2 = subTopicData.every(item => !item.isAssignmentRequired || item.isAssignmentExist >= 1);
        setIsAssignment(enableButton1 && enableButton2);
    }
    return (
        <React.Fragment>
            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.courseContainer} >
                <Grid container spacing={0} padding={0} mt={0}>
                    <Grid item xs={12} sm={12} md={3} lg={3} borderRight={"1px solid lightgray"} >
                        <EditTopicAssignment handleSelectedTopicData={handleSelectedTopic} topicData={topicData} subTopicData={subTopicData} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9}>
                        <EditMultipleChoice SendAssignmentId={CheckFlag} topicId={topicId} subTopicId={subTopicId} courseId={CourseId} topicLabel={topicLabel} isAssignmentRequired={isAssignmentRequired} />
                    </Grid>
                </Grid>
            </Scrollbars>
            <Box m={0} p={0.5} width={'100%'} display={'inline-flex'} justifyContent={'flex-end'} borderTop={'1px solid lightgray'}>
                {!isAssignment && (
                    <Typography variant="body2" color="error">
                        Please complete all required assignments before Moving Next.
                    </Typography>
                )}
                <Button component="label" variant="contained" size="small" color="success" disabled={activeStep === 0}
                    onClick={() => setActiveStep(activeStep - 1)} sx={{ marginRight: 1, marginBottom: 1 }} >
                    <b>Back</b>
                </Button>
                <Button
                    component="label"
                    variant="contained"
                    size="small"
                    color="success"
                    onClick={() => { setActiveStep(activeStep + 1); }}
                    disabled={!isAssignment}
                    sx={{ marginRight: 1, marginBottom: 1 }}
                >
                    <b>Next</b>
                </Button>
            </Box>
        </React.Fragment >
    )
}

export default EditAssesments;