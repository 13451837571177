/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { useEffect, useState } from 'react';
import { MoonLoader } from "react-spinners";
import { ICoupon } from '../../../model/course';
import { usePost } from '../../../utils/apiHelper';
import DeleteCoupon from './DeleteCoupon';
import ManageCoupon from './ManageCoupon';
import useStyles from '../CourseCss';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';

const PaymentDetail = ({ activeStep, setActiveStep, courseCode, courseId , onCloseStepper}) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [couponData, setCouponData] = useState<ICoupon[]>([]);

    const handleFinish = () => {
        setOpenDialog(true);
    };


    const handleCloseDialog = () => {
        setOpenDialog(false);
        onCloseStepper(); 
    };
    useEffect(() => (
        Getcoupon()
    ), []);

    const Getcoupon = () => {
        (async() => {
            setLoading(true);
            await usePost<ICoupon[]>(`Course/GetCourseCoupon?courseId=${courseId}`, courseId).then((r) =>{
                setCouponData(r.data); 
            }).finally(() => {
                setLoading(false);
            })
        })();
    };

    const initial: ICoupon = {
        couponId: 0,
        courseId: courseId,
        amount: 0,
        discountPercentage: 0,
        gstPercentage: null,
        couponCode: "",
        validityDays: 0,
        actualAmount: 32,
        isActive: null,
        createdBy: 0,
        modifiedBy: 0,
        createdDate: null,
        modifiedDate: null,
    }
    return (
    <React.Fragment>
      <Grid container spacing={0} padding={0.1} borderBottom="1px solid lightgray">
        <Grid item xs={6} padding={0} mt={1}>
          <Typography variant="body1" align="center">
            <b>Course Discount Coupons</b>
          </Typography>
        </Grid>
        <Grid item xs={6} padding={0.9} display={"flex"} justifyContent={"flex-end"}>
          <ManageCoupon
            coupon={initial}
            courseId={courseId}
            Code={courseCode}
            GetCoupon={Getcoupon}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent="center" alignItems="center" p={1}>
      <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.courseQuestion}>
                {loading ? (
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                        <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
                    </Backdrop>
                ) : (
                    <Grid item xs={8} ml={30} p={1}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead className={classes.tableHeadBackground}>
                                    <TableRow   >
                                        <TableCell size='small' className={classes.textcolor}>Course-Code</TableCell>
                                        <TableCell size='small' className={classes.textcolor}>Amount</TableCell>
                                        <TableCell size='small' className={classes.textcolor}>Discount %</TableCell>
                                        <TableCell size='small' className={classes.textcolor}>GST %</TableCell>
                                        <TableCell size='small' className={classes.textcolor}>Coupon Code</TableCell>
                                        <TableCell size='small' className={classes.textcolor}>Validity Days</TableCell>
                                        <TableCell size='small' className={classes.textcolor}>Actual Amount</TableCell>
                                        <TableCell size='small' className={classes.textcolor} colSpan={2} align='center'>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {couponData ?
                                        (couponData.map((coupon) => (
                                            <TableRow key={coupon.couponId} >
                                                <TableCell size='small'>{courseCode}</TableCell>
                                                <TableCell size='small'>{coupon.amount}</TableCell>
                                                <TableCell size='small'>{coupon.discountPercentage}</TableCell>
                                                <TableCell size='small'>{coupon.gstPercentage}</TableCell>
                                                <TableCell size='small'>{coupon.couponCode}</TableCell>
                                                <TableCell size='small'>{coupon.validityDays}</TableCell>
                                                <TableCell size='small'>{coupon.actualAmount}</TableCell>
                                                <TableCell size='small'><ManageCoupon coupon={coupon} Code={courseCode} courseId={courseId} GetCoupon={Getcoupon} /></TableCell>
                                                <TableCell size='small'><DeleteCoupon CouponId={coupon.couponId} onDelete={Getcoupon} /></TableCell>
                                            </TableRow>
                                        )))
                                        : (
                                            // Render a TableRow with a message if no coupons are available
                                            <TableRow>
                                                <TableCell color='danger'>No Coupons available...</TableCell>
                                            </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
        )}
        </Scrollbars>
      </Grid>

            <Box m={0} p={0.4} width={'100%'} display={'inline-flex'} justifyContent={'flex-end'} borderTop={'1px solid lightgray'}>
                <Button
                    component="label"
                    variant="contained"
                    size="small"
                    color="success"
                    disabled={activeStep === 0}
                    onClick={() => setActiveStep(activeStep - 1)}
                    style={{ marginRight: 1, marginBottom: 1 }}
                >
                    <b>Back</b>
                </Button>
                <Button
                    component="label"
                    variant="contained"
                    size="small"
                    color="success"
                    onClick={handleFinish} // Changed onClick to handleFinish
                    style={{ marginRight: 1, marginBottom: 1 }}
                >
                    <b>Finish</b>
                </Button>
            </Box>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <CheckCircleOutlineOutlinedIcon fontSize="large" color="success" />
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">
                                Course Updated Successfully
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
    </React.Fragment>
  );
};

export default PaymentDetail;
