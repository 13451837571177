import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Backdrop, Button } from '@mui/material';
import { useState } from 'react';
import { MoonLoader } from 'react-spinners';
import { useDelete } from '../../../utils/apiHelper';
import useStyles from '../CourseCss';
import MLearnTooltip from '../../../GlobalStyles/ToolTip';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const DeleteSubTopic: React.FC<{ subTopicId: number, Flag: boolean, onCallBack: () => void }> = (props) => {
    const { subTopicId, onCallBack } = props;

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteSubTopic = () => {
        setLoading(true);
        if (!props.Flag) {
            (async () => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                await useDelete(`Course/DeleteSubTopic?SubTopicId=${subTopicId}`);
            })().finally(() => {

                handleClose();

            })
        }
        else {
            (async () => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                await useDelete(`/Course/DeleteTopic?TopicId=${subTopicId}`);
            })().finally(() => {

                handleClose();

            })
        }

        setLoading(false);
        onCallBack();
    }

    return (
        <div>
            <MLearnTooltip title="Delete Topic" placement="top">
            <IconButton onClick={handleClickOpen} size='small'>
                <DeleteTwoToneIcon fontSize="small" color="error" />
            </IconButton>
            </MLearnTooltip>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
            </Backdrop>
            < Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'sm'}
                classes={{ paper: classes.deleteDialogue }}
                PaperProps={{ style: { borderRadius: 15 } }}
                className={classes.dialogBackground}
            >
                <DialogTitle id="alert-dialog-title"className={classes.dialogStyles}><b>{"Delete Topic"}</b>
                    <CancelOutlinedIcon onClick={handleClose}   className={classes.closeButton}/>
                </DialogTitle>
                <DialogContent >
                        Are you sure want to delete the topic?
                </DialogContent>
                <DialogActions className={classes.dialogActionPadding}>
                    <Button onClick={deleteSubTopic} color="success" variant='contained' size='small' startIcon={<CheckCircleIcon />}>
                        <b>Yes</b>
                    </Button>
                    <Button onClick={handleClose} color="error" variant='contained' size='small' startIcon={<CancelIcon />}>
                        <b>NO</b>
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default DeleteSubTopic;