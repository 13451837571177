/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteTwoTone from "@mui/icons-material/DeleteTwoTone";
import { Autocomplete, Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { SyncLoader } from 'react-spinners';
import { useGlobalState } from "../../../GlobalStore/globalStore";
import VisuallyHiddenInput from "../../../GlobalStyles/VisuallyHiddenInput";
import { baseImage } from "../../../constant/Constant";
import { ICourseInformation, IGetCourseDetail } from "../../../model/ICourse";
import { CourseLevel, IError } from "../../../model/Users/IUser";
import { ICourseFile, ILevel } from "../../../model/course";
import { base64toBlob } from "../../../utils/Base64toblob";
import { useDelete, usePost } from "../../../utils/apiHelper";
import * as constant from '../../Constant/Constant';
import useStyles from "../CourseCss";
import CustomizedDividers from "../Topic/TopicContent/CustomizedDividers";
import { Skeleton } from '@mui/material';

const AddEditCourseDetails: React.FC<ICourseInformation> = (props) => {
  const { state } = useGlobalState();
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState<any>();
  const { activeStep, setActiveStep, GetCourseInformation, courseDetails } = props;
  const [CourseMediaList, setCourseMediaList] = useState<ICourseFile[]>([]);
  const [course, setCourse] = useState<IGetCourseDetail>(courseDetails);
  const [deleteImageId, setDeleteImageId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [hoveredImageId, setHoveredImageId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [courseLevelError, setCourseLevelError] = useState<string>('');
  const [courseDetailValidation, setcourseDetailValidation] = useState<IError[]>([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isNextButtonEnable, setIsNextButtonEnable] = useState(false);

  const handleConfirm = () => {
    setCourse((prevCourse) => ({
      ...prevCourse,
      createdBy: prevCourse.courseId ? prevCourse.createdBy : state.userAccessContext.userId,
      modifiedBy: state.userAccessContext.userId,
    }));
    setOpenConfirmation(true);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleCancelNext = () => {
    setOpenConfirmation(false);
  };

  useEffect(() => {
    GetCourseMediaDetail();
  }, [courseDetails?.courseId]);

  const handleCourseLevelChange = (event, newValue: ILevel) => {
    if (newValue) {
      setCourse({
        ...course,
        courseLevel: newValue.id,
      });
      setCourseLevelError('');
    } else {
      setCourseLevelError('Course Level is required');
    }
  };

  const handleChange = (event: any) => {
    setCourse({
      ...course,
      isCertification: JSON.parse(event.target.value),
    });
  };

  useEffect(() => {
    const Data = !!courseDetails?.courseId;
    setIsNextButtonEnable(Data);
  }, [courseDetails?.courseId]);

  const handleValidateNames = (event: any) => {
    let controlValue = event.target.value;
    let controlName = event.target.name;

    if (controlName?.includes("courseCode") || controlName?.includes("courseTitle")) {
      let nameRegex = new RegExp(constant.NameRegex);

      // This is to take the copy of the existing state values 
      let currentValidationError = _.cloneDeep(courseDetailValidation);

      // Check the inuted values are matches with the regex which is provided
      if (!nameRegex.test(controlValue)) {
        let nameValidation: IError = {
          errorNumber: 1,
          errorMessage: `${controlName} Should be Valid and Required`,
          fieldName: controlName
        }
        // Checks the dublicate errors are exist or not if exist not pushing the error values to the specific state
        if (currentValidationError.findIndex(x => x.fieldName === controlName) <= -1) {
          // push the new Error list values to the current Error list
          currentValidationError.push(nameValidation);
          setcourseDetailValidation(currentValidationError);
        }
      }
      else if ((nameRegex.test(controlValue))) {
        // Remove the Error Message if the valid data is entered
        if (currentValidationError.findIndex(x => x.fieldName === controlName) > -1) {
          // To filter the error values from the list which is matches with the controlName 
          let errorList = currentValidationError.filter(x => x.fieldName !== controlName);
          setcourseDetailValidation(errorList);
        }
      }
    }
  }

  const handleValidateDuration = (event: any) => {
    let controlValue = event.target.value;
    let controlName = event.target.name;

    if (controlName?.includes("durationDays")) {
      let nameRegex = new RegExp(constant.durationDays);

      // This is to take the copy of the existing state values 
      let currentValidationError = _.cloneDeep(courseDetailValidation);

      // Check the inuted values are matches with the regex which is provided
      if (!nameRegex.test(controlValue)) {
        let nameValidation: IError = {
          errorNumber: 1,
          errorMessage: `${controlName} Should be Valid and Required`,
          fieldName: controlName
        }
        // Checks the dublicate errors are exist or not if exist not pushing the error values to the specific state
        if (currentValidationError.findIndex(x => x.fieldName === controlName) <= -1) {
          // push the new Error list values to the current Error list
          currentValidationError.push(nameValidation);
          setcourseDetailValidation(currentValidationError);
        }
      }
      else if ((nameRegex.test(controlValue))) {
        // Remove the Error Message if the valid data is entered
        if (currentValidationError.findIndex(x => x.fieldName === controlName) > -1) {
          // To filter the error values from the list which is matches with the controlName 
          let errorList = currentValidationError.filter(x => x.fieldName !== controlName);
          setcourseDetailValidation(errorList);
        }
      }
    }
  }

  const handleEditorChange = (newContent: string) => {
    setCourse({
      ...course,
      whatyouwillLearn: newContent,
    });
  };

  const handleAbstractChange = (newContent: string) => {
    setCourse({
      ...course,
      courseAbstract: newContent,
    });
  }
  const allowedImageTypes = ['.jpeg', '.jpg', '.svg', '.png'];
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    const allowedImageTypes = ['jpeg', 'jpg', 'svg', 'png'];
  
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileType = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
  
      if (allowedImageTypes.includes(fileType)) {
        setFile(selectedFile);
  
        if (imageUrl[0]?.fileId) {
          setDeleteImageId(imageUrl[0].fileId);
        }
  
        if (courseDetails.courseId) {
          const formData = new FormData();
          formData.append('file', selectedFile);
          formData.append('CourseId', courseDetails.courseId.toString());
  
          await usePost('File/InsertCourseMediaDetail', formData);
        }
      } else {
        console.log('Invalid file type. Only JPEG, JPG, SVG, and PNG are allowed.');
      }
    }
  };
  

  const handleImageHover = (fileId) => {
    setHoveredImageId(fileId);
  };

  const handleImageLeave = () => {
    setHoveredImageId(null);
  };

  const handleCancelUpload = () => {
    setFile(null);
  };

  const handleSubmit = () => {
    setOpenConfirmation(false);
    (async () => {
      await usePost("Course/ManageCourse", course).then(async (result) => {
        GetCourseInformation(result.data[0]);
        setCourse(result.data[0]);
        setActiveStep(activeStep + 1);
        props.onCourseAddedOrEdited();
        if (file) {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('CourseId', result.data[0]?.courseId);
          await usePost('File/InsertCourseMediaDetail', formData,)
        }
        handleConfirm();
      })
    })()
  };

  const GetCourseMediaDetail = () => {
    (async () => {
      let requests = {
        courseId: course.courseId,
        courseTopicId: null,
        courseSubTopicId: null,
      };
      await usePost<ICourseFile[]>("File/GetCourseMediaDetail", requests).then(
        (r) => {
          setCourseMediaList(r?.data);
        }
      );
    })();
  };


  const handleChanges = (e) => {
    setCourse({
      ...course,
      [e.target.name]: e.target.value,
    });
  }

  const fetchProductImages = async (blobUri: any) => {
    const request = { "pdfUri": blobUri };

    const response = await usePost<any>("File/FileDownload", request);
    const imageUrl = URL.createObjectURL(base64toBlob(baseImage + response?.data));
    return imageUrl;
  };

  useEffect(() => {
    fetchImageUrls();
  }, [CourseMediaList]);

  useEffect(() => {
    setLoading(true);
    GetCourseInformation(courseDetails);
  }, [])
  const fetchImageUrls = async () => {
    const imageDetails = await Promise.all(
      CourseMediaList.map(async (result) => {
        if (allowedImageTypes.includes(result.fileType.toLowerCase())) {
          const imageUrl = await fetchProductImages(result.fileURI);
          if (imageUrl) {
            return {
              fileId: result.fileId,
              imageUrl: imageUrl,
            };
          }
        }
        return null;
      })
    );

    // Filter out null values from the result
    const filteredImageDetails = imageDetails.filter((detail) => detail !== null);

    // Set the filtered image URLs
    setImageUrl(filteredImageDetails);
    setLoading(false);
  };
  const handleImageDelete = (fileId) => {
    setDeleteImageId(fileId);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirmation = () => {
    (async () => {
      await useDelete(`File/DeleteCourseMediaDetail?FileId=${deleteImageId}`);
      setIsDeleteDialogOpen(false);
      setCourseMediaList((prevMediaList) =>
        prevMediaList.filter((media) => media?.fileId !== deleteImageId)
      );
    })();
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <React.Fragment>
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <SyncLoader size={10} color={"#5e35b1"} loading={loading} />
        </Backdrop>
      )}
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={10}
        className={classes.courseContainer}
      >
        <Grid container spacing={1} padding={"8px 16px"} mt={0}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              label="Course Code"
              variant="outlined"
              size="small"
              name="courseCode"
              value={course?.courseCode}
              className={classes.textfiled}
              onChange={(e) => { handleChanges(e); handleValidateNames(e); }}
              error={!!courseDetailValidation.find((err) => err.fieldName === "courseCode")}
              helperText={courseDetailValidation.find((err) => err.fieldName === "courseCode")?.errorMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              size="small"
              label="Course Title"
              variant="outlined"
              value={course?.courseTitle}
              name="courseTitle"
              className={classes.textfiled}
              onChange={(e) => { handleChanges(e); handleValidateNames(e); }}
              error={!!courseDetailValidation.find((err) => err.fieldName === "courseTitle")}
              helperText={courseDetailValidation.find((err) => err.fieldName === "courseTitle")?.errorMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              size="small"
              label="Duration Days"
              variant="outlined"
              name="durationDays"
              value={course?.durationDays}
              className={classes.textfiled}
              onChange={(e) => { handleChanges(e); handleValidateDuration(e); }}
              error={!!courseDetailValidation.find((err) => err.fieldName === "durationDays")}
              helperText={courseDetailValidation.find((err) => err.fieldName === "durationDays")?.errorMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              options={CourseLevel}
              size="small"
              onChange={handleCourseLevelChange}
              getOptionLabel={(option) => option.label}
              value={
                CourseLevel.find(
                  (option) => option.id === course?.courseLevel
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Course Level"
                  variant="outlined"
                  className={classes.textfiled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              variant="body2"
              border={"1px solid lightgray"}
              padding={"1px"}
              color={"#9b9b9b !important"}>Course Abstract</Typography>
            <Box
              border={"1px solid lightgray"}
              height={"130px"}
              borderRadius={"5px"}
            >
              <CustomizedDividers
                onChange={handleAbstractChange}
                value={course?.courseAbstract}
                minHeight="150px"
                minWidth={100}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              variant="body2"
              border={"1px solid lightgray"}
              padding={"1px"}
              color={"#9b9b9b !important"}>What you will learn</Typography>
            <Box  border={"1px solid lightgray"}  height={"130px"}  borderRadius={"5px"} >
              <CustomizedDividers  onChange={handleEditorChange}  value={course?.whatyouwillLearn} minHeight="150px" minWidth={100}/>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} mt={7}>
            <label htmlFor="fileInput">
              <Box
                m={0}
                border={"1px dashed #5e35b1"}
                className={classes.imageBox}
                p={2.5}
                borderRadius={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                bgcolor={"#f6f5f8"}
                mt={2}
                itemType="file"
              >
                <CloudUploadIcon   fontSize="large"  style={{ color: "#5e35b1" }} />
                <Typography   variant="body2"  textAlign={"center"}  color={"#5e35b1"} >
                  <b>Choose image file to upload</b>
                </Typography>
                <Typography  variant="body2" color={"gray"} textAlign={"center"}  fontSize={12} >
                  Supported formats: PNG, JPG, JPEG
                </Typography>
              </Box>
            </label>
            <VisuallyHiddenInput  type="file"  id="fileInput"
              onChange={handleFileChange}
            />
          </Grid>
          {/* Skeleton loading state for image grid */}
          {!file && !(imageUrl && imageUrl.length > 0) && (
            <Grid item xs={12} sm={12} md={2} mt={7}>
              <Skeleton variant="rectangular" className={classes.skeleton} height={100} />
            </Grid>
          )}

          {file || imageUrl?.length > 0 ?
            <Grid item xs={12} sm={12} md={6} lg={(file || imageUrl?.length > 0) ? 2 : 0} mt={7}>
              {file && (
                <img
                  src={URL.createObjectURL(file)}
                  alt="Thumbnail"
                  className={classes.image}
                />
              )}
              {file && (
                <IconButton
                  onClick={handleCancelUpload} style={{ position: "absolute" }}>
                  <CancelIcon fontSize="small" />
                </IconButton>
              )}
            
                <Grid
                  onMouseEnter={() => handleImageHover(imageUrl[0]?.fileId)}
                  onMouseLeave={handleImageLeave}
                >
                  <img
                    src={imageUrl[0]?.imageUrl}
                    alt="Thumbnail"
                    className={classes.image}
                  />
                  {hoveredImageId === imageUrl[0]?.fileId && (
                    <IconButton
                      onClick={() => handleImageDelete(imageUrl[0]?.fileId)} style={{ position: "absolute" }}
                    >
                      <DeleteTwoTone fontSize="small" color="error" />
                    </IconButton>
                  )}
                </Grid>
            
            </Grid>
            :
            null}
          <Grid item xs={12} sm={12} md={6} lg={6} mt={8}>
            <Grid display={"flex"} justifyContent={"space-between"}>
              <Grid item xs={6} sm={5} md={5} lg={5}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="No of attempt permitted"
                  variant="outlined"
                  value={course?.noOfAttemptsPermitted}
                  className={classes.textfiled}
                  onChange={handleChanges}
                  name="noOfAttemptsPermitted"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={5} md={5} lg={5}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="Pass Criteria"
                  variant="outlined"
                  value={course?.passCriteria}
                  className={classes.textfiled}
                  onChange={handleChanges}
                  name="passCriteria"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControl size="small" className={classes.textfiled}>
                <FormLabel id="demo-controlled-radio-buttons-group" sx={{ fontSize: 13 }} >
                  Eligible for Certificate
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="certificationShowed"
                  value={course?.isCertification}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Scrollbars>
      <Box
        m={0}
        p={1}
        width={"100%"}
        display={"inline-flex"}
        justifyContent={"flex-end"}
        borderTop={"1px solid lightgray"}

      >
        <Button
          component="label"
          variant="contained"
          size="small"
          color="success"
          onClick={handleConfirm}
          disabled={!course?.courseCode || !course?.courseTitle || !course?.durationDays || !!courseDetailValidation?.length}
          sx={{ marginRight: 1 }}
        >
          <b>Save</b>
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleNext}
          size="small"
          disabled={!isNextButtonEnable || !course?.courseCode || !course?.courseTitle || !course?.durationDays || !!courseDetailValidation?.length}>
          Next
        </Button>
      </Box>

      <Dialog fullWidth open={isDeleteDialogOpen} onClose={handleDeleteCancel} classes={{ paper: classes.deleteDialogue }}>
        <DialogTitle id="alert-dialog-title" className={classes.dialogStyles}>
          <b>{"Delete Thumbnail"}</b>
          <CloseIcon
            onClick={handleDeleteCancel}
            className={classes.closeButton}
          />
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this THUMNAIL?
        </DialogContent>
        <DialogActions >
          <Button
            onClick={handleDeleteConfirmation}
            color="success"
            variant="contained"
            size="small" className={classes.ButtonStyle} startIcon={<CheckCircleIcon />}
          >
            yes
          </Button>
          <Button
            onClick={handleDeleteCancel}
            color="error"
            variant="contained"
            size="small"
            className={classes.AddbuttonStyle} startIcon={<CancelIcon />}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirmation} onClose={handleCancelNext} PaperProps={{ className: classes.dialogBorderRadius }} >
        <DialogTitle className={classes.dialogStyles}>Confirmation
          <CloseIcon
            fontSize="small"
            onClick={handleCancelNext}
            className={classes.closeButton}
          />
        </DialogTitle>
        <DialogContent>
          Are you sure you want to save the changes?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} variant="contained" size="small" className={classes.AddbuttonStyle} startIcon={<CheckCircleIcon />}>
            Yes
          </Button>
          <Button onClick={handleCancelNext} size="small" color="error" variant="contained" className={classes.ButtonStyle} startIcon={<CancelIcon />}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddEditCourseDetails;