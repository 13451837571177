import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: '20px',
    borderRadius: '12px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
    backgroundColor: '#fff',
    position: 'relative',
    textAlign: 'center',
  },
  iconContainer: {
    position: 'absolute',
    top: '0px',
    left: '12px',
    backgroundColor: '#fff',
    borderRadius: '15%',
    padding: '5px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
  },
  courseContainer: {
    [theme.breakpoints.up("lg")]: {
      maxHeight: "calc( 100vh - 75px) !important",
      minHeight: "calc( 100vh - 75px) !important",
    },
  },
}));