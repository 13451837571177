export const baseImage = "data:image/png,image/jpg,image/jpeg;base64";

export const NameRegex =/^[a-zA-Z0-9\s-]*$/;
export const phoneRegex = /^\+?[1-9]\d{1,9}$/;
export const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
export const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
export const AddressRegex  =/^[a-zA-Z0-9\s\-_!@#$%^&*()+=?<>{}[\]:";',./|\\]+$/i;
export const DescriptionRegex =/^.{1,3000}$/;
export const imageFilenameRegex = /^[\w\d\s-]+\.jpg$/i;
export const priceRegex = /^\$?\d+(\.\d{1,2})?$/;
export const futureDateRegex = /^(?!.*(?:(?:19|20)\d\d-(?:0[1-9]|1[0-2])-0[1-9]|1\d|2[0-8]|29(?=-0[1-9]|1[0-2]))).*$/;
export const messageRegex= /^\w+$/;
export const durationDays= /^[0-9]*$/;