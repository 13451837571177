/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
//MUI_COMPONENTS
import { AccordionDetails, Button, FormControlLabel, Grid, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Link, useHistory, useLocation } from "react-router-dom";
//COMPONENTS 
import CountDown from "./Timer";
import CustomizedProgressBars from "./progressBar";
//STYLE
import Cup from "../../images/Assesment/cup.svg";
import { useStyles } from "./UserAssessmentCss";
//REACT_ANIMATION
import ConfettiExplosion from "react-confetti-explosion";
import Scrollbars from "react-custom-scrollbars";
import { useGlobalState } from "../../GlobalStore/globalStore";
import { baseImage } from "../../constant/Constant";
import { IAssignmentResult, ICourseAssignment, IGetCourseDetail, IUserAssignment } from "../../model/ICourse";
import { IAssignmentReviewState, IGetUserCourseProgressResult, IUserCourseProgressTracker } from "../../model/UserCourse/IUserCourseContract";
import { Question } from "../../model/multipleChoiceQuestions";
import { base64toBlob } from "../../utils/Base64toblob";
import { usePost } from "../../utils/apiHelper";
import AssessmentReview from "./Review";

const UserMultipleChoice = () => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const [showResult, setShowResult] = useState(true);
    const [showAnimation, setShowAnimation] = useState(true);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const location = useLocation();
    const { courseId, topicId, topicName, subTopicId, subTopicName, courseTitle } = location.state;

    const baseQuestion: Question[] = [{
        question: "",
        files: [],
        answers: [],
        currentQuestion: { options: [{ checked: false, description: "" }] },
        unsavedChanges: false,
        blobUri: ""
    },];

    const [questions, setQuestions] = useState<Question[]>(baseQuestion);
    const [imageUrl, setImageUrl] = useState("");
    const [score, setScore] = useState<string>("");
    const [selectedOptions, setSelectedOptions] = useState(Array(questions.length).fill(null));
    const [courseAssignmentId, setCourseAssignmentId] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [getcourse, setGetCourse] = useState<IGetCourseDetail>();
    const [courseProgress, setCourseProgress] = useState<IGetUserCourseProgressResult>();

    useEffect(() => {
        getImageUrl();
    }, [currentQuestion, questions]);

    const getImageUrl = () => {
        (async () => {
            const url = await fetchProductImages(questions[currentQuestion]?.blobUri);
            setImageUrl(url);
        })().finally(() => {
        })
    }

    let value: IAssignmentReviewState = {
        courseId: courseId,
        topicId: topicId,
        topicName: topicName,
        subTopicId: subTopicId,
        subTopicName: subTopicName,
        selectedOptions: selectedOptions,
        questions: questions,
    };


    useEffect(() => {
        if (questions.length === 0) {
            setShowAnimation(false);
            setShowResult(false);
            return;
        }
        fetchData();
        GetCourseProgressDetails();
    }, []);

    const handleReattempt = () => {
        fetchData();
        setShowResult(true);
        setCurrentQuestion(0);
        setScore("");
        setSelectedOptions(Array(questions.length).fill(null));
    };
    // get the question and options
    const fetchData = () => {

        fetchCourseDetail();
        (async () => {
            let request = {
                "courseId": courseId,
                "courseTopicId": topicId,
                "courseSubTopicId": subTopicId
            };
            await usePost<ICourseAssignment>("Course/GetCourseAssignment", request).then((result) => {
                setCourseAssignmentId(result?.data[0]?.courseAssignmentId);
                if (Array.isArray(result.data)) {
                    const updatedQuestions = result.data.flatMap((assignment: any) => {
                        let newAssignmentList = JSON.parse(assignment?.assignmentDescription);
                        if (Array.isArray(newAssignmentList)) {
                            const assignmentDescriptionArray = newAssignmentList;
                            const questions = assignmentDescriptionArray.map((question?: any) => ({
                                question: question?.question,
                                files: [],
                                answers: question?.options?.map((option?: any) => option?.Option?.answer),
                                currentQuestion: {
                                    options: question?.options?.map((option?: any) => ({
                                        checked: option?.Option?.answer === question?.selectedAnswer,
                                        description: option?.Option?.description,
                                    })),
                                },
                                selectedAnswer: question?.selectedAnswer,
                                unsavedChanges: false,
                                blobUri: question?.blobUri,
                            }));
                            return questions;
                        } else {
                            return [];
                        }
                    });
                    const flattenedQuestions = updatedQuestions.flat();
                    setQuestions(flattenedQuestions);
                }
            }).finally(
            );
        })()
    };

    const updateAssignmentDescription = () => {
        const serializeQuestion = (question, index) => {
            return {
                question: question?.question,
                options: question?.answers,
                userSelectedAnswer: selectedOptions[index], // Include the selected option
            };
        };
        const assignmentDescription = questions?.map(serializeQuestion);
        return JSON.stringify(assignmentDescription);
    };

    const handleNextQuestion = () => {
        setCurrentQuestion(prev => prev + 1);
    };

    const handlePreviewQuestion = () => {
        setCurrentQuestion(prev => prev - 1);
    };

    const isLastQuestion = currentQuestion === questions?.length - 1;
    const isFirstQuestion = currentQuestion === 0;

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowAnimation(false);
        }, 3000);

        return () => {
            clearTimeout(timeout);
        }
    }, []);

    const fetchProductImages = (blobUri: any) => {
        return (async () => {
            try {
                const request = { "pdfUri": blobUri };
                const response = await usePost<any>("File/FileDownload", request);
                return URL.createObjectURL(base64toBlob(baseImage + response?.data));
            } catch (error) {
                console.error("An error occurred while fetching product images:", error);
                return "";
            }
        })();
    };

    const handleRadioChange = (event, currentQuestion) => {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions[currentQuestion] = event.target.value;
        setSelectedOptions(newSelectedOptions);
    };

    const calculateScore = () => {
        let correctAnswers = 0;

        for (let i = 0; i < questions.length; i++) {
            const correctAnswer = questions[i].selectedAnswer;
            const userAnswer = selectedOptions[i];
            if (userAnswer === correctAnswer) {
                correctAnswers++;
            }
        }
        const scoreFraction = `${correctAnswers} / ${questions.length}`;
        return scoreFraction;
    };
    //To get the passcriteria
    const fetchCourseDetail = () => {
        (async () => {
            const request = {
                "courseId": courseId
            };
            await usePost<IGetCourseDetail>("Course/GetCourse?courseId=" + courseId, request).then((result) => {
                setGetCourse(result.data[0]);
            });
        })();
    };

    // to save the submit questions with answer
    const handleSubmit = () => {
        const scoreFraction = calculateScore();
        setScore(scoreFraction);
        (async () => {
            let request = {
                "CourseAssignmentId": courseAssignmentId,
                "userId": state?.userAccessContext?.userId,
                "courseId": courseId,
                "courseTopicId": topicId,
                "courseSubTopicId": subTopicId,
                "learnerDescription": updateAssignmentDescription(),
                "result": scoreFraction
            };
            await usePost<IUserAssignment>("Course/ManageUserAssignment", request).then((response) => {
                // const courseAssignmentId = response.data[0].courseAssignmentId;

            }).finally(() => {
                if (isLastQuestion) {
                    setShowResult(false);
                } else {
                    setShowResult(true);
                }
                const updatedScoreFraction = calculateScore();
                // Evaluate if pass criteria is met
                const passCriteria = parseFloat(getcourse?.passCriteria);
                const passorFail = parseFloat(updatedScoreFraction.split('/')[0]) / parseFloat(updatedScoreFraction.split('/')[1]) * 100 >= passCriteria;
                // Save assignment progress with passorFail condition
                handleSaveAssignmentProgress(passorFail);
                setScore(updatedScoreFraction);

                fetchResult(courseAssignmentId, updatedScoreFraction);
            });
        })();
    };

    const fetchResult = (courseAssignmentId, scoreFraction) => {
        (async () => {
            const request = {
                "courseAssignmentId": courseAssignmentId,
                "userId": state?.userAccessContext?.userId,
                "result": scoreFraction
            };
            await usePost<IAssignmentResult[]>("Course/ManageAssignmentResult", request).then((result) => {
                setTableData(result.data);
                GetCourseProgressDetails();
            });
        })();
    };

    const GetCourseProgressDetails = () => {
        let request = {
            "userId": state?.userAccessContext?.userId,
            "courseId": courseId,
            "courseTopicId": topicId,
            "courseSubTopicId": subTopicId,
        };

        (async () => {
            await usePost<IGetUserCourseProgressResult>("UserCourse/GetUserCourseProgressResult", request).then((response) => setCourseProgress(response.data[0]))
        })()
    };

    const noofAttempts = tableData.length;
    const disableReattempt = noofAttempts >= getcourse?.noOfAttemptsPermitted;
    const passCriteria = parseFloat(getcourse?.passCriteria);
    const calculateTotalScorePercentage = () => {
        let highestScore = 0;
        tableData.forEach(row => {
            const score = parseFloat(row.result.split('/')[0]) / parseFloat(row.result.split('/')[1]) * 100;
            if (score > highestScore) {
                highestScore = score;
            }
        });
        return highestScore.toFixed(2);
    };

    const determinePassOrFail = () => {
        const totalScorePercentage = parseFloat(calculateTotalScorePercentage());
        return totalScorePercentage >= passCriteria ? true : false;
    };

    const passOrFail = determinePassOrFail();

    const handleSaveAssignmentProgress = async (passorFail: boolean) => {
        if (!courseProgress.isAssignmentCompleted || passorFail) {
            const request = {
                progressTrackingId: courseProgress?.progressTrackingId,
                userId: state?.userAccessContext?.userId,
                courseId: courseId,
                courseTopicId: topicId,
                courseSubTopicId: subTopicId,
                screenTime: courseProgress?.screenTime,
                isVideoCompleted: courseProgress?.isVideoCompleted,
                isTopicCompleted: courseProgress?.isTopicCompleted,
                isAssignmentCompleted: passorFail,
                IsTopicFlag: false,
                IsVideoFlag: false,
                IsAssignmentFlag: true,
            };

            await usePost<IUserCourseProgressTracker[]>("UserCourse/ManageUserCourseProgressTracker", request);
        }
    };

    return (
        <React.Fragment>
            {showAnimation ? (
                <Grid container bgcolor={'white'} display={'flex'} justifyContent="center" alignItems="center">
                    <CountDown />
                </Grid>
            ) : (
                <Grid container spacing={0} display={'flex'} className={classes.topicContainer}>
                    <Grid item xs={2} bgcolor={'whitesmoke'}></Grid>
                    {showResult ? (
                        <Grid item xs={8} bgcolor={'white'}>
                            <CustomizedProgressBars />
                            <Grid textAlign={'center'} mt={3}>
                                <Typography>
                                    <b>{topicName}</b>
                                    <b>{subTopicName}</b>
                                </Typography>
                            </Grid>
                            <Grid container justifyContent="space-between" m={2}>
                                <Typography variant="body1">
                                    Question {currentQuestion + 1} of {questions.length}
                                </Typography>
                                {/* Timer: {timer} seconds */}
                            </Grid>
                            <Grid container spacing={0} key={currentQuestion} display={'flex'} direction={'row'} alignItems={'center'}>
                                {imageUrl ? (
                                    <Grid item xs={4} height={350} width={400} textAlign={'center'} mt={10}>
                                        <img src={imageUrl} alt={topicName} height={300} width={300} />
                                    </Grid>
                                ) : null}
                                <Grid item xs={imageUrl ? 8 : 12} ml={imageUrl ? 0 : 12} mt={10} textAlign={'center'}>
                                    <Grid p={1}>
                                        <Typography variant="h5"><b>{currentQuestion + 1}. {questions[currentQuestion]?.question}</b></Typography>
                                    </Grid>
                                    <Grid display={'flex'} direction={'column'} p={1} ml={imageUrl ? 5 : 8} >
                                        {questions[currentQuestion]?.answers.map((option, optionIndex) => (
                                            <FormControlLabel
                                                key={optionIndex}
                                                value={option}
                                                control={
                                                    <Radio
                                                        size="small"
                                                        checked={selectedOptions[currentQuestion] === option}
                                                        onChange={(event) => handleRadioChange(event, currentQuestion)}
                                                    />
                                                }
                                                label={`${String.fromCharCode(65 + optionIndex)}. ${option}`}
                                            />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container display={'flex'} justifyContent={'space-around'} mt={5}>
                                <Grid item>
                                    <Button color={'success'} size={'small'} variant="contained" disabled={isFirstQuestion} onClick={handlePreviewQuestion}>
                                        <b>Previous</b>
                                    </Button>
                                </Grid>
                                <Grid display={'flex'}>
                                    {questions.map((_, index) => (
                                        <Grid item key={index} >
                                            <Typography
                                                variant="body1"
                                                className={classes.questionnumber}
                                                style={{
                                                    backgroundColor:
                                                    index === currentQuestion ? 'blue' :
                                                        selectedOptions[index] ? 'green' : 'red',
                                                }}
                                            >
                                                {index + 1}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid item>
                                    <Button
                                        color={isLastQuestion ? 'primary' : 'success'}
                                        size="small"
                                        variant="contained"
                                        onClick={isLastQuestion ? handleSubmit : handleNextQuestion}
                                    >
                                        <b>{isLastQuestion ? "Finish" : "Next"}</b>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={8} bgcolor={'white'} display={'flex'} flexDirection={'column'} style={{ minHeight: '80vh' }}>
                            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10}>
                                <Grid display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <ConfettiExplosion />
                                    <Grid item xs={12} mt={1}>
                                        <img src={Cup} alt="success" height={100} width={100} />
                                    </Grid>
                                    {passOrFail ? (
                                        <Typography color={"#5e35b1"} mt={2}>
                                            <h1>Congratulations!! You've passed Well done</h1>
                                        </Typography>
                                    ) : (
                                        <Typography color={"#5e35b1"} mt={2}>
                                            <h1>Failure is just another step towards success!! Try Again</h1>
                                        </Typography>
                                    )}
                                    <Typography color={"darkgray"} mt={1}>
                                        <h4>Your Score</h4>
                                    </Typography>
                                    <Typography variant="body1" style={{ display: "flex" }}>
                                        <div className={classes.score}>{score}</div>
                                    </Typography>
                                    <Typography color={'darkgray'} mt={1}>
                                        <h5>Never stop learnning, because life never stops teaching</h5>
                                    </Typography>
                                    <Grid container display="flex" justifyContent={'center'}>
                                        {!disableReattempt && (
                                            <Grid item mt={1} mr={1} textAlign="center">
                                                <Button color="success" variant="contained" size="small" onClick={handleReattempt} disabled={disableReattempt}>
                                                    <Typography><b>Try Again</b></Typography>
                                                </Button>
                                            </Grid>
                                        )}
                                        {disableReattempt && (
                                            <Grid item mt={1} mr={1} >
                                                <Link to={`/courseView/${courseId}/${courseTitle}`} style={{ textDecoration: 'none' }}>
                                                    <Button color="success" variant="contained" size="small" >
                                                        <Typography><b>Retake</b></Typography>
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        )}
                                        <Grid item mt={1}>
                                            <AssessmentReview
                                                AssignmentReview={value}
                                                IsAttempt={passOrFail || noofAttempts >= getcourse?.noOfAttemptsPermitted}
                                            />
                                            <Link to={`/courseView/${courseId}/${courseTitle}`} style={{ textDecoration: 'none', marginLeft: '10px' }}>
                                                <Button color="error" variant="contained" size="small">
                                                    <Typography><b>Close</b></Typography>
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <AccordionDetails>
                                    <Grid container justifyContent="center">
                                        <Grid item xs={9}>
                                            <TableContainer className={classes.tableContainer}>
                                                <Table className={classes.table}>
                                                    <TableHead className={classes.tableHead}>
                                                        <TableRow className={classes.tableCellCenter}>
                                                            <TableCell className={`${classes.tableCell} ${classes.tableCellCenter} ${classes.tableCellUserId}`}>TopicName</TableCell>
                                                            <TableCell className={`${classes.tableCell} ${classes.tableCellCenter} ${classes.tableCellAttempts}`}>Attempts({getcourse?.noOfAttemptsPermitted})</TableCell>
                                                            <TableCell className={`${classes.tableCell} ${classes.tableCellCenter} ${classes.tableCellScore}`}>Your Score</TableCell>
                                                            <TableCell className={`${classes.tableCell} ${classes.tableCellCenter} ${classes.tableCellTime}`}>Pass Criteria</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {tableData.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell className={`${classes.tableCell} ${classes.tableCellCenter} `}>{topicName}</TableCell>
                                                                <TableCell className={`${classes.tableCell} ${classes.tableCellCenter} `}>{row?.retakecorrelationId}.{row?.noofAttempts}</TableCell>
                                                                <TableCell className={`${classes.tableCell} ${classes.tableCellCenter} `}>  {`${((parseInt(row.result.split('/')[0]) / parseInt(row.result.split('/')[1])) * 100).toFixed(2)}%`}</TableCell>
                                                                <TableCell className={`${classes.tableCell} ${classes.tableCellCenter} `}>{getcourse?.passCriteria}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Typography textAlign={'right'} >Your BestPercentage:{calculateTotalScorePercentage()}% </Typography>
                                            <Typography textAlign={'right'} color={passOrFail ? "green" : "red"}>Result:{passOrFail ? "Pass" : "Fail"}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Scrollbars>
                        </Grid>
                    )}
                </Grid>
            )}
        </React.Fragment >
    );
};

export default UserMultipleChoice;
