import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textAlign: {
    textAlign: "center",
  },
  dialogTitle: {
    borderBottom: "1px solid #ccc",
    padding:"3px"
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  closeButton: {
    cursor: "pointer",
    position: "absolute",
    right: "14px",
    top: "6px",
  },
  TreeViewContainer: {
    [theme.breakpoints.up("lg")]: {
      maxHeight: "calc( 100vh - 130px) !important",
      minHeight: "calc( 82vh - 180px) !important",
    },
  },
  dialog:{
    width:"700px"
  },
  assignUserStyle: {
    mt: 1,
    width: '40px !important', // Adjust width to make the circle smaller
    height: "33px !important",
    right: "20px !important",
    borderRadius: '8px !important', // Initial border radius for rectangle shape
    transition: 'border-radius 0.3s !important', // Smooth transition for border radius change
    '&:hover': {
      backgroundColor: "#5e35b1 !important",
      border: "2px solid black !important",
    },
  },

}));

export default useStyles;
