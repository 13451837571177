/* eslint-disable @typescript-eslint/no-unused-vars */
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ICourseStatus } from '../../model/IDashboardCount';
import { useStyles } from './DashboardCss';

const CardDesign: React.FC<{ courseStatus: ICourseStatus }> = (props) => {
    const classes = useStyles();

    // const DashboardCounts = props?.DashboardCount;
    // const [dashboardCounts, setDashboardCount] = useState(props?.DashboardCount);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} display={'flex'}>
                    <Grid item xs={3} >
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} borderRadius={2} padding={2}
                            style={{ background: 'linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255)' }} >
                            <AutoStoriesIcon sx={{ color: 'rgb(0, 75, 80)', height: 50, width: 50 }} />
                            <Typography variant='h5' textAlign={'center'} color={"rgb(0, 75, 80)"}><b>{props?.courseStatus?.pastDueCourses}</b></Typography>
                            <Typography variant='body2' textAlign={'center'} color={"rgb(0, 75, 80)"} ><b>Past Due Course</b></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3} marginLeft={"15px"}>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} borderRadius={2} padding={2}
                            style={{ background: 'linear-gradient(135deg, rgba(97, 243, 243, 0.2), rgba(0, 184, 217, 0.2)) rgb(255, 255, 255)' }} >
                            <AutoStoriesIcon sx={{ color: 'rgb(0, 55, 104)', height: 50, width: 50 }} />
                            <Typography variant='h5' textAlign={'center'} color={"rgb(0, 55, 104)"}><b>{props?.courseStatus?.todaysDueCourses}</b></Typography>
                            <Typography variant='body2' textAlign={'center'} color={"rgb(0, 55, 104)"}><b>Today's Due Course</b></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3} marginLeft={"15px"}>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} borderRadius={2} padding={2}
                            style={{ background: 'linear-gradient(135deg, rgba(255, 214, 102, 0.2), rgba(255, 171, 0, 0.2)) rgb(255, 255, 255)' }} >
                            <AutoStoriesIcon sx={{ color: 'rgb(122, 65, 0)', height: 50, width: 50 }} />
                            <Typography variant='h5' textAlign={'center'} color={"rgb(122, 65, 0)"}><b>{props?.courseStatus?.upcomingDueCourses}</b></Typography>
                            <Typography variant='body2' textAlign={'center'} color={"rgb(122, 65, 0)"}><b>UpComming Due Course</b></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3} marginLeft={"15px"}>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} borderRadius={2} padding={2}
                            sx={{ background: 'linear-gradient(135deg, rgba(185, 133, 244, 0.2), rgba(118, 53, 220, 0.2)) rgb(255, 255, 255)' }} >
                            <GroupAddIcon sx={{ color: 'rgb(94, 53, 177)', height: 50, width: 50 }} />
                            <Typography variant='h5' color={"rgb(94, 53, 177)"} textAlign={'center'}><b>{props?.courseStatus?.completedCourse}</b></Typography>
                            <Typography variant='body2' textAlign={'center'} color={"rgb(94, 53, 177)"}><b>Users Completed Course</b></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3} marginLeft={"15px"}>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} borderRadius={2} padding={2}
                            sx={{ background: 'linear-gradient(135deg, rgba(255, 172, 130, 0.2), rgba(255, 86, 48, 0.2)) rgb(255, 255, 255)' }} >
                            <NotificationsActiveIcon sx={{ color: 'rgb(122, 9, 22)', height: 50, width: 50 }} />
                            <Typography variant='h5' color={"rgb(122, 9, 22)"} textAlign={'center'} ><b>{props?.courseStatus?.overallScheduledCourse}</b></Typography>
                            <Typography variant='body2' textAlign={'center'} color={"rgb(122, 9, 22)"}><b>Overall Assigned Course</b></Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default CardDesign;