import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      cursor: "pointer",
      position: "absolute",
      right: "14px",
      top: "14px",
    },
    iconColor: {
      color: "rgb(103, 58, 183)",
    },
    iconPadding: {
      padding: "15px !important",
    },
    width: {
      width: "100%",
    },
    display: {
      display: "flex",
      borderRadius: "10px"
    },
    boxContainer: {
      borderRadius: "8px",
      padding: "10px",
    },
    richTextEditor:{
      width: "20px ",
      height: "20px",
      cursor: "pointer",
    },
    Box: {
      border: "1px solid gray",
      borderRadius: "4px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    },
    iconJustify: {
      justifyContent: "flex-start",
      flexDirection: "column",
    },
    textEditor: {
      border: "1px solid lightgray",
      height: "200px",
    },
    DropzoneArea: {
      minHeight: "161px !important",
      // maxHeight: '190px',
      fontSize: "12px",
      // minWidth: '550px',
      backgroundColor: "#f4f6f8 !important",
      border: "2px dashed rgba(145, 158, 171, 0.24)",
      borderRadius: "5px",
      [theme.breakpoints.up("sm")]: {
        maxWidth: "auto",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "auto",
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: "auto",
      },
    },
    DropzoneAreaText: {
      fontSize: "15px!important",
      // textAlign: "center",
      color: "rgb(10, 10, 10)",
    },
    dialog: {
      width: "800px",
    },
    dialog1: {
      width: "800px",
      height: "300px",
    },
    dialogBackground: {
      backdropFilter: "blur(5px)",
      backgroundColor: "rgba(145, 158, 171, 0.24)",
    },
    linkView: {
      width: "100%",
      height: "500px",
    },
    submitButton: {
      // background: '#5e35b1 !important',
      fontSize: "12px !important",
      borderRadius: 2,
    },
    courseQuestion: {
      maxHeight: "calc( 100vh - 240px) !important",
      minHeight: "calc( 100vh - 240px) !important",
    },
    inputButton: {
      minHeight: "calc(50px + 10vh) !important",
      width: "100%",
      backgroundColor: "#f4f6f8 !important",
      border: "1px dotted gray !important",
      position: "absolute",
    },
    VedioButton: {
     // minHeight: "5px !important",
     padding:"0px",
      width: "100%",
      backgroundColor: "#f4f6f8 !important",
      border: "1px dotted gray !important",
      position: "absolute",
    },
    closeButton: {
      cursor: "pointer",
      position: "absolute",
      right: "14px",
      top: "14px",
    },
    snackbarColor: {
      color: "white !important",
      backgroundColor: "green !important"
    },
    content: {
      padding: "25px",
      overflowY: "hidden",
      [theme.breakpoints.up("lg")]: {
        padding: "25px",
        overflowY: "hidden",
      },
      [theme.breakpoints.down("md")]: {
        padding: "25px",
        overflowY: "scroll",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "25px",
        overflowY: "scroll",
      },
    },
    textAlign: {
      textAlign: "center",
    },
    save: {
      background: "green",
      borderRadius: "8px",
      fontSize: "13px",
      marginRight: "5px",
      transition:
        "background - color 250ms cubic- bezier(0.4, 0, 0.2, 1) 0ms, box- shadow 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms, border - color 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        backgroundColor: "green",
      },
    },
    button: {
      padding: "4px 69px !important",
    },
    //attachment
    container: {
      height: "700px",
      maxWidth: "900px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '40%'
    },
    buttonWidth:{
      width: "max-content !important"
    },
    deleteDialogue: {
      minWidth: "500px",
    },
    dialogStyles: {
      display: "flex !important",
      justifyContent: "center !important",
      backgroundColor: "#5e35b1 !important",
      color: "white !important",
      padding: "9px 1px 2px 2px !important"
    },
  })
);

export default useStyles;
