/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Grid, InputAdornment, TextField, Typography, Box, Button, Stepper, Step, StepLabel } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import VisuallyHiddenInput from '../../../../GlobalStyles/VisuallyHiddenInput';
import { IEducationalQualification, IExperience, IPrepareCV, IProject, ISkill, IUserInformation } from '../../../../model/Users/IUser';
import useStyles from '../ProfileCardCss';
import Scrollbars from 'react-custom-scrollbars';
import ColorlibConnector from '../../../../GlobalStyles/ColorlibConnector';
import ColorlibStepIcon from './ColorlibStepIcon';
import { steps } from './steps';
import { useGlobalState } from '../../../../GlobalStore/globalStore';
import { useFetch, usePost } from '../../../../utils/apiHelper';
import { useHistory } from 'react-router-dom';


const PersonalInformation = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { state } = useGlobalState();
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setloading] = useState(false);
    const [profileInformation, setProfileInformation] = useState<IPrepareCV[]>(props.location?.state?.profileInformation ? props.location?.state?.profileInformation : []);
    const [educationInformations, setEducationInformation] = useState<IEducationalQualification[]>(props.location?.state?.educationInformations ? props.location?.state?.educationInformations : []);
    const [skillInformation, setSkillInformation] = useState<ISkill[]>(props.location?.state?.skillInformation ? props.location?.state?.skillInformation : []);
    const [experienceInformation, setExperienceInformation] = useState<IExperience[]>(props.location?.state?.experienceInformation ? props.location?.state?.experienceInformation : []);
    const [projectInformations, setProjectInformation] = useState<IProject[]>(props.location?.state?.projectInformations ? props.location?.state?.projectInformations : []);

    useEffect(() => {
        handlePrepareCV();
    }, []);


    useEffect(() => {
        if (!profileInformation?.length) {
            (async () => {
                setloading(true);
                await useFetch<IUserInformation[]>(`Users/GetUserInformationById?userId=${state?.userAccessContext?.userId}`).then(response => {
                    let userDetails: IPrepareCV = {
                        personalId: 0,
                        firstName: response?.data[0]?.firstName,
                        lastName: response?.data[0]?.lastName,
                        emailInformation: response?.data[0]?.emailAddress,
                        phoneNumber: response?.data[0]?.phoneNumber,
                        linkedInUserName: response?.data[0]?.linkedInUserName,
                        gitHubUserName: response?.data[0]?.gitHubUserName,
                        address: '',
                        careerObjective: '',
                        profileImage: ''
                    }
                    setProfileInformation([userDetails]);
                })
            })().finally(() => {
                setloading(false);
            });
        }
    }, [!profileInformation?.length]);

    const handlePrepareCV = () => {
        if (!(props?.location?.state?.profileInformation?.length > 0)) {
            (async () => {
                let request = {
                    'userId': state?.userAccessContext?.userId
                }
                setloading(true);
                await usePost<{ prepareCV: IPrepareCV[]; educationalQualification: IEducationalQualification[]; experience: IExperience[]; project: IProject[]; skill: ISkill[]; }>(`PrepareCV/GetUserDataById?userId=${state?.userAccessContext?.userId}`, request).then((response) => {
                    setProfileInformation(response?.data?.prepareCV);
                    setEducationInformation(response?.data?.educationalQualification);
                    setSkillInformation(response?.data?.skill);
                    setExperienceInformation(response?.data?.experience);
                    setProjectInformation(response?.data?.project)
                });
            })().finally(() => {
                setloading(false);
            });
        } else {

            setProfileInformation(props?.location?.state?.profileInformation);
            setEducationInformation(props?.location?.state?.educationInformations);
            setSkillInformation(props?.location?.state?.skillInformation);
            setExperienceInformation(props?.location?.state?.experienceInformation);
            setProjectInformation(props?.location?.state?.projectInformations)
        }
    }

    const handleChange = (index, field) => (e) => {
        const { value } = e.target;
        setProfileInformation(prevProfileInformation => {
            const updatedProfileInformation = [...prevProfileInformation];
            updatedProfileInformation[index][field] = value;
            return updatedProfileInformation;
        });
    };

    const handleNextStep = () => {
        history.push("/EducationalInformation", {
            profileInformation: profileInformation,
            educationInformations: educationInformations,
            skillInformation: skillInformation,
            experienceInformation: experienceInformation,
            projectInformations: projectInformations
        });
    };

    return (
        <Grid container spacing={1}>
                     <Grid item xs={2} display={"flex"} justifyContent={"center"} alignItems={"center"}  borderRight={"1px solid lightGray"} style={{backgroundColor:"#7a64a7"}}>
                     <Stepper activeStep={activeStep} orientation='vertical' connector={<ColorlibConnector />} >
                                {steps.map((step, index) => (
                                    <Step key={step.label} sx={{ color: "white" }}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon} icon={step.icon}>
                                            <b style={{ color: activeStep === index ? "orange" : (index < activeStep ? "limegreen" : "#ccc"), fontSize: activeStep === index ? "18px" : "14px" }}>
                                                {step.label}
                                            </b>
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={9.7}>
                                {activeStep === 0 && (
                                    <Grid container spacing={2} ml={.2} >
                                        <Grid item xs={12} borderBottom={'1px dashed blue'} pt={6} >
                                            <Typography variant='body1' color="#5e35b1" ><b>Personal Information</b></Typography>
                                        </Grid>
                                        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.educationContainer}>
                                            <Grid container spacing={1.5} p={1.5}>
                                                <Grid item xs={6}>
                                                    <Typography variant='body1'><b>First Name</b></Typography>
                                                    <TextField
                                                        id="outlined-basic-small"
                                                        size="small"
                                                        fullWidth
                                                        placeholder='First Name'
                                                        name='firstName'
                                                        value={profileInformation[0]?.firstName}
                                                        onChange={handleChange(0, 'firstName')}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonOutlineIcon fontSize='small' />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant='body1'><b>Last Name</b></Typography>
                                                    <TextField
                                                        id="outlined-basic-small"
                                                        size="small"
                                                        fullWidth
                                                        placeholder='Last Name'
                                                        name='lastName'
                                                        value={profileInformation[0]?.lastName}
                                                        onChange={handleChange(0, 'lastName')}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonOutlineIcon fontSize='small' />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant='body1'><b>Email Information</b></Typography>
                                                    <TextField
                                                        id="outlined-basic-small"
                                                        size="small"
                                                        fullWidth
                                                        placeholder='Email Information'
                                                        name='emailInformation'
                                                        value={profileInformation[0]?.emailInformation}
                                                        onChange={handleChange(0, 'emailInformation')}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EmailIcon fontSize='small' />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant='body1'><b>Phone Number</b></Typography>
                                                    <TextField
                                                        id="outlined-basic-small"
                                                        size="small"
                                                        fullWidth
                                                        placeholder='Phone Number'
                                                        name='phoneNumber'
                                                        value={profileInformation[0]?.phoneNumber}
                                                        onChange={handleChange(0, 'phoneNumber')}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhoneIcon fontSize='small' />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant='body1'><b>LinkedIn UserName</b></Typography>
                                                    <TextField
                                                        id="outlined-basic-small"
                                                        size="small"
                                                        fullWidth
                                                        placeholder='LinkedIn UserName'
                                                        name='linkedInUserName'
                                                        value={profileInformation[0]?.linkedInUserName}
                                                        onChange={handleChange(0, 'linkedInUserName')}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LinkedInIcon fontSize='small' />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant='body1'><b>Github UserName</b></Typography>
                                                    <TextField
                                                        id="outlined-basic-small"
                                                        size="small"
                                                        fullWidth
                                                        placeholder='GitHub UserName'
                                                        name='gitHubUserName'
                                                        value={profileInformation[0]?.gitHubUserName}
                                                        onChange={handleChange(0, 'gitHubUserName')}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <GitHubIcon fontSize='small' />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant='body1'><b>Address</b></Typography>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        fullWidth
                                                        placeholder='Address'
                                                        name='address'
                                                        value={profileInformation[0]?.address}
                                                        onChange={handleChange(0, 'address')}
                                                        multiline
                                                        rows={3}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant='body1'><b>Career Objective</b></Typography>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        fullWidth
                                                        placeholder='Career Objective....'
                                                        name='careerObjective'
                                                        value={profileInformation[0]?.careerObjective}
                                                        onChange={handleChange(0, 'careerObjective')}
                                                        multiline
                                                        rows={3}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant='body1'><b>Upload ProfileImage</b></Typography>
                                                    <label htmlFor="fileInput">
                                                        <Box
                                                            m={0}
                                                            border={"1px dashed #5e35b1"}
                                                            p={2.5}
                                                            borderRadius={1}
                                                            display={"flex"}
                                                            justifyContent={"center"}
                                                            alignItems={"center"}
                                                            flexDirection={"column"}
                                                            bgcolor={"#f6f5f8"}
                                                            mt={2}
                                                            itemType="file"
                                                        >
                                                            <CloudUploadIcon
                                                                fontSize="large"
                                                                style={{ color: "#5e35b1" }}
                                                            />
                                                            <Typography
                                                                variant="body2"
                                                                textAlign={"center"}
                                                                fontSize={12}
                                                                color={"#5e35b1"}
                                                            >
                                                                <b>Supported formats: PNG, JPG, JPEG</b>
                                                            </Typography>
                                                        </Box>
                                                    </label>
                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        id="fileInput"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Scrollbars>
                                        <Box m={0} pt={0.5} width={'100%'} display={'inline-flex'} justifyContent={'flex-end'} borderTop={'1px solid lightgray'}>
                                            <Button  variant="contained" size="small" color="success" sx={{marginRight: "20px" }} onClick={handleNextStep}>
                                                <b>Next</b>
                                            </Button>
                                        </Box>
                                    </Grid>
                                )}
                        </Grid>
        </Grid >
    )
};

export default PersonalInformation;