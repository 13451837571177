import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import "./timer.css";

const CountDown = () => (
  <div className="countdown-container">
    <CountdownCircleTimer
      isPlaying
      isSmoothColorTransition
      strokeWidth={20}
      trailStrokeWidth={20}
      duration={3}
      size={250}
      colors={['#008744', '#0057e7', '#d62d20', '#ffa700']}
      colorsTime={[5, 3, 1, 0]}
    >
      {({ remainingTime }) => (
        <div className="countdown-text">
          <div className="countdown-value">{remainingTime}</div>
        </div>
      )}
    </CountdownCircleTimer>
  </div>
);

export default CountDown;
