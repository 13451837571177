/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, Box, Button, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import award from '../../../images/course/certificate/award.png';
import certificate1bg from '../../../images/course/certificate/certificate1-1.png';
import certificate2bg from '../../../images/course/certificate/certificate2-1.png';
import certificate3bg from '../../../images/course/certificate/certificate3-1.png';
import certificate4bg from '../../../images/course/certificate/certificate4-1.png';
import certificate1 from '../../../images/course/certificate/certificate1.png';
import certificate2 from '../../../images/course/certificate/certificate2.png';
import certificate3 from '../../../images/course/certificate/certificate3.png';
import certificate4 from '../../../images/course/certificate/certificate4.png';
import sign from '../../../images/course/certificate/sign.png';
import logo from '../../../images/logo/logo.png';
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import useStyles from '../CourseCss';
import { usePost } from '../../../utils/apiHelper';
import { MoonLoader } from 'react-spinners';
import { ICertificateName } from '../../../model/Course/ICertificateList';

const certificateList = [
    {
        label: "Certificate 1",
        icon: certificate1,
        bgImage: certificate1bg
    },
    {
        label: "Certificate 2",
        icon: certificate2,
        bgImage: certificate2bg
    },
    {
        label: "Certificate 3",
        icon: certificate3,
        bgImage: certificate3bg
    },
    {
        label: "Certificate 4",
        icon: certificate4,
        bgImage: certificate4bg
    }
];

const EditCertificateDetails = ({ CourseId, activeStep, setActiveStep, CourseTitle }) => {
    const classes = useStyles();
    const [selectedCertificate, setSelectedCertificate] = useState(null);
    const [certificateTemplateList, setCertificateTemplateList] = useState<ICertificateName>(null);
    const [bgImageUrl, setBgImageUrl] = useState<string>("");
    const [loading, setloading] = useState(false);

    const handleCertificateClick = (certificate: any) => {
        setSelectedCertificate(certificate);
        const selectedTemplate = certificateList.find((item) => item.label === certificate);
        if (selectedTemplate) {
            setBgImageUrl(selectedTemplate?.bgImage);
        } 
    };

    const handleSave = async () => {
        (async() => {
            setloading(true);
            let request = {
                courseId: CourseId,
                certificateName: selectedCertificate,
                isActive: true
            }
            // eslint-disable-next-line react-hooks/rules-of-hooks
            await usePost("UserCourse/EditCertificaeTemplate", request);
            handleGetCertificate();
        })().finally(() => {
            setloading(false);
            console.error();
        })
    };

    const handleGetCertificate = () => {
        (async() => {
            setloading(true);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            await usePost<any>(`UserCourse/GetCertificateTemplate?courseId=${CourseId}`, {}).then((r) => {
                setCertificateTemplateList(r?.data);
                const receivedCertificateName = (r?.data?.certificateName);
                    if(receivedCertificateName){
                        const selectedTemplate  = certificateList.find(r => r?.label === receivedCertificateName);
                        if(selectedTemplate) {
                            setBgImageUrl(selectedTemplate?.bgImage);
                        }
                        else {
                            console.error(`Certificate with name not found in the certificate list.`);
                        }
                    } else {
                        console.error(`Certificate name not received from the API.`);
                    }
            })
        })().finally(() => {
            setloading(false);
        })
        
    }

    useEffect(() => {
        handleGetCertificate();
    }, [CourseId, certificateList]);

    return (
        <React.Fragment>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
            </Backdrop>
            
                <Grid container spacing={0}>
                  
                    <Grid item xs={2} borderRight={"1px solid lightgray"}>
                    <Scrollbars
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={10}
                      className={classes.courseContainer}
                     >
                         <Typography variant="body1" textAlign={"center"}><b>Choose Templete</b></Typography>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            {certificateList?.map((certificate) => (
                                <ListItemButton
                                    key={certificate.label} 
                                    onClick={() => handleCertificateClick(certificate.label) }
                                    className={
                                        certificate?.label === selectedCertificate
                                          ? `${classes.inActiveCertificate}`
                                          : certificate?.label === certificateTemplateList?.certificateName
                                          ? `${classes.activeCertificate}`
                                          : `${classes.CertificateTemplate}`
                                      }>
                                    <ListItemIcon sx={{ justifyContent: 'center' }}>
                                        <Box m={0} boxShadow={5}>
                                            <img src={certificate?.icon} alt={certificate?.label} width={'200px'} height={'150px'} />
                                        </Box> 
                                    </ListItemIcon>
                                    <ListItemText primary={certificate?.label} />
                                </ListItemButton>
                            ))}
                        </List>
                    </Scrollbars>
                    </Grid >
                    <Grid item xs={10}>
                    <Scrollbars
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={10}
                      className={classes.courseContainer}
                     >
                        <Box m={0} p={3} >
                            <Grid container spacing={0} m={0} p={1}>
                                <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                                    <Button variant="contained" onClick={() => handleSave()} size='small' color='success'>SAVE</Button>
                                </Grid>
                                <Grid item xs={12} justifyContent={'center'} display={'inline-flex'} alignItems={'center'}>
                                    <div className="outer-container">
                                        <div className="inner-container" style={{ backgroundImage: `url(${bgImageUrl})` }}>
                                            <Grid container spacing={0} m={0} p={"50px 200px"} >
                                                <Grid item xs={12} >
                                                    <Typography variant='h4' mt={0} textAlign={'center'} fontFamily={'Open Sans, sans-serif'} >
                                                        <img src={logo} alt="logo" width={'150px'} height={'40px'} />
                                                    </Typography>
                                                    <Typography  fontSize={30} fontWeight={600} mt={3} ml={7} letterSpacing={1} className='typography'>
                                                        <b>CERTIFICATE OF COMPLETION</b>
                                                    </Typography>

                                                    <Typography  fontSize={17} fontWeight={200} mt={2} ml={13} letterSpacing={1} color={'gray'} className='typography1'>
                                                        THIS CERTIFICATE IS PRESENTED TO
                                                    </Typography>

                                                    <Typography variant='h5' fontSize={42} fontWeight={600} mt={2} textAlign={'center'} color={'#032F66'} letterSpacing={4} fontFamily={'Dancing Script, cursive'} className='fontStyle'>
                                                        John Doe
                                                    </Typography>

                                                    <Typography  fontSize={15} fontWeight={200} mt={2} ml={10} letterSpacing={1}  className='typography' >
                                                        has successfully completed the online Course
                                                    </Typography>

                                                    <Typography variant='h5' fontSize={22} fontWeight={600} mt={2} textAlign={'center'} letterSpacing={1}>
                                                        <b>{CourseTitle}</b>
                                                    </Typography>

                                                    <Typography  fontSize={15} fontWeight={200} mt={2} ml={2} letterSpacing={0}  className='typography1' >
                                                        This Professional has demonstrated initiative and a commitment to deepending their skills and advancing their career. Well done!
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} >
                                                    <Typography fontWeight={400} mt={3} padding={"0px 50px 0px 50px"} className='typography2'>
                                                        <b>MM/DD/YYYY</b>
                                                    </Typography>
                                                    <Typography fontSize={15} fontWeight={400} mt={1} padding={"0px 0px 0px 20px"} className='typography2'>
                                                        <Divider />
                                                    </Typography>
                                                    <Typography fontSize={15} fontWeight={500} mt={1} padding={"0px 50px"} className='typography2'>
                                                        <b>DATE OF ISSUE</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} >
                                                    <img src={award} alt="award" />
                                                </Grid>
                                                <Grid item xs={5} >
                                                    <Typography  fontSize={15} fontWeight={400} ml={0} mt={2} mr={2}  padding={"0px 50px 0px 50px"} className='typography3'>
                                                        <img src={sign} alt="sign" width={'80px'} height={'20px'} />
                                                    </Typography>
                                                    <Typography fontSize={15} fontWeight={400} ml={0} mt={1}  padding={"0px 20px 0px 0px"} className='typography3'>
                                                        <Divider />
                                                    </Typography>
                                                    <Typography  fontSize={14} fontWeight={500} mt={1}  className='typography3'>
                                                        <b>DR. PETER KULANDAI RAJ K</b>
                                                    </Typography>
                                                    <Typography fontSize={12} fontWeight={500} mt={0}  className='typography3'>
                                                        <b>CEO, COGNULTSOFT</b>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box >
                        </Scrollbars>
                    </Grid >
                </Grid >
            <Box m={0} p={0.5} width={'100%'} display={'inline-flex'} justifyContent={'flex-end'} borderTop={'1px solid lightgray'}>
                <Button
                    component="label"
                    variant="contained"
                    size="small"
                    color="success"
                    disabled={activeStep === 0}
                    onClick={() => setActiveStep(activeStep - 1)}
                    sx={{ marginRight: 1, marginBottom: 1 }}
                >
                    <b>Back</b>
                </Button>
                <Button
                    component="label"
                    variant="contained"
                    size="small"
                    color="success"
                    onClick={() => setActiveStep(activeStep + 1)}
                    sx={{ marginRight: 1, marginBottom: 1 }}
                >
                    <b>Next</b>
                </Button>
            </Box>
        </React.Fragment >
    )
}

export default EditCertificateDetails;