import { Theme, createStyles, makeStyles } from "@material-ui/core";
import bgImage from '../../../images/ProfileCard/backgroudn1.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ProfileCard: {
            margin: '64px 0px 0px -24px',
            '& .css-6hp17o-MuiList-root-MuiMenu-list': {
                paddingTop: '0px'
            }
        },
        menuItem: {
            fontSize: 'small !important',

        },
        expandedAccordion: {
            backgroundColor: 'beige !important', // Change this to your desired background color
            borderBottom: "1px solid lightgrey !important"
        },
        profileGrid: {
            display: 'flex',
            padding: '5px',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            backdropFilter: 'blur(10px)',
            background: 'linear-gradient(45deg, #6200ea 30%, #9c27b0 90%)',
            color: '#ffff'
        },
        iconButtons: {
            marginTop: '3px !important',
            color: '#5e35b1 !important',
            backgroundColor: "#E6E6FA !important",
            '&:hover': { color: 'white !important', backgroundColor: "#5e35b1 !important" },
            borderRadius: '10px'
        },

        //ProfileAccount
        profileBground: {
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            // background: 'linear-gradient(45deg, #6200ea 30%, #9c27b0 90%)'
        },
        tableCell: {
            justifyContent: "center !important",
            alignItems: "center !important",
        },
        scrollbar: {
            minHeight: 'calc(100vh - 96px)',
            maxHeight: 'calc(100vh - 96px)'
        },
        profilePhoto: {
            cursor: 'pointer',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            borderRadius: '50%',
            padding: '4px'
        },
        //Prepare CV

        prepareCVList: {
            justifyContent: 'center',
            width: '180px', // Adjust width as needed
            height: '200px', // Adjust height as needed
            borderRadius: '5px', // Adjust border radius as needed
            border: '1px solid lightgrey',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px', // Adjust font size as needed
            fontWeight: 'bold', // Adjust font weight as needed
            backgroundColor: 'white', // Adjust background color as needed
        },
        personalContainer: {
            [theme.breakpoints.up("lg")]: {
                maxHeight: "calc( 100vh - 200px) !important",
                minHeight: "calc( 100vh - 200px) !important",
            },
        },
        educationContainer: {
            [theme.breakpoints.up("lg")]: {
                maxHeight: "calc( 100vh - 155px) !important",
                minHeight: "calc( 100vh - 155px) !important",
            },
        },
        skillContainer: {
            [theme.breakpoints.up("lg")]: {
                maxHeight: "calc( 100vh - 143px) !important",
                minHeight: "calc( 100vh - 143px) !important",
            },
        },
        experienceContainer: {
            [theme.breakpoints.up("lg")]: {
                maxHeight: "calc( 100vh - 143px) !important",
                minHeight: "calc( 100vh - 143px) !important",
            },
        },
        projectContainer: {
            [theme.breakpoints.up("lg")]: {
                maxHeight: "calc( 100vh - 140px) !important",
                minHeight: "calc( 100vh - 140px) !important",
            },
        },
        CvContainer: {
            [theme.breakpoints.up("lg")]: {
                maxHeight: "calc( 100vh - 150px) !important",
                minHeight: "calc( 100vh - 150px) !important",
            },
        },
        prepareCVAppBar: {
            minHeight: "50px !important",
            borderBottom: "1px solid lightgray !important"
        },
        tabRecords: {
            minHeight: "30px !important",
            fontSize: "12px !important",
            fontWeight: "bold",
        },
        JoinIconButton: {
            color: 'black',
            borderRadius: "5px !important",
            backgroundColor: "white !important",
            border: '2px solid lightGray !important',
            "&:hover": {
                backgroundImage: 'linear-gradient( 136deg, #000000 0%, #000000 50%)',
                color: 'white'
            }
        },
        scrolbar: {
            minHeight: 'calc(100vh - 165px)',
            maxHeight: 'calc(100vh - 165px)'
        },
        formContainer: {
            position: 'relative',
            // minHeight: '100%', // Ensures that the container takes up at least the full height of its parent
        },
        createCV: {
            minHeight: 'calc(100vh - 92px)',
            maxHeight: 'calc(100vh - 92px)',
            overflow: 'hidden !important'
        },
        jobScrollbar: {
            minHeight: 'calc(100vh - 320px) !important',
            maxHeight: 'calc(100vh - 320px) !important',
        },
        ViewDetailScrollbar: {
            minHeight: 'calc(100vh - 105px) !important',
            maxHeight: 'calc(100vh - 105px) !important',
        },
        courseCard: {
            boxShadow:
                "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important",
            "&:hover": {
                border: "1px solid #007FFF !important",
            },
        },
        jobCard: {
            boxShadow:
                "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important",
        },
        borderBottom: {
            borderBottom: "1px dashed lightgray",
        },
        ellipsis: {
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            wordWrap: "break-word",
            textOverflow: "ellipsis",
            WebkitLineClamp: 5,
            height: "80px",
        },
        ovalTextField: {
            '& .MuiOutlinedInput-root': {
                borderRadius: '999px', // Set a large value to make it a circle
                padding: theme.spacing(1), // Adjust padding as needed
            },
        },
        noBottomBorder: {
            '& .MuiInput-underline:before': {
                borderBottom: 'none !important',
            },
            '& .MuiInput-underline:after': {
                borderBottom: 'none !important',
            },
        },
        containerpaperStyle: {
            border: '2px solid #2196F3',
            padding: '5px',
            height: '100px',
            overflow: 'auto'
        },
        iconButton: {
            borderRadius: '50% !important', // Round shape
            backgroundColor: theme.palette.primary.main, // Change the background color as needed
            color: theme.palette.primary.contrastText, // Change the icon color as needed
            marginRight: theme.spacing(1),
            "&.MuiIconButton-root": {
                padding: "7px !important"
            },
        },
        iconButton1: {
            borderRadius: '50% !important', // Round shape
            backgroundColor: "white", // Change the background color as needed
            color: "#2a3944", // Change the icon color as needed
            marginRight: theme.spacing(1),
            "&.MuiIconButton-root": {
                padding: "7px !important"
            },
        },
        pdfoutercontainer: {
            width: "70% !important",
            height: "100% !important",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5) !important",
            overflow: "hidden !important",
            display: "flex !important",
            justifyContent: "center !important",
            alignItems: "center !important"
        }
    }),
);

export default useStyles;