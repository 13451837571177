/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/rules-of-hooks */
import { Typography } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Snackbar, } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import React, { useEffect, useState } from "react";
import VisuallyHiddenInput from "../../../../GlobalStyles/VisuallyHiddenInput";
import { baseImage } from "../../../../constant/Constant";
import { AttachmentsProps, ICourseFile, IFile } from "../../../../model/course";
import { base64toBlob } from "../../../../utils/Base64toblob";
import { useDelete, usePost } from "../../../../utils/apiHelper";
import useStyles from "./TopicContentCss";
import PostAddIcon from '@mui/icons-material/PostAdd';
import { AlertColor } from '@mui/material/Alert';

export const Attachments: React.FC<AttachmentsProps> = (props) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const { topicId, subTopicId, courseId, flag } = props;
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [CourseMediaList, setCourseMediaList] = useState<ICourseFile[]>([]);
  const [fileUrl, setFileUrl] = useState<IFile[]>([]);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("info");

  const handleClickOpen = () => {
    setOpen(true);
    GetCourseMediaDetail();
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleFileClose = () => {
    setOpenDialog(false);
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity); 
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleAlertClose = () => {
    setSnackbarOpen(false);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      handleSave(selectedFile);
      handleSnackbarOpen("File saved successfully.", "success");
    } else {
      handleSnackbarOpen("Please upload only PDF files.", "error");
    }
  };

  const handleSave = async (file) => {
    (async () => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("CourseId", courseId?.toString());
      if (flag) {
        formData.append("TopicId", topicId?.toString());
      } else {
        formData.append("TopicId", topicId?.toString());
        formData.append("SubTopicId", subTopicId?.toString());
      }
      await usePost("File/InsertCourseMediaDetail", formData).finally(() => {
        GetCourseMediaDetail();
      }
    );
    })()
  };


  const GetCourseMediaDetail = async () => {
    (async () => {
      let requests = {
        courseId: courseId,
        courseTopicId: topicId,
        courseSubTopicId: subTopicId,
        assignmentId: null,
      };

      await usePost<ICourseFile[]>("File/GetCourseMediaDetail", requests).then((result2) => { setCourseMediaList(result2.data); }
      );
    })()
  };

  const fetchProductImages = (blobUri: any) => {
    (async () => {
      let request = { pdfUri: blobUri };
      await usePost("File/FileDownload", request).then((response) => {
        let fileUrl = URL.createObjectURL(base64toBlob(baseImage + response?.data));
        setSelectedUrl(fileUrl);
        setOpenDialog((response?.status === 404 || response?.status === 400) ? false : true);
      });
    })()
  };

  useEffect(() => {
    fetchImages();
  }, [CourseMediaList]);

  const fetchImages = async () => {
    let MediaResult = [];
    CourseMediaList?.map((r) => {
      if (r.fileType === '.pdf') {
        let newResult: IFile = {
          fileId: r?.fileId,
          fileUrl: r?.fileURI,
          fileName: r?.fileName
        }
        MediaResult?.push(newResult);
      }
      return null;
    })
    setFileUrl(MediaResult);
  };


  const handleDelete = (fileId) => {
    (async () => {
      await useDelete(`File/DeleteCourseMediaDetail?FileId=${fileId}`).finally(() => GetCourseMediaDetail());
    })()
  }

  return (
    <React.Fragment>
      <Button variant="contained" size="small" onClick={handleClickOpen} startIcon={<PostAddIcon fontSize="small" />} className={`${classes.button} ${classes.buttonWidth}`}
      >
        Add Document
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ className: classes.dialog }}
        className={classes.dialogBackground}
      >
        <DialogTitle id="alert-dialog-title" className={classes.textAlign}>
          <b>{"Upload File"}</b>
          <CloseIcon onClick={handleClose} className={classes.closeButton} />
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Button component="label" variant="outlined" size="small" startIcon={<CloudUploadIcon fontSize="small" />}
            className={classes.inputButton}
          >
            <b>Upload PDF</b>
            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
          </Button>
          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert severity={snackbarSeverity} sx={{ width: '100%' }} onClose={handleAlertClose}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {fileUrl?.map((fileDetails, index) => (
            <Grid key={index} container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="body2">{fileDetails.fileName || `fileName${index}`}</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => fetchProductImages(fileDetails?.fileUrl)} size="small">
                  <VisibilityIcon fontSize="small" color="primary" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="error" size="small" onClick={() => handleDelete(fileDetails.fileId)}>
                  <DeleteForeverIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Dialog open={openDialog} onClose={handleFileClose} maxWidth={"lg"} fullScreen PaperProps={{ className: classes.dialog }} className={classes.dialogBackground} >
            <DialogTitle>
              {"Merry-Learn-pdf-viewer"}
              <CloseIcon onClick={handleFileClose} className={classes.closeIcon} />
            </DialogTitle>
            <DialogContent>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                <div
                  className={classes.container}
                >
                  <Viewer fileUrl={selectedUrl} plugins={[defaultLayoutPluginInstance]} />
                </div>
              </Worker>
            </DialogContent>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained" size="small" startIcon={<SaveIcon />} className={classes.save} >
            <b>close</b>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

