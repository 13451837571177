/* eslint-disable react-hooks/rules-of-hooks */
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Alert, Autocomplete, Box, Button, Grid, Snackbar, TextField, Typography } from '@mui/material';
import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import MLearnTooltip from '../../GlobalStyles/ToolTip';
import useStyles from "../../components/ManageCourse/CourseCss";
import { usePost } from '../../utils/apiHelper';
import DownloadManager from './DownloadManager';
const ImportCourse = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [selectedHeaders, setSelectedHeaders] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const headers = Object.keys(data[0] || {});
    const [disableSave, setDisableSave] = useState(true); // Initially set to true
    const validHeaders = ['EmailAddress', 'CourseCode', 'CourseStartDate', 'FirstReminderDate', 'SecondReminderDate', 'ThirdReminderDate', 'CourseEndDate']; 


    const handleCloseDialog = () => {
        props.onCloseStepper();
    };

    const { activeStep, setActiveStep } = props;
    // Function to check if all selected headers are not empty and are unique
    const isSaveEnabled = () => {
        if (selectedHeaders.length !== headers.length) {
            return false; // If all headers are not selected
        }
        const selectedValues = selectedHeaders.map(header => header.value);
        const uniqueValues = new Set(selectedValues);
        return uniqueValues.size === selectedValues.length; // If all selected values are unique
    };

    // Update the disableSave state whenever selectedHeaders change
    useEffect(() => {
        setDisableSave(!isSaveEnabled());
    }, [selectedHeaders]);

    useEffect(() => {
        if (headers.length > 0) {
            // Set the default selected header for each Autocomplete
            setSelectedHeaders(headers.map((header) => ({ name: header, value: header })));
        }
    }, []);
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };


    const handleClose = () => {
        setData([]);
        props.onCreate();
        setOpenSnackbar(false);
    }
    useEffect(() => {
        if (data.length > 0) {
            const headers = Object.keys(data[0]);
            setSelectedHeaders(headers.map((header) => ({ value: header })));
        }
    }, [data]);

    const handleChangeHeader = (index, newHeader) => {
        setSelectedHeaders((prevHeaders) =>
            prevHeaders.map((header, i) => (i === index ? { ...header, value: newHeader } : header))
        );
    };
    const handleFileChange = (event) => {
        const fileInput = event.target;
        const file = fileInput.files[0];
    
        if (file) {
            Papa.parse(file, {
                complete: (result) => {
                    const headers = Object.keys(result.data[0]);
                    const invalidHeaders = headers.filter(header => !validHeaders.includes(header));
    
                    if (invalidHeaders.length > 0) {
                        setErrorMessage(`Invalid headers: ${invalidHeaders.join(', ')}`);
                        setOpenSnackbar(true);
                        setData([]); 
                    } else {
                        setData(result.data);
                    }
    
                    fileInput.value = null;
                },
                header: true,
            });
        }
    };
    const remapData = (data, headers) => {
        return data.map(row => {
            const remappedRow = {};
            headers.forEach((header, index) => {
                remappedRow[header.value] = row[Object.keys(row)[index]] || '';
            });
            return remappedRow;
        });
    };

    const handleSave = () => {
        if(data.length===0){
            setDisableSave(true);
            setErrorMessage(`Import the csv file`);
            setOpenSnackbar(true);
            return;
        }
        let success = false;
        let nonExisting: any = [];
        const fieldsToCheck = ['EmailAddress', 'CourseCode', 'CourseStartDate', 'FirstReminderDate', 'SecondReminderDate', 'ThirdReminderDate', 'CourseEndDate'];
        const remappedData = remapData(data, selectedHeaders);
        const formattedData = remappedData
            .filter(entry =>
                fieldsToCheck.every(field =>
                    entry?.[field] !== null &&
                    entry?.[field] !== "" &&
                    entry?.[field] !== undefined
                )
            )
            .map(entry => ({
                [selectedHeaders[0].value]: entry?.EmailAddress,
                [selectedHeaders[1].value]: entry?.CourseCode,
                [selectedHeaders[2].value]: formatDate(entry?.CourseStartDate),
                [selectedHeaders[3].value]: formatDate(entry?.FirstReminderDate),
                [selectedHeaders[4].value]: formatDate(entry?.SecondReminderDate),
                [selectedHeaders[5].value]: formatDate(entry?.ThirdReminderDate),
                [selectedHeaders[6].value]: formatDate(entry?.CourseEndDate),
            }));

        const request = { importCourseInputs: formattedData };

        (async () => {
            await usePost('Course/ImportCourseSchedule', request)
                .then((response) => {
                    success = true;
                    nonExisting = response.data;
                })
        })().finally(() => {
            if (success) {
                setSuccessMessage('Courses imported successfully.');
                handleClose();
                const csvContent = "emailAddress\n" + nonExisting.map(entry => entry.emailAddress).join("\n");

                // Create a Blob containing the CSV data
                const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

                // Create a download link for the Blob
                const link = document.createElement("a");
                link.setAttribute("href", URL.createObjectURL(blob));
                link.setAttribute("download", "InValidEmails.csv");

                // Simulate a click on the download link to trigger the download
                link.click();
            } else {
                setErrorMessage('An error occurred while importing courses.');
                handleClose();
            }
            setOpenSnackbar(true);
        });
    };
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const [day, month, year] = dateString.split('-');
        return new Date(`${year}-${month}-${day}`).toISOString();
    };
   const handleClear = () => {
        setData([]);
        setSelectedHeaders([]);
        setDisableSave(true);
    };
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };
    return (
        <React.Fragment>
            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.courseContainer}>
                <Grid container p={2}>
                    <Grid item xs={2} display="flex" justifyContent='space-evenly'>

                        <input style={{ display: 'none' }} id="course-button-file" type="file" accept=".csv" onChange={handleFileChange} />
                        <label htmlFor="course-button-file">
                            <MLearnTooltip title="Import" placement="top">
                                <Button variant="contained" size="small" component="span" aria-label="Import Course" className={classes.importcourseStyle}>
                                    <CloudUploadOutlinedIcon />
                                </Button>
                            </MLearnTooltip>
                        </label >
                        <DownloadManager />
                    </Grid>
                    <Grid item xs={10} display="flex" justifyContent={'flex-end'}>
                    <Button variant="contained" color="primary"  size="small" sx={{mr:"2px"}} onClick={handleClear}>
                               clear
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleSave} size="small" disabled={disableSave}>
                                Save
                            </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} p={1} border={"1px solid grey"} borderRadius={2}>
                    {data.length ? (
                        <React.Fragment>
                            <Grid container item xs={12} spacing={1}>
                                {selectedHeaders.map((header, index) => (
                                    <Grid item xs={1.7} key={index}>
                                        <Autocomplete
                                            value={header.value} // Set the value to the value of the selected header
                                            onChange={(event, newValue) => handleChangeHeader(index, newValue)} // Update the selected header on change
                                            options={headers} // Set the options to the list of available headers, not selectedHeaders
                                            getOptionLabel={(option) => option} // Assuming options are strings
                                            renderInput={(params) => (
                                                <TextField {...params} size="small" label="Select Header" />
                                            )}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            {data.slice(0, 10).map((row, rowIndex) => (
                                <Grid container item xs={12} key={rowIndex} spacing={1}>
                                    {Object.keys(row).map((key, columnIndex) => (
                                        <Grid item xs={1.7} key={columnIndex}>
                                            <Typography variant="body1" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                {row[key]}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            ))}
                        </React.Fragment>
                    ) : (
                        <Typography variant="body1">Import the CSV file</Typography>
                    )}
                </Grid>
            </Scrollbars>
            <Box m={0} p={1} width={"100%"} display={"inline-flex"} justifyContent={"flex-end"} borderTop={"1px solid lightgray"}>
                <Button component="label" variant="contained" size="small" color="success" onClick={handleBack} sx={{ marginRight: 1 }}>
                    <b>Back</b>
                </Button>
                <Button variant="contained" color="success" onClick={handleCloseDialog} size="small" >
                    Finish
                </Button>
            </Box> <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={successMessage ? 'success' : 'error'}>
                    {successMessage || errorMessage}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
};

export default ImportCourse;
