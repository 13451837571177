import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>({
    gridPosition:{
        position: 'relative', 
        width: '40px', 
        height: '40px'
    },
    typography:{
         position: 'absolute',
         top: '50%',
         left: '55%',
         transform: 'translate(-50%, -50%)',
         fontWeight: 800
    },
    typography1:{
      position: 'absolute',
      top: '120%',
      left: '120%',
      transform: 'translate(-50%, -50%)',
      color:'#000f82',
      fontWeight: 'bold'
 },
    courseContainer: {
        [theme.breakpoints.up("lg")]: {
          maxHeight: "calc( 100vh - 75px) !important",
          minHeight: "calc( 100vh - 75px) !important",
        },
      },
      chartContainer: {
        backgroundColor: 'linear-gradient(to top, rgba(255,0,0,0), rgba(255,0,0,1))',
        padding: 20,
      },
      twitterIconStyle:{
        color:'rgb(0 15 130) !important',
      },
      marginStyle:{
        marginTop: '15px !important',
      },
      GroupIconStyle:{
        borderRadius: '25px !important', 
        backgroundColor: 'white !important', 
        marginRight: '10', 
        paddingRight: '10'
      },
      autoiconStyle:{
        borderRadius: '25px !important',
         backgroundColor: 'white !important'
      }
  }));