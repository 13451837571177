/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Alert, Autocomplete, Backdrop, Box, Button, Chip, FormControlLabel, Grid, IconButton, Paper, Radio, Snackbar, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { SyncLoader } from "react-spinners";
import addTopicImg from '../../../asset/Topic/addTopic.svg';
import { ICourseAssignment, IEditMultipleChoiceProps } from '../../../model/ICourse';
import { Question } from '../../../model/multipleChoiceQuestions';
import { usePost } from '../../../utils/apiHelper';
import useStyles from "../CourseCss";

const EditMultipleChoice: React.FC<IEditMultipleChoiceProps> = (props) => {
  const classes = useStyles();
  const { topicId, courseId, subTopicId, topicLabel, SendAssignmentId } = props;

  const baseQuestion: Question[] = [{
    question: '',
    files: [],
    answers: [],
    currentQuestion: { options: [{ checked: false, description: '' }] },
    unsavedChanges: false,
    blobUri: ''
  }];

  const [course, setCourse] = useState<ICourseAssignment>();
  const [selectedAssignmentType, setSelectedAssignmentType] = useState("Multiple Choice");
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(null);
  const [questions, setQuestions] = useState<Question[]>(baseQuestion);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");


  useEffect(() => {
    if (topicId) {
      fetchData();
    } else {
      setSelectedQuestionIndex(0);
    }
  }, [props]);


  const handleClose = () => {
    setOpen(false);
  };


  const fetchData = () => {
    setLoading(true);
    (async () => {
      let request = {
        "courseId": courseId,
        "courseTopicId": topicId,
        "courseSubTopicId": subTopicId
      }
      await usePost<ICourseAssignment>("Course/GetCourseAssignment", request).then((result) => {
        setCourse(result.data);
        if (Array.isArray(result.data)) {
          const updatedQuestions = result.data.flatMap((assignment: any) => {
            let newAssignmentList = JSON.parse(assignment?.assignmentDescription);
            if (Array.isArray(newAssignmentList)) {
              const assignmentDescriptionArray = newAssignmentList;
              const questions = assignmentDescriptionArray.map((question: any) => ({
                question: question.question,
                files: [], // Assuming files are empty initially
                answers: question.options.map((option: any) => option.Option.answer),
                currentQuestion: {
                  options: question.options.map((option: any) => ({
                    checked: option.Option.answer === question.selectedAnswer,
                    description: option.Option.description,
                  })),
                },
                selectedAnswer: question.selectedAnswer,
                unsavedChanges: false,
                blobUri: question.blobUri,
              }));
              return questions;
            } else {
              return [];
            }
          });
          const flattenedQuestions = updatedQuestions.flat();
          setQuestions(flattenedQuestions);
        }
      }).finally(() => {
        setLoading(false);
      });
    })()
  };

  const updateAssignmentDescription = () => {
    const serializeQuestion = (question: Question) => {
      return {
        question: question.question,
        options: question.answers.map((answer, index) => ({
          Option: {
            answer,
            description: question.currentQuestion.options[index].description // Include the option description here
          }
        })),
        selectedAnswer: question.selectedAnswer,
        blobUri: question.blobUri, // Ensure BlobUri is included here
      };
    };
    const assignmentDescription = questions.map(serializeQuestion);
    return JSON.stringify(assignmentDescription);
  };

  const handleSubmit = () => {
    (async () => {
      let request = {
        "courseAssignmentId": course[0]?.courseAssignmentId,
        "courseId": courseId,
        "courseTopicId": topicId,
        "courseSubTopicId": subTopicId,
        "assignmentTypeId": course[0]?.assignmentTypeId,
        "assignmentDescription": updateAssignmentDescription(),
        "createdBy": course[0]?.createdBy,
        "modifiedBy": course[0]?.modifiedBy
      }
      await usePost("Course/ManageCourseAssignment", request).then(() => {
        setSnackbarMessage('Assignment Added Successfully');
      }).finally(() => {
        setOpen(true);
      });
    })()

    if (topicId && subTopicId) {
      props.SendAssignmentId(false, subTopicId);
    }
    else {
      props.SendAssignmentId(true, topicId);
    }

  }

  const handleFileAttachClick = (questionIndex: number) => {
    setSelectedQuestionIndex(questionIndex);
    document.getElementById(`file-upload-${questionIndex}`)?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>, questionIndex: number) => {
    const files = (e.target as HTMLInputElement).files;

    if (files && files.length) {
      const formData = new FormData();
      formData.append('file', files[0]); // Assuming only one file is uploaded per question
      formData.append("CourseId", courseId.toString());
      formData.append("CourseAssignmentId", course[0]?.courseAssignmentId ? course[0]?.courseAssignmentId.toString() : "");
      (async () => {
        const response = await usePost('File/InsertAssignmentMediaDetail', formData) as { data: { BlobUri: string } };
        const blobUri = response.data[0];
        setQuestions((prevQuestions) => {
          const newQuestions = [...prevQuestions];
          newQuestions[questionIndex].files = [...newQuestions[questionIndex].files, files[0]];
          newQuestions[questionIndex].blobUri = blobUri;
          return newQuestions;
        });
      })();
    }
  };

  const handleDeleteFile = (questionIndex: number, fileIndex: number) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex].files.splice(fileIndex, 1);
      return newQuestions;
    });
  };

  const handleAddQuestion = () => {
    setQuestions((prevQuestions) => [
      {
        question: '',
        files: [],
        answers: [],
        currentQuestion: { options: [{ checked: false, description: '' }] },
        unsavedChanges: false,
        blobUri: ''
      },
      ...prevQuestions,
    ]);
  };

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>, questionIndex: number) => {
    const value = e.target.value;
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex].question = value;
      return newQuestions;
    });
    updateAssignmentDescription();
  };

  const handleAddAnswer = (questionIndex: number) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex].answers = [
        ...newQuestions[questionIndex].answers,
        `Option ${newQuestions[questionIndex].answers.length + 1}`,
      ];
      newQuestions[questionIndex].currentQuestion.options.push({
        checked: false, description: "",
      });
      return newQuestions;
    });
  };

  const handleRadioChange = (questionIndex: number, index: number) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      const currentQuestion = newQuestions[questionIndex].currentQuestion;
      const updatedOptions = [...currentQuestion.options];
      // Uncheck all options
      updatedOptions.forEach((option) => {
        option.checked = false;
      });
      // Check the selected option
      updatedOptions[index].checked = true;
      // Update selected answer
      const newSelectedAnswer = updatedOptions.findIndex((option) => option.checked);
      newQuestions[questionIndex].selectedAnswer = newSelectedAnswer !== -1 ? newQuestions[questionIndex].answers[newSelectedAnswer] : null;
      return newQuestions;
    });
  };

  const handleSave = (questionIndex: number) => {
    const currentQuestion = questions[questionIndex];
    const isAtLeastOneRadioButtonChecked = currentQuestion.answers.some((answer, index) => currentQuestion.currentQuestion.options[index].checked);
    if (isAtLeastOneRadioButtonChecked) {
      setQuestions((prevQuestions) => {
        const newQuestions = [...prevQuestions];
        newQuestions[questionIndex].unsavedChanges = false;
        updateAssignmentDescription(); // Update assignment description whenever there's a save
        return newQuestions;
      });
    }
  };

  const handleDoubleClick = (questionIndex: number, index: number) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex].editedAnswerIndex = index;
      return newQuestions;
    });
  };

  const handleCancelAnswer = (questionIndex: number, answerIndex: number) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      const currentQuestion = newQuestions[questionIndex];
      // Remove the answer and its corresponding option
      currentQuestion.answers.splice(answerIndex, 1);
      currentQuestion.currentQuestion.options.splice(answerIndex, 1);
      // Update selected answer if needed
      const newSelectedAnswer = currentQuestion.currentQuestion.options.findIndex((option) => option.checked);
      currentQuestion.selectedAnswer = newSelectedAnswer !== -1 ? currentQuestion.answers[newSelectedAnswer] : null;
      // Mark as unsaved changes
      currentQuestion.unsavedChanges = true;
      return newQuestions;
    });
  };

  const handleAnswerChange = (questionIndex: number, index: number, value: string) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex].answers[index] = value;
      return newQuestions;
    });
    handleSave(questionIndex)
  };

  const handleAnswerBlur = (questionIndex: number) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex].lastEditedAnswerIndex = newQuestions[questionIndex].editedAnswerIndex;
      newQuestions[questionIndex].editedAnswerIndex = null;
      updateAssignmentDescription();
      return newQuestions;
    });
  };

  useEffect(() => {
    // setTopic(topicId);
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const hasUnsavedChanges = questions.some((question) => question.unsavedChanges);
      if (hasUnsavedChanges) {
        const message = "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [topicId, questions]);


  const handleDeleteQuestion = (questionIndex: number) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      // Remove the question at the specified index
      newQuestions.splice(questionIndex, 1);
      return newQuestions;
    });
  };

  const handleQuestionClick = (questionIndex: number) => {
    // Always set the selected question
    setSelectedQuestionIndex(questionIndex);
  };
  const getFileNameFromUri = (uri) => {
    const parts = uri.split('/');
    return parts[parts.length - 1];
  };

  const handleOptionDescriptionChange = (event, questionIndex, optionIndex) => {
    const { value } = event.target;
    setQuestions(prevQuestions => {
      const newQuestions = [...prevQuestions];
      const updatedQuestion = { ...newQuestions[questionIndex] };
      const updatedOptions = [...updatedQuestion.currentQuestion.options];
      updatedOptions[optionIndex] = {
        ...updatedOptions[optionIndex],
        description: value
      };
      // Update the options in the current question
      updatedQuestion.currentQuestion = {
        ...updatedQuestion.currentQuestion,
        options: updatedOptions
      };
      // Update the question in the state
      newQuestions[questionIndex] = updatedQuestion;
      return newQuestions;
    });
  };

  return (
    <Grid container padding={0} spacing={0}>
      {loading && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <SyncLoader size={10} color={"#5e35b1"} loading={loading} />
        </Backdrop>
      )}
      <Grid item xs={12} borderBottom={'1px solid lightgray'}>
        <Grid container padding={0.3} spacing={0}>
          <Grid item xs={6} >
            <Typography variant="body2" padding={1} color="black" >
              <b>Topic Name : {topicLabel}</b>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              fullWidth
              value={selectedAssignmentType}
              size="small"
              onChange={(event, newValue) => {
                if (typeof newValue === "string" && newValue === "Multiple Choice") {
                  setSelectedAssignmentType(newValue);
                  if (selectedQuestionIndex !== null) {
                    setQuestions((prevQuestions) => {
                      const newQuestions = [...prevQuestions];
                      return newQuestions;
                    });
                  }
                }
              }}
              options={["Multiple Choice"]}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={3} alignItems={'center'} justifyContent={'space-evenly'} display={'inline-flex'}>
            <Button onClick={handleAddQuestion} variant="contained" color="primary" size="small" >
              <b> Add questions</b>
            </Button>
            <Button variant="contained" size="small" color="success" onClick={handleSubmit} >
              <b>Save</b>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.courseQuestion}>
        <Box p={1.5}>
          {questions.length === 0 ? (
            <Grid item xs={12} justifyContent={'center'} mt={20} textAlign={'center'} >
              <img src={addTopicImg} alt="add a topic" width={'100px'} />
              <Typography variant="body2" textAlign={'center'} justifyContent={'center'}>Please Add The Questions</Typography>
            </Grid>
          ) : (
            questions?.map((question, questionIndex) =>
              // Conditionally render based on the selectedQuestionIndex
              selectedQuestionIndex === null || selectedQuestionIndex === questionIndex ? (
                <Grid container padding={1} spacing={0} mt={1.5} component={Paper} boxShadow={4} borderLeft={'4px solid #5e35b1'}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      label="Type the Question Here"
                      fullWidth
                      size="small"
                      value={question.question}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleQuestionChange(e, questionIndex)}
                      onClick={() => handleQuestionClick(questionIndex)}
                    />
                  </Grid>
                  <Grid item xs={1} alignItems={'center'} justifyContent={'space-evenly'} display={'inline-flex'}>
                    <IconButton size="small" onClick={() => handleFileAttachClick(questionIndex)}>
                      <AttachFileIcon fontSize="small" color="primary" />
                    </IconButton>
                    <input
                      type="file"
                      id={`file-upload-${questionIndex}`}
                      style={{ display: "none" }}
                      multiple
                      onChange={(e) => handleFileChange(e, questionIndex)}
                    />
                    <IconButton size="small" sx={{ marginRight: 1 }} onClick={() => handleDeleteQuestion(questionIndex)}>
                      <DeleteTwoToneIcon fontSize="small" color="error" />
                    </IconButton>
                  </Grid>
                  <Grid container spacing={2}>
                    {question?.answers?.map((answer, index) => (
                      <Grid item xs={12} key={index}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={5}>
                            {question.editedAnswerIndex === index ? (
                              <TextField
                                fullWidth
                                label={"Enter options here"}
                                size="small"
                                sx={{ margin: '5px' }}
                                variant="standard"
                                onDoubleClick={() => handleDoubleClick(questionIndex, index)}
                                value={answer}
                                onChange={(e) => handleAnswerChange(questionIndex, index, e.target.value)}
                                onBlur={() => handleAnswerBlur(questionIndex)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    handleSave(questionIndex);
                                  }
                                }}
                              />
                            ) : (
                              <FormControlLabel
                                value={answer}
                                control={<Radio size="small" sx={{ marginLeft: '5px' }} checked={question?.currentQuestion?.options[index]?.checked} color={question?.currentQuestion?.options[index]?.checked ? 'success' : 'default'} />}
                                label={answer}
                                onDoubleClick={() => handleDoubleClick(questionIndex, index)}
                                onChange={() => handleRadioChange(questionIndex, index)}
                              />
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label={"Enter Option Description"}
                              size="small"
                              sx={{ margin: '5px' }}
                              variant="outlined"
                              multiline
                              value={question?.currentQuestion?.options[index]?.description}
                              onChange={(e) => handleOptionDescriptionChange(e, questionIndex, index)}

                            />
                          </Grid>
                          <Grid item xs={1} justifyContent={'flex-end'} display={'inline-flex'}>
                            <IconButton size="small" onClick={() => handleCancelAnswer(questionIndex, index)}>
                              <DeleteTwoToneIcon color="error" fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container>
                    <Grid item xs={2} borderTop={'1px dashed lightgray'}>
                      <Button variant="contained" color="warning" size="small" onClick={() => handleAddAnswer(questionIndex)} sx={{ mt: 1 }}><b>Add Option</b></Button>
                    </Grid>
                    <Grid item xs={8} pt={1} borderTop={'1px dashed lightgray'}>
                      {question.files.length > 0 && question.files.map((file, fileIndex) => (
                        <Chip
                          key={fileIndex}
                          label={file.name}
                          onDelete={() => handleDeleteFile(questionIndex, fileIndex)}
                          variant="outlined"
                          color="primary"
                          size="small"
                          sx={{ mt: 0.5 }}
                        />
                      ))}
                      {question.blobUri && question.files.length === 0 && (
                        <Grid spacing={1}>
                          <Chip
                            label={getFileNameFromUri(question.blobUri)}
                            onDelete={() => handleDeleteFile(questionIndex, 0)}
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{ mt: 0.5 }}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={2} borderTop={'1px dashed lightgray'}>
                      {question.selectedAnswer && (
                        <Typography variant="body2" color="green" mt={1.5} fontWeight={'bold'} textAlign={'end'}>
                          Answer Key : {question.selectedAnswer}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container padding={1} spacing={0} mt={1.5} component={Paper} boxShadow={4} borderLeft={'4px solid #5e35b1'}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      label="Type the Question Here"
                      fullWidth
                      size="small"
                      value={question.question}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleQuestionChange(e, questionIndex)}
                      onClick={() => handleQuestionClick(questionIndex)}
                    />
                  </Grid>

                  <Grid item xs={1} alignItems={'center'} justifyContent={'space-evenly'} display={'inline-flex'}>
                    <IconButton size="small" onClick={() => handleFileAttachClick(questionIndex)}>
                      <AttachFileIcon fontSize="small" color="primary" />
                    </IconButton>
                    <input
                      type="file"
                      id={`file-upload-${questionIndex}`}
                      style={{ display: "none" }}
                      multiple
                      onChange={(e) => handleFileChange(e, questionIndex)}
                    />

                    <IconButton size="small" onClick={() => handleDeleteQuestion(questionIndex)}>
                      <DeleteTwoToneIcon fontSize="small" color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            )
          )}
        </Box>
      </Scrollbars>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        color='success'
        className={classes.snackbarColor}
      >
        <Alert onClose={handleClose} severity="success" className={classes.snackbarColor}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid >

  );
};

export default EditMultipleChoice;