import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

// SETUP COLORS
export const Primary = {
  lighter: "#7a8b95",
  light: "#4c5d67",
  main: "#3b4a54",
  dark: "#2a3944",
  darker: "#192533",
};
export const Secondary = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
};
export const Info = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
};
export const Success = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
};
export const Warning = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
};
export const Error = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
};

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: Primary.dark,
      light: Primary.light,
      dark: Primary.dark,
    },
    secondary: {
      main: Secondary.dark,
      light: Secondary.light,
      dark: Secondary.dark,
    },
    success: {
      light: Success.light,
      main: Success.main,
      dark: Success.dark,
    },
    warning: {
      light: Warning.light,
      main: Warning.main,
      dark: Warning.dark,
    },
    error: {
      light: Error.light,
      main: Error.main,
      dark: Error.dark,
    },
    info: {
      light: Info.light,
      main: Info.main,
      dark: Info.dark,
    },
  },
});

export default responsiveFontSizes(theme);
