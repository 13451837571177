import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBox: {
      marginTop: '2px !important',
      borderRadius: "8px !important",
      // border: '2px solid #5e35b1 !important',
      height: '35px !important',
      textIndent: '10px !important',
      fontSize: "12px !important",
      '& .MuiInputBase-root': {
        fontSize: "12px !important",
      },
      '& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root': {
        paddingRight: '2px',
        paddingTop: '2px !important',
      },
      backgroundColor: 'white !important'
      // boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    },
    outlinedInput: {
      fontSize: "12px !important",
      color: "black !important",
      marginTop: '-2px !important',
      '&$focused $notchedOutline': {
        color: 'white !important',
        border: '0px !important'
      },
    },
    notchedOutline: {
      color: 'white !important',
      border: 'none !important',
      fontSize: "12px !important",
    },
    searchIcon: {
      padding: '3px',
      color: '#5e35b1 !important',
      '&:hover': { color: 'white !important', backgroundColor: "#5e35b1 !important" },
      borderRadius: '20px'
    },
    closeIcon: {
      padding: '3px',
      color: '#5e35b1 !important',
      '&:hover': { color: 'white !important', backgroundColor: "#5e35b1 !important" },
      borderRadius: '20px'
    },
    iconButtons: {
      marginTop: '3px !important',
      color: '#5e35b1 !important',
      backgroundColor: "#E6E6FA !important",
      '&:hover': { color: 'white !important', backgroundColor: "#5e35b1 !important" },
      borderRadius: '10px'
    },

    switchIcon: {    
      marginTop: '3px !important',
      color: '#5e35b1 !important',
      backgroundColor: "#E6E6FA !important",
      borderRadius: '10px',
      '&:hover': {
        border: "2px solid #5e35b1 !important",
      },
    },
    iconAnimation:{
      animation: `$blink 0.7s infinite alternate`,
    },
    '@keyframes blink': {
      from: { opacity: 1 },
      to: { opacity: 0 },
    },
    
  }),
);

export default useStyles;

