/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Avatar, Backdrop, Grid, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import profileIcon from '../../../../images/ProfileCard/avatar.svg';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import useStyles from '../ProfileCardCss';
import VisuallyHiddenInput from '../../../../GlobalStyles/VisuallyHiddenInput';
import { IUserInformation } from '../../../../model/Users/IUser';
import PhoneIcon from '@mui/icons-material/Phone';
import DownloadIcon from '@mui/icons-material/Download';
import { useFetch } from '../../../../utils/apiHelper';
import EmailIcon from '@mui/icons-material/Email';
import { useGlobalState } from '../../../../GlobalStore/globalStore';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Box } from '@mui/system';
import { ProfileCustomLiner } from './ProfileCustomLiner';
import EditIcon from '@mui/icons-material/Edit';
import Scrollbars from 'react-custom-scrollbars';
import MLearnTooltip from '../../../../GlobalStyles/ToolTip';
import EditProfile from './EditProfile';
import { MoonLoader } from 'react-spinners';
import Jobs from './Jobs';
import PersonalInformation from './PersonalInformation';

const ProfileAccount = () => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setloading] = useState(false);
    const [uploadedImage, setUploadedImage] = useState<string>('');
    const [users, setUsers] = useState<IUserInformation[]>([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [editType, setEditType] = useState<'PersonalDetails' | 'AboutMe'>('PersonalDetails');
    const [selectedFile, setSelectedFile] = useState<File>();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setSelectedFile(files[0]);
        }
    };


    const handleEditClick = (type: 'PersonalDetails' | 'AboutMe') => {
        setIsDrawerOpen(true);
        setEditType(type);
    };

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUploadedImage(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = () => {
        setUploadedImage(null);
    };

    useEffect(() => {
        fetchUserInformation();
    }, []);

    const handleEditButtonClick = () => {
        fetchUserInformation();
    }

    const fetchUserInformation = () => {
        (async () => {
            setloading(true);
            await useFetch<IUserInformation[]>(`Users/GetUserInformationById?userId=${state?.userAccessContext?.userId}`)
                .then(response => {
                    const userData = response?.data[0];
                    setUsers([userData]);
                })
        })()
            .finally(() => {
                setloading(false);
            });
    };

    function LinearProgressWithLabel(props) {
        return (
            <Box display="flex" alignItems="center" p={0}>
                <Box width="100%" mr={3}>
                    <ProfileCustomLiner variant="determinate" {...props} color='primary' />
                </Box>
                <Box minWidth={25}>
                    <Typography variant="body2" color="textSecondary"><b>{`${Math.round(
                        props?.value
                    )}%`}</b></Typography>
                </Box>
            </Box>
        );
    }

    return (
        <React.Fragment>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
            </Backdrop>
            <Grid container spacing={0} bgcolor={"white"}>
                <Grid item xs={12} pb={0} borderBottom={"1px dashed lightgrey"}>
                    <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" aria-label="Horizontal tabs example">
                        <Tab label="Profile" className={classes.tabRecords} />
                        <Tab label="Prepare Curriculum Vitae" className={classes.tabRecords} />
                        <Tab label="Set a Goal" className={classes.tabRecords} />
                        <Tab label="Evaluate Your Skills" className={classes.tabRecords} />
                        <Tab label="Find Jobs" className={classes.tabRecords} />
                    </Tabs>
                </Grid>
                <Grid item xs={12}>
                    {activeTab === 0 && (
                        <React.Fragment>
                            <Grid container spacing={2} p={1.5}>
                                <Grid item xs={3} borderRight={"1px solid lightgrey"}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} display={"flex"} justifyContent={"center"} style={{ marginTop: "15px" }}>
                                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                                <Avatar
                                                    alt="user profile"
                                                    src={uploadedImage || profileIcon} // Use uploadedImage if available
                                                    sx={{ width: 80, height: 80, border: '2px solid #5e35b1' }}
                                                />
                                                <label htmlFor="upload-image" style={{ position: 'absolute', bottom: 0, right: 0 }}>
                                                    {uploadedImage ? (
                                                        <React.Fragment>
                                                            <DeleteIcon
                                                                onClick={handleRemoveImage}
                                                                className={classes.profilePhoto}
                                                                fontSize='medium'
                                                            />
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <AddAPhotoIcon
                                                                className={classes.profilePhoto}
                                                                fontSize='large'
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                </label>
                                                <VisuallyHiddenInput
                                                    id="upload-image"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageUpload}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                                            <Typography variant="body1" color="#5e35b1"><b>{`${users?.length > 0 ? users[0]?.firstName : ''} ${users?.length > 0 ? users[0]?.lastName : ''}`}</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} display={"flex"} justifyContent={"center"} borderBottom={"1px solid lightgrey"}>
                                            <Typography variant="caption" color="#a7a7a7">
                                                <b>{state?.userAccessContext?.userRole ? "Admin" : "Learner"}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} display={"flex"} justifyContent={"center"} p={2} style={{ borderBottom: "1px solid lightgray" }}>
                                            <Grid item xs={4} textAlign={"center"} borderRight={"1px solid lightgray"}>
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                    accept=".pdf,.doc,.docx"
                                                />
                                                <label htmlFor="fileInput">
                                                    <IconButton className={classes.JoinIconButton} component="span">
                                                        <UploadFileIcon fontSize='small' />
                                                    </IconButton>
                                                    <Typography variant='subtitle1' fontSize={"small"} color={"gray"}>Upload CV</Typography>
                                                </label>
                                                {selectedFile && (
                                                    <Typography variant="body2">Selected File: {selectedFile.name}</Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={4} textAlign={"center"} borderRight={"1px solid lightgray"}>
                                                <IconButton className={classes.JoinIconButton}>
                                                    <DownloadIcon fontSize='small' />
                                                </IconButton>
                                                <Typography variant='subtitle1' fontSize={"small"} color={"gray"}>Download CV</Typography>
                                            </Grid>
                                            <Grid item xs={4} textAlign={"center"}>
                                                <IconButton className={classes.JoinIconButton}>
                                                    <MenuBookIcon fontSize='small' />
                                                </IconButton>
                                                <Typography variant='subtitle1' fontSize={"small"} color={"gray"}>Course Taken</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} pt={2}>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle1' textAlign={"left"} color="#5e35b1">
                                                    <b>Contact Information</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} textAlign={"center"}>
                                                <EmailIcon fontSize='small' />
                                            </Grid>
                                            <Grid item xs={11} display={"flex"} justifyContent={"end"}>
                                                <Typography variant='body1' textAlign={"left"}>
                                                    {state?.userAccessContext?.emailAddress}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} textAlign={"center"}>
                                                <PhoneIcon fontSize='small' />
                                            </Grid>
                                            <Grid item xs={11} display={"flex"} justifyContent={"end"}>
                                                <Typography variant='body1' textAlign={"left"}>
                                                    {users?.length > 0 ? users[0]?.phoneNumber : ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} textAlign={"center"}>
                                                <MyLocationIcon fontSize='small' />
                                            </Grid>
                                            <Grid item xs={11} display={"flex"} justifyContent={"end"}>
                                                <Typography variant='body1' textAlign={"left"}>
                                                    {users?.length > 0 ? users[0]?.location : ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} textAlign={"center"}>
                                                <LocationOnIcon fontSize='small' />
                                            </Grid>
                                            <Grid item xs={11} display={"flex"} justifyContent={"end"}>
                                                <Typography variant='body1' textAlign={"left"}>
                                                    {users?.length > 0 ? users[0]?.state : ''}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} pt={2}>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle1' textAlign={"left"} color="#5e35b1">
                                                    <b>Skills</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant='caption'>
                                                    <b>React</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <LinearProgressWithLabel value={70} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant='caption'>
                                                    <b>UX/UI</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <LinearProgressWithLabel value={50} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={9}>
                                    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.scrolbar}>
                                        <Grid container spacing={2} pt={2}>
                                            <Grid container spacing={0} alignItems="center" ml={3} borderBottom={"1px solid lightgrey"}>
                                                <Grid item xs={11}>
                                                    <Typography variant='body1' color="#5e35b1">
                                                        <b>About Me</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1} display={"flex"} justifyContent={"flex-end"}>
                                                    <MLearnTooltip title="Edit About" placement="top">
                                                        <IconButton onClick={() => handleEditClick('AboutMe')}>
                                                            <EditIcon fontSize='small' color='success' />
                                                        </IconButton>
                                                    </MLearnTooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='body1' ml={1}>
                                                    {users?.length > 0 ? users[0]?.aboutMe : ''}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} pt={2}>
                                            <Grid container spacing={0} alignItems="center" ml={3} borderBottom={"1px solid lightgrey"}>
                                                <Grid item xs={11}>
                                                    <Typography variant='body1' color="#5e35b1">
                                                        <b>Personal Details</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1} display={"flex"} justifyContent={"flex-end"} >
                                                    <MLearnTooltip title="Edit PersonalDetails" placement="top">
                                                        <IconButton onClick={() => handleEditClick('PersonalDetails')}>
                                                            <EditIcon fontSize='small' color='success' />
                                                        </IconButton>
                                                    </MLearnTooltip>
                                                    <EditProfile
                                                        isOpen={isDrawerOpen}
                                                        onClose={handleCloseDrawer}
                                                        onUpdate={handleEditButtonClick}
                                                        editType={editType}
                                                        users={users}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant='subtitle1' ml={1}>
                                                    <b>First Name</b>
                                                </Typography>
                                                <Typography variant='body1' ml={1}>
                                                    {users?.length > 0 ? users[0]?.firstName : ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant='subtitle1'>
                                                    <b>Last Name</b>
                                                </Typography>
                                                <Typography variant='body1'>
                                                    {users?.length > 0 ? users[0]?.lastName : ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant='subtitle1'>
                                                    <b>Email Address</b>
                                                </Typography>
                                                <Typography variant='body1'>
                                                    {state?.userAccessContext?.emailAddress}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant='subtitle1' ml={1}>
                                                    <b>Phone Number</b>
                                                </Typography>
                                                <Typography variant='body1' ml={1}>
                                                    {users?.length > 0 ? users[0]?.phoneNumber : ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant='subtitle1'>
                                                    <b>LinkedIn UserName</b>
                                                </Typography>
                                                <Typography variant='body1'>
                                                    {users?.length > 0 ? users[0]?.linkedInUserName : ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant='subtitle1'>
                                                    <b>Github UserName</b>
                                                </Typography>
                                                <Typography variant='body1'>
                                                    {users?.length > 0 ? users[0]?.gitHubUserName : ''}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} pt={3}>
                                            <Grid container spacing={0} alignItems="center" ml={3} borderBottom={"1px solid lightgrey"}>
                                                <Grid item xs={12}>
                                                    <Typography variant='body1' color="#5e35b1">
                                                        <b>Activity</b>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} ml={1}>
                                                <TableContainer>
                                                    <Table sx={{ border: "1px solid lightgrey" }}>
                                                        <TableHead style={{ backgroundColor: "rgb(181, 150, 242)" }}>
                                                            <TableRow className={classes.tableCell}>
                                                                <TableCell className={classes.tableCell} sx={{ textAlign: "center" }}>Stars</TableCell>
                                                                <TableCell className={classes.tableCell} sx={{ textAlign: "center" }}>Certificates</TableCell>
                                                                <TableCell className={classes.tableCell} sx={{ textAlign: "center" }}>Lessons</TableCell>
                                                                <TableCell className={classes.tableCell} sx={{ textAlign: "center" }}>Exercises</TableCell>
                                                                <TableCell className={classes.tableCell} sx={{ textAlign: "center" }}>Total</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow className={classes.tableCell}>
                                                                <TableCell className={classes.tableCell} sx={{ textAlign: "center" }}>0</TableCell>
                                                                <TableCell className={classes.tableCell} sx={{ textAlign: "center" }}>0</TableCell>
                                                                <TableCell className={classes.tableCell} sx={{ textAlign: "center" }}>42</TableCell>
                                                                <TableCell className={classes.tableCell} sx={{ textAlign: "center" }}>42</TableCell>
                                                                <TableCell className={classes.tableCell} sx={{ textAlign: "center" }}>84</TableCell>
                                                                {/* Add more cells for the first row */}
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </Scrollbars>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {activeTab === 1 && (
                        <React.Fragment>
                            <PersonalInformation />
                        </React.Fragment>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {activeTab === 4 && (
                        <React.Fragment>
                            <Jobs />
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ProfileAccount