/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from "react";
import ReactPlayer from 'react-player';
import VisuallyHiddenInput from "../../../../GlobalStyles/VisuallyHiddenInput";
import { ICourseFile, IFile, VideoViewProps } from "../../../../model/course";
import { useDelete, usePost } from "../../../../utils/apiHelper";
import useStyles from "./TopicContentCss";
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import { SmartButton } from "@mui/icons-material";

const VideoView: React.FC<VideoViewProps> = (props) => {

  const { onVideoIdChange, value, topicId, subTopicId, courseId, flag } = props;
  const classes = useStyles();
  const [videourl, setVideoUrl] = useState<string>(null);
  const [videoList, setVideoList] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState<IFile[]>([]);
  const [CourseMediaList, setCourseMediaList] = useState<ICourseFile[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [blobUrl, setBlobUrl] = useState<string>(null);

  useEffect(() => {
    fetchVedios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CourseMediaList]);
  const handleClickOpen = () => {
    setOpen(true);
    GetCourseMediaDetail();
  };

  const handleClose = () => {
    onVideoIdChange(videoList?.join(', '));
    setOpen(false);
    setVideoUrl(null);
  };

  useEffect(() => {
    setVideoList(value?.split(',').map((videourl) => videourl.trim()));
  }, [value]);

  const handleVideoIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVideoUrl(e.target.value);
  };

  const handleAddVideoUrl = () => {

    if (videourl && videourl.trim() !== "") {
      setVideoList((prevList) => {
        const currentList = Array.isArray(prevList) ? [...prevList] : [];
        currentList.push(videourl.trim());
        return currentList;
      });
      setVideoUrl("");
    }
  };


  const handleCancelClick = (index: number) => {
    setVideoList((prevList) => {
      const newList = Array.isArray(prevList) ? [...prevList] : [];
      newList.splice(index, 1);
      return newList;
    });
  };
  const handleChipClick = (enteredVideoUrl: string) => {
    setVideoUrl(enteredVideoUrl);
  };
  // Upload vedio 
 
  const handleFileChange = (event) => {
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      handleSave(selectedFiles);
    }
  };

  const handleSave = (file) => {

    (async () => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("CourseId", courseId?.toString());
      if (flag) {
        formData.append("TopicId", topicId?.toString());
      } else {
        formData.append("TopicId", topicId?.toString());
        formData.append("SubTopicId", subTopicId?.toString());
      }
      await usePost("File/InsertCourseMediaDetail", formData).finally(() => GetCourseMediaDetail());
    })()
  };


  const GetCourseMediaDetail = () => {
    (async () => {
      let requests = {
        courseId: courseId,
        courseTopicId: topicId,
        courseSubTopicId: subTopicId,
        assignmentId: null,
      };

      await usePost<ICourseFile[]>("File/GetCourseMediaDetail", requests).then((result2) => { setCourseMediaList(result2.data); }
      );
    })()
  };

  const handleDelete = (fileId) => {
    (async () => {
      await useDelete(`File/DeleteCourseMediaDetail?FileId=${fileId}`).finally(() => GetCourseMediaDetail());
    })()
  }
  const fetchVideo = (uri) => {
    (async () => {
      let request = {
        pdfUri: uri,
      };

      await usePost<any>("File/FileDownload", request).then((response) => {
        setDialogOpen(true);
        const byteCharacters = atob(response.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'video/mp4' });
        const blobUrl = URL.createObjectURL(blob);
        if (blobUrl) {
          setDialogOpen(true);
        }
        setBlobUrl(blobUrl);
      })

    })()

  };

  const fetchVedios =  () => {
    let MediaResult = [];
    CourseMediaList?.map((r) => {
      if (r.fileType === '.mp4') {
        let newResult: IFile = {
          fileId: r?.fileId,
          fileUrl: r?.fileURI,
          fileName: r?.fileName
        }
        MediaResult?.push(newResult);
      }
      return null;
    })
    setFileUrl(MediaResult);
  };

  const handleVideoPlayerClose = () => {
    setDialogOpen(false);
  }

  //end
  return (
    <React.Fragment>
      <Button  variant="contained"  size="small"  onClick={handleClickOpen}  startIcon={<SlowMotionVideoIcon />}  className={classes.button}  >
        Add Video
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ className: classes.dialog1 }}
        className={classes.dialogBackground}
      >
        <DialogTitle id="alert-dialog-title" className={classes.textAlign}>
          <b>{"Add  Video Link"}</b>
          <CloseIcon onClick={handleClose} className={classes.closeButton} />
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={6} className={classes.display} >
              <TextField
                variant="outlined"
                size="small"
                placeholder="Video Link"
                fullWidth
                value={videourl}
                helperText="Enter only Youtube url only"
                onChange={handleVideoIdChange}
                onBlur={handleAddVideoUrl}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <IconButton onClick={handleAddVideoUrl}>
                        <AddIcon fontSize="small" color="primary" />
                      </IconButton>
                    </React.Fragment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} >
              <Button component="label" variant="outlined" size="medium" startIcon={<CloudUploadIcon fontSize="medium"/>}
                className={classes.VedioButton}
              >
                <b>Upload Video</b>
                <VisuallyHiddenInput type="file" multiple onChange={handleFileChange} />
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} className={classes.display} >
              {videoList?.length > 0 && (
                <Container>
                  <Stack direction="row" spacing={2} sx={{ padding: "5px", flexWrap: 'wrap' }}>
                    {videoList.map((enteredVideoUrl, index) => (
                      <Chip
                        key={index}
                        size="small"
                        label={enteredVideoUrl}
                        onClick={() => handleChipClick(enteredVideoUrl)}
                        onDelete={() => handleCancelClick(index)}
                        color="primary"
                        variant="outlined"
                        sx={{ flexShrink: 0, maxWidth: '40%' }}
                      />
                    ))}
                  </Stack>
                </Container>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} className={classes.display} >
              <Container>
                {fileUrl?.map((fileDetails, index) => (
                  <Grid key={index} container alignItems="center" display={'flex'}>
                    <Typography variant="body2" className={classes.ellipsis}>
                      {fileDetails.fileName}
                    </Typography>
                    <IconButton   onClick={() => fetchVideo(fileDetails?.fileUrl)}  size="small">
                      <VisibilityIcon fontSize="small" color="primary" />
                    </IconButton>
                    <IconButton color="error" size="small" onClick={() => handleDelete(fileDetails.fileId)}>
                      <DeleteForeverIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                ))}
              </Container>
            </Grid>
          </Grid>
          <Dialog open={dialogOpen} onClose={handleVideoPlayerClose} maxWidth={'xs'} PaperProps={{ className: classes.dialog }} className={classes.dialogBackground} >
            <DialogTitle>
              {"Merry-Learn-Video-viewer"}
              <CloseIcon onClick={handleVideoPlayerClose} className={classes.closeIcon} />
            </DialogTitle>
            <DialogContent>
              <Grid width={'390px'} height={'300px'} >
                {blobUrl && (
                <div>
                <ReactPlayer
                  url={blobUrl}
                  width='100%'
                  height='100%'
                  controls
                  volume={0.3}
                  playing // Start playing automatically
                  progressInterval={1000} // Update progress every second
                  playbackRate={1} // Set playback rate to 1x
                />
              </div>
                )}
              </Grid>
            </DialogContent>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained" size="small" startIcon={<SaveIcon />} className={classes.save}>
            <b>Save</b>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default VideoView;