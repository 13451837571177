/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { DialogContent, DialogTitle } from "@material-ui/core";
import { Button, Dialog, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from "@mui/icons-material/Done";
import { IUserAssignment } from "../../model/ICourse";
import { UserQuestion } from "../../model/multipleChoiceQuestions";
import { usePost } from "../../utils/apiHelper";
import { useStyles } from "./UserAssessmentCss";

const AssessmentReview = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [questions, setQuestions] = useState<UserQuestion[]>([]);
    const { courseId, topicId, topicName, subTopicId, subTopicName, courseTitle, selectedOptions } = props.AssignmentReview;

    const fetchData = () => {
        (async () => {
            let request = {
                "courseId": courseId,
                "courseTopicId": topicId,
                "courseSubTopicId": subTopicId
            };
            await usePost<IUserAssignment>("Course/GetCourseAssignment", request).then((result) => {
                if (Array.isArray(result.data)) {
                    const updatedQuestions = result?.data?.flatMap((assignment) => {
                        let learnerDescriptionArray = JSON.parse(assignment?.assignmentDescription); // Parse the JSON string
                        return learnerDescriptionArray.map((question) => ({
                            question: question?.question,
                            answers: question?.options.map((option) => option?.Option?.answer),
                            currentQuestion: {
                                options: question?.options?.map((option) => ({
                                    checked: option?.Option?.answer === question?.selectedAnswer,
                                    description: option?.Option?.description,
                                })),
                            },
                            selectedAnswer: question?.selectedAnswer,
                            unsavedChanges: false,
                            blobUri: question?.blobUri,
                        }));
                    });
                    const flattenedQuestions = updatedQuestions.flat();
                    setQuestions(flattenedQuestions);
                }
            }).finally(() => {
            });
        })()
    };
    const handleClickOpen = () => {
        fetchData();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <React.Fragment>
            <Button size="small" variant="contained" color="success" onClick={handleClickOpen} aria-label="Assign User" disabled={!props.IsAttempt}>
                <b>Review</b>
            </Button>
            <Dialog open={open} onClose={handleClose} fullScreen aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ backgroundColor: "#5e35b1", color: "white" }} className={`${classes.textAlign} ${classes.dialogTitle}`}>
                    <b>Assignment Review</b>
                    <CancelOutlinedIcon onClick={handleClose} className={classes.closeButton} />
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={0} display={'flex'} className={classes.topicContainer}>
                        <Grid item xs={2} bgcolor={'whitesmoke'}></Grid>
                        <Grid item xs={8} bgcolor={'white'}>
                            <Grid textAlign={'center'}>
                                <Typography>
                                    <b>{topicName}</b>
                                    <b>{subTopicName}</b>
                                </Typography>
                            </Grid>
                            {questions.map((question, questionIndex) => {
                                const selectedAnswer = question?.selectedAnswer; // Set selectedAnswer for each question
                                return (
                                    <Grid container spacing={0} key={questionIndex} display={'flex'} direction={'row'} alignItems={'center'}>
                                        <Grid item xs={12} m={4}>
                                            <Grid item m={1}>
                                                <Typography variant="h6"><b>{`${questionIndex + 1}. ${question?.question}`}</b></Typography>
                                            </Grid>
                                            <Grid item display={'flex'} direction={'column'} ml={5}>
                                                {question.answers.map((option, optionIndex) => {
                                                    const isSelectedOption = selectedOptions[questionIndex] === option;
                                                    return (
                                                        <Grid container key={optionIndex} alignItems="center">
                                                            <Grid item xs={5}
                                                                marginBottom={2}
                                                                display={'flex'}
                                                                className={`${classes.border} ${isSelectedOption ? (selectedAnswer === option ? classes.greenBorder : classes.redBorder) : ''}`}
                                                                style={{
                                                                    marginRight: '25px',
                                                                    borderRadius: '5px',
                                                                    backgroundColor: isSelectedOption ? (selectedAnswer === option ? '#C0FFC0' : '#FFE0E0') : 'white',
                                                                }}
                                                            >
                                                                <Typography variant="body2">
                                                                  {`${String.fromCharCode(65 + optionIndex)}. ${option}`}
                                                                  {isSelectedOption && selectedAnswer !== option && (
                                                                    <CloseIcon style={{ color: 'red', marginRight: '5px' }} />
                                                                  )}
                                                                  {selectedAnswer === option && (
                                                                    <DoneIcon style={{ color: 'green', marginRight: '5px' }} />
                                                                  )}
                                                                </Typography>

                                                            </Grid>
                                                            <Grid item xs={6} marginBottom={2}>
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{
                                                                        border: isSelectedOption ? (selectedAnswer === option ? '2px solid #00FF00' : '2px solid #FF0000') : '2px solid black',
                                                                        padding: '5px',
                                                                        borderRadius: '5px',
                                                                        backgroundColor: isSelectedOption ? (selectedAnswer === option ? '#C0FFC0' : '#FFE0E0') : 'white',
                                                                    }}
                                                                >
                                                                    {question?.currentQuestion?.options[optionIndex]?.description}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Grid item xs={2} bgcolor={'whitesmoke'}></Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment >
    );
};

export default AssessmentReview;
