import CloseIcon from '@mui/icons-material/Close';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import React, { useState } from 'react';
import { IThemeCssData } from '../../../../_mock/themebg';
import useStyles from './SettingsCss';
import MLearnTooltip from '../../../../GlobalStyles/ToolTip';

const Settings: React.FC<{ themes: IThemeCssData[], selectedTheme: IThemeCssData, handleSelectTheme: (themes: IThemeCssData) => void }> = (props) => {
    const classes = useStyles();
    const { themes, selectedTheme, handleSelectTheme } = props;
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (

        <React.Fragment>
            <IconButton size='small' className={classes.iconButtons} onClick={() => { setOpen(true); }}>
            <MLearnTooltip title={ "Settings"} placement="top">
                <SettingsTwoToneIcon fontSize='small' />
                </MLearnTooltip>
            </IconButton>
            <SwipeableDrawer
                anchor="right"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                PaperProps={{
                    sx: {
                        width: 300, 
                        backdropFilter: 'blur(10px)',
                        background: 'rgba(250, 250, 250, 0.9)',
                        color: '#000',
                    },
                }}
            >
                <Grid container spacing={0} padding={0}>
                    <Grid item xs={12} borderBottom={'1px dashed lightgray'}>
                        <Grid container spacing={0} p={1.5}>
                            <Grid item xs={11} >
                                <Typography variant='h6' fontWeight="bold" >Settings</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={handleClose} size="small" >
                                    <CloseIcon fontSize='small' />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} p={1.5}>
                        <Typography variant='body2' mt={1} color={'gray'}>
                            <b>Theme</b>
                        </Typography>
                        <Grid container spacing={0} mt={1.5}>
                            {themes?.map((r) => {
                                return (
                                    <Grid item xs={3} key={r?.themeId} p={0.5} >
                                        <Box width="100%" height="60px" /* bgcolor={r?.backgroundColor} */
                                            sx={{
                                                backgroundImage: r?.backgroundImage ? `url(${r.backgroundImage})` : (r?.backgroundColor ? 'none' : `url(${r.backgroundImage})`),
                                                backgroundColor: r?.backgroundColor || "transparent",
                                                cursor: 'pointer',
                                                border: selectedTheme?.themeId === r.themeId ? '3px solid black' : 'none'
                                            }}
                                            borderRadius={1} onClick={() => handleSelectTheme(r)} >
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>

                    {/* Future Use */}
                    {/* <Grid item xs={12} p={1.5}>
                        <Typography variant='body2' mt={1} color={'gray'}>
                            <b>Theme by Colors</b>
                        </Typography>
                        <Grid container spacing={0} mt={1.5}>
                            {ThemeMockData?.map((r) => {
                                return (
                                    <Grid item xs={3} key={r?.themeId} m={0.5}>
                                        <Box width="100%" height="60px" bgcolor={r?.backgroundColor} sx={{ backgroundImage: r?.backgroundImage, cursor: 'pointer' }} borderRadius={1}>
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid> */}
                </Grid>
            </SwipeableDrawer>
        </React.Fragment>
    );
};

export default Settings;
