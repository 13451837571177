import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    div: {
      flexGrow: 1,
      padding: '0px'
    },
    container: {
      bordeRadius: '15px',
      padding: "5px !important"
    },
    tableCellHeader: {
      color: "white !important",
      padding: '4px 15px !important',
      fontSize: '12px',
      fontWeight: 'bold'
    },
    tableCellStyle: {
      padding: '3px 15px !important',
      '& .MuiTableCell-root': {
        padding: '3px 15px!important',
      }
    },
    tabList: {
      borderBottom: '1px solid lightgray',
      '& .MuiTabs-indicator': {
        backgroundColor: '#5e35b1 !important',
      },
    },
    tabs: {
      textTransform: "none",
      minHeight: '45px !important',
      '&.Mui-selected': {
        color: '#5e35b1 !important',
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    userContainer: {
      // maxHeight: '660px',
      // minHeight: '660px',
      maxHeight: "calc(100vh - 268px) !important",
      minHeight: "calc(100vh - 268px) !important",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "calc(100vh - 208px) !important",
        minHeight: "calc(100vh - 208px) !important",
      },
    },
    tableHead: {
      background: '#7d77c6 !important',
    },
    checkBoxStyle: {
      color: 'primary !important',
      padding: '5px !important'
    },
    buttonStyle1: {
      backgroundColor: '#d2f1df !important',
      color: 'green !important',
      borderRadius: '15px !important',
      fontSize: '12px !important',
      padding: '2px 12px !important'
    },
    buttonStyle2: {
      backgroundColor: 'rgba(255, 86, 48, 0.16) !important',
      color: 'rgb(183, 29, 24) !important',
      borderRadius: '15px !important',
      fontSize: '12px !important',
      padding: '2px 12px !important'
    },
    
    dialogTitle: {
      display: "flex !important",
      justifyContent: "center !important",
      backgroundColor: "#5e35b1 !important",
      color: "white !important",
      padding: "9px 1px 2px 2px !important"
    },   IconButton: {
      marginTop: '2px !important',
      color: ' #5e35b1 !important',
      borderRadius: "5px !important",

      border: '2px solid #5e35b1 !important',
      "&:hover": {
        backgroundColor: '#E6E6FA !important',
        color: '#5e35b1'
      }
    },
    assignUserGrid: {
      minHeight: '70px !important',
      maxHeight: '70px !important',
      overflowY: 'auto'
    },
    dialogContent: {
      padding: theme.spacing(2),
    },
    closeButton: {
      cursor: "pointer",
      position: "absolute",
      right: "14px",
      top: "10px",
      color: "white"
    },
    TreeViewContainer: {
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 130px) !important",
        minHeight: "calc( 82vh - 180px) !important",
      },
    },
    dialogStyles: {
      display: "flex !important",
      justifyContent: "center !important",
      backgroundColor: "#5e35b1 !important",
      color: "white !important",
      padding: "9px 1px 2px 2px !important"
    },
    chipDeleteIcon: {
      color: 'green !important',
    },
    selectedOption: {
      backgroundColor: '#ccfcee !important',
      color: 'green !important',
    },

    unselectedOption: {
      backgroundColor: 'transparent !important',
      color: 'black !important',
    },
    chip: {
      marginRight: '5px',
      marginBottom: '5px',
      backgroundColor: '#ccfcee !important',
      color: "green !important"
    },
    containerpaperStyle: {
      border: '2px solid #2196F3',
      padding: '10px',
      height: '200px',
      overflow: 'auto'
    },
    ButtonStyle: {
      backgroundColor: "red !important",
      color: "white !important"
    },
    AddbuttonStyle: {
      backgroundColor: "green !important",
      color: "white !important"
    },
    textField: {
      width: 240,
      alignContent: 'flex-start',
      justify: "flex-start",
      //   marginTop: '14px',
      '& .MuiFormControl-marginNormal': {
        marginTop: '10px'
      }
    },
    outlinedInput: {
      fontSize: "12px !important",
      color: "black !important",
      marginTop: '-2px !important',
      '&$focused $notchedOutline': {
        color: 'white !important',
        border: '0px !important'
      },
    },
    scrollbarChip: {
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 700px) !important",
        minHeight: "calc( 100vh - 700px) !important",
      },
    },
    autoCompleteStyle: {
      padding: '0px important',
      '& .MuiOutlinedInput-root': {
        padding: '0px !important'
      }
    },
    LabelBoxStyle: {
      margin: '6px 2px',
      marginTop: '12px'
    },
    formLabel: {
      fontWeight: 'bold',
      color: 'green',
      fontSize: 14,
    },    clientCodeBox: {
      width: '300%',
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px 4px 4px 4px',
        selectedTopic: {
          padding: '3px 30px 3px 15px'
        },
      }
    },  paper2: {
      border: '2px solid black',
      height: 'auto',
      margin: 0
    },  GreenColor: {
      fontSize: 12,
      marginLeft: '5px',
      color: 'green',
      minWidth: 350
    },
    clientCodeStyle: {
      fontSize: 12,
      marginLeft: '5px',
      minWidth: 350
    },    boxBorder: {
      padding: '2px 8px',
      borderRadius: '5px',
      width: '98%',
      marginTop: '12px',
      border: '2px solid #3bb78f',
      boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
    },
    textBoxStyle2: {
      fontSize: 10,
      // padding: '2px',
      '& .MuiChip-labelSmall': {
        fontSize: 10
      }
    },
    scrollMargin: {
      '& div:first-child': {
        marginBottom: '5px',
      },
      '& div:nth-child(3) div': {
        backgroundColor: '#2377e4 !important',
      },
      '& div:nth-child(3)': {
        top: '10px !important',
        bottom: '5px !important'
      },
    },

    valueContainer: {
      "& > :not(:last-child)": {
        marginRight: '5px'
      },
      "& > *": {
        // marginTop: '5px',
        marginBottom: '5px'
      },
      
    },    inputFontSize: {
      fontSize: '12px !important',
      padding:"0px !important"
    },
    textBoxStyle: {
      marginTop: '13px',
      fontSize: 13,
      width: '100%',
      '& .MuiChip-labelSmall': {
        paddingLeft: '5px',
        paddingRight: '5px',
        fontSize: '13px'
      },

    },
  })
);

export default useStyles