import GraphChart from './GraphChart';
import { useStyles } from "./UserDashboardCss";
import Scrollbars from "react-custom-scrollbars";
import CourseProgressChart from './CourseProgressChart';
import course from "../../../asset/image/Dashboard/course.svg";
import MLearnTooltip from "../../../GlobalStyles/ToolTip";
import progress from "../../../asset/image/Dashboard/progressbar.svg";
import certificate from "../../../asset/image/Dashboard/certificate (2).svg";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Avatar,Box, Button, Grid, IconButton,LinearProgress, Typography } from "@mui/material";
import { avatars,colorList,courseDetails,data } from '../../../model/Dashborad/Dashboard';

const UserDashboard = (props) => {
  const classes = useStyles();
  const combinedCourses = [...props?.ongoingCourses, ...props?.completedCourses];

  const row = [
    {
      icon: <img src={progress} alt="Custom Icon" style={{ width: '35px', height: '35px' }} />,
      count: props?.ongoingCourses.length,
      title: "Course InProgress",
      bgColor: "#fdf3f1",
      color: "#e57155",
    },
    {
      icon: <img src={course} alt="Custom Icon" style={{ width: '35px', height: '35px' }} />,
      count: props?.completedCourses.length,
      title: "Course Completed",
      bgColor: "#f0f9f6",
      color: "#3bb77f",
    },
    {
      icon: <img src={certificate} alt="Custom Icon" style={{ width: '35px', height: '35px' }} />,
      count: props?.completedCourses.length,
      title: "Certificate Earned",
      bgColor: "#ecf3f9",
      color: "#0d61b4",
    },]


  return (
    <Grid>
      <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.courseContainer}>
        <Grid container spacing={1} >
          <Grid item xs={12} md={8.3} ml={1}>
            <Grid container spacing={1}>
              {row.map((r, index) => (
                <Grid item xs={12} md={4} key={index} mt={1}>
                  <Box className={classes.card} style={{ background: r.bgColor }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box className={classes.iconContainer} mt={2}>
                          {r.icon}
                        </Box>
                        <Typography variant="h6" fontWeight={700}>
                          {r.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} mb={4}>
                        <Typography variant="h3" component="div">
                          {r.count}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <LinearProgress
                          sx={{
                            width: "100%",
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: r.color,
                            },
                          }}
                          variant="determinate"
                          value={(20 / 20) * 100}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6.5} mt={1} bgcolor={"white"} boxShadow={1} borderRadius={2}><CourseProgressChart CourseData={combinedCourses} /></Grid>
              <Grid item xs={12} md={5.3} mt={1} ml={1} bgcolor={"white"} boxShadow={1} borderRadius={2}><GraphChart data={data} /></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3.5} ml={1} mt={1}>
            <Grid container spacing={1} >
              <Grid item xs={12} bgcolor={"white"} boxShadow={1} borderRadius={3}>
                <Typography variant="subtitle1" align="left" fontWeight={800} gutterBottom>
                  Upcoming events
                </Typography>
                {courseDetails?.slice(0, 5).map((r, index) => (
                  <Box key={r.courseId} display={"flex"} p={1} borderRadius={2} mb={1} border={"1px dashed #E6E6FA"}>
                    <Box p={0} width={"18%"} mt={1}>
                      <IconButton size="large" sx={{ borderRadius: "5px", background: colorList[index].bgColor }}>
                        <NotificationsActiveIcon htmlColor={colorList[index].color} fontSize="large" />
                      </IconButton>
                    </Box>
                    <Box p={0} width={"62%"}>
                      <Typography variant="body2" ml={1} color={"#212b36"} align="left">
                        {r.courseTitle}
                      </Typography>
                      <Typography variant="body2" ml={1} mt={1} color={"#212b36"} align="left">
                        {`Reminder: ${r.reminder.type} by ${r.reminder.staff} on ${r.reminder.date}`}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12} bgcolor={"white"} boxShadow={1} borderRadius={3} mt={1}>
                <Box sx={{ p: 2, border: '1px solid #E0E0E0', borderRadius: 2, display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="h6" fontWeight="bold">
                      Reporting Managers
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Report your course progress
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                    {avatars.map((avatar, index) => (
                      <MLearnTooltip title={avatar.alt} placement="top">
                        <Avatar key={index} src={avatar.src} alt={avatar.alt} sx={{ ml: index === 0 ? 0 : -1 }} />
                      </MLearnTooltip>
                    ))}
                  </Box>
                  <Box sx={{ ml: 3 }}>
                    <Button variant="contained" color="primary">
                      Report
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Scrollbars>
    </Grid>
  );
}
export default UserDashboard;


