import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const FeedbackGraph = ({ courseTitles,ratings }) => {
  const series = [
    {
      name: 'Course Name',
      type: 'column',
      data: ratings  
    }
  ];

  const [options] = useState<ApexOptions>({
    chart: {
      height: 350,
      type: 'area'
    },
    xaxis: {
        categories: courseTitles,
        title: {
          text: 'Course Name'
        },
        labels: {
          show: false
        }
      },
    yaxis: [
      {
        title: {
          text: 'Ratings'
        }
      }
    ],
    colors: ['#0ab5ff']
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={178} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default FeedbackGraph;
