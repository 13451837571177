/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
//react imports
import React, { useState } from "react";
//mui components
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
//Style
import useStyles from "../ManageCourse/CourseCss";
//Components
import AddLab from "./AddLab";
import RichTextEditor from './RichTextEditor';

const LabManagement = (props) => {
    const classes = useStyles();
    const [openTopicDialog, setOpenTopicDialog] = useState(false);
    const [texteditorvalue,setTextEditorValue]=useState('');
    const handleOpenDialog = () => {
        setOpenTopicDialog(true);
    };
    const handleChanges = (e) => {
        setTextEditorValue(e);
        console.log(e);
      }
    const handleClose = () => {
        setOpenTopicDialog(false);
    };
    return (
        <React.Fragment>
            <Grid>
                {!props.value ? (
                    <IconButton
                        aria-label="Add course"
                        color="primary"
                        onClick={handleOpenDialog}
                        //disabled={!state.userAccessContext.isManageCourse}
                        style={{ backgroundColor: "#5e35b1", color: "white" }}
                    >
                        <AddCircleIcon />
                    </IconButton>
                ) : (<ModeTwoToneIcon fontSize="small" color="primary" onClick={handleOpenDialog} />)}
                <Dialog
                    maxWidth={'lg'}
                    open={openTopicDialog}
                    onClose={handleClose}
                    fullWidth
                    classes={{ paper: classes.deleteDialogue }}
                    PaperProps={{ style: { borderRadius: 15 } }}
                    className={classes.dialogBackground}
                >
                    <DialogTitle id="alert-dialog-title" className={classes.dialogStyles}>
                        <b>{true ? 'Add Lab' : "Edit Topic"}</b>
                        <CloseIcon onClick={handleClose} className={classes.closeButton} />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={0} padding={0} mt={0}>
                            <Grid item xs={12} sm={12} md={2} lg={7} borderRight={"1px solid lightgray"}>
                                <AddLab />
                            </Grid>
                            <Grid item xs={12} sm={12} md={10} lg={5} p={1}>
                                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} borderBottom="1px dotted lightgray" p={1}>
                                    <Typography variant="body2"><b> Steps and Descriptions</b></Typography> <Button size="small" variant="contained">Add</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <RichTextEditor onChange={handleChanges} value={texteditorvalue} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={classes.dialogActionPadding}>
                        <Button color="success" variant="contained" size="small" className={classes.AddbuttonStyle} startIcon={<CheckCircleIcon />}>
                            <b>Save</b>
                        </Button>
                        <Button color="error" variant="contained" size="small" className={classes.ButtonStyle} startIcon={<CancelIcon />}>
                            <b>Cancel</b>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </React.Fragment>
    );
};

export default LabManagement;
