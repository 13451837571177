
import SchoolIcon from '@mui/icons-material/School';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import StarsIcon from '@mui/icons-material/Stars';
import AssessmentIcon from '@mui/icons-material/Assessment';
import VerifiedIcon from '@mui/icons-material/Verified';
import PersonIcon from '@mui/icons-material/Person';
export const steps = [
    {
        label: 'Personal',
        icon: <PersonIcon />,
    },
    {
        label: 'Education',
        icon: <SchoolIcon />,
    },
    {
        label: 'Skills',
        icon: <AutoFixHighIcon />,
    },
    {
        label: 'Experience',
        icon: <StarsIcon />,
    },
    {
        label: 'Project',
        icon: <AssessmentIcon />,
    },
    {
        label: 'Preview CV',
        icon: <VerifiedIcon />,
    },
];

