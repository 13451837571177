/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarRateIcon from '@mui/icons-material/StarRate';
import { Backdrop, Box, Grid, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { SyncLoader } from "react-spinners";
import addTopicImg from '../../../asset/Topic/addTopic.svg';
import { AddTopicProp, ITreeItem } from "../../../model/course";
import useStyles from "../CourseCss";

const EditTopicAssignment: React.FC<AddTopicProp> = (props) => {
    const { handleSelectedTopicData, topicData, subTopicData } = props;
    const classes = useStyles();
    const [data, setData] = useState<ITreeItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [defaultSelectedTopic, setDefaultSelectedTopic] = useState<number | null>(null);

    const formulate = () => {
        const newData: ITreeItem[] = topicData?.map((menu) => {
            const matchingSubmenus = subTopicData?.filter((submenu) => submenu?.courseTopicId === menu?.courseTopicId);
            return {
                courseTopicId: menu.courseTopicId,
                courseTopicName: menu.courseTopicName,
                isAssignmentRequired: menu?.isAssignmentRequired,
                nestedItems: matchingSubmenus.map((submenu) => ({
                    courseSubTopicId: submenu.courseSubTopicId,
                    courseSubTopicName: submenu.courseSubTopicName,
                    stipulatedTime: submenu?.stipulatedTime,
                    isAssignmentRequired: submenu?.isAssignmentRequired,
                })),
                isExpanded: !!matchingSubmenus.length,
                isActive: false,
            };
        });

        setData(newData);
        setLoading(false);

        if (newData && newData.length > 0) {
            setDefaultSelectedTopic(newData[0].courseTopicId);
            handleSelectedTopicData(newData[0].courseTopicName, newData[0].courseTopicId, 0, newData[0].isAssignmentRequired); // Pass the default selected topic to display its questions
        }
    };
    useEffect(() => {
        formulate();
    }, [topicData, subTopicData]);

    const handleToggleExpand = (itemId: number) => {
        const updatedData = data.map((node) =>
            node?.courseTopicId === itemId
                ? { ...node, isExpanded: !node.isExpanded }
                : { ...node, isExpanded: false } // Close other topics
        );

        setData(updatedData);
    };

    const Id = 0;
    const handleSelectedTopic = (topicName: string, ParentId: number, childId: number, isAssignmentRequired: boolean) => {
        const updatedData: ITreeItem[] = data?.map((node) => {
            if (node?.courseTopicId === ParentId) {
                return {
                    ...node,
                    isActive: true,
                    nestedItems: node.nestedItems.map((nestedItem) => ({
                        ...nestedItem,
                        isSubMenuActive: nestedItem.courseSubTopicId === childId
                    }))
                };
            } else {
                return {
                    ...node,
                    isActive: false,
                    nestedItems: node.nestedItems.map((nestedItem) => ({
                        ...nestedItem,
                        isSubMenuActive: false
                    }))
                };
            }
        });
        setData(updatedData);
        handleSelectedTopicData(topicName, ParentId, childId, isAssignmentRequired);
    };
    return (
        <React.Fragment>
            <Grid container spacing={0} p={0}>
                <Grid item xs={12}>
                    {loading ? (
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                            <SyncLoader size={10} color={"#5e35b1"} loading={loading} />
                        </Backdrop>
                    ) : (
                        <List sx={{ padding: 1 }}>
                            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.topicContainer}>
                                {data.length === 0 && (
                                    <Grid item xs={12} display={'flex'} justifyContent={'center'} mt={20}>
                                        <img src={addTopicImg} alt="add a topic" width={'100px'} />
                                    </Grid>
                                )}
                                {data?.map((node) => (
                                    <React.Fragment key={node?.courseTopicId}>
                                        <ListItem className={node.isActive ? classes.listButtonActive : classes.listButtonInActive}>
                                            <Grid item xs={1} className={classes.listItemIcon} onClick={() => handleToggleExpand(node?.courseTopicId)} >
                                                {node?.nestedItems.length > 0 ? (
                                                    node?.isExpanded ? (
                                                        <ExpandLessIcon fontSize="small" />
                                                    ) : (
                                                        <ExpandMoreIcon fontSize="small" />
                                                    )
                                                ) : null}
                                            </Grid>
                                            <Grid container display={'flex'} alignItems={'center'} sx={{ padding: '3px 0px 3px 0px' }}
                                                onClick={() => handleSelectedTopic(node.courseTopicName, node?.courseTopicId, Id, node?.isAssignmentRequired)}>
                                                <Grid item xs={8}>
                                                    <Typography variant='body2' ml={1} className={classes.elipises} sx={{ color: node?.isActive ? '#5e35b1' : null, fontWeight: node?.isSubMenuActive ? 600 : 400 }} >
                                                        {node?.courseTopicName}
                                                        {node?.isAssignmentRequired ?<StarRateIcon fontSize="small" className={classes.iconColor} />: null}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <Collapse in={node.isExpanded} timeout="auto" unmountOnExit>
                                            <List disablePadding sx={{ padding: '0px 0px 0px 20px' }}>
                                                {node.nestedItems.map((nestedItem) => (
                                                    <Grid container spacing={0} ml={0} mr={"14px"} key={nestedItem.courseSubTopicId} onClick={() => handleSelectedTopic(nestedItem.courseSubTopicName, node.courseTopicId, nestedItem.courseSubTopicId, nestedItem?.isAssignmentRequired)} className={classes.selectedTopic} sx={{ borderLeft: nestedItem?.isSubMenuActive ? '3px solid #5e35b1' : '1px solid lightgray' }} >
                                                        <Grid item xs={1} >
                                                            <Box width={nestedItem?.isSubMenuActive ? '0.4rem' : '0.3rem'} ml={nestedItem?.isSubMenuActive ? 1 : 1} mt={1}
                                                                height={nestedItem?.isSubMenuActive ? '0.4rem' : '0.3rem'}
                                                                bgcolor={nestedItem?.isSubMenuActive ? '#5e35b1' : 'lightgrey'} borderRadius={5} >
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={9} >
                                                            <Typography className={classes.elipises} sx={{ color: nestedItem?.isSubMenuActive ? '#5e35b1' : null, fontWeight: nestedItem?.isSubMenuActive ? 600 : 400 }}>
                                                                {nestedItem?.courseSubTopicName}
                                                                {nestedItem?.isAssignmentRequired ? <StarRateIcon fontSize="small" className={classes.iconColor} />: null}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </React.Fragment>
                                ))}
                            </Scrollbars>
                        </List>
                    )}
                </Grid>
            </Grid >
        </React.Fragment>
    );
};

export default EditTopicAssignment;