import { Theme, alpha, createStyles, makeStyles } from "@material-ui/core";

const drawerWidth = "240px";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: 'none',
            paddingLeft: "0px !important",
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha('#919EAB', 0.30),
            '&:hover': {
                backgroundColor: alpha('#919EAB', 0.35),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            color: 'black',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '20ch',
                '&:focus': {
                    width: '50ch',
                },
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        appBar: {
            // backgroundColor: '#ffffff !important', Future use
            boxShadow: 'none !important',
            borderBottom: '1px solid lightgray',
            '& .MuiPaper-elevation4': {
                boxShadow: 'none !important'
            },
            minHeight: '50px !important'
        },
        toolBar: {
            // backgroundColor: '#ffffff !important', Future use
            paddingLeft: "10px !important",
            paddingRight: "0px !important",
            minHeight: '50px !important',
            '& .MuiToolbar-regular': {
                minHeight: '50px'
            }
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },

        /* Mobile Navigation Css */
        closeIcon: {
            backgroundColor: '#f1f5f8 !important',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        },
        toolbar: theme.mixins.toolbar,
        listMenu: {
            padding: '8px',
            '&:hover': {
            },
            '&:hover a': {
                color: '#fff',
            }
        },
        mobileSidebarContainer: {
            maxHeight: '660px',
            minHeight: '660px',
            [theme.breakpoints.down("sm")]: {
                maxHeight: "calc( 100vh - 98px)",
                minHeight: "calc( 100vh - 98px)",
            },
        },
        mobildeListMui: {
            paddingTop: "10px !important",
            // paddingLeft: "5px !important",
            width: drawerWidth,
            borderRight: '1px solid lightgray',

            // background: '#f1f5f8',
            // marginTop: '68px !important',
            padding: "5px 5px !important",
            // backgroundColor:'blue'
        },
        // Sidebar 
        scrollbarContainer: {
            maxHeight: '660px',
            minHeight: '660px',
            [theme.breakpoints.up("lg")]: {
                maxHeight: "calc( 100vh - 66px)",
                minHeight: "calc( 100vh - 66px)",
            },
        },
        listMui: {
            paddingTop: "10px !important",
            // paddingLeft: "5px !important",
            // width: drawerWidth,
            borderRight: '1px solid lightgray',

            // background: '#f1f5f8',
            // marginTop: '68px !important',
            padding: "5px 5px !important",
            // backgroundColor:'blue'
        },
        listItemMui: {
            padding: "5px 5px !important",
            borderRadius: 5,
            transition: 'transform 300ms ease-in'
        },
        listItemActive: {
            // justifyContent: 'center',
            // flexDirection: 'column',
            padding: "5px !important",
            // borderLeft: "3px solid #283593 !important",
            backgroundColor: "#E6E6FA !important",
            borderRadius: "5px !important"
        },
        listItemInactive: {
            padding: "5px !important",
            '&:hover': {
                backgroundColor: "#E6E6FA !important",
                borderRadius: "5px !important",
            }
        },
        listItemIconActive: {
            fontSize: 25,
            color: '#5e35b1 !important',
            minWidth: "30px !important"
        },
        listItemIconInActive: {
            fontSize: 25,
            color: 'rgb(95, 116, 141) !important',
            minWidth: "30px !important"
        },
        listItemTextActive: {
            fontSize: "13px !important",
            color: '#5e35b1 !important',
            fontWeight: 'bold'
        },
        listItemTextInActive: {
            fontSize: "13px !important",
            color: 'rgb(95, 116, 141)',
            fontWeight: 'bold'
        },
        contentContainer: {
            flexGrow: 1,
            maxWidth: '100% !important',
            margin: '0 auto',
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
            // backgroundColor: '#ffffff !important',
            // backgroundColor: '#f8f8f8 !important',
            backgroundColor: 'rgb(238, 242, 246) !important',
            // marginTop: '68px !important'
        },
        iconButton: {
            color: "rgb(94, 53, 177) !important",
            backgroundColor: "rgb(237, 231, 246) !important",
            marginLeft: "0px !important",
            marginRight: 3,
        },
        logoIcon: {
            '&:hover': {
                backgroundColor: "white !important",
                borderRadius: "0px !important",
            }
        },
    }),
);


export default useStyles;