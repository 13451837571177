import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import PageviewIcon from "@mui/icons-material/Pageview";
import {  Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton,TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./TopicContentCss";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { UrlViewProps } from "../../../../model/course";
import LinkIcon from '@mui/icons-material/Link';

const UrlLinkView:React.FC<UrlViewProps>=(props)=> {
  const { onUrlChange, value }=props;
  const classes = useStyles();
  const [url, setUrl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [urlList, setUrlList] = useState<string[]>([]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onUrlChange(urlList?.join(','));
    setOpen(false);
    setUrl(null);
  };

  useEffect(() => {
    setUrlList(value?.split(',')?.map((url) => url?.trim()));
  }, [value]);

  const handleViewClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const handleUrlInputChange = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setUrl((prevVideoId) => prevVideoId + "\n");
    } else {
      const newUrlId = (e.target.value);
      setUrl(newUrlId);
    }

  };
  const handleAddUrl = () => {
    setUrlList((prevList) => {
      const currentList = Array.isArray(prevList) ? prevList : [];

      if (url?.trim() !== "") {
        const updatedList = [...currentList, url?.trim()];
        return updatedList;
      }
      return currentList;
    });
 setUrl("");
  };

  const handleCancelClick = (index: number) => {
    setUrlList((prevList) => {
      const newList = [...prevList];
      newList?.splice(index, 1);
      return newList;
    });
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        size="small"
        onClick={handleClickOpen}
        startIcon={<LinkIcon />}
        className={classes.button}
      >
        Add Url Link
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ className: classes.dialog1 }}
        className={classes.dialogBackground}
      >
        <DialogTitle id="alert-dialog-title" className={classes.textAlign}>
          <b>{"Add Website Url"}</b>
          <CloseIcon onClick={handleClose} className={classes.closeButton} />
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.display}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                placeholder="URL Link"
                value={url}
                onChange={handleUrlInputChange}
                onBlur={handleAddUrl}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <IconButton onClick={handleViewClick}>
                        <PageviewIcon
                          fontSize="small"
                          color="primary"
                          className={classes.iconColor}
                        />
                      </IconButton>
                      <IconButton onClick={handleAddUrl} disabled={!url?.length}>
                        <AddIcon fontSize="small" color="primary" />
                      </IconButton>
                    </React.Fragment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.display}>
              {urlList?.length > 0 && (
                <Container>
                <Stack direction="row" spacing={2} sx={{ padding: "5px", flexWrap: 'wrap' }}>
                  {urlList.map((enteredVideoUrl, index) => (
                    <Chip
                      key={index}
                      size="small"
                      label={enteredVideoUrl}
                      onClick={() => setUrl(enteredVideoUrl)}
                      onDelete={() => handleCancelClick(index)}
                      color="primary"
                      variant="outlined"
                      sx={{ flexShrink: 0, maxWidth: '30%', }}
                    />
                  ))}
                </Stack>
              </Container>
              
              )}
            </Grid>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              maxWidth={"lg"}
              PaperProps={{ className: classes.dialog }}
              className={classes.dialogBackground}
            >
              <DialogTitle>
                {"Content"}
                <CloseIcon
                  onClick={handleCloseDialog}
                  className={classes.closeIcon}
                />
              </DialogTitle>
              <DialogContent>
                <iframe title="URL Viewer" src={url} className={classes.linkView} />
              </DialogContent>
            </Dialog>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<SaveIcon />}
            className={classes.save}
          >
            <b>Save</b>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default UrlLinkView;
