/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Card, Rating } from '@mui/material';
import { Avatar, Box, CardContent, Grid, Typography } from '@mui/material';
import FeedbackChart from './FeedbackChart';
import profileIcon from '../images/ProfileCard/avatar.svg'; 
import { PieChart } from '@mui/x-charts/PieChart';
import { PieItemIdentifier, DefaultizedPieValueType } from '@mui/x-charts/models';
import { Stack } from '@mui/material';
import useStyles from '../components/NavbarSection/Header/NotificationCss';
import { IFeedback } from '../model/ICourse';
import { usePost } from '../utils/apiHelper';
import FeedbackGraph from './FeedbackGraph';
import Scrollbars from "react-custom-scrollbars";

const FeedbackDetails = () => {
    const [feedback, setFeedback] = useState<IFeedback[]>([]);
    const [courseFeedbackData, setCourseFeedbackData] = useState<{ coursetitle: string; rating: number }[]>([]);
    const [topCourses, setTopCourses] = useState<IFeedback[]>([]);
    const [selectedCourse, setSelectedCourse] = useState<string>();
    const classes = useStyles();

    useEffect(() => {
        GetFeedbackDetails();
    }, []);

    useEffect(() => {
        if (feedback.length > 0) {
            const extractedData = feedback.map(item => ({
                coursetitle: item.courseTitle,
                rating: item.rating
            }));
            setCourseFeedbackData(extractedData);
        }
        if (feedback.length > 0) {
            const sortedData = feedback.sort((a, b) => b.rating - a.rating);
            const topCourses = sortedData.slice(0, 3);
            setTopCourses(topCourses);
            setSelectedCourse(topCourses[0]?.courseTitle);
        }
    }, [feedback]);

    const GetFeedbackDetails = () => {
        (async () => {
            let request = {
                feedbackId: null
            };
            await usePost<IFeedback[]>("Course/GetFeedback", request).then((response) => {
                setFeedback(response.data);
            }).finally(() => { });
        })();
    };
    const calculateRatings = () => {
        const ratingsArray = [0, 0, 0, 0, 0];
        feedback.forEach((item) => {
            ratingsArray[item.rating - 1]++;
        });
        return ratingsArray;
    };

    const handleClick = (
        event: React.MouseEvent<SVGPathElement, MouseEvent>,
        itemIdentifier: PieItemIdentifier,
        item: DefaultizedPieValueType,
    ) => {
        const clickedCourse = topCourses.find(course => course.courseTitle === item.label);
        if (clickedCourse) {
            setSelectedCourse(clickedCourse.courseTitle);
        }
    };


    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                    <Typography variant='subtitle1' align='left' fontWeight={800} mb={1}>
                        User Feedback
                    </Typography>
                    <Box pb={2} borderRadius={5} bgcolor={'white'} boxShadow={1} justifyContent={"center"} alignItems={"center"}>
                        <FeedbackChart feedbackData={{ ratings: calculateRatings() }} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} >
                    <Typography variant='subtitle1' align='left' fontWeight={800} mb={1}>
                        Feedback Graph
                    </Typography>
                    <Box borderRadius={5} bgcolor={'white'} boxShadow={1} >
                        {courseFeedbackData.length > 0 && (
                            <FeedbackGraph courseTitles={courseFeedbackData.map(item => item.coursetitle)} ratings={courseFeedbackData.map(item => item.rating)} />
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant='subtitle1' align='left' fontWeight={800} mb={1}>
                        Top Course Analysis
                    </Typography>
                    <Box borderRadius={5} bgcolor={'white'} boxShadow={1} >
                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            alignItems={{ xs: 'flex-start', md: 'center' }}
                            justifyContent="space-between"
                            sx={{ width: '100%' }}
                        >
                            <PieChart
                                series={[
                                    {
                                        data: topCourses.map((item, index) => ({
                                            id: `id_${index}`,
                                            value: item.rating,
                                            label: item.courseTitle,
                                        })),
                                    },
                                ]}
                                onClick={handleClick}
                                width={400}
                                height={188}
                                margin={{ right: 280 }}
                            />
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} >
                    <Typography variant='subtitle1' align='left' fontWeight={800}>
                        Reviews and Ratings
                    </Typography>
                    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.UserFeedback}>
                    {feedback && feedback.map((feedbackItem, index) => (
                        <Card
                            className={classes.admincard}
                        >
                            <CardContent>
                                <Grid spacing={0} container key={index} >
                                    <Grid item >
                                        <Avatar
                                            alt="user profile"
                                            src={profileIcon}
                                            sx={{ width: 34, height: 34 }}
                                        />
                                    </Grid>
                                    <Grid item ml={1} xs={9}>
                                        <Typography variant="body2" fontWeight={800}>{feedbackItem.firstName}</Typography>
                                        <Typography variant="body2" fontSize={'12px'} color={'gray'}>
                                            {new Date(feedbackItem.createdDate).toLocaleDateString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign={'right'}>
                                        <Rating name="read-only" value={feedbackItem.rating} readOnly />
                                    </Grid>
                                </Grid>
                                <Grid mt={1}>
                                    <Typography variant='body1' align='left'>
                                        {feedbackItem.message}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
                    </Scrollbars> 
                </Grid>
            </Grid >
        </div >
    )
}

export default FeedbackDetails;
