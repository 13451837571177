import { Button, Checkbox, Grid, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react'
import Scrollbars from 'react-custom-scrollbars';
import useStyles from '../ProfileCardCss';
import CourseCard from './CourseCard';
import { Profilejob } from '../../../../_mock/ProfileJob';
import FilterListIcon from '@mui/icons-material/FilterList';
import JobDetails from './JobDetails';

const Jobs = () => {
    const classes = useStyles();
    const [jobFilter, setJobFilter] = useState<boolean>(false);
    const [selectedJob, setSelectedJob] = useState();
    const [jobSearch, setJobSearch] = useState<string>('');
    const [experience, setExperience] = useState<string>('');
    const [location, setLocation] = useState<string>('');

    const handleJobSearchChange = (event) => {
        setJobSearch(event.target.value);
    };

    const handleExperienceChange = (event) => {
        setExperience(event.target.value as string);
    };

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };

    const handleJobFilterChange = (event) => {
        setJobFilter(event.target.checked);
    };

    const handleViewDetails = (job: any) => {
        setSelectedJob(job); // Set the selected job
    };

    const handleCloseDetails = () => {
        setSelectedJob(null);
    };

    return (
        <React.Fragment>
            {selectedJob ? ( // Render job details if a job is selected
                <JobDetails job={selectedJob} onClose={handleCloseDetails} />
            ) : (
                <React.Fragment>
                    <Grid container spacing={1} display={"flex"} justifyContent={"center"} textAlign={"center"}>
                        <Grid item xs={12} pt={6}>
                            <Typography variant='h4' color="#5e35b1">
                                <b> Find Your Dream Job Now</b>
                            </Typography>
                            <br />
                            <Typography variant='h5'>
                                <b> Start Your Career</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                id="outlined-basic"
                                placeholder='Search for Job...'
                                fullWidth
                                size='small'
                                value={jobSearch}
                                className={classes.ovalTextField}
                                onChange={handleJobSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize='small' sx={{ marginLeft: "5px" }} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <>
                                            <TextField
                                                select
                                                variant="standard"
                                                size="small"
                                                className={classes.noBottomBorder}
                                                value={experience}
                                                onChange={handleExperienceChange}
                                                sx={{ borderLeft: "1px solid lightgrey" }}
                                                fullWidth
                                            >
                                                <MenuItem value="">Experience</MenuItem>
                                                <MenuItem value="0-1 years">0-1 years</MenuItem>
                                                <MenuItem value="1-3 years">1-3 years</MenuItem>
                                                <MenuItem value="3-5 years">3-5 years</MenuItem>
                                                <MenuItem value="5+ years">5+ years</MenuItem>
                                            </TextField>
                                            <TextField
                                                id="outlined-basic-location"
                                                variant='standard'
                                                placeholder='Location'
                                                size="small"
                                                value={location}
                                                className={classes.noBottomBorder}
                                                sx={{ borderLeft: "1px solid lightgrey",paddingLeft:1}}
                                                onChange={handleLocationChange}
                                                fullWidth

                                            />
                                            <Button variant='contained' sx={{ height: "35px", width: "300px", marginLeft: "20px", borderRadius: "15px" }}>
                                                Search
                                            </Button>
                                        </>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} bgcolor={"white"} p={2} display={"flex"} justifyContent={"center"} flexDirection={"row"}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.jobScrollbar}>
                                        <Grid container spacing={0} borderRight={"1px solid lightgrey"}>
                                            <Grid item xs={10}>
                                                <Typography variant="h6" gutterBottom color="#5e35b1">
                                                    Filters
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <IconButton>
                                                    <FilterListIcon fontSize='medium' />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" gutterBottom color="#5e35b1">
                                                    <b> Employee Type</b>
                                                </Typography>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={jobFilter}
                                                            onChange={handleJobFilterChange}
                                                            color="primary"
                                                            size="small"
                                                            inputProps={{ "aria-label": "job filter checkbox" }}
                                                        />
                                                        Full Time
                                                    </Grid>
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={jobFilter}
                                                            onChange={handleJobFilterChange}
                                                            color="primary"
                                                            size="small"
                                                            inputProps={{ "aria-label": "job filter checkbox" }}
                                                        />
                                                        Part Time
                                                    </Grid>
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={jobFilter}
                                                            onChange={handleJobFilterChange}
                                                            color="primary"
                                                            size="small"
                                                            inputProps={{ "aria-label": "job filter checkbox" }}
                                                        />
                                                        Full Time Remote
                                                    </Grid>
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={jobFilter}
                                                            onChange={handleJobFilterChange}
                                                            color="primary"
                                                            size="small"
                                                            inputProps={{ "aria-label": "job filter checkbox" }}
                                                        />
                                                        Part Time Remote
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" gutterBottom color="#5e35b1">
                                                    <b> Discipline</b>
                                                </Typography>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={jobFilter}
                                                            onChange={handleJobFilterChange}
                                                            color="primary"
                                                            size="small"
                                                            inputProps={{ "aria-label": "job filter checkbox" }}
                                                        />
                                                        Front End
                                                    </Grid>
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={jobFilter}
                                                            onChange={handleJobFilterChange}
                                                            color="primary"
                                                            size="small"
                                                            inputProps={{ "aria-label": "job filter checkbox" }}
                                                        />
                                                        Back End
                                                    </Grid>
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={jobFilter}
                                                            onChange={handleJobFilterChange}
                                                            color="primary"
                                                            size="small"
                                                            inputProps={{ "aria-label": "job filter checkbox" }}
                                                        />
                                                        Full Stack
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" gutterBottom color="#5e35b1">
                                                    <b> Job Location</b>
                                                </Typography>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={jobFilter}
                                                            onChange={handleJobFilterChange}
                                                            color="primary"
                                                            size="small"
                                                            inputProps={{ "aria-label": "job filter checkbox" }}
                                                        />
                                                        India
                                                    </Grid>
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={jobFilter}
                                                            onChange={handleJobFilterChange}
                                                            color="primary"
                                                            size="small"
                                                            inputProps={{ "aria-label": "job filter checkbox" }}
                                                        />
                                                        UK
                                                    </Grid>
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={jobFilter}
                                                            onChange={handleJobFilterChange}
                                                            color="primary"
                                                            size="small"
                                                            inputProps={{ "aria-label": "job filter checkbox" }}
                                                        />
                                                        Dubai
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Scrollbars>
                                </Grid>
                                <Grid item xs={10} style={{ display: "flex", flexDirection: "row" }}>
                                    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.jobScrollbar}>
                                        <Grid container spacing={2}>
                                            {Profilejob.map((job, index) => (
                                                <CourseCard key={index} title={job.title} description={job.description} onViewDetails={() => handleViewDetails(job)} />
                                            ))}
                                        </Grid>
                                    </Scrollbars>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment >
    )
}

export default Jobs;