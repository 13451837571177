/* eslint-disable react-hooks/exhaustive-deps */
import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ITopManager } from '../../model/IDashboardCount';

interface LineChartProps {
  topManager: ITopManager[];
}

const ChartComponent: React.FC<LineChartProps> = ({ topManager }) => {
  const [month, setMonth] = useState<any[]>([]);
  const type = ["column", "area", "line"]
  const managerData = {};
  useEffect(() => {
    if (Array.isArray(topManager)) {
      setMonth(topManager.map(course => course.monthName));

      topManager.forEach(item => {
        // Add the average completion percentage to the array corresponding to the manager ID
        if (!managerData[item.managerId]) { // Corrected from topManager to managerData
          managerData[item.managerId] = [];
        }
        managerData[item.managerId].push(item.averageCompletionPercentage);
      });
    }
  }, [topManager]);


  const managerDetails = topManager.map(item => ({
    managerId: item.managerId,
    managerName: item.managerName
  }));

  // Removing duplicates
  const uniqueManagerDetails = Array.from(new Map(managerDetails.map(item => [item.managerId, item])).values());
  topManager.forEach(item => {
    if (!managerData[item.managerId]) {
      managerData[item.managerId] = [];
    }
    managerData[item.managerId].push(item.averageCompletionPercentage);
  });

  const seriesData = uniqueManagerDetails.map((detail, index) => ({
    name: detail.managerName,
    type: type[index], // Ensure 'type' array has appropriate values for each series
    data: managerData[detail.managerId]
  }));

  const options: ApexOptions = {
    series: seriesData,
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
    },
    stroke: {
      width: [0, 2, 5],
      curve: 'smooth',
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
    },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: month,
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      title: {
        text: 'Points',
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== 'undefined') {
            return y.toFixed(0) + ' points';
          }
          return y;
        },
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={options.series} type="line" height={350} />
    </div>
  );
};

export default ChartComponent;
