import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButtons: {
            marginTop: '3px !important',
            color: '#5e35b1 !important',
            backgroundColor: "#E6E6FA !important",
            '&:hover': { color: 'white !important', backgroundColor: "#5e35b1 !important" },
            borderRadius: '10px'
        },
    }),
);

export default useStyles;

