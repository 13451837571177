/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import AutoStoriesTwoToneIcon from '@mui/icons-material/AutoStoriesTwoTone';
import HowToRegTwoToneIcon from '@mui/icons-material/HowToRegTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import { Backdrop, Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { SyncLoader } from "react-spinners";
import { CourseDetails, ICourseStatus, IDashboardCount, ITopManager, IWeekCourseProgress } from "../../model/IDashboardCount";
import { useFetch } from "../../utils/apiHelper";
import CardDesign from "./CardChart";
import CourseProgress from "./CourseProgressChart";
import { useStyles } from "./DashboardCss";
import LineChart from "./LineChart";

const RoundedProgressBar = ({ percentage, color, backgroundColor }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.gridPosition}>
      <CircularProgress
        variant="determinate"
        value={percentage}
        size={40}
        thickness={5}
        style={{ position: "absolute", top: 0, left: 0, color: backgroundColor }}
      />
      <Typography
        variant="caption"
        component="div"
        color={color}
        fontSize={10}
        className={classes.typography}
      >
        {`${Math.round(percentage)}`}
      </Typography>
    </Grid>
  );
};

const RoundedProgressBar1 = ({ percentage }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.gridPosition}>
      <CircularProgress
        variant="determinate"
        value={percentage}
        size={100}
        thickness={5}
        color="warning"
        style={{ position: "absolute", top: 0, marginRight: '5px', color: "#000f82" }}
      />
      <b>
        <Typography
          variant="h4"
          component="div"
          className={classes.typography1}
        >
          {`${Math.round(percentage)}`}
        </Typography>
      </b>
    </Grid>
  );
};

const Dashboard = () => {
  const classes = useStyles();
  const [dashboardCount, setDashboardCount] = useState<IDashboardCount>();
  const [courseDetails, setCourseDetails] = useState<CourseDetails[]>([]);
  const [courseStatus, setCourseStatus] = useState<ICourseStatus>();
  const [weeklyProgress, setWeeklyProgress] = useState<IWeekCourseProgress[]>();
  const [topManager, setTopManager] = useState<ITopManager[]>([]);
  const [Data, setFormData] = useState();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    (async () => {
      setloading(true);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      await useFetch<{
        dashboardResult: IDashboardCount;
        courseDetails: CourseDetails[];
        courseStatus: ICourseStatus;
        weeklyCourseProgresses: IWeekCourseProgress[];
        topManagerLists: ITopManager[];
      }>("Dashboard/GetDashboardCount").then((r) => {
        setDashboardCount(r?.data?.dashboardResult);
        setCourseDetails(r?.data?.courseDetails);
        setCourseStatus(r?.data?.courseStatus);
        setWeeklyProgress(r?.data?.weeklyCourseProgresses);
        setTopManager(r?.data?.topManagerLists);
      });
    })().finally(() => {
      setloading(false);
      console.error();
    });
  }, []);


  const row = [
    {
      icon: <HowToRegTwoToneIcon style={{ fontSize: "70", color: 'rgb(0, 75, 80)' }} />,
      count: dashboardCount?.subscribedUsers === undefined ? 0 : dashboardCount?.subscribedUsers,
      title: "Subscribed User",
      bgColor: "linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255)",
      color: "rgb(0, 75, 80)",
    },
    {
      icon: <ManageAccountsTwoToneIcon style={{ fontSize: "70", color: 'rgb(0, 55, 104)' }} />,
      count: dashboardCount?.managerUser === undefined ? 0 : dashboardCount?.managerUser,
      title: "Manager User",
      bgColor: "linear-gradient(135deg, rgba(97, 243, 243, 0.2), rgba(0, 184, 217, 0.2)) rgb(255, 255, 255)",
      color: "rgb(0, 55, 104)",
    },
    {
      icon: <AutoStoriesTwoToneIcon style={{ fontSize: "70", color: '#03a9f4' }} />,
      count: dashboardCount?.activeCourse === undefined ? 0 : dashboardCount?.activeCourse,
      title: "Active Courses",
      bgColor: "linear-gradient(135deg, rgba(255, 214, 102, 0.2), rgba(255, 171, 0, 0.2)) rgb(255, 255, 255)",
      color: "#03a9f4",
    },
    {
      icon: <PersonOutlineTwoToneIcon style={{ fontSize: "70", color: 'rgb(94, 53, 177)' }} />,
      count: dashboardCount?.students === undefined ? 0 : dashboardCount?.students,
      title: "Learners",
      bgColor: "linear-gradient(135deg, rgba(255, 214, 102, 0.2), rgba(255, 171, 0, 0.2)) rgb(255, 255, 255)",
      color: "rgb(94, 53, 177)",
    },
    {
      icon: <AutoStoriesTwoToneIcon style={{ fontSize: "70", color: 'rgb(122, 9, 22)' }} />,
      count: dashboardCount?.totalCourse === undefined ? 0 : dashboardCount?.totalCourse,
      title: "Total Courses",
      bgColor: "linear-gradient(135deg, rgba(255, 214, 102, 0.2), rgba(255, 171, 0, 0.2)) rgb(255, 255, 255)",
      color: "rgb(122, 9, 22)",
    },
  ];
  const colorList = [
    { color: "rgb(0, 75, 80)", bgColor: 'linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255)' },
    { color: "rgb(0, 55, 104)", bgColor: 'linear-gradient(135deg, rgba(97, 243, 243, 0.2), rgba(0, 184, 217, 0.2)) rgb(255, 255, 255)' },
    { color: "rgb(122, 65, 0)", bgColor: 'linear-gradient(135deg, rgba(255, 214, 102, 0.2), rgba(255, 171, 0, 0.2)) rgb(255, 255, 255)' },
    { color: "rgb(94, 53, 177)", bgColor: 'linear-gradient(135deg, rgb(192, 180, 223, 0.6), rgb(192, 180, 223, 0.6)) rgb(255, 255, 255)' },
    { color: "rgb(122, 9, 22)", bgColor: 'linear-gradient(135deg, rgba(255, 172, 130, 0.2), rgba(255, 86, 48, 0.2)) rgb(255, 255, 255)' },
  ];
  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <SyncLoader size={10} color={"#5e35b1"} loading={loading} />
      </Backdrop>
      <Scrollbars autoHide autoHideTimeout={1000}
        autoHideDuration={10} className={classes.courseContainer} >
        <Grid container spacing={1} padding={0}>
          {row?.map((r, index) => (
            <Grid item xs={12} md={2.4}>
              <Grid container spacing={0} padding={1} bgcolor={"white"} boxShadow={0} borderRadius={3}  >
                <Grid item xs={12} md={7} pl={0}>
                  <Typography variant="body2" color={r?.color} fontWeight={700} ml={1} textAlign={'left'}  >
                    {r?.icon}
                  </Typography>
                  <Typography variant="body2" color={r?.color} fontWeight={700} ml={2} textAlign={'left'}  >
                    {r?.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <RoundedProgressBar1 percentage={r?.count} />
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} md={12}>
            <Grid container spacing={0} padding={2} bgcolor={"white"} boxShadow={0} borderRadius={3} >
              <Grid item xs={12} md={12}>
                <Typography variant="subtitle1" align="left" fontWeight={800} mb={1} >
                  Today's Activity
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <CardDesign courseStatus={courseStatus} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={0} padding={2} bgcolor={"white"} boxShadow={0} borderRadius={3} >
              <Grid item xs={12} md={12}>
                <Typography variant="subtitle1" align="left" fontWeight={800} mb={1} >
                  Primary Courses
                </Typography>
                {courseDetails?.slice(0, 5).map((r, index) => (
                  <Box key={r.courseId} display={"flex"} p={1} borderRadius={2} mb={1.4} border={"1px dashed #E6E6FA"} // You can also move this to CSS if it's static
                  >
                    <Box p={0} width={"18%"}>
                      <IconButton size="medium" sx={{ borderRadius: "5px", background: colorList[index].bgColor }}>
                        <AutoStoriesTwoToneIcon htmlColor={colorList[index].color} />
                      </IconButton>
                    </Box>
                    <Box p={0} width={"62%"}>
                      <Typography variant="body2" ml={1} color={"#212b36"} align="left" >
                        {r.courseTitle}
                      </Typography>
                      <Typography variant="caption" ml={1} mt={1} color={"gray"} fontWeight={700}
                        align="left"
                      >
                        OnGoing Courses
                      </Typography>
                    </Box>
                    <Box p={0} width={"20%"} display={"flex"} justifyContent={"center"}>
                      <RoundedProgressBar percentage={r.scheduledUsersCount} color={colorList[index].color} backgroundColor={colorList[index].bgColor} />
                    </Box>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid container spacing={0} padding={2} bgcolor={"white"} boxShadow={0} borderRadius={3} >
              <Grid item xs={12} md={12}>
                <Typography variant="subtitle1" align="left" fontWeight={800} ml={1}>
                  User Count based on the Course
                </Typography>
                <LineChart weeklyProgress={weeklyProgress} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid container spacing={0} padding={2} bgcolor={"white"} boxShadow={0} borderRadius={3} >
              <Grid item xs={12} md={12}>
                <Typography
                  variant="subtitle1"
                  align="left"
                  fontWeight={800}
                  mb={1}
                  ml={1}
                >
                  Monthly Course Progress based on Top 5 Manager
                </Typography>
                <CourseProgress topManager={topManager} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Scrollbars>
    </React.Fragment>
  );
};

export default Dashboard;
