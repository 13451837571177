import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

    dialogTitle: {
        borderBottom: "1px solid #ccc",
        padding: "3px"
    },
    dialogContent: {
        padding: theme.spacing(2),
    },

    Button: {
        fontSize: "12px !important",
        borderRadius: 2,
    },
    textAlign: {
        textAlign: "center",
    },
    closeButton: {
        cursor: "pointer",
        position: "absolute",
        right: "14px",
        top: "14px",
    },
    dialogBackground: {
        backdropFilter: "blur(5px)",
        backgroundColor: "rgba(145, 158, 171, 0.24)",
    },
    //MultipleChoice
    topicContainer: {
        [theme.breakpoints.up("lg")]: {
            maxHeight: "calc( 100vh - 60px) !important",
            minHeight: "calc( 100vh - 100px) !important",
        },
    },
    tableCell: {
        justifyContent: "center !important",
        alignItems: "center !important",
    },
    tableContainer: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    tableHead: {
        backgroundColor: '#f2f2f2',
        fontSize: '18px !important',
        fontWeight: 'bolder'
    },
    tableCellCenter: {
        padding: 8,
        border: '1px solid lightgrey',
        textAlign: 'center'
    },
    tableCellUserId: {
        backgroundColor: '#c9daf8',
    },
    tableCellAttempts: {
        backgroundColor: '#f7cac9',
    },
    tableCellTime: {
        backgroundColor: '#ffedb3',
    },
    tableCellScore: {
        backgroundColor: '#d4a5d4',
    },
    tableCellPassingScore: {
        backgroundColor: '#a5d4a5',
    },
    score: {
        color: '#5e35b1 !important',
        fontSize: '50px !important',
        fontWeight: 'bold'
    },
    border: {
        padding: '5px',
        borderRadius: '5px',
        marginBottom: '5px',
        height: "50px",
    },

    greenBorder: {
        border: '2px solid #00FF00'
    },

    redBorder: {
        border: '2px solid #FF0000'
    },

    //review Page
    closeIcon: {
        color: "gray !important",
        borderRadius: "10px !important",
        border: "1px solid gray !important"
    },
    doneIcon: {
        color: 'green !important',
        marginRight: '5px !important'
    },
    closeIcon1: {
        color: 'red !important',
        marginRight: '5px !important'
    },
    description: {
        border: '2px solid black !important',
        padding: '5px !important',
        borderRadius: '5px !important'
    },
    questionnumber: {
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
    }
}));