//React 
import React, { useState } from "react";
//mui components
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Button, Card, CardMedia, Collapse, Grid, IconButton, ListItem, ListItemText, Popover, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import Scrollbars from 'react-custom-scrollbars';
import { MoonLoader } from "react-spinners";
//Images
import image from "../../asset/image/Login/bg1.png";
//Styles
import useStyles from '../UserCourseView/CourseLab/LabCss';

const AddLab = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState([0]);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [expanded2, setExpanded2] = useState<string | false>(false);
    const [image, setImage] = useState("");

    const handleChange = (panel: string) => {
        setExpanded((prevExpanded) => (prevExpanded === panel ? null : panel));
    };

    const hanldeChange2 = (panel: string) => {
        setExpanded2((prevExpanded) => (prevExpanded === panel ? null : panel));

    };

    const [expandedImage, setExpandedImage] = useState(null);

    const handleImageClick = (event, image) => {
        setImage(image);
        setExpandedImage(event.currentTarget);
    };

    const handleClose = () => {
        setExpandedImage(null);
    };
    return (
        <Grid container spacing={0} p={0}>
            <Grid item xs={12}>
                {loading ? (
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                        <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
                    </Backdrop>
                ) : (
                    <List>
                        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.LabContainer}>
                            {mockData.map((lab, index) => (
                                <React.Fragment>
                                    <ListItem key={index} disableGutters sx={{ p: "1px", border: "1px solid gray", marginBottom: "5px", bgcolor: "#baf4ba", borderRadius: "5px" }}>
                                        <ListItemText primary={`Lab ${index + 1}:${lab.description}`} />
                                        <IconButton onClick={() => handleChange(`panel${index}`)}>
                                            {expanded === `panel${index}` ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                    </ListItem>
                                    <Collapse in={expanded === `panel${index}`} timeout="auto" unmountOnExit>
                                        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.InstructionContainer}>
                                            {lab.steps.map((step, stepIndex) => (
                                                <React.Fragment>
                                                    <ListItem key={stepIndex} disableGutters sx={{ p: "1px", borderBottom: "0.5px solid gray" }}>
                                                        <Grid item xs={12} display={'flex'}>
                                                            <Checkbox size='small' checked={checked.indexOf(stepIndex) !== -1} tabIndex={-1} />
                                                            <Typography variant='body2' paddingTop={1} >{`Step ${stepIndex}`}</Typography>
                                                            <Grid item xs={10} display={"flex"} justifyContent={"flex-end"} >
                                                                <Button size='small' color='success'>Verify</Button>
                                                            </Grid>
                                                        </Grid>
                                                        <IconButton size={'small'} onClick={() => hanldeChange2(`panel${stepIndex}`)}>
                                                            {expanded2 === `panel${stepIndex}` ? <ExpandLess /> : <ExpandMore />}
                                                        </IconButton>
                                                    </ListItem>
                                                    <Collapse in={expanded2 === `panel${stepIndex}`} timeout="auto" unmountOnExit>
                                                        <Typography variant='body2' gutterBottom>{step.text}</Typography>
                                                        {step.image && (
                                                            <Grid container spacing={1} >
                                                                {step.image.map((image, imageIndex) => (
                                                                    <Grid item xs={3} key={imageIndex} display={'flex'} justifyContent={'flex-start'}>
                                                                        <Card onClick={(event) => handleImageClick(event, image)} sx={{ m: "3px" }}>
                                                                            <CardMedia
                                                                                component="img"
                                                                                image={image}
                                                                                alt="Step Image"
                                                                                height={200}
                                                                            />
                                                                        </Card>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        )}
                                                        <Popover
                                                            open={Boolean(expandedImage)}
                                                            anchorEl={expandedImage}
                                                            onClose={handleClose}
                                                            anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                                                            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                                                        >
                                                            <Card>
                                                                <CardMedia component="img" image={image} height={450} alt="Expanded Image" />
                                                            </Card>
                                                        </Popover>                                                        {step.file && step.file.map((file, fileIndex) => (
                                                            <Grid item xs={4} mt={1} className={classes.paper} key={fileIndex} display={'flex'} border={'1px solid grey'} bgcolor={'ButtonFace'} borderRadius={2}>
                                                                <Typography variant='caption'>File Name: {file}</Typography>
                                                                <IconButton size='small'>
                                                                    <CloudDownloadIcon color='primary' fontSize='small' />
                                                                </IconButton>
                                                            </Grid>
                                                        ))}
                                                    </Collapse>
                                                </React.Fragment>
                                            ))}

                                        </Scrollbars>
                                    </Collapse>
                                </React.Fragment>
                            ))}
                        </Scrollbars>
                    </List>
                )}
            </Grid>
        </Grid>
    );
};

export default AddLab;

const mockData = [
    {
        id: 'Lab1',
        description: "Accomplish AD Permissions",
        steps: [
            {
                text: "Description AWS Identity and Access Management (IAM) enables you to securely control access to AWS services and resources for your users. The service is targeted at organizations with multiple users or systems that use AWS products such as Amazon EC2, Amazon RDS, and the AWS Management Console. With IAM, you can centrally manage users, security credentials such as access keys, and permissions that control which AWS resources users can access.",
                image: [image, image],
                file: ["samplefile.txt"]
            },
            {
                text: "AWS Identity and Access Management (IAM) enables you to securely control access to AWS services and resources for your users. Using IAM, you can create and manage AWS users and groups, and use permissions to allow and deny their access to AWS resources.",
                image: [image],
                file: ["step2_file.txt", "step2_file.txt"]
            },
            {
                text: "Skip down to the Attach permissions policies section, enter AmazonEC2ReadOnlyAccess into the search bar and select the resulting policy: AmazonEC2ReadOnlyAccess",
                image: null,
                file: ["step3_file.txt"]
            },
            {
                text: "The Groups page now lists the new group and you are able to assign the DevOps group to any available user:",
                image: null,
                file: ["step4_file.txt"]
            },
            {
                text: "The Groups page now lists the new group and you are able to assign the DevOps group to any available user",
                image: [],
                file: ["step5_file.txt"]
            },
            {
                text: "The Groups page now lists the new group and you are able to assign the DevOps group to any available user",
                image: [],
                file: ["step5_file.txt"]
            },
            {
                text: "The Groups page now lists the new group and you are able to assign the DevOps group to any available user",
                image: [],
                file: ["step5_file.txt"]
            }, {
                text: "Description AWS Identity and Access Management (IAM) enables you to securely control access to AWS services and resources for your users. The service is targeted at organizations with multiple users or systems that use AWS products such as Amazon EC2, Amazon RDS, and the AWS Management Console. With IAM, you can centrally manage users, security credentials such as access keys, and permissions that control which AWS resources users can access.",
                image: [image, image],
                file: ["samplefile.txt"]
            }
        ]
    },
    {
        id: 'Lab2',
        description: "Implement Security Measures",
        steps: [
            {
                text: "Step 1 Description",
                image: [],
                file: ["step1_file.txt"]
            },
            {
                text: "The Groups page now lists the new group and you are able to assign the DevOps group to any available user",
                image: [],
                file: ["step2_file.txt"]
            },
            {
                text: "Step 3 Description",
                image: [],
                file: ["step3_file.txt"]
            },
            {
                text: "Step 4 Description",
                image: [],
                file: ["step4_file.txt"]
            },
            {
                text: "Step 5 Description",
                image: null,
                file: ["step5_file.txt"]
            }
        ]
    }
];
