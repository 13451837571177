/* eslint-disable react-hooks/rules-of-hooks */
import { Grid } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddIcon from "@mui/icons-material/Add";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';
import { useGlobalState } from '../../../GlobalStore/globalStore';
import MLearnTooltip from '../../../GlobalStyles/ToolTip';
import { AddSubTopicDialogProps, ISubTopicDetail } from '../../../model/course';
import { usePost } from '../../../utils/apiHelper';
import useStyles from "../CourseCss";

const AddSubTopicDialog: React.FC<AddSubTopicDialogProps> = (props) => {
  const { state } = useGlobalState();
  const Initail: ISubTopicDetail = {
    courseSubTopicId: 0,
    courseId: 0,
    courseSubTopicName: "",
    courseSubTopicDescrition: "",
    courseSubTopicObjective: "",
    courseSubTopicVideoURL: "",
    courseSubTopicSearchURL: "",
    courseSubTopicPrompt: "",
    createdDate: "",
    modifiedDate: "",
    createdBy: "",
    modifiedBy: "",
    courseTopicId: 0,
    stipulatedTime: "",
    isAssignmentRequired: false,
    isAssignmentExist: 0
  };

  const classes = useStyles();
  const [openTopic, setOpenTopic] = useState(false);
  const [subTopicData, setSubTopicData] = useState<ISubTopicDetail>(Initail);

  const handleOpenDialog = () => {
    if (props?.SubTopicId) {
      const filteredTopic = props.selectedSubTopic.find(
        (topic) => topic?.courseSubTopicId === props?.SubTopicId
      );
      setSubTopicData(filteredTopic);
    }
    setOpenTopic(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setSubTopicData((prevCourse) => ({
      ...prevCourse,
      createdBy: prevCourse.courseId ? prevCourse.createdBy : state.userAccessContext.userId.toString(),
      modifiedBy: state.userAccessContext.userId.toString(),
    }));

    const { value } = e.target;

    setSubTopicData((prevData) => ({
      ...prevData,
      courseSubTopicName: value
    }));
  };

  const handleClose = () => {
    setSubTopicData(Initail)
    setOpenTopic(false);
  };

  const handleSave = async () => {
    let SubTopicData = {
      courseTopicId: props?.TopicId,
      courseSubTopicId: subTopicData?.courseSubTopicId,
      courseId: props.courseId,
      courseSubTopicName: subTopicData.courseSubTopicName,
      courseSubTopicDescrition: subTopicData.courseSubTopicDescrition,
      courseSubTopicObjective: subTopicData.courseSubTopicObjective,
      courseSubTopicVideoURL: subTopicData.courseSubTopicVideoURL,
      courseSubTopicSearchURL: subTopicData.courseSubTopicSearchURL,
      courseSubTopicPrompt: subTopicData.courseSubTopicPrompt,
      stipulatedTime: subTopicData.stipulatedTime,
      isAssignmentRequired: subTopicData?.isAssignmentRequired,
      createdBy: subTopicData.createdBy,
      modifiedBy: subTopicData.modifiedBy,
    };
    (async () => {
      await usePost("Course/CreateSubTopic", SubTopicData).then(() => {
        props.onCallBack();
      }).finally(() => {
        handleClose();
      });
    })()
  };

  return (
    <Grid>
      {!props?.SubTopicId ? (
        <MLearnTooltip title="Add Sub Topic" placement="top">
          <AddIcon fontSize="small" color="primary" onClick={handleOpenDialog} />
        </MLearnTooltip>
      ) : (
        <MLearnTooltip title="Edit Topic" placement="top">
          <ModeTwoToneIcon fontSize="small" color="primary" onClick={handleOpenDialog} />
        </MLearnTooltip>
      )}
      <Dialog open={openTopic} onClose={handleClose} fullWidth PaperProps={{ style: { borderRadius: 15 } }} classes={{ paper: classes.deleteDialogue }}>
        <DialogTitle id="alert-dialog-title" className={classes.dialogStyles}>
          <b>{!subTopicData?.courseTopicId ? 'Add Sub Topic' : "Edit Topic"}</b>
          <CancelOutlinedIcon onClick={handleClose} className={classes.closeButton} />
        </DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" fullWidth size="small" type="text" value={subTopicData?.courseSubTopicName} onChange={handleChange} sx={{ mt: "1" }} />
        </DialogContent>
        <DialogActions className={classes.dialogActionPadding}>
          <Button onClick={handleSave} color="success" variant="contained" size="small" className={classes.AddbuttonStyle} startIcon={<CheckCircleIcon />}>
            <b>Save</b>
          </Button>
          <Button onClick={handleClose} color="error" variant="contained" size="small" className={classes.ButtonStyle} startIcon={<CancelIcon />}>
            <b>Cancel</b>
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AddSubTopicDialog;
