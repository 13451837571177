/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-undef */
import {
    Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography
} from "@mui/material";
import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Link, useLocation } from "react-router-dom";
import routes, { IRoute } from '../../Routes';
import { Dashboard, Management, MyCourse, QueryDetails } from '../../constant/Constant';
import logo from '../../images/logo/logo.png';
import useStyles from "./NavbarCss";

interface INavigationDrawerProps {
    open: boolean,
    onClose: () => void
}

const NavigationDrawer: React.FC<INavigationDrawerProps> = (props) => {
    const classes = useStyles();
    const { open, onClose } = props;
    const [subMenuOpen, setsubMenuOpen] = useState<number | null>(null);
    const location = useLocation();

    const handleClick = (index: number) => {
        if (subMenuOpen === index) {
            setsubMenuOpen(null);
        } else {
            setsubMenuOpen(index);
        }
    };

    // Function to check if the route is active
    const isActiveRoute = (route: IRoute) => {
        return location.pathname === route.path;
    };

    const renderMenuItems = (groupName: string) => (
        routes
            .filter(route => route.groupName === groupName)
            .map((route, index) => (
                <ListItem className={classes.listItemMui} key={index}>
                    <ListItemButton
                        className={isActiveRoute(route) ? classes.listItemActive : classes.listItemInactive}
                        component={Link as React.ElementType} to={route.path}
                        onClick={() => handleClick(index)}
                    >
                        <ListItemIcon sx={{ justifyContent: 'center' }}>
                            {route.icon && <route.icon fontSize="small" color='primary'
                                className={isActiveRoute(route) ? classes.listItemIconActive : classes.listItemIconInActive} />}
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant='body2' sx={{ fontWeight: isActiveRoute(route) ? 600 : "bold" }} className={isActiveRoute(route) ? classes.listItemTextActive : classes.listItemTextInActive} >
                                {route.title}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            ))
    );


    return (
        <React.Fragment>
            <Drawer variant="temporary" open={open} onClose={onClose} anchor="left">
                <nav className={classes.toolbar}>
                    <Box m={0} p={1} display={'flex'} justifyContent={'center'} borderBottom={'1px dashed lightgray'}>
                        <img src={logo} alt="company-logo" width="150px" height="40px" />
                    </Box>

                    <List className={classes.mobildeListMui}                >
                        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.mobileSidebarContainer} >

                            <Typography variant='body2' sx={{ padding: 1 }} fontSize={'12px'} color={'rgb(95, 116, 141)'}><b>DASHBOARD</b></Typography>
                            {renderMenuItems(Dashboard)}
                            <Divider variant="middle" component="li" sx={{ margin: 1 }} />
                            <Typography variant='body2' sx={{ padding: 1 }} fontSize={'12px'} color={'rgb(95, 116, 141)'}><b>MANAGEMENT</b></Typography>
                            {renderMenuItems(Management)}
                            <Divider variant="middle" component="li" sx={{ margin: 1 }} />
                            <Typography variant='body2' sx={{ padding: 1 }} fontSize={'12px'} color={'rgb(95, 116, 141)'}><b>MY COURSE</b></Typography>
                            {renderMenuItems(MyCourse)}
                            <Divider variant="middle" component="li" sx={{ margin: 1 }} />
                            <Typography variant='body2' sx={{ padding: 1 }} fontSize={'12px'} color={'rgb(95, 116, 141)'}><b>QUERY DETAILS</b></Typography>
                            {renderMenuItems(QueryDetails)}
                        </Scrollbars>
                    </List >
                </nav>
            </Drawer >
        </React.Fragment>
    )
}

export default NavigationDrawer