/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import MLearnTooltip from '../../GlobalStyles/ToolTip';
import CourseDownload from "../../asset/image/Course/CourseDownload.svg";
import Project from "../../asset/image/Course/Project.svg";
import { SampleUser, sampleCourse } from '../../constant/Constant';
import { ISampleFileData } from '../../model/Users/IUser';
import { usePost } from '../../utils/apiHelper';
import useStyles from '../MyCourse/MyCourseCss';


const DownloadManager: React.FC = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const downloadFile = (file) => {
        (async () => {
            if (file?.length) {  // Ensure sampleFile is not empty
                let request = {
                    pdfUri: file[0]?.fileUri,
                };
                try {
                   await usePost<any>("File/FileDownload", request).then((response)=>{
                    const byteCharacters = atob(response?.data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: 'application/pdf' }); // Specify type as 'application/pdf'
                    const blobUrl = URL.createObjectURL(blob);

                    // Create an anchor element to trigger the download
                    const anchor = document.createElement('a');
                    anchor.href = blobUrl;
                    anchor.download = file[0].fileName; // Set desired filename here
                    anchor.click();

                    // Clean up the object URL after the download
                    URL.revokeObjectURL(blobUrl);
                   })
                    
                } catch (error) {
                    console.error('Error downloading file:', error);
                }
            } else {
                console.warn('No file available to download.');
            }
        })();
    };

    const handleDownloadSampleFile = (id) => {
        (async () => {
            let request = {
                sampleFileId: id
            }
            await usePost<ISampleFileData[]>(`File/GetSampleFileDetail?sampleFileId=${id}`, request).then((result) => {  downloadFile(result.data) });
        })()
    }


    return (
        <React.Fragment>
            <MLearnTooltip title="Download Import Template" placement="top">
                <Button
                    size="small"
                    variant="contained"
                    color="success"
                    onClick={handleClickOpen}
                    aria-label=" Download Manager"
                    className={classes.downloadStyles}>
                    <DownloadingOutlinedIcon />
                </Button>
            </MLearnTooltip>
            <Dialog open={open} onClose={handleClose} PaperProps={{ className: classes.dialogTitle }}>
                <DialogTitle borderBottom={"1px dashed lightgrey"}>
                    <b>Manager Template</b>
                    <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: "white" }}>
                        <CancelOutlinedIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box p={1} m={1} border={"1px solid lightgrey"} height="140px" width="90%"
                                onClick={(e) => {
                                    e.stopPropagation(); handleDownloadSampleFile(SampleUser);
                                }}>
                                <img
                                    src={CourseDownload}
                                    alt={"course"}
                                    className={classes.iconstyle}
                                />
                                <Typography variant='body2' align='center' ml={'5px'} mr={1} p={1} >
                                    <b>User Download</b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box p={1} m={1} border={"1px solid lightgrey"} height="140px" width="90%" onClick={() => handleDownloadSampleFile(sampleCourse)}>
                                <img
                                    src={Project}
                                    alt={"Project"}
                                    className={classes.iconstyle}
                                />
                                <Typography variant='body2' align='center' ml={'5px'} mr={1} p={1}>
                                    <b>Course Download</b>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.borderTop1}>
                    <Button onClick={handleClose} color="error" variant="contained" size="small">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DownloadManager;
