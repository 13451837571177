/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
//react Imports
import React, { useEffect, useState } from 'react';
//Mui components
import Scrollbars from 'react-custom-scrollbars';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Backdrop, Box, Tab } from '@mui/material';
import AutoStoriesTwoToneIcon from '@mui/icons-material/AutoStoriesTwoTone';
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import { SyncLoader } from 'react-spinners';
//Models
import { IUserCourseDetail } from '../../model/UserCourse/IUserCourseContract';
//GlobalFunctions
import { usePost } from '../../utils/apiHelper';
import { useGlobalState } from '../../GlobalStore/globalStore';
//style
import useStyles from '../ManageCourse/CourseCss';
//Components
import CourseList from './CourseList';
import CompletedCourseView from './CompletedCourseView';
import UserDashboard from './UserDashboard/Dashboard';

const UserCourseLayout = () => {
    const { state } = useGlobalState();
    const classes = useStyles();
    const [value, setValue] = useState('1');
    const [loading, setLoading] = useState(true);
    const [userCourseDetails, setUserCourseDetails] = useState<IUserCourseDetail[]>([]);
    const [ongoingCourses, setOngoingCourses] = useState<IUserCourseDetail[]>([]);
    const [completedCourses, setCompletedCourses] = useState<IUserCourseDetail[]>([]);

    useEffect(() => {
        GetUserCourse();
    }, []);

    const GetUserCourse = () => {
        (async () => {
            let request = state?.userAccessContext?.userId;
            await usePost<IUserCourseDetail[]>(`UserCourse/GetUserCourse?userId=${request}`, {}).then((r) => {
                setUserCourseDetails(r?.data);
            });
        })().finally(() => {
            setLoading(false);
        })
    }


    useEffect(() => {
        const ongoing = userCourseDetails?.filter(course => course?.courseCompletionPercentage < 100);
        const completed = userCourseDetails?.filter(course => course?.courseCompletionPercentage === 100);
        setOngoingCourses(ongoing);
        setCompletedCourses(completed);
    }, [userCourseDetails]);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            {loading && (
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <SyncLoader size={10} color={"#5e35b1"} loading={loading} />
                </Backdrop>
            )}
            <Box bgcolor={'white'} pl={0} pr={0} p={0} borderRadius={2}>
                <TabContext value={value} >
                    <TabList onChange={handleChange} aria-label="lab API tabs example" className={classes.tabList}>
                        <Tab icon={<SchoolTwoToneIcon fontSize="small" />} iconPosition="start" sx={{ textTransform: 'none' }}
                            label={<span><b>DashBoard</b></span>} value="1" className={classes.tabs} />
                        <Tab icon={<AutoStoriesTwoToneIcon fontSize="small" />} iconPosition="start" sx={{ textTransform: 'none' }}
                            label={<span><b>My Course</b></span>} value="2" className={classes.tabs} />
                        <Tab icon={<SchoolTwoToneIcon fontSize="small" />} iconPosition="start" sx={{ textTransform: 'none' }}
                            label={<span><b>Completed Course</b></span>} value="3" className={classes.tabs} />
                    </TabList>
                    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.userCourseContainer}>
                        <TabPanel value="1" sx={{ padding: "5px 0px" }}>
                            <UserDashboard ongoingCourses={ongoingCourses} completedCourses={completedCourses} />
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: "5px 0px" }}>
                            <CourseList userCourseList={ongoingCourses} />
                        </TabPanel>
                        <TabPanel value="3" sx={{ padding: "5px 0px" }}>
                            <CompletedCourseView userCourseList={completedCourses} callback={GetUserCourse} />
                        </TabPanel>
                    </Scrollbars>
                </TabContext>
            </Box>
        </React.Fragment>
    )
}

export default UserCourseLayout