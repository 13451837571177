
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, InputAdornment, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import MailOutlineSharpIcon from '@material-ui/icons/MailOutlineSharp';
import PhoneSharpIcon from '@material-ui/icons/PhoneSharp';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import { useRef, useState } from 'react';
import { MoonLoader } from 'react-spinners';
import { Backdrop } from '@mui/material';
import { useStyles } from '../components/ContactUs.tsx/ContactUsCss';


export default function ContactUs() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false); // when making api call make sure to change this false to true

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: '',
  });
  const formRef = useRef<HTMLFormElement>(null);
  // Update form data when input changes
  const handleInputChange = (field: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = () => {
    // Convert formData to JSON
    const jsonData = JSON.stringify(formData);
    
    // Reset the form
    formRef.current?.reset();
  };

  return (
    <div >
       {loading && (
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                  <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
               </Backdrop> 
            )}
      <form ref={formRef}>
        <Grid container spacing={0} direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '80vh' }}>
          <Grid container spacing={0} component={Paper} className={classes.gridContainer}>
            <Grid xs={4} className={classes.gridAddress}>
              <Grid container spacing={2} style={{ padding: '30px 35px' }}>
                <Grid xs={12}>
                  <Typography variant='h6' className={classes.color} >
                    <b>Get In Touch !</b>
                  </Typography>
                  <Typography variant='subtitle2' className={classes.typography}>
                    Fill up the form and our team will get back to you.
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <Grid container spacing={2} style={{ padding: '35px 25px' }}>
                    <Grid xs={2} className={classes.margin}>
                      <EmailIcon className={classes.color} />
                    </Grid>
                    <Grid xs={10} className={classes.margin}>
                      <Typography variant='subtitle2' className={classes.color}>
                        <b>Email us</b>
                        <br />
                        Our friendly team is ere to help.
                      </Typography>
                      <Typography variant='body2' className={classes.color}>
                        info@cognultsoft.com
                      </Typography>
                    </Grid>
                    <Grid xs={2} className={classes.marginTop} >
                      <CallIcon className={classes.color} />
                    </Grid>
                    <Grid xs={10} className={classes.marginTop}>
                      <Typography variant='subtitle2' className={classes.color} >
                        <b>Call us</b>
                        <br />
                        Mon-Fri from 9am to 5pm.
                      </Typography>
                      <Typography variant='body2' className={classes.color} >
                        +91 9100687126
                      </Typography>
                    </Grid>
                    <Grid xs={2} className={classes.marginTop}>
                      <LocationOnOutlinedIcon className={classes.color} />
                    </Grid>
                    <Grid xs={10} className={classes.marginTop}>
                      <Typography variant='subtitle2' className={classes.color} >
                        Visit us
                        <br />
                        Come say hello at our office.
                      </Typography>
                      <Typography variant='body2' className={classes.color} >
                        #6, K No : 7/8/6/360,<br />
                        10th Main Road,
                        Hosur Road,<br />
                        Papannareddy Layout,<br />
                        Garebhavipalaya, Bengalore<br />
                        Karnataka - 560068
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={8} style={{ padding: '2rem 4rem' }}>
              <Grid container spacing={5}>
                <Grid xs={6}>
                  <TextField
                    required
                    id="outlined-required"
                    placeholder="First Name"
                    variant="outlined"
                    className={classes.textField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                      style: { fontSize: 'small' },
                    }} onChange={(e) => handleInputChange('firstName', e.target.value)}
                  />
                </Grid>
                <Grid xs={6}>
                  <TextField
                    required
                    id="outlined-required"
                    placeholder="Last Name"
                    variant="outlined"
                    className={classes.textField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                      style: { fontSize: 'small' },
                    }} onChange={(e) => handleInputChange('lastName', e.target.value)}
                  />
                </Grid>
                <Grid xs={6}>
                  <TextField
                    required
                    id="outlined-required"
                    placeholder="Email"
                    variant="outlined"
                    className={classes.textField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <MailOutlineSharpIcon />
                        </InputAdornment>
                      ),
                      style: { fontSize: 'small' },
                    }} onChange={(e) => handleInputChange('email', e.target.value)}
                  />
                </Grid>
                <Grid xs={6}>
                  <TextField
                    required
                    id="outlined-required"
                    placeholder="Phone Number"
                    variant="outlined"
                    className={classes.textField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <PhoneSharpIcon />
                        </InputAdornment>
                      ),
                      style: { fontSize: 'small' },
                    }} onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
                  />
                </Grid>
                <Grid xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    placeholder="Subject"
                    className={classes.textField}
                    variant="outlined"
                    InputProps={{
                      style: { fontSize: 'small' },
                    }}
                    onChange={(e) => handleInputChange('subject', e.target.value)}
                  />
                </Grid>
                <Grid xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    placeholder="Your Message"
                    multiline
                    rows={6}
                    className={classes.textField} 
                    variant="outlined"
                    InputProps={{
                      style: { fontSize: 'small' },
                    }}
                    onChange={(e) => handleInputChange('message', e.target.value)}
                  />
                </Grid>
                <Grid xs={12}>
                  <Button variant='contained' color='primary' className={classes.textField} onClick={handleSubmit}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid >
      </form>
    </div>
  );
}
