import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Backdrop, Button } from '@mui/material';
import { useState } from 'react';
import { MoonLoader } from 'react-spinners';
import { useDelete } from '../../../utils/apiHelper';
import useStyles from '../CourseCss';

const DeleteCoupon: React.FC<{ CouponId: number, onDelete: () => void }> = (props) => {
  const { CouponId, onDelete } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteCourseDetails = () => {
    setLoading(true);
    (async () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      await useDelete(`Course/DeleteCourseCoupon?CouponId=${CouponId}`);
    })().finally(() => {

      handleClose();

    })

    setLoading(false);
    onDelete();
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen} size='small'>
        <DeleteTwoToneIcon fontSize="small" color="error" />
      </IconButton>


      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
      </Backdrop>

      < Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'sm'}
        classes={{ paper: classes.deleteDialogue }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { borderRadius: 15 } }}
        className={classes.dialogBackground}
      >
        <DialogTitle id="alert-dialog-title" ><b>{"Delete Course"}</b>
          <CloseIcon onClick={handleClose} className={classes.close} />
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure want to delete the Coupon?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActionPadding}>
          <Button onClick={deleteCourseDetails} color="success" variant='contained' size='small' startIcon={<CheckCircleIcon />}  /* className={classes.save} */>
            <b>Yes</b>
          </Button>
          <Button onClick={handleClose} color="error" variant='contained' size='small' startIcon={<CancelIcon />} /* className={classes.cancel} */>
            <b>NO</b>
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}

export default DeleteCoupon;

