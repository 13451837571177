/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { TablePagination } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import PersIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Chip, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Link } from 'react-router-dom';
import { SyncLoader } from "react-spinners";
import { useGlobalState } from "../../GlobalStore/globalStore";
import { searchTermRegex } from '../../constant/Constant';
import { IGetCourseDetail } from '../../model/ICourse';
import { usePost } from '../../utils/apiHelper';
import useStyles from './CourseCss';
import DeleteCourse from './DeleteCourse';
import MangeCourse from './MangeCourse';

const ManageCourse = () => {
  const { state } = useGlobalState();
  const mockCourseDetail: IGetCourseDetail = {
    courseId: 0,
    courseCode: "",
    courseTitle: "",
    durationDays: "",
    courseAbstract: "",
    courseLevel: null,
    whatyouwillLearn: "",
    createdDate: "",
    modifiedDate: "",
    isCertification: true,
    createdBy: state.userAccessContext.userId,
    modifiedBy: state.userAccessContext.userId,
    noOfAttemptsPermitted: 0,
    passCriteria: ""
  };
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [searchDebtor, setSearchDebtor] = useState("");
  const [courseDetail, setCourseDetail] = useState<IGetCourseDetail[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState<string>(null);
  const [TotalCount, setTotalCount] = useState<number>(0);
  const [startingRecordNumber, setStartingRecordNumber] = useState<number>(1);
  const [isAdmin, setIsAdmin] = useState(true);

  const getCourseLevelLabel = (id) => {
    switch (id) {
      case 1:
        return "Beginner";
      case 2:
        return "Intermediator";
      case 3:
        return "Expert";
      default:
        return "Not Selected";
    }
  };

  useEffect(() => {
    GetCourseDetails();
  }, [rowsPerPage, page]);

  const GetCourseDetails = () => {
    setLoading(true);
    (async () => {
      let searchRequest = {
        SearchParameters: searchText,
        RowsPerPage: rowsPerPage,
        startingRecordNumber: page * rowsPerPage,
      };
      await usePost<{ courseDetailOutputs: IGetCourseDetail[]; totalCount: number }>('Course/SearchCourse', searchRequest).then((response) => {
        setCourseDetail(response?.data.courseDetailOutputs);
        setTotalCount(response?.data.totalCount);
      })
    })().finally(() => {
      setLoading(false);
    })
  };

  const handleClear = () => {
    setSearchText("");
    GetClearCourseDetail();
  };

  const GetClearCourseDetail = () => {

    (async () => {
      setLoading(true);
      let clearRequest = {
        SearchParameters: null,
        startingRecordNumber: startingRecordNumber,
        RowsPerPage: rowsPerPage,
      };
      await usePost<{ courseDetailOutputs: IGetCourseDetail[]; totalCount: number }>('Course/SearchCourse', clearRequest).then((response) => {
        setCourseDetail(response.data.courseDetailOutputs);
        setTotalCount(response.data.totalCount);
        setStartingRecordNumber(page * rowsPerPage);
      }).finally(() => {
        setLoading(false);
      })
    })()

  }


  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    GetCourseDetails();
  }, []);

  const highlightSearchTerm = (text: string, searchText: string | null) => {
    if (!searchText) return text;

    const regex = searchTermRegex(searchText);
    const highlightedText = text?.split(regex)?.map((part, index) => {
      return regex?.test(part) ? (
        <mark key={index} style={{ backgroundColor: "#FFF44F", padding: "2px" }}>
          {part}
        </mark>
      ) : (
        part
      );
    });

    return <>{highlightedText}</>;
  };
  const handleIconClick = () => {
    setIsAdmin(!isAdmin); // Toggle admin mode
  };


  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0} bgcolor={'white'} borderRadius={2}>
        <Grid item xs={12} className={classes.borderBottom}>
          <Grid container spacing={1} p={1}>
            <Grid item xs={12} md={6.8}>
              <Typography variant='h6' color={'black'} ml={1}><b>Manage Course</b></Typography>
            </Grid>
            <Grid item xs={8} md={4.2} display={"flex"} justifyContent={"flex-end"}>
              <TextField
                fullWidth
                size="small"
                id="standard-bare"
                variant="outlined"
                value={searchText}
                className={classes.searchBox}
                type='text'
                placeholder="Search Course by Title..."

                onChange={(e: any) => setSearchText(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter" && searchDebtor !== "") {
                    GetCourseDetails();
                    setShowSearchIcon(false);
                  } else if (event.key === "Enter" && searchDebtor === "") {
                    setShowSearchIcon(true);
                    GetCourseDetails();
                  }
                }}
                inputProps={{ maxLength: 275 }}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.notchedOutline,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {showSearchIcon === true ?
                        <IconButton size='small' onClick={e => { setShowSearchIcon(false); GetCourseDetails(); }} className={classes.searchIcon}>
                          <SearchIcon fontSize='small' />
                        </IconButton>
                        :
                        <IconButton size='small' onClick={(e) => {
                          setShowSearchIcon(true);
                          setSearchDebtor("");
                        }} className={classes.closeIcon}>
                          <CloseIcon fontSize='small' onClick={handleClear} />
                        </IconButton>
                      }
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={4} md={1} display={'flex'} justifyContent={'space-evenly'}>
              {state?.userAccessContext?.isManageCourse && (
                <IconButton aria-label="delete" onClick={handleIconClick} color={isAdmin ? "primary" : "success"} style={{ backgroundColor: isAdmin ? "#5e35b1" : "#a0ff9e", color: "white" }}>
                  <Tooltip title={isAdmin ? "Admin Mode" : "User Mode"}>
                    <PersIcon />
                  </Tooltip>
                </IconButton>
              )}
              <MangeCourse row={mockCourseDetail} getCourseList={GetClearCourseDetail} onCourseAddedOrEdited={GetCourseDetails} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.borderBottom}>
          <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={10}
            className={classes.courseContainer}
          >
            {loading ? (
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <SyncLoader size={10} color={"#5e35b1"} loading={loading} />
              </Backdrop>
            ) : (
              courseDetail?.length ? (
                <Grid container spacing={0} p={1} m={0} className={classes.courseContainer}>
                  {courseDetail?.map((r) => (
                    <Grid item xs={12} md={3} m={0} p={1} >
                      <Grid container spacing={0} p={2} m={0} borderRadius={3} border={'1px solid rgb(238, 242, 246)'} bgcolor={'rgb(248, 250, 252)'} className={classes.courseCard}>
                        {!isAdmin ? (
                          <Grid item xs={10} borderBottom={'1px dashed lightgray'}>
                            <Link to={`/courseView/${r.courseId}/${r.courseTitle}`} className={classes.courseTitle}>
                              <Typography variant="body2" color="#5e35b1"><b>{highlightSearchTerm(r.courseTitle, searchText)}</b></Typography>
                            </Link>
                          </Grid>
                        ) : (
                          <Grid item xs={10} borderBottom={'1px dashed lightgray'}>
                            <Typography variant="body2" color="#5e35b1"><b>{highlightSearchTerm(r.courseTitle, searchText)}</b></Typography>
                          </Grid>
                        )}
                        {isAdmin && (
                          <>
                            <Grid item xs={1} className={classes.borderBottom}>
                              <MangeCourse row={r} getCourseList={GetClearCourseDetail} onCourseAddedOrEdited={GetCourseDetails} />
                            </Grid>
                            <Grid item xs={1} className={classes.borderBottom}>
                              <DeleteCourse courseId={r?.courseId} onDelete={GetCourseDetails} />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} className={classes.borderBottom} padding="5px 0px">
                          <Typography variant="body2" color="gray" marginTop={1} className={classes.ellipsis}>
                            {r?.courseAbstract}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" color="gray" marginTop={1}>Duration : <b>{r.durationDays} days</b></Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" color="gray" marginTop={1} textAlign={'end'}>
                            <Chip label={getCourseLevelLabel(r.courseLevel)} size="small"
                              color={
                                r?.courseLevel === 1 ? 'primary' :
                                  r?.courseLevel === 2 ? 'secondary' :
                                    r?.courseLevel === 3 ? 'success' : 'default'
                              }
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              ) : (<Grid item xs={12}>
                <Typography variant="body2" color={'red'} marginTop={1} align='center'>There is No Course..............</Typography>
              </Grid>)
            )}
          </Scrollbars>
        </Grid>
        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
          <TablePagination
            count={TotalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className='table-pagination'
            color='primary'
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ManageCourse;
