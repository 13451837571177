/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SchoolSharpIcon from '@mui/icons-material/SchoolSharp';
import {
    Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControlLabel, FormGroup, Grid, IconButton,
    Stack, Switch, TextField, Typography
} from '@mui/material';
import React, { useState } from "react";
import Scrollbars from 'react-custom-scrollbars';
import MLearnTooltip from '../../GlobalStyles/ToolTip';
import { ICourseList, IUserListResult } from "../../model/Manager/IMangerCourseDate";
import { IAssignUserDetail, ICourseAssign, ICoursecode, IManagedLearner, } from "../../model/Users/IUser";
import { useFetch, usePost } from '../../utils/apiHelper';
import useStyles from './UserCss';

export function ManagerUserPermission(props) {
    let initailUserData: IAssignUserDetail = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        userRole: '',
        isManageCourse: false,
        isManageUser: false
    }

 
    let CourseDetails: ICourseAssign = {
        courseStartDate: '',
        courseEndDate: '',
        firstRemainderDate: '',
        SecondRemainderDate: '',
        ThirdRemainderDate: '',
    }

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [userDetail, setUserDetail] = useState<IAssignUserDetail>(initailUserData);
    const [StartDate, setStartDate] = useState<any>();
    const [managerUserListResult, setManagerUserListResult] = useState<IUserListResult[]>([]);
    const [courseListResult, setCourseListResult] = useState<ICoursecode[]>([]);
    const [localState, setLocalState] = useState(CourseDetails);

    //leaners
    const [inputValue, setInputValue] = useState<string>('');
    const [selectedOptionLearner, setSelectedOptionsLearner] = useState<IUserListResult[]>([]);
    const [selectAllLearnerOptions, setSelectAllLearnerOptions] = useState<any[]>();
    const [selectAllLearner, setSelectAllLearner] = useState<boolean>(false);
    //course 
    const [selectedOptionCourse, setSelectedOptionCourse] = useState<ICoursecode[]>([]);
    const [courseInputValue, setCourseInputValue] = useState<string>('');
    const [selectAllCourse, setSelectAllCourse] = useState<boolean>(false);
    const [allCourseOptions, setAllCourseOptions] = useState<ICoursecode[]>();

    const handleSelectAllLearner = () => {
        setSelectAllLearner(true);
        setSelectAllLearnerOptions(managerUserListResult); // Set all options when switch is on
        setSelectedOptionsLearner([...selectedOptionLearner, ...selectAllLearnerOptions]);
    };

    const handleDeselectAllLearner = () => {
        setSelectedOptionsLearner([]);
        setSelectAllLearner(false);
    };

    const handleSelectAllCourse = () => {
        setSelectAllCourse(true);
        setAllCourseOptions(courseListResult); // Set all options when switch is on
        setSelectedOptionCourse([...selectedOptionCourse, ...allCourseOptions]);
    };

    const handleDeselectAllCourse = () => {
        setSelectedOptionCourse([]);
        setSelectAllCourse(false);
    };

    const setDefaultMappedUsers = (Data) => {
        const defaultSelectedValues: IUserListResult[] = Data.map(option => ({
            userId: option.userId,
            emailAddress: option.emailAddress,
            firstName: "",
            lastName: ""
        }));
        setSelectedOptionsLearner(defaultSelectedValues);
    }
    const handleDefaultMappedCourse = (Data) => {
        const defaultSelectedValues: ICoursecode[] = Data.map(option => ({
            courseId: option.courseId,
            courseTitle: option.courseTitle,
            courseCode: option.courseCode
        }));
        setSelectedOptionCourse(defaultSelectedValues);
    }
    const GetManagerUserPermissionDetail = () => {
        let request = {
            'UserId': props.UserId
        };
        (async () => {
            await usePost<{ userDetails: IAssignUserDetail[]; courses: ICoursecode[]; managedLearners: IManagedLearner[]; courseListResult: ICourseList[] }>(`Users/GetManageUserDetail?UserId=${props.UserId}`, request).then((response) => {
                setUserDetail(response.data?.userDetails[0]);
                setDefaultMappedUsers(response.data?.managedLearners); //default leaners
                handleDefaultMappedCourse(response.data?.courses);  //default course detais

            });
        })()
    }
    const GetAllCourseDetails = () => {
        (async () => {
            await useFetch<{ listCode: ICoursecode[] }>("Course/GetCourseCodeWithUser").then((r) => {
                setCourseListResult(r?.data?.listCode); //all course list
            })
        })()
    }

    const GetFilterUserdetsils = () => {
        (async () => {
            await useFetch<{ userListResult: IUserListResult[], courseListResult: ICourseList[] }>('Users/GetManagerUserDetails').then((result) => {
                setManagerUserListResult(result?.data?.userListResult); //all user list
            });
        })()
    }

    const handleDialogClose = () => {
        setOpen(false);
        props.oncreate();
        setSelectedOptionsLearner([]);
        setAllCourseOptions([]);
        setSelectAllLearnerOptions([]);
        setSelectedOptionCourse([]);
    }

    const handleClickOpen = () => {
        GetManagerUserPermissionDetail();
        GetFilterUserdetsils();
        GetAllCourseDetails();
        setOpen(true);
    };

    const handleChange = (event) => {
        const { name, value, checked } = event.target;
        setUserDetail(prevState => ({
            ...prevState,
            [name]: name === 'isManageUser' || name === 'isManageCourse' ? checked : value
        }));

    };

    const handleDeleteChip = (index: number) => {
        if (selectAllLearner) {
            const updatedOptions = [...selectAllLearnerOptions];
            updatedOptions.splice(index, 1);
            setSelectAllLearnerOptions(updatedOptions);
            setSelectedOptionsLearner(updatedOptions);
        } else {
            const updatedOptions = [...selectedOptionLearner];
            updatedOptions.splice(index, 1);
            setSelectedOptionsLearner(updatedOptions);
        }
    };

    const handleDeleteCourseChip = (index: number) => {
        if (selectAllCourse) {
            const updatedOptions = [...allCourseOptions];
            updatedOptions.splice(index, 1);
            setAllCourseOptions(updatedOptions);
            setSelectedOptionCourse(updatedOptions);
        } else {
            const updatedOptions = [...selectedOptionCourse];
            updatedOptions.splice(index, 1);
            setSelectedOptionCourse(updatedOptions);
        }
    };

    const handleOptionSelect = (event, value) => {
        if (value && !selectedOptionLearner.find(option => option.emailAddress === value.emailAddress)) {
            setSelectedOptionsLearner([...selectedOptionLearner, value]);
        }
        setInputValue('');
    };

    const handleCourseOptionSelect = (event, value) => {
        if (value && !selectedOptionCourse.find(option => option.courseTitle === value.courseTitle)) {
            setSelectedOptionCourse([...selectedOptionCourse, value]);
        }
        setCourseInputValue('');
    }


    const handleSubmit = () => {
   

        const importCourseInputs = selectedOptionCourse?.map(course => ({
            emailAddress:localState?.SecondRemainderDate? userDetail.emailAddress:null,
            courseCode: course?.courseCode,
            courseStartDate: localState?.courseStartDate||null,
            firstReminderDate: localState?.firstRemainderDate||null,
            secondReminderDate: localState?.SecondRemainderDate||null,
            thirdReminderDate: localState?.ThirdRemainderDate||null,
            courseEndDate: localState?.courseEndDate||null,
        }));
        

        const request = {
            firstName:userDetail.firstName,
            lastName:userDetail.lastName,
            emailAddress:userDetail.emailAddress,
            isManageUser: userDetail.isManageUser,
            isManageCourse: userDetail.isManageCourse,
            userId: props.UserId,
            importCourseInputs: importCourseInputs,
        };
        (async () => {
            await usePost("Users/ManageUserPermissionAndAssignCourseUser", request).then(() => handleDialogClose());
        })()
    };


    const handleManagerNameChange = (e) => {
        let name = e.target.name;
        setLocalState({
            ...localState, [name]: e.target.value
        });
    }

    return (
        <React.Fragment>
            <MLearnTooltip title="Manage User Permission" placement="top">
                <IconButton size="small" onClick={handleClickOpen}>
                    <EditIcon color='success' fontSize='small' />
                </IconButton>
            </MLearnTooltip>
            <Dialog open={open} onClose={handleDialogClose}
                maxWidth={'lg'} PaperProps={{ style: { borderRadius: 15 } }}>
                <DialogTitle className={classes.dialogStyles}>
                    Manage User Permissions
                </DialogTitle>
                <CancelOutlinedIcon
                    onClick={handleDialogClose}
                    className={classes.closeButton}
                />
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography align='center'>User Information</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label="First Name"
                                        size="small"
                                        name="firstName"
                                        value={userDetail?.firstName}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Last Name"
                                        size="small"
                                        name="lastName"
                                        value={userDetail?.lastName}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Email Address"
                                        name="emailAddress"
                                        size="small"
                                        value={userDetail?.emailAddress}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    size='small'
                                                    checked={userDetail.isManageUser}
                                                    name='isManageUser'
                                                    onChange={handleChange}
                                                />
                                            }
                                            label="Can Manage User"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    size='small'
                                                    checked={userDetail.isManageCourse}
                                                    name='isManageCourse'
                                                    onChange={handleChange}
                                                />
                                            }
                                            label="Can Manage Course"
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} boxShadow={1} border={'2px solid #2196F3'} borderRadius={1} p={1}>
                                    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.scrollbarChip}>
                                        {(selectAllCourse ? allCourseOptions : selectedOptionCourse)?.map((option, index) => (
                                            <Chip
                                                key={index}
                                                label={option?.courseTitle}
                                                onDelete={() => handleDeleteCourseChip(index)}
                                                color='primary'
                                                size='small'
                                                sx={{ m: '3px' }}
                                            />
                                        ))}
                                        {((selectAllCourse ? allCourseOptions : selectedOptionCourse)?.length === 0) && (
                                            <Grid container justifyContent="center" alignItems="center" flexDirection={'column'} color="#5e35b1">
                                                <Typography variant='subtitle1' textAlign={'center'} ><b>Select Course</b></Typography>
                                                <SchoolSharpIcon fontSize='large' />
                                            </Grid>
                                        )}
                                    </Scrollbars>
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <Autocomplete
                                        size='small'
                                        fullWidth
                                        id="checkboxes-tags-demo"
                                        getOptionLabel={(option) => option?.courseTitle}
                                        value={null} // Reset the Autocomplete Value
                                        inputValue={courseInputValue}
                                        className={classes.autoCompleteStyle}
                                        onChange={handleCourseOptionSelect}
                                        onInputChange={(event, newInputValue) => setCourseInputValue(newInputValue)}
                                        getOptionSelected={(option, value) => option?.courseTitle === value?.courseTitle}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Assign Course" placeholder="Favorites" />
                                        )}
                                        options={courseListResult}
                                        renderOption={(option) => {
                                            const selected = selectedOptionCourse?.some((selectedOption) => selectedOption?.courseTitle === option?.courseTitle);
                                            return <li className={selected ? classes.selectedOption : classes.unselectedOption}>{option?.courseTitle}</li>;
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} mt={1}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography>Deselect All Course</Typography>
                                        <Switch
                                            id="selected course"
                                            defaultChecked={false}
                                            checked={selectAllCourse}
                                            onChange={(e) => {
                                                e.target?.checked ? handleSelectAllCourse() : handleDeselectAllCourse();
                                            }}
                                            inputProps={{ 'aria-describedby': 'switch-description' }}
                                        />
                                        <Typography>Select All Course</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography align='center'>Course Report Date</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="GM_Startdate"
                                        size="small"
                                        fullWidth
                                        label="Course Start Date"
                                        name='courseStartDate'
                                        variant="outlined"
                                        type="date"
                                        value={StartDate}
                                        onChange={handleManagerNameChange}
                                        inputProps={{ max: "9999-12-31" }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="GM_Enddate"
                                        size="small"
                                        fullWidth
                                        label="Course End Date"
                                        name='courseEndDate'
                                        variant="outlined"
                                        type="date"
                                        value={StartDate}
                                        onChange={handleManagerNameChange}
                                        inputProps={{ max: "9999-12-31" }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="GM_FirstReminder"
                                        size="small"
                                        fullWidth
                                        label="First Reminder Date"
                                        name='firstRemainderDate'
                                        variant="outlined"
                                        type="date"
                                        value={StartDate}
                                        onChange={handleManagerNameChange}
                                        inputProps={{ max: "9999-12-31" }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="GM_SecondReminder"
                                        size="small"
                                        fullWidth
                                        label="Second Reminder Date"
                                        name='SecondRemainderDate'
                                        variant="outlined"
                                        type="date"
                                        value={StartDate}
                                        onChange={handleManagerNameChange}
                                        inputProps={{ max: "9999-12-31" }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="GM_ThirdReminder"
                                        size="small"
                                        fullWidth
                                        label="Third Reminder Date"
                                        name='ThirdRemainderDate'
                                        variant="outlined"
                                        type="date"
                                        value={StartDate}
                                        onChange={handleManagerNameChange}
                                        inputProps={{ max: "9999-12-31" }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary" className={classes.AddbuttonStyle} startIcon={<CancelIcon />}>
                        Update
                    </Button>
                    <Button onClick={handleDialogClose} color="secondary" className={classes.ButtonStyle} startIcon={<CheckCircleIcon />}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}