import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from '@mui/material';
import useStyles from './NavbarCss';
import AdminRoute, { IRoute } from '../../Routes';

const Content = () => {
    const classes = useStyles();
    return (
        <Container component="main" className={classes.contentContainer} maxWidth="xl">
            <Switch>
                {
                    AdminRoute.map((route: IRoute) => {
                        return (
                            <Route exact path={route.path} key={route.path} component={route.component}>
                            </Route>
                        )
                    })
                }

                <Redirect from="/" to="/dashboard " />

            </Switch>
        </Container >
    );
};

export default Content;
