import bg1 from '../images/themeImage/bg1.png'
import bg2 from '../images/themeImage/bg2.png'
import bg3 from '../images/themeImage/bg3.png'
import bg4 from '../images/themeImage/bg4.png'
import bg5 from '../images/themeImage/bg5.png'
import bg6 from '../images/themeImage/bg6.png'
import bg7 from '../images/themeImage/bg7.png'

export interface IThemeCssData {
    themeId: number;
    color: string;
    backgroundColor: string;
    backgroundPosition: string;
    backgroundRepeatX: string;
    backgroundImage: string;
}

const ThemeMockData: IThemeCssData[] = [
    {
        themeId: 1,
        color: 'white',
        backgroundColor: "#2055A4",
        backgroundPosition: "left top",
        backgroundRepeatX: "repeat",
        backgroundImage: bg6
    },
    {
        themeId: 2,
        color: 'white',
        backgroundColor: "#911844",
        backgroundPosition: "left top",
        backgroundRepeatX: "repeat",
        backgroundImage: bg1
    },
    {
        themeId: 3,
        color: 'white',
        backgroundColor: "#911844",
        backgroundPosition: "left top",
        backgroundRepeatX: "repeat",
        backgroundImage: bg2
    },
    {
        themeId: 4,
        color: 'white',
        backgroundColor: "#911844",
        backgroundPosition: "left top",
        backgroundRepeatX: "repeat",
        backgroundImage: bg3
    },
    {
        themeId: 5,
        color: 'white',
        backgroundColor: "#911844",
        backgroundPosition: "left top",
        backgroundRepeatX: "repeat",
        backgroundImage: bg4
    },
    {
        themeId: 6,
        color: 'white',
        backgroundColor: "#911844",
        backgroundPosition: "left top",
        backgroundRepeatX: "repeat",
        backgroundImage: bg5
    },
    {
        themeId: 7,
        color: 'white',
        backgroundColor: "#911844",
        backgroundPosition: "left top",
        backgroundRepeatX: "repeat",
        backgroundImage: bg7
    },
    // Add more objects as needed
];

export default ThemeMockData;