import { withStyles } from "@material-ui/core";
import { StepConnector } from "@mui/material";

const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 0,
    },
    active: {
      "& $line": {
        color: "rgb(9,9,121,1)",
        backgroundColor: "transparent",
        background: "radial-gradient(currentColor 1px, transparent 2px)",
        backgroundSize: "7px 7px",
        backgroundPosition: "center left",
      },
    },
    completed: {
      "& $line": {
        backgroundColor: "#5e35b1",
      },
    },
    pending: {
      "& $line": {
        backgroundImage:
          "linear-gradient( 136deg, rgb(255,255,255,1) 0%, rgb(217,40,40,1) 35%, rgb(255,255,255,1) 100%)",
      },
    },
    line: {
      height: 5,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 5,
      marginTop: "5px",
      borderColor: "transparent !important",
      "&.css-8t49rw-MuiStepConnector-line": {
        display: "inline-block !important",
        minHeight: "45px !important",
    },
    },
    
  })(StepConnector);

  export default ColorlibConnector;