/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { Box, Grid, Rating, Typography, TextField, Button } from "@mui/material";
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import useStyles from "./UserFeedbackCss";
import feedback from '../../images/course/feedback.svg';
import CloseIcon from '@mui/icons-material/Close';
import { usePost } from "../../utils/apiHelper";
import { IFeedback } from "../../model/ICourse";
import { useGlobalState } from "../../GlobalStore/globalStore";

const UserFeedbackDetails = (props) => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const [ratingValue, setRatingValue] = useState(0);
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [openDialog, setOpenDialog] = useState(true);


    const handleSubmit = () => {
        (async () => {
            let request = {
                feedbackId: 0,
                userId: state?.userAccessContext?.userId,
                courseId: props.courseId,
                rating: ratingValue,
                message: message
            };
            await usePost<IFeedback[]>("Course/ManageFeedback", request);
            setSubmitted(true);
            setOpenDialog(true);
        })()
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <React.Fragment>
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth
                classes={{ paper: classes.deleteDialogue }}
                PaperProps={{ style: { borderRadius: 15 } }}
                className={classes.dialogBackground}>
                {!submitted && (
                    <DialogTitle id="alert-dialog-title" className={classes.dialogStyles}>
                        <b>{"Give Me Your Feedback "}</b>
                        <CloseIcon onClick={handleCloseDialog} className={classes.closeicon} />
                    </DialogTitle>
                )}
                <CloseIcon onClick={handleCloseDialog} className={classes.closeicon} />
                <DialogContent>
                    <Grid container className={classes.grid}>
                        <Box
                            width={600}
                            height={290}
                            p={2}
                            borderRadius={5}
                            bgcolor={'white'}
                            boxShadow={0}
                        >
                            {!submitted && (
                                <React.Fragment>
                                    <Typography variant='h6' className={classes.typography}>
                                        How would you rate the overall course
                                    </Typography>
                                    <Grid textAlign={'center'}>
                                        <Rating
                                            name="feedback-rating"
                                            value={ratingValue}
                                            onChange={(event, newValue) => setRatingValue(newValue)}
                                            size="large"
                                            sx={{ fontSize: '50px' }}
                                        />
                                    </Grid>
                                    <Grid className={classes.textbox}>
                                        <TextField
                                            label="Give Me Feedback"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            style={{ marginBottom: '16px' }}
                                        />
                                        <Button onClick={handleSubmit} variant="contained" color="primary">Submit Feedback</Button>
                                    </Grid>
                                </React.Fragment>
                            )}
                            {submitted && (
                                <Grid textAlign={'center'}>
                                    <Typography variant="h5" className={classes.feedback}>
                                        Thanks for your feedback!
                                    </Typography>
                                    <img src={feedback} height="200px" alt="feedback" />
                                </Grid>
                            )}
                        </Box>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default UserFeedbackDetails;
