/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { TablePagination } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PersIcon from '@mui/icons-material/Person';
import ScienceTwoToneIcon from '@mui/icons-material/ScienceTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { useState } from "react";
import { Link } from 'react-router-dom';
import { SyncLoader } from "react-spinners";
import { useGlobalState } from "../../GlobalStore/globalStore";
import useStyles from '../ManageCourse/CourseCss';
import LabManagement from './ManageLab';
const LabActivity = () => {
  const { state } = useGlobalState();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [searchLab, setSearchLab] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState<string>(null);
  const [TotalCount, setTotalCount] = useState<number>(0);


  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0} bgcolor={'white'} borderRadius={2}>
        <Grid item xs={12} className={classes.borderBottom}>
          <Grid container spacing={1} p={1}>
            <Grid item xs={12} md={8} display={'flex'}>
              <Typography variant='h6' color={'black'} ><b>Manage Lab</b></Typography>
            </Grid>
            <Grid item xs={8} md={3} display={"flex"} justifyContent={"flex-end"}>
              <TextField
                fullWidth
                size="small"
                id="standard-bare"
                variant="outlined"
                value={searchText}
                className={classes.searchBox}
                type='text'
                placeholder="Search Lab-Activity by Title..."
                onChange={(e: any) => setSearchText(e.target.value)}
                inputProps={{ maxLength: 255 }}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.notchedOutline,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {showSearchIcon === true ?
                        <IconButton size='small' onClick={undefined} className={classes.searchIcon}>
                          <SearchIcon fontSize='small' />
                        </IconButton>
                        :
                        <IconButton size='small' onClick={(e) => {
                          setShowSearchIcon(true);
                          setSearchLab("");
                        }} className={classes.closeIcon}>
                          <CloseIcon fontSize='small' onClick={undefined} />
                        </IconButton>
                      }
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={4} md={1} display={'flex'} justifyContent={'space-evenly'}>
              {state?.userAccessContext?.isManageCourse && (
                <IconButton aria-label="delete" onClick={undefined} color={true ? "primary" : "success"} style={{ backgroundColor: true ? "#5e35b1" : "#a0ff9e", color: "white" }}>
                  <Tooltip title={true ? "Admin Mode" : "User Mode"}>
                    <PersIcon />
                  </Tooltip>
                </IconButton>
              )}
              <LabManagement value={0} />

            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.borderBottom}>
          {false ? (
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
              <SyncLoader size={10} color={"#5e35b1"} loading={loading} />
            </Backdrop>
          ) : (
            labActivities?.length ? (
              <Grid container spacing={0} p={1} m={0} className={classes.courseContainer}>
                {labActivities?.map((r) => (
                  <Grid item xs={12} md={3} m={0} p={1} key={r.activityId}>
                    <Grid container spacing={0} p={2} m={0} borderRadius={3} border={'1px solid rgb(238, 242, 246)'} bgcolor={'rgb(248, 250, 252)'} className={classes.courseCard}>
                      {!true ? (
                        <Grid item xs={10} borderBottom={'1px dashed lightgray'}>
                          <Link to={`/courseView/${r.labId}/${r.activityName}`} className={classes.courseTitle}>
                            <Typography variant="body2" color="#5e35b1"><b>{r.activityName}</b></Typography><ScienceTwoToneIcon />
                          </Link>
                        </Grid>
                      ) : (
                        <Grid item xs={10} borderBottom={'1px dashed lightgray'} display={'flex'}>
                          <Typography variant="body2" color="#5e35b1"><b>{r.activityName}</b></Typography><ScienceTwoToneIcon color={'success'} />
                        </Grid>
                      )}
                      {true && (
                        <Grid item xs={2}>
                          <Grid item xs={1} className={classes.borderBottom} display={'flex'}>
                            <LabManagement value={r.activityId} />
                            <DeleteTwoToneIcon color='error' fontSize='small' />
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12} className={classes.borderBottom} padding="5px 0px">
                        <Typography variant="body2" color="gray" marginTop={1} className={classes.ellipsis}>
                          {r?.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="gray" marginTop={1}>Session : <b>{r.activityId}</b></Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="gray" marginTop={1} textAlign={'end'}>
                          <Typography variant="body2" color="gray" marginTop={1}>Duration : <b>{r.durationHours}</b></Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="body2" color={'red'} marginTop={1} align='center'>There is No Lab..............</Typography>
              </Grid>
            )
          )}
        </Grid>
        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
          <TablePagination
            count={TotalCount}
            page={page}
            onPageChange={undefined}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={undefined}
            className='table-pagination'
            color='primary'
          />
        </Grid>
      </Grid>
    </Box>
  )

}

export default LabActivity;
//sample mock data for lab card details
const labActivities = [
  {
    labId: 207,
    activityId: 2,
    activityName: "Installing VMware ESXi",
    durationHours: 2,
    description: "A hands-on session guiding participants through the installation process of VMware ESXi hypervisor.",
    createdBy: "375",
    modifiedBy: "375"
  },
  {
    labId: 207,
    activityId: 3,
    activityName: "Configuring Virtual Machines",
    durationHours: 1.5,
    description: "Practical exercises on configuring virtual machines, including CPU, memory, storage, and network settings.",
    createdBy: "375",
    modifiedBy: "375"
  },
  {
    labId: 207,
    activityId: 4,
    activityName: "Networking in VMware",
    durationHours: 1,
    description: "Exploration of networking concepts and configuration options in VMware virtualized environments.",
    createdBy: "375",
    modifiedBy: "375"
  },
  {
    labId: 207,
    activityId: 5,
    activityName: "VMware Backup and Restore",
    durationHours: 2.5,
    description: "Hands-on exercises on implementing backup and restore strategies for VMware virtual machines.",
    createdBy: "375",
    modifiedBy: "375"
  }
];
