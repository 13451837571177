import { makeStyles, Theme, createStyles } from "@material-ui/core";

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
                minWidth: "154px"
            },
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 161px)',
                minHeight: 'calc( 100vh - 161px)',
            },
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            // width: drawerWidth,
            height: 'auto',
            top: "157px !important",
            left: "275px !important",
            width: "238px",
            marginTop: "1px !important",
            marginLeft: "0px !important",
            position: 'absolute',
            boxShadow: '0px 2px 4px -1px rgba(145, 158, 171, 0.2), 0px 4px 5px 0px rgba(145, 158, 171, 0.14), 0px 1px 10px 0px rgba(145, 158, 171, 0.12)',
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 91vh - 100px)',
                minHeight: 'calc( 91vh - 100px)',
            },
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(0),
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 180px)',
                minHeight: 'calc( 100vh - 180px)',
            }
        },
        toolbararrow: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        socialIcons: {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            paddingBottom: "5px",
            paddingTop: "5px",
        },
        facebookStyle: {
            backgroundColor: "#385998",
            boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
        },
        microsoftStyle: {
            backgroundColor: "#e9eaec",
            boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
        },
        twitterStyle: {
            backgroundColor: "#00ACEE",
            boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
        },
        googleStyle: {
            backgroundColor: "#CA3224",
            boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
        },
        linkedinStyle: {
            backgroundColor: "#0E76AB",
            boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
        },
        appleStyle: {
            backgroundColor: "#161617cc",
            boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
        },
        socialIconStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly !important",
            fontSize: "1.4rem",
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            cursor: "pointer",
            marginBottom: "10px",
            boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
        },
        image: {
            width: "18px",
            height: "18px"
        },
        profileImage: {
            width: '100px !important',
            height: '100px !important',
            borderRadius: '50% !important',
            position: 'absolute',
            top: '50% !important',
            left: '50% !important',
            transform: 'translate(-50%, -50%) !important',
            backgroundColor: "#5e35b1 !important",
            justifyContent: "center !important",
            paddingBottom: "10px !important"
        },
        sidearrow: {
            position: 'relative',
            marginTop: '10px',
            width: '24px',
            height: '32px',
            borderLeft: 0,
            marginLeft: '0px',
            cursor: 'poiter',
        },
        tabRecords: {
            minHeight: "30px !important",
            fontSize: "12px !important",
            fontWeight: "bold",
        },
        courseTabRecords: {
            minHeight: "40px !important",
            fontSize: "12px !important",
            fontWeight: "bold",
        },
        videoConent: {
            padding: '0px 18px'
        },
        videoDisplay: {
            [theme.breakpoints.up('md')]: {
                height: '400px',
                width: '100%'
            },
            [theme.breakpoints.up('lg')]: {
                height: '400px',
                width: '100%'
            },
            [theme.breakpoints.up('xl')]: {
                height: '600px',
                width: '100%'
            }
        },
        listIcon: {
            padding: '0px',
            minWidth: '15px'
        },
        listIcon1: {
            minWidth: '36px'

        },
        nextGrid: {
            display: 'flex',
            justifyContent: 'end',
            padding: '20px',
        },
        nextButton: {
            "&.MuiGrid-grid-lg-6": {
                flexGrow: "0",
                maxWidth: "42%",
                flexBasis: "50%"
            }
        },
        iconstyle: {
            height: '100%',
            width: '100%',
            background: "linear-gradient(blue, 10%, pink) !important",
        },
        previousGrid: {
            padding: '20px 30px'
        },
        container: {
            bordeRadius: '10px',
            padding: '20px',
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 160px)',
                minHeight: 'calc( 100vh - 160px)',
            }
        },
        quizList: {
            padding: '10px 10px',
            border: '1px solid lightgray',
            borderRadius: '5px',
            cursor: 'pointer'
        },
        save: {
            background: 'green',
            borderRadius: '8px',
            fontSize: '13px',
            marginRight: '5px',
            transition: 'background - color 250ms cubic- bezier(0.4, 0, 0.2, 1) 0ms, box- shadow 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms, border - color 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
            '&:hover': {
                backgroundColor: 'rgba(54, 179, 126, 0.16)',
                color: 'rgb(27, 128, 106)',
            }
        },
        cancel: {
            background: 'red',
            borderRadius: '8px',
            marginRight: '15px',
            fontSize: '13px',
            transition: 'background - color 250ms cubic- bezier(0.4, 0, 0.2, 1) 0ms, box- shadow 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms, border - color 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
            '&:hover': {
                backgroundColor: 'rgba(255, 86, 48, 0.16)',
                color: 'rgb(183, 29, 24)',
            }
        },
        tyographyTpoic: {
            marginLeft: '15px',
            marginTop: '12px'
        },
        margin: {
            marginLeft: '0px'
        },
        listPadding: {
            padding: '0px 5px'
        },
        buttonPadding: {
            padding: "8px"
        },
        border: {
            borderRadius: '10px'
        },
        drawerPaperStyle: {
            "&.MuiDrawer-paper": {
                height: "100%",
                position: "inherit",
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            }
        },
        listItemPadding: {
            "&.MuiListItem-gutters": {
                paddingLeft: "10px",
                paddingRight: "0px",
            }
        },
        arrowRightIcon: {
            display: "contents",
            minWidth: '40px',
        },
        listItemContent: {
            "&.MuiListItem-root  ": {
                paddingTop: "0px",
                paddingBottom: "0px"

            }
        },
        typographyColor: {
            color: '#ffffff'
        },
        typographyColorAdmin: {
            color: '#919EAB'
        },
        ArrowForwardIosIcon: {
            color: 'white',
            fontSize: 16
        },
        marginLeft: {
            marginLeft: '15px'
        },
        borderTop: {
            width: '100%',
            borderTop: '1px solid #ccc',
            margin: '5px 0'
        },
        typographyMargin: {
            marginLeft: '15px',
            marginTop: '12px',
        },
        typographyMarginTop: {
            marginTop: '15px'
        },
        fontSize: {
            fontSize: '15px'
        },
        gridPadding: {
            padding: '8px'
        },
        topic: {
            marginLeft: '0px',
            marginBottom: '0px'
        },
        display: {
            display: 'flex'
        },
        expandMoreIcon: {
            color: 'white'
        },
        fontFamily: {
            fontFamily: "serif",
        },
        buttonColor: {
            backgroundColor: "rgb(30, 136, 229) !important"
        },
        selectedListItemText: {
            color: "rgb(94, 53, 177) !important"
        },

        /* Manage Topic for user Course view*/
        topicContainer: {
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 390px) !important',
                minHeight: 'calc( 100vh - 390px) !important',
            },
        },
        listButtonActive: {
            padding: "3px 5px !important",
            // marginLeft: "10px !important",
            marginTop: '0px !important',
            // borderRadius: '5px !Important',
            backgroundColor: '#E6E6FA !important',
            // backgroundColor: '#5e35b1 !important',
            cursor: 'pointer !important',
            color: '#5e35b1 !important',
            // color: 'white !important',
            // borderRight: '5px solid #5e35b1 !important',
            fontWeight: 'bold',
            // borderBottom: '1px solid #5e35b1 !important'
        },
        listButtonsubActive: {
            padding: "3px 5px !important",
            // marginLeft: "10px !important",
            marginTop: '0px !important',
            // borderRadius:'5px !Important',
            backgroundColor: '#E6E6FA !important',
            // backgroundColor: '#5e35b1 !important',
            cursor: 'pointer !important',
            color: '#5e35b1 !important',
            // color: 'white !important',
            borderRight: '5px solid #5e35b1 !important',
            fontWeight: 'bolder',
            // borderBottom: '1px solid #5e35b1 !important'
        },
        listButtonInActive: {
            padding: "3px 5px !important",
            // marginLeft: "10px !important",
            marginTop: '0px !important',
            backgroundColor: 'transparent !important',
            cursor: 'pointer !important',
            borderRight: '5px solid transparent !important',
        },
        listItemIcon: {
            minWidth: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        submenu: {
            padding: "0px 5px !important",
            // borderRadius: '25px !Important',
            backgroundColor: 'gray !important',
            width: '5px !important',
            height: '5px !important',
        },
        listItemTextActive: {
            fontSize: "13px !important",
            color: '#5e35b1 !important',
            fontWeight: 'bold'
        },
        listItemTextInActive: {
            fontSize: "13px !important",
            color: 'rgb(95, 116, 141)',
            fontWeight: 'bold'
        },
        scrolbar: {
            minHeight: 'calc(100vh - 135px)',
            maxHeight: 'calc(100vh - 135px)'
        },
        coureViewScrolbar: {
            minHeight: 'calc(100vh - 150px)',
            maxHeight: 'calc(100vh - 150px)'
        },
        scrolbar1: {
            minHeight: 'calc(100vh - 65px)',
            maxHeight: 'calc(100vh - 65px)'
        },
        linearProgress: {
            //borderRadius: '20px !important',
            borderStyle: '-moz-initial !important',
            color: '#ffbd20 !important',
        },
        //course View
        iconCSS: {
            marginTop: '10px',
            color: '#f7a614'
        },
        YesButton: {
            backgroundColor: "green",
            color: "white",
            borderRadius: "20px"
        },
        NoButton: {
            backgroundColor: "red",
            color: "white",
            borderRadius: "20px"
        },
        nextBackButton: {
            height: "30px",
            marginTop: "6px !important",
            marginLeft: "10px"
        },

        socialIcons1: {
            display: "flex",
            width: "100%",
            justifyContent: "end",
            paddingBottom: "0px",
            paddingTop: "0px",
        },
        socialIconStyle1: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly !important",
            fontSize: "1.4rem",
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            cursor: "pointer",
            marginBottom: "10px",
            boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
            marginLeft: "10px !important"
        },
        courseContainer: {
            [theme.breakpoints.up("lg")]: {
                maxHeight: "calc( 100vh - 70px) !important",
                minHeight: "calc( 100vh - 70px) !important",
            },
        },
        VideoProgress: { width: '100%', borderRadius: '5px', marginBottom: '10px' },
        viewcorseIcon: { marginTop: '10px !imporant', color: '#f7a614 !important' },
        iconFontSize: {
            fontSize: '15px !important'
        },
        progressFontStyle: {
            fontWeight: 'bold'
        },
        certificateButtonColor: {
            color: "purple !important"
        },
        downloadStyles: {
            mt: 1,
            width: '40px !important',
            height: "33px !important",
            right: "20px",
            backgroundColor: "green !important",
            borderRadius: '8px !important',
            transition: 'border-radius 0.3s !important',
            '&:hover': {
                backgroundColor: "#5e35b1 !important",
                border: "2px solid black !important",
            },
        },
        progressCompletedDiv: {
            display: 'flex !important',
            alignItems: 'center !important'
        },
        completedIconStyle: {
            color: 'green !important',
            marginRight: '8px !important'
        },
        pinIconStyle:{
           fontSize: '15px !important'
        },
        certificateStyle:{
            width:"938px", 
            height:"585px", 
            padding:"0px"
        },
        borderTop1:{
            borderTop:'1px dashed lightgray'
        },
        dialogTitle:{
            width: '500px',
            height: '300px' 
        },
        downloadButton: {
            float: 'right', 
            '&:hover': {
              backgroundColor: '#1976d2 !important',
              color: 'white !important',
              '& .MuiSvgIcon-root': {
                color: 'white',
              }
            },
        },
    })
);

export default useStyles