import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { AppBar, Grid, IconButton, Typography } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import React, { useState } from 'react';
import ThemeMockData, { IThemeCssData } from '../../_mock/themebg';
import { NavbarHeaderProps } from '../../model/course';
import ToolbarProfile from './Header/Toolbar';
import useStyles from './NavbarCss';
import NavigationDrawer from './NavigationDrawer';

const NavbarHeader: React.FC<NavbarHeaderProps> = (props) => {
    const { toggleSidebar, admin, emailAddress } = props;
    const classes = useStyles();
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState<boolean>(false);
    const [selectedTheme, setSelectedTheme] = useState<IThemeCssData | null>(null);

    // Function to handle theme selection
    const handleSelectTheme = (theme: IThemeCssData) => {
        setSelectedTheme(theme);
    };

    const closeMobileDrawer = () => {
        setMobileDrawerOpen(false);
    }
    return (
        <div className={classes.grow}>
            <AppBar position="sticky" className={classes.appBar} sx={{
                backgroundImage: `url(${selectedTheme?.backgroundImage})`,
                backgroundColor: selectedTheme?.backgroundImage ? 'transparent' : selectedTheme?.backgroundColor || '#FFFFFF'
            }}>
                {/* <Toolbar className={classes.toolBar}> Future use*/}
                {admin ? <Hidden mdUp>
                    <IconButton
                        edge="start"
                        className={`${classes.menuButton} ${classes.iconButton}`}
                        color="inherit"
                        aria-label="open drawer"
                        size='small'
                        sx={{ marginLeft: '10px' }}
                        onClick={() => setMobileDrawerOpen(true)}
                    >
                        <MenuOpenIcon color='primary' fontSize='small' />
                    </IconButton>
                    <NavigationDrawer
                        open={mobileDrawerOpen}
                        onClose={closeMobileDrawer}
                    />
                </Hidden> : null}
                <Grid container spacing={0} >
                    <Grid item xs={7} display={'flex'}  md={7}>
                        {admin ?
                            <Hidden mdDown>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    // onClick={handleDrawerToggle}
                                    edge="start"
                                    size='small'
                                    onClick={toggleSidebar}
                                    sx={{ marginLeft: '10px' }}
                                /* Future use */
                                // sx={{ ml: 2 }}
                                // className={classes.iconButton}
                                >
                                    <MenuOpenIcon fontSize='medium' htmlColor={selectedTheme?.themeId ? selectedTheme?.color : '#5e35b1'} />
                                </IconButton>
                                <Typography variant='h6' fontWeight={800} color={selectedTheme?.themeId ? selectedTheme?.color : '#5e35b1'} mt={0.5} ml={0.5} sx={{
                                    fontFamily: 'Bookman Old Style, Georgia, serif'
                                }}>
                                    <b>Merry Learn</b>
                                </Typography>

                                {/* Future use */}
                                {/* <IconButton className={classes.logoIcon}>
                                        <img src={logo} alt="company-logo" width="125px" height="30px" style={{ marginTop: "0px" }} />
                                    </IconButton> */}
                            </Hidden>
                            :
                            <Typography variant='h6' fontWeight={800} color={selectedTheme?.themeId ? selectedTheme?.color : '#5e35b1'} mt={1} ml={1} sx={{
                                fontFamily: 'Bookman Old Style, Georgia, serif'
                            }}>
                                <b>Merry Learn</b>
                            </Typography>
                            /* Future use */
                            // <IconButton className={classes.logoIcon}>
                            //     <img src={logo} alt="company-logo" width="125px" height="30px" style={{ marginTop: "0px" }} />
                            // </IconButton>
                        }
                    </Grid>
                    <Grid item xs={12} md={5} >
                        <ToolbarProfile emailAddress={emailAddress} userRole={admin} themes={ThemeMockData} selectedTheme={selectedTheme} handleSelectTheme={handleSelectTheme} switchUser={props.switchUser} />
                    </Grid>
                </Grid>
            </AppBar>
        </div >
    );
}

export default NavbarHeader;
