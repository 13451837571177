/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Container, Divider, Hidden, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { IRoute } from '../../Routes';
import { Dashboard, Management, QueryDetails } from '../../constant/Constant';
import useStyles from './NavbarCss';
import { useGlobalState } from '../../GlobalStore/globalStore';

const Sidebar: React.FC<{ routes: IRoute[], admin: number; sidebarVisible: boolean, toggleSidebar: () => void; }> = (props) => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const { routes, sidebarVisible, admin } = props;
    const [open, setOpen] = useState<number | null>(null);
    const location = useLocation();
    const drawerWidthIn = "240px";
    const drawerWidthOut = "72px";

    const handleClick = (index: number) => {
        if (open === index) {
            setOpen(null);
        } else {
            setOpen(index);
        }
    };

    // Function to check if the route is active
    const isActiveRoute = (route: IRoute) => {
        return location.pathname === route.path;
    };

    const renderMenuItems = (groupName: string) => (
        routes?.filter(route => route.groupName === groupName && route.noNavLink)?.map((route, index) => (
                <ListItem className={classes.listItemMui} key={index}>
                    <ListItemButton disabled={route.ComponentNo === 4 && !state?.userAccessContext?.isManageUser}
                        className={isActiveRoute(route) ? classes.listItemActive : classes.listItemInactive}
                        component={Link as React.ElementType} to={route.path}
                        onClick={() => handleClick(index)}
                    >
                        <Tooltip placement='right' title={!sidebarVisible ? route?.title : null} arrow>
                            <ListItemIcon sx={{ minWidth: "40px !important", padding: !sidebarVisible ? "5px 1px" : null }}>
                                {route.icon && <route.icon fontSize="small" color='primary'
                                    className={isActiveRoute(route) ? classes.listItemIconActive : classes.listItemIconInActive} />}
                            </ListItemIcon>
                        </Tooltip>
                        {sidebarVisible ?
                            <ListItemText>
                                <Typography variant='body2' sx={{ fontWeight: isActiveRoute(route) ? 600 : "bold" }} className={isActiveRoute(route) ? classes.listItemTextActive : classes.listItemTextInActive} >
                                    {route.title}
                                </Typography>
                            </ListItemText>
                            : null}
                    </ListItemButton>
                </ListItem>
            ))
    );

    return (
        <Box m={0} p={0} display="flex">
            <Hidden smDown>
                <List className={classes.listMui} sx={{ width: sidebarVisible ? drawerWidthIn : drawerWidthOut }}>
                    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.scrollbarContainer} >
                        {sidebarVisible ?
                            <Typography variant='body2' sx={{ padding: 1 }} fontSize={'12px'} color={'rgb(95, 116, 141)'}><b>DASHBOARD</b></Typography> : null}
                        {renderMenuItems(Dashboard)}
                        <Divider variant="middle" component="li" sx={{ margin: 1 }} />
                        {sidebarVisible ?
                            <Typography variant='body2' sx={{ padding: 1 }} fontSize={'12px'} color={'rgb(95, 116, 141)'}><b>MANAGEMENT</b></Typography> : null}
                        {renderMenuItems(Management)}
                        <Divider variant="middle" component="li" sx={{ margin: 1 }} />
                        {sidebarVisible ?
                            <Typography variant='body2' sx={{ padding: 1 }} fontSize={'12px'} color={'rgb(95, 116, 141)'}><b>QUERY DETAILS</b></Typography> : null}
                        {renderMenuItems(QueryDetails)}
                    </Scrollbars>
                </List >
            </Hidden>
            <Container component="main" className={classes.contentContainer} maxWidth="xl">
                <Box p={1.5}>
                    <Switch>
                        {routes?.map((route: IRoute) => (
                            <Route exact={true} path={route.path} key={route.path} component={route.component as any} />
                        ))}
                        {admin ?
                            <Redirect from="/" to="/course" />
                            : null}
                    </Switch>
                </Box>
            </Container >
        </Box >
    );
};

export default Sidebar;
