import { Button, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react'
import useStyles from '../ProfileCardCss';
import StarIcon from '@mui/icons-material/Star';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import job from '../../../../asset/image/Profile/job.svg';
import Scrollbars from 'react-custom-scrollbars';
import { ProfileCustomLiner } from './ProfileCustomLiner';
import { Box } from '@mui/system';
import JobMockData from '../../../../_mock/Users/JobMockData.json';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface JobDetailsProps {
    onClose: () => void;
    job: any;
}

const JobDetails: React.FC<JobDetailsProps> = (props) => {
    const classes = useStyles();

    function LinearProgressWithLabel(props) {
        return (
            <Box display="flex" alignItems="center" p={0}>
                <Box width="100%" mr={3}>
                    <ProfileCustomLiner variant="determinate" {...props} color='primary' />
                </Box>
                <Box minWidth={25}>
                    <Typography variant="body2" color="textSecondary"><b>{`${Math.round(
                        props?.value
                    )}%`}</b></Typography>
                </Box>
            </Box>
        );
    }
    return (
        <React.Fragment>
            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.ViewDetailScrollbar}>
                <Grid container spacing={2} pt={3}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={0.5} p={2} m={0} borderRadius={3} height={"240px"} border={'1px solid rgb(238, 242, 246)'} bgcolor={'white'} className={classes.jobCard} ml={2}>
                            <Grid item xs={10}>
                                <Typography variant='h6' gutterBottom color="#5e35b1">
                                    <b>{JobMockData?.jobTitle}</b>
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {JobMockData?.company?.name} |<StarIcon sx={{ fontSize: "x-small" }} />{JobMockData?.company?.rating}
                                </Typography>
                                <Typography variant='body1' gutterBottom display={"flex"}>
                                    <WorkIcon fontSize='small' />{JobMockData?.experience}
                                </Typography>
                                <Typography variant='body1' gutterBottom display={"flex"}>
                                    <LocationOnIcon fontSize='small' /> {JobMockData?.location}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} display={"flex"} justifyContent={"flex-end"}>
                                <img src={job} alt="Job Logo" width={'200px'} height={"150px"} />
                            </Grid>
                            <Grid item xs={10} borderTop={"1px solid black"}>
                                <Typography variant='body2' gutterBottom pt={0}>
                                    <b>Posted:{JobMockData?.postedAgo} | Opening : {JobMockData?.openings} | Applicant: {JobMockData?.applicants}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={2} borderTop={"1px solid black"} display={"flex"} justifyContent={"flex-end"}>
                                <Button variant='contained' size='small' color='secondary' sx={{ height: "28px",  width: "100px" }}>
                                    Apply
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={0.5} p={2} m={0} borderRadius={3} height={"240px"} border={'1px solid rgb(238, 242, 246)'} bgcolor={'white'} className={classes.jobCard}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography variant='h6' gutterBottom color="#5e35b1">
                                        <b>Skill Requirement</b>
                                    </Typography>
                                    <Grid container spacing={2} display={"flex"} justifyContent={"center"}>
                                        <Grid item xs={4}>
                                            <Typography variant='body1' gutterBottom>
                                                <b>React</b>
                                            </Typography>
                                            <Typography variant='body1' gutterBottom>
                                                <b>Javascript</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <LinearProgressWithLabel value={70} />
                                            <LinearProgressWithLabel value={50} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body1' gutterBottom color="#5e35b1">
                                        <b>{JobMockData?.reviews?.title}</b>
                                    </Typography>
                                    <Typography variant='body1' gutterBottom>
                                        {JobMockData?.jobTitle}|<StarIcon sx={{ fontSize: "x-small" }} /> 3.5 Review
                                    </Typography>
                                    <Typography variant='body1' gutterBottom>
                                        {JobMockData?.reviews?.reviewText}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} pt={0.5}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={0.5} p={2} m={0} borderRadius={3} height={"430px"} border={'1px solid rgb(238, 242, 246)'} bgcolor={'white'} className={classes.jobCard} ml={2}>
                            <Grid item xs={12}>
                                <Typography variant='h6' gutterBottom color="#5e35b1">
                                    <b>Job Description</b>
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {JobMockData.jobDescription.text}
                                </Typography>
                                <Typography variant='h6' gutterBottom color="#5e35b1">
                                    <b>About Company</b>
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {JobMockData.aboutCompany.description}
                                </Typography>
                                <Typography variant='h6' gutterBottom color="#5e35b1">
                                    <b>Company Info</b>
                                </Typography>
                                <Typography variant='body1'>
                                    {JobMockData?.companyInfo?.address}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={0.5} p={2} m={0} borderRadius={3} height={"430px"} border={'1px solid rgb(238, 242, 246)'} bgcolor={'white'} className={classes.jobCard}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography variant='h6' gutterBottom color="#5e35b1">
                                        <b>Quality Assurance</b>
                                    </Typography>
                                    <Grid container spacing={2} display={"flex"} justifyContent={"center"}>
                                        <Grid item xs={6}>
                                            <Typography variant='body1' gutterBottom>
                                                Role
                                            </Typography>
                                            <Typography variant='body1' gutterBottom>
                                                Industry Type
                                            </Typography>
                                            <Typography variant='body1' gutterBottom>
                                                Department
                                            </Typography>
                                            <Typography variant='body1' gutterBottom>
                                                Employment Type
                                            </Typography>
                                            <Typography variant='body1' gutterBottom>
                                                Role Category
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant='body1' gutterBottom>
                                                {JobMockData?.qualityAssurance.role}
                                            </Typography>
                                            <Typography variant='body1' gutterBottom>
                                                {JobMockData?.qualityAssurance.industryType}
                                            </Typography>
                                            <Typography variant='body1' gutterBottom>
                                                {JobMockData?.qualityAssurance.department}
                                            </Typography>
                                            <Typography variant='body1' gutterBottom>
                                                {JobMockData?.qualityAssurance.employmentType}
                                            </Typography>
                                            <Typography variant='body1' gutterBottom>
                                                {JobMockData?.qualityAssurance.roleCategory}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' gutterBottom color="#5e35b1">
                                                Requirement for the Candidate Profile
                                            </Typography>
                                            <List>
                                                {JobMockData?.qualityAssurance?.candidateRequirements?.map((requirement, index) => (
                                                    <ListItem key={index}>
                                                        <ListItemIcon>
                                                            <CheckBoxIcon fontSize='small' />
                                                        </ListItemIcon>
                                                        <ListItemText primary={requirement} />
                                                    </ListItem>
                                                ))}
                                            </List>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Scrollbars>
        </React.Fragment>
    )
}

export default JobDetails;