import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

const CourseProgressChart = (props) => {

    const { CourseData } = props;
    const courseNames = CourseData?.map(course => course.courseCode);
    const courseProgress = CourseData?.map(course => Math.min(course.courseCompletionPercentage, 100));


    const getColor = (progress: number): string => {
        if (progress >= 90) return '#0033cc';
        if (progress >= 70) return '#4caf50';
        if (progress >= 50) return '#ffeb3b';
        return '#f44336';
    };

    const colors = courseProgress.map(progress => getColor(progress));
    const options: ApexOptions = {
        chart: {
            height: 300,
            type: 'bar',
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: (val: number) => `${val}%`,
        },
        xaxis: {
            categories: courseNames,
            max: 100,
        },
        title: {
            text: 'Weekly Course Progress',
        },
        colors: colors,
    };
    const series = [{
        name: 'Progress',
        data: courseProgress.map((progress, index) => ({
            x: courseNames[index],
            y: progress,
            fillColor: getColor(progress),
        }))
    }];

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="bar" height={450} />
        </div>
    );
};
export default CourseProgressChart;