/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
//React
import { CSSProperties, useEffect, useRef, useState } from 'react';
//Mui Components
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import ImageIcon from '@mui/icons-material/Image';
import LinkIcon from '@mui/icons-material/Link';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField, Toolbar, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Scrollbars from 'react-custom-scrollbars';
//Richtext editor Draft Js imports
import { AtomicBlockUtils, ContentBlock, DraftInlineStyle, Editor, EditorState, Modifier, RichUtils, convertFromRaw, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
//Global Constants
import { COLOR_STYLE_MAP, INLINE_STYLES } from '../../constant/Constant';
//Style components
import React from 'react';
import useStyles from "../UserCourseView/CourseLab/LabCss";

interface RichTextEditorProps {
  onChange: (value: string) => void;
  value: any;
}
const RichTextEditor: React.FC<RichTextEditorProps> = React.memo(function RichTextEditor(props) {
  const classes = useStyles();
  const [showLinkDialog, setShowLinkDialog] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const editorRef = useRef<Editor>(null);
  const isInitialized = useRef(false);
  const cursorPosition = useRef(null);

  useEffect(() => {
    console.log(props.value);
    if (!isInitialized.current && props.value) {
      const rawContent = JSON.parse(props.value);
      const contentState = convertFromRaw(rawContent);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
      isInitialized.current = true;
    } else if (props.value && cursorPosition.current !== null) {
      const rawContent = JSON.parse(props.value);
      const contentState = convertFromRaw(rawContent);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(EditorState.forceSelection(newEditorState, cursorPosition.current));
      cursorPosition.current = null; // Reset cursor position after restoring
    }
  }, [props.value]);

  const handleChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawContent = JSON.stringify(convertToRaw(contentState));
    props.onChange(rawContent); // Pass the raw content string to the parent component
    console.log(rawContent);
  };


  useEffect(() => {
    const timeoutId = requestAnimationFrame(() => {
      if (editorRef.current) {
        editorRef.current.focus();
      }
    });

    return () => cancelAnimationFrame(timeoutId);
  }, [editorState]);

  const handleBeforeInput = (chars, editorState) => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const newState = RichUtils.handleKeyCommand(editorState, 'backspace');
      if (newState) handleChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const toggleStyle = (style: string) => {
    handleChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  const toggleTextColor = (color: string) => {
    const selection = editorState.getSelection();
    const nextContentState = Object.keys(COLOR_STYLE_MAP).reduce((contentState, style) => {
      return Modifier.removeInlineStyle(contentState, selection, style);
    }, editorState.getCurrentContent());

    let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');

    const currentStyle = editorState.getCurrentInlineStyle();

    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, style) => {
        return RichUtils.toggleInlineStyle(state, style);
      }, nextEditorState);
    }

    if (!currentStyle.has(color)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, color);
    }

    handleChange(nextEditorState);
  };

  const handleKeyCommand = (command: string) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      handleChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };


  const customStyleFn = (style: DraftInlineStyle, block: ContentBlock): CSSProperties => {
    const colorStyle = COLOR_STYLE_MAP[style.toString()];
    return colorStyle ? colorStyle : {};
  };

  const toggleAlignment = (alignment: string) => {
    let blockType;
    switch (alignment) {
      case 'h1':
        blockType = 'header-one';
        break;
      case 'h2':
        blockType = 'header-two';
        break;
      case 'h3':
        blockType = 'header-three';
        break;
      case 'none':
        blockType = 'unstyled';
        break;
      default:
        blockType = 'unstyled';
    }

    const newEditorState = RichUtils.toggleBlockType(editorState, blockType);
    console.log('New editor state:', newEditorState);

    handleChange(newEditorState);
  };
  const blockRenderer = (contentBlock: ContentBlock) => {
    const type = contentBlock.getType();

    // Handle atomic blocks
    if (type === 'atomic') {
      // Check if the atomic block is an image
      if (type.startsWith('image/')) {
        return {
          component: ImageComponent,
          editable: false,
        };
      } else {
        // For other atomic blocks, render as FileComponent
        return {
          component: FileComponent,
          editable: false,
        };
      }
    } else if (type === 'unstyled') {
      // Handle unstyled blocks, which may contain links
      const contentState = contentBlock.getData().get('contentState');
      if (contentState) {
        const entityKey = contentBlock.getEntityAt(0);

        // Check if the block contains a link entity
        if (entityKey) {
          const entity = contentState.getEntity(entityKey);
          if (entity) {
            const entityType = entity.getType();

            // Render the block as LinkComponent if it contains a link entity
            if (entityType === 'LINK') {
              // Pass entityKey to LinkComponent
              return {
                component: LinkComponent,
                editable: false,
                props: {
                  entityKey: entityKey, // Pass the entityKey as a prop
                },
              };
            }
          }
        }
      }
    }

    return null;
  };


  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('atomic', 'IMMUTABLE', {
          src: fileData,
          type: file.type,
          name: file.name,
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
          currentContent: contentStateWithEntity,
        });
        setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
      };
      reader.readAsDataURL(file);
    }
  };
  const handleInsertLink = () => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: linkUrl });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.push(
      editorState,
      contentStateWithEntity,
      'apply-entity'
    );
    setEditorState(RichUtils.toggleLink(
      newEditorState,
      selection,
      entityKey
    ));
    setLinkUrl('');
    setShowLinkDialog(false);
  };
  
  const LinkComponent = ({ contentState, entityKey, children }: any) => {
    if (!entityKey) {
      return null;
    }

    const entity = contentState.getEntity(entityKey);
    if (!entity) {
      return null;
    }

    const { url } = entity.getData();
    return (
      <a href={url} style={{ color: 'blue', textDecoration: 'underline' }}>
        {children}
      </a>
    );
  };


  const handleDownload = (src: string) => {
    window.open(src, '_blank');
  };

  const ImageComponent = ({ block, contentState }) => {
    const entity = contentState.getEntity(block.getEntityAt(0));
    const { src } = entity.getData();
    return <img src={src} style={{ width: '70%' }} />;
  };

  const FileComponent = ({ block, contentState }) => {
    const entityKey = block.getEntityAt(0);
    if (!entityKey) {
      return null;
    }

    const entity = contentState.getEntity(entityKey);
    const { src, name } = entity.getData();

    return (
      <Grid
        item
        xs={6}
        component={Paper}
        border={'1px solid #ccc'}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Typography variant="caption" m={1}>
          File Name: {name}
        </Typography>
        <IconButton onClick={() => handleDownload(src)}>
          <CloudDownloadIcon color="success" fontSize="small" />
        </IconButton>
      </Grid>
    );
  };

  return (
    <Grid>
      <Grid item xs={12} borderBottom={'2px solid #ccc'}>
        <Toolbar>
          <IconButton size="small" onClick={() => toggleTextColor('red')}>
            <FormatColorTextIcon style={{ color: 'red' }} />
          </IconButton>
          <IconButton size="small" onClick={() => toggleTextColor('blue')}>
            <FormatColorTextIcon style={{ color: 'blue' }} />
          </IconButton>
          <IconButton size="small" onClick={() => toggleTextColor('green')}>
            <FormatColorTextIcon style={{ color: 'green' }} />
          </IconButton>
          <IconButton size="small" onClick={() => toggleStyle(INLINE_STYLES.bold)}>
            <FormatBoldIcon />
          </IconButton>
          <IconButton size="small" onClick={() => toggleStyle(INLINE_STYLES.italic)}>
            <FormatItalicIcon />
          </IconButton>
          <IconButton size="small" onClick={() => toggleStyle(INLINE_STYLES.underline)}>
            <FormatUnderlinedIcon />
          </IconButton>
          <Button size="small" onClick={() => toggleAlignment('h1')}>
            <Typography variant="subtitle2">h1</Typography>
          </Button>
          <Button size="small" onClick={() => toggleAlignment('h2')}>
            <Typography variant="subtitle2">h2</Typography>
          </Button>
          <Button size="small" onClick={() => toggleAlignment('h3')}>
            <Typography variant="subtitle2">h3</Typography>
          </Button>
          <IconButton size="small" onClick={() => setShowLinkDialog(true)}>
            <LinkIcon />
          </IconButton>
          <IconButton size="small" component="label">
            <ImageIcon />
            <input type="file" accept="*" style={{ display: 'none' }} onChange={handleFileUpload} />
          </IconButton>
        </Toolbar>
      </Grid>
      <Grid p={1}>
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={10}
          className={classes.AdminSessionContainer}
        >
          <Editor
            editorState={editorState}
            onChange={handleChange}
            handleKeyCommand={handleKeyCommand}
            customStyleMap={COLOR_STYLE_MAP}
            customStyleFn={customStyleFn}
            blockRendererFn={blockRenderer} 
            handleBeforeInput={handleBeforeInput}
          />
        </Scrollbars>
      </Grid>
      <Dialog open={showLinkDialog} onClose={() => setShowLinkDialog(false)}>
        <DialogTitle>Insert Link</DialogTitle>
        <DialogContent>
          <TextField
            label="URL"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowLinkDialog(false)}>Cancel</Button>
          <Button onClick={handleInsertLink}>Insert</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
});

export default RichTextEditor;