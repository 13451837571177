/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
//MUI_COMPONENT
import { Fab, Grid, Typography } from "@mui/material";
//STYLE
//ICONS
//IMAGES
import assesment from "../../images/Assesment/assesment.svg"
//REACT_COMPONENTS
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { useHistory } from "react-router-dom";
import nodata from '../../images/Assesment/nodata.svg';
import { usePost } from "../../utils/apiHelper";
import { ICourseAssignment } from "../../model/ICourse";
import { Question } from "../../model/multipleChoiceQuestions";

const baseQuestion: Question[] = [{
  question: "",
  files: [],
  answers: [],
  currentQuestion: { options: [{ checked: false, description: "" }] },
  unsavedChanges: false,
  blobUri: ""
},];

const UserCourseAssessment = ({ courseId, courseTitle, topicId, topicName, subTopicId, subTopicName }) => {
  const history = useHistory();
  const [questions, setQuestions] = useState<Question[]>(baseQuestion);

  const handleAssessmentClick = () => {
    history.push({
      pathname: "/multichoice",
      state: {
        courseId: courseId,
        courseTitle: courseTitle,
        topicId: topicId,
        topicName: topicName,
        subTopicId: subTopicId,
        subTopicName: subTopicName
      }
    });
  };

  useEffect(() => {
    fetchData();
}, []);

  const fetchData = () => {
    (async () => {
        let request = {
            "courseId": courseId,
            "courseTopicId": topicId,
            "courseSubTopicId": subTopicId
        };
        await usePost<ICourseAssignment>("Course/GetCourseAssignment", request).then((result) => { 
            if (Array.isArray(result.data)) {
                const updatedQuestions = result.data.flatMap((assignment: any) => {
                    let newAssignmentList = JSON.parse(assignment?.assignmentDescription);
                    if (Array.isArray(newAssignmentList)) {
                        const assignmentDescriptionArray = newAssignmentList;
                        const questions = assignmentDescriptionArray.map((question?: any) => ({
                            question: question?.question,
                            files: [],
                            answers: question?.options?.map((option?: any) => option?.Option?.answer),
                            currentQuestion: {
                                options: question?.options?.map((option?: any) => ({
                                    checked: option?.Option?.answer === question?.selectedAnswer,
                                    description: option?.Option?.description,
                                })),
                            },
                            selectedAnswer: question?.selectedAnswer,
                            unsavedChanges: false,
                            blobUri: question?.blobUri,
                        }));
                        return questions;
                    } else {
                        return [];
                    }
                });
                const flattenedQuestions = updatedQuestions.flat();
                setQuestions(flattenedQuestions);
            }
        }).finally(() => {
        });
    })()
};
  if (!questions?.length) {
    return (
      <Grid container justifyContent="center" alignItems="center" mt={20}>
        <img src={nodata} alt="add a topic" width={'400px'} height={'300px'} />
        <Typography variant="body1" textAlign="center">
          No assignments are available for this {topicName} {subTopicName}
        </Typography>
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <Grid xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'} height={500}>
        <Grid xs={12} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Grid>
            <Typography variant="h5">
              <h1>Are you ready to Start</h1>
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="h5">
              <h1>Assesment</h1>
            </Typography>
          </Grid>
          <Grid>
            <Fab color="secondary" variant="extended" onClick={handleAssessmentClick}>
              <DirectionsRunIcon sx={{ mr: 1 }} />
              <b>I'm Ready</b>
            </Fab>
          </Grid>
        </Grid>
        <Grid>
          <img src={assesment} alt="assesment" height={400} width={400} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default UserCourseAssessment;