/* eslint-disable @typescript-eslint/no-unused-vars */
import { Backdrop, Box, Button, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import award from '../../../images/course/certificate/award.png';
import certificate1bg from '../../../images/course/certificate/certificate1-1.png';
import certificate1 from '../../../images/course/certificate/certificate1.png';
import certificate2bg from '../../../images/course/certificate/certificate2-1.png';
import certificate2 from '../../../images/course/certificate/certificate2.png';
import certificate3bg from '../../../images/course/certificate/certificate3-1.png';
import certificate3 from '../../../images/course/certificate/certificate3.png';
import certificate4bg from '../../../images/course/certificate/certificate4-1.png';
import certificate4 from '../../../images/course/certificate/certificate4.png';
import sign from '../../../images/course/certificate/sign.png';
import logo from '../../../images/logo/logo.png';
import { usePost } from '../../../utils/apiHelper';
import google from "../../../asset/image/Course/google.svg";
import facebook from "../../../asset/image/Course/facebook.svg";
import linkedin from "../../../asset/image/Course/linkedin.svg";
import microsoft from "../../../asset/image/Course/microsoft.svg";
import twitter from "../../../asset/image/Course/twitter.svg";
import useStyles from '../../MyCourse/MyCourseCss';
import Scrollbars from 'react-custom-scrollbars';
import { useGlobalState } from '../../../GlobalStore/globalStore';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { MoonLoader } from 'react-spinners';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import DownloadIcon from '@mui/icons-material/Download';

const certificateList = [
    {
        label: "Certificate 1",
        icon: certificate1,
        bgImage: certificate1bg
    },
    {
        label: "Certificate 2",
        icon: certificate2,
        bgImage: certificate2bg
    },
    {
        label: "Certificate 3",
        icon: certificate3,
        bgImage: certificate3bg
    },
    {
        label: "Certificate 4",
        icon: certificate4,
        bgImage: certificate4bg
    }
];

const UserCertificate = (props) => {
    const { state } = useGlobalState();
    const [loading, setloading] = useState(false);
    const [bgImageUrl, setBgImageUrl] = useState<string>("");
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const [userName, setUserName] = useState("");
    const certificateRef = useRef(null);
    const { courseTitle, courseId } = props?.location?.state;
    const [issuedDate, setIssuedDate] = useState("");

    useEffect(() => {
        const getIssuedDate = () => {
            const date = new Date();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        };

        setIssuedDate(getIssuedDate());
    }, []); 

    useEffect(() => {
        if (location.state && location.state.userName) {
            setUserName(location.state.userName);
        }
    }, [location.state]);

    useEffect(() => {
        (async() => {
            setloading(true);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            await usePost<any>(`UserCourse/GetCertificateTemplate?courseId=${courseId}`, {}).then((r) => {
                const receivedCertificateName = (r?.data?.certificateName);
                    if(receivedCertificateName){
                        const selectedTemplate  = certificateList.find(r => r?.label === receivedCertificateName);
                        if(selectedTemplate) {
                            setBgImageUrl(selectedTemplate?.bgImage);
                        }
                        else {
                            console.error(`Certificate with name not found in the certificate list.`);
                        }
                    } else {
                        console.error(`Certificate name not received from the API.`);
                    }
            })
        })().finally(() => {
            setloading(false);
        })
    }, [])

    const handleNext = () => {
        history.push("/myCourse", { state });
    };

    const downloadPdf = () => {
        html2canvas(certificateRef.current).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'landscape',
                unit: 'px',
                format: [canvas.width, canvas.height]
            });
            pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
            pdf.save(`${userName}-certificate.pdf`);
        });
    };

    return (
        <React.Fragment>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
            </Backdrop>
            <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={10}
                className={classes.courseContainer}
            >
                <Grid container spacing={0} justifyContent="center" alignItems="center">
                    <Grid item xs={6}>
                        <Button variant='text' onClick={handleNext} startIcon={<ArrowCircleLeftIcon fontSize='small' />}>Go to My course</Button>
                    </Grid>
                    <Grid item xs={6} >
                        <div className={classes.socialIcons1}>
                            <span className={`${classes.socialIconStyle1} ${classes.googleStyle}`}>
                                <img src={google} alt="google" className={classes.image} />
                            </span>
                            <span className={`${classes.socialIconStyle1} ${classes.facebookStyle}`}>
                                <img src={facebook} alt="facebook" className={classes.image} />
                            </span>
                            <span className={`${classes.socialIconStyle1} ${classes.microsoftStyle}`}>
                                <img src={microsoft} alt="microsoft" className={classes.image} />
                            </span>
                            <span className={`${classes.socialIconStyle1} ${classes.twitterStyle}`}>
                                <img src={twitter} alt="twitter" className={classes.image} />
                            </span>
                            <span className={`${classes.socialIconStyle1} ${classes.linkedinStyle}`}>
                                <img src={linkedin} alt="linkedIn" className={classes.image} />
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Box m={0}>
                            <Grid container spacing={0} m={0} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <div className={`outer-container ${classes.certificateStyle}`} ref={certificateRef} >
                                    <div className="inner-container" style={{ backgroundImage: `url(${bgImageUrl})` }}>

                                        <Grid container spacing={0} m={0} p={"50px 200px"} >
                                            <Grid item xs={12} >
                                                <Typography variant='h4' mt={0} textAlign={'center'} fontFamily={'Open Sans, sans-serif'} >
                                                    <img src={logo} alt="logo" width={'150px'} height={'40px'} />
                                                </Typography>
                                                <Typography variant='h5' fontSize={30} fontWeight={600} mt={3} textAlign={'center'} fontFamily={'times new roman'} letterSpacing={1}>
                                                    <b>CERTIFICATE OF COMPLETION</b>
                                                </Typography>

                                                <Typography variant='h6' fontSize={17} fontWeight={200} mt={2} textAlign={'center'} fontFamily={'times new roman'} letterSpacing={1} color={'gray'}>
                                                    THIS CERTIFICATE IS PRESENTED TO
                                                </Typography>

                                                <Typography variant='h5' fontSize={42} fontWeight={600} mt={2} textAlign={'center'} color={'#032F66'} letterSpacing={4} fontFamily={'Dancing Script, cursive'} className='fontStyle'>
                                                    {userName}
                                                </Typography>

                                                <Typography variant='h5' fontSize={15} fontWeight={200} mt={2} textAlign={'center'} letterSpacing={1} fontFamily={'times new roman'} >
                                                    has successfully completed the online Course
                                                </Typography>

                                                <Typography variant='h5' fontSize={22} fontWeight={600} mt={2} textAlign={'center'} letterSpacing={1}>
                                                    <b>{courseTitle}</b>
                                                </Typography>

                                                <Typography variant='h6' fontSize={15} fontWeight={200} mt={2} textAlign={'center'} letterSpacing={0} fontFamily={'times new roman'} >
                                                    This Professional has demonstrated initiative and a commitment to deepending their skills and advancing their career. Well done!
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5} mt={1}>
                                                <Typography variant='h6' fontSize={15} fontWeight={400} ml={0} mt={2} textAlign={'center'} letterSpacing={0} fontFamily={'times new roman'} padding={"0px 50px 0px 50px"} >
                                                    <b>{issuedDate}</b>
                                                </Typography>
                                                <Typography variant='h6' fontSize={15} fontWeight={400} ml={0} mt={1} textAlign={'center'} letterSpacing={0} fontFamily={'times new roman'} padding={"0px 0px 0px 20px"} >
                                                    <Divider />
                                                </Typography>
                                                <Typography variant='h6' fontSize={15} fontWeight={500} ml={0} mt={1} textAlign={'center'} letterSpacing={0} fontFamily={'times new roman'}>
                                                    <b>DATE OF ISSUE</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} >
                                                <img src={award} alt="award" />
                                            </Grid>
                                            <Grid item xs={5} >
                                                <Typography variant='h6' fontSize={15} fontWeight={400} ml={0} mt={2} mr={2} textAlign={'center'} letterSpacing={0} fontFamily={'times new roman'} padding={"0px 50px 0px 50px"}>
                                                    <img src={sign} alt="sign" width={'80px'} height={'20px'} />
                                                </Typography>
                                                <Typography variant='h6' fontSize={15} fontWeight={400} ml={0} mt={1} textAlign={'center'} letterSpacing={0} fontFamily={'times new roman'} padding={"0px 20px 0px 0px"} >
                                                    <Divider />
                                                </Typography>
                                                <Typography variant='h6' fontSize={14} fontWeight={500} mt={1} textAlign={'left'} letterSpacing={0} fontFamily={'times new roman'} >
                                                    <b>DR. PETER KULANDAI RAJ K</b>
                                                </Typography>
                                                <Typography variant='h6' fontSize={12} fontWeight={500} mt={0} textAlign={'left'} letterSpacing={0} fontFamily={'times new roman'} >
                                                    <b>CEO, COGNULTSOFT</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Box >
                    </Grid >
                    <Grid item xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'} mt={'15px'} padding={'2px'}>
                        <Button onClick={downloadPdf} variant="outlined" className={classes.downloadButton} startIcon={<DownloadIcon fontSize='small' color='primary' />}>
                            Download Certificate
                        </Button>
                    </Grid>
                </Grid >
            </Scrollbars>
        </React.Fragment >
    )
}

export default UserCertificate;