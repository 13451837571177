/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */

import {Grid, Typography, makeStyles } from '@material-ui/core'
import vision from '../images/aboutUs/vision.png';
import mission from '../images/aboutUs/mission.png';
import { useState } from 'react';
import { MoonLoader } from 'react-spinners';
import { Backdrop } from '@mui/material';
import { useStyles } from '../components/AboutUs.tsx/AboutUsCss';


const AboutUs = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false); // when making api call make sure to change this false to true

  return (
    <div style={{ padding: 20 ,marginTop:70}}>
      {loading && (
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                  <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
               </Backdrop> 
            )} 
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} >
          <Typography variant="h6" align='center'><b>About Us</b></Typography>
          <Typography variant="body1" align='center' style={{ padding: '15px 40px' }}>
            <i>"At Cognultsoft Analytics, we understand the importance of staying ahead in the tech industry. That's why we've forged strategic partnerships with industry stalwarts like Microsoft, enabling us to offer cutting-edge certificate programs and tailored training sessions designed to meet the evolving needs of professionals and organizations alike.
Our commitment to excellence drives us to continually innovate and refine our training methodologies, ensuring that every individual who walks through our doors emerges empowered with the expertise needed to succeed in their chosen field.
Come, join us on a journey of knowledge, growth, and unparalleled opportunity at Cognultsoft Analytics with Our Products."</i>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} >
          <Grid container spacing={0} className={classes.gridPadding} >
            <Grid item xs={12} sm={12} md={12} lg={2} >
              <img src={vision} width="60px" height="60px" className={classes.aboutGrid}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} >
              <Typography variant="h6" align='left'><b>Our Vision</b></Typography>
              <Typography variant="body1" align='justify'>
                <i>We work hard to offer software development services that add value to our clients’ business.
                  We make you out-stand your competition through reliable and quality-oriented web development services.
                </i>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} >
          <Grid container spacing={0} className={classes.gridPadding}>
            <Grid item xs={12} sm={12} md={12} lg={2} >
              <img src={mission} width="60px" height="60px" className={classes.aboutGrid}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Typography variant="h6" align='left'><b>Our Mission</b></Typography>
              <Typography variant="body1" align='justify'>
                <i>Cognultsoft Analytics Mission is to dedicate our business process and technology innovation know-how,
                  deep industry expertise and worldwide resources to working together with clients to make their businesses stronger.
                </i>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} >
          <Typography variant="h6" align='center'><b>We Care About Your Life For Better Future</b></Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.background}>
        </Grid>
      </Grid>
    </div >
  )
}

export default AboutUs
