import clsx from "clsx";
import React, { useState } from "react";
import ImportUser from "./ImportUser";
import ImportCourse from "./ImportCourse";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import MLearnTooltip from "../../GlobalStyles/ToolTip";
import { Transition } from "../../GlobalStyles/Transition";
import ColorlibConnector from "../../GlobalStyles/ColorlibConnector";
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import {  AppBar, Box, Button, Dialog, DialogContent, Grid,
    IconButton, Step, StepLabel, Stepper, Toolbar, Typography
} from "@mui/material";
import ImportContactsTwoToneIcon from '@mui/icons-material/ImportContactsTwoTone';
import useColorlibStepIconStyles from "../../GlobalStyles/useColorlibStepIconStyles";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';


const ManageImportUserCourse = (props) => {

    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const handleClickOpen = () => { setOpen(true) };

    const handleClose = () => {
        setOpen(false);
        setActiveStep(0);
        props.onCreate();
    };
    const CourseSteps = [
        {
            label: "Import Users",
            icon: <GroupAddTwoToneIcon />,
            component: <ImportUser activeStep={activeStep} setActiveStep={setActiveStep} />
        },
        {
            label: "Import Course",
            icon: <ImportContactsTwoToneIcon />,
            component: <ImportCourse activeStep={activeStep} setActiveStep={setActiveStep} onCloseStepper={handleClose} />

        },
    ];

    function ColorlibStepIcon(props: ColorlibStepIconProps) {

        const { active, completed, pending } = props;
        const classes = useColorlibStepIconStyles();
        const activeStep = props?.index;

        const icons: Record<number, React.ReactNode> = {
            1: activeStep > 0 ? <DoneIcon /> : <GroupAddTwoToneIcon fontSize="small" />,
            2: activeStep > 1 ? <DoneIcon /> : <ImportContactsTwoToneIcon fontSize="small" />,
        };

        return (
            <div className={clsx(classes.stepperRoot, { [classes.active]: active, [classes.completed]: completed, [classes.pending]: pending })} >
                {icons[(props.icon)]}
            </div>
        );
    }

    return (
        <React.Fragment>
            <MLearnTooltip title="Import Users and Course" placement="top">
                <Button
                    size="small"
                    color="success"
                    variant="contained"
                    onClick={handleClickOpen}
                    sx={{ borderRadius: "5px" }}
                >
                    <CloudUploadOutlinedIcon />
                </Button>
            </MLearnTooltip>
            <Dialog fullScreen maxWidth={"xl"} open={open} onClose={handleClose} TransitionComponent={Transition} >
                <AppBar sx={{ position: "relative", minHeight: "50px !important", borderBottom: "1px solid lightgray" }} color="transparent" >
                    <Toolbar sx={{ minHeight: "50px !important", paddingLeft: 1, paddingRight: 1 }} >
                        <Typography sx={{ ml: 1, flex: 1 }} variant="h6" component="div">
                            Import User and course
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ padding: "0px" }}>
                    <Grid container spacing={0} borderRadius={3}>
                        <Grid item xs={12} padding={"10px 0px"} borderBottom={"1px solid lightgray"} >
                            <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />} sx={{ width: "100%" }} >
                                {CourseSteps.map((step, index) => (
                                    <Step key={index}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} >
                                                <Typography variant="body2" color={activeStep >= index ? "#5e35b1" : "gray"} >
                                                    <b>{step?.label}</b>
                                                </Typography>
                                            </Box>
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={12} >
                            {CourseSteps[activeStep].component && (
                                <Typography variant="body2" component="div">
                                    {CourseSteps[activeStep].component}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
export default ManageImportUserCourse;