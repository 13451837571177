/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Profile from './ProfileCard';
import useStyles from './ToolbarCss';
import Notification from './Notification';
import { useHistory } from 'react-router-dom';
import { usePost } from '../../../utils/apiHelper';
import Settings from './Settings/Settings';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import MLearnTooltip from '../../../GlobalStyles/ToolTip';
import { INotificationDetails } from '../../../model/ICourse';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useGlobalState } from '../../../GlobalStore/globalStore';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Box, Grid, IconButton, InputAdornment, Snackbar, TextField, Typography } from '@mui/material';
import FullscreenExitTwoToneIcon from '@mui/icons-material/FullscreenExitTwoTone';
import { useIsAuthenticated } from '@azure/msal-react';

const ToolbarProfile= (props) => {
  const { emailAddress, userRole, themes, selectedTheme, handleSelectTheme } = props;
  let history = useHistory();
  const classes = useStyles();
  const { state } = useGlobalState();
  const isAuthenticated = useIsAuthenticated();
  const [open, setOpen] = useState(true);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [searchDebtor, setSearchDebtor] = useState<string>("");
  const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(false);
  const [notificationDetails, setNotificationDetails] = useState<INotificationDetails[]>([]);



  const handleChange = () => {
    props.switchUser(true);
    setChecked(prevChecked => !prevChecked);
    checked ? history.push('/dashboard') : history.push('/myCourse');
  };

  const handleToggleFullScreen = () => {
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  const handleToggleNotificationDialog = () => {
    setIsNotificationDialogOpen(!isNotificationDialogOpen);
  };
//feature
  const handleGetNotifications = () => {
    (async () => {
      setLoading(true);
      let UserId = state?.userAccessContext?.userId;
      await usePost<INotificationDetails[]>("Notification/GetNotificationRemainders?UserId=" + UserId, UserId).then((r) => [
        setNotificationDetails(r?.data)
      ])
    })().finally(() => {
      setLoading(false);
    })
  }



  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, 5000); // 5000 ms = 5 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const getGreetingMessage = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };
  return (
    <Box p={0}>
      <Grid container spacing={1} pt={0.5}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            id="standard-bare"
            variant="outlined"
            value={searchDebtor}
            className={classes.searchBox}
            type='text'
            sx={{ border: `2px solid ${selectedTheme?.themeId ? 'none' : '#5e35b1'}` }}
            placeholder="Search All"
            onChange={(e) => setSearchDebtor(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter" && searchDebtor !== "") {
                setShowSearchIcon(false);
              } else if (event.key === "Enter" && searchDebtor === "") {
                setShowSearchIcon(true);
              }
            }}
            inputProps={{ maxLength: 260 }}
            InputProps={{
              classes: {
                root: classes.outlinedInput,
                notchedOutline: classes.notchedOutline,
              },
              endAdornment: (
                <InputAdornment position="end">
                  {showSearchIcon === true ?
                    <IconButton size='small' onClick={e => { setShowSearchIcon(false); }} className={classes.searchIcon}>
                      <SearchIcon fontSize='small' />
                    </IconButton>
                    :
                    <IconButton size='small' onClick={(e) => {
                      setShowSearchIcon(true);
                      setSearchDebtor("");
                    }} className={classes.closeIcon}>
                      <CloseIcon fontSize='small' />
                    </IconButton>
                  }
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2} display="flex" justifyContent={"flex-end"}>
            {(state.userAccessContext.isManager || state.userAccessContext.userRole) ? <Grid item xs={2}>
              <MLearnTooltip title={checked ? "Switch to Admin View" : "Switch to Learner View"} placement="top">
                <IconButton
                  size="small"
                  className={classes.switchIcon}
                  aria-label="Switch as Admin and Learner"
                  onClick={handleChange}
                >
                  <ManageAccountsIcon style={{ color: checked ? 'green' : 'inherit' }} className={classes.iconAnimation} />
                </IconButton>
              </MLearnTooltip>
            </Grid> : null}
            <Grid item xs={2}>
              <Notification  />
            </Grid>
            <Grid item xs={2}>
              <Settings themes={themes} selectedTheme={selectedTheme} handleSelectTheme={handleSelectTheme} />
            </Grid>
            <Grid item xs={2}>
              <IconButton size='small' className={classes.iconButtons} onClick={handleToggleFullScreen} aria-label="Full Screen Icon">
                {!isFullScreen ?
                  <MLearnTooltip title={ "Full Screen"} placement="top"><FullscreenIcon fontSize='small' /></MLearnTooltip> 
                  :
                  <MLearnTooltip title={ "Full Exit Screen"} placement="top"><FullscreenExitTwoToneIcon fontSize='small' /></MLearnTooltip>
                }
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <Profile emailAddress={emailAddress} userRole={userRole} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
      open={open} 
      color='green'
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      message={<Typography>{`${getGreetingMessage()}, ${emailAddress}!`}</Typography>}
      action={
        <IconButton size="small" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      autoHideDuration={10000} // 10000 ms = 10 seconds
      sx={{ 
        '& .MuiSnackbarContent-root': {
          backgroundColor: 'green', 
          color: 'white',
        }
      }}
    />
    </Box>
  )
}

export default ToolbarProfile;