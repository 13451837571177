/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FactoryIcon from '@mui/icons-material/Factory';
import LanguageIcon from '@mui/icons-material/Language';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, InputAdornment, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Box } from '@mui/system';
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import ColorlibConnector from '../../../../GlobalStyles/ColorlibConnector';
import MLearnTooltip from '../../../../GlobalStyles/ToolTip';
import { FieldConfig } from '../../../../model/DynamicForms';
import { IEducationalQualification, IExperience, IPrepareCV, IProject, ISkill } from '../../../../model/Users/IUser';
import useStyles from '../ProfileCardCss';
import ColorlibStepIcon from './ColorlibStepIcon';
import { DynamicTextFieldGroup } from './DynamicForms';
import { steps } from './steps';


const Experience = (props) => {
    const classes = useStyles();
    const [expandedIndex, setExpandedIndex] = useState<number>(0);
    const [experience, setExperience] = useState([]);
    const [activeStep, setActiveStep] = useState(3);
    function parseExperienceData(data: any[]): IExperience[] {
        return data?.map(item => ({
            experienceId: 0, 
            experience: item?.experience || "",
            descriptionOfExperience: item?.descriptionOfExperience || "",
            organization: item?.organization || "",
            industry: item?.industry || "",
            location: item?.location || "",
            website: item?.website || "",
            role: item?.role || "",
            dateOfJoining: item?.dateOfJoining || new Date(),
            dateofLeaving: item?.dateofLeaving || new Date(),
            reference: item?.reference || "",
            nameOfReportingManager:item?.nameOfReportingManager,
            referenceDesignation:item?.referenceDesignation,
            referenceMailId:item?.referenceMailId,
            referencePhoneNo:item?.referencePhoneNo,
        }));
    }
    const experienceInformation: IExperience[] = parseExperienceData(props?.location?.state?.experienceInformation);

    const initializeFields = () => {
        if (Array.isArray(experienceInformation) && experienceInformation.length > 0) {
            return experienceInformation.map((data, index) => [
                {
                    index: index,
                    id: 'organization',
                    placeholder: 'Name of the Company',
                    startAdornment: <InputAdornment position="start"><CorporateFareIcon fontSize='small' /></InputAdornment>,
                    value: data.organization || ''
                },
                {
                    index: index,
                    id: 'industry',
                    placeholder: 'Industry',
                    startAdornment: <InputAdornment position="start"><FactoryIcon fontSize='small' /></InputAdornment>,
                    value: data.industry || ''
                },
                {
                    index: index,
                    id: 'location',
                    placeholder: 'Location',
                    startAdornment: <InputAdornment position="start"><AddLocationIcon fontSize='small' /></InputAdornment>,
                    value: data.location || ''
                },
                {
                    index: index,
                    id: 'dateOfJoining',
                    placeholder: 'Date of joining',
                    value: data.dateOfJoining || new Date()
                },
                {
                    index: index,
                    id: 'dateOfLeaving',
                    placeholder: 'Date of Leaving',
                    value: data.dateofLeaving || new Date()
                },
                {
                    index: index,
                    id: 'website',
                    placeholder: 'Website',
                    startAdornment: <InputAdornment position="start"><LanguageIcon fontSize='small' /></InputAdornment>,
                    value: data.website || ''
                },
                {
                    index: index,
                    id: 'role',
                    startAdornment: <InputAdornment position='start'><LanguageIcon fontSize='small' /></InputAdornment>,
                    placeholder: 'Designation',
                    value: data.role || ''
                },
                {
                    index: index,
                    id: 'descriptionOfExperience',
                    startAdornment: <InputAdornment position='start'><ContactMailIcon fontSize='small' /></InputAdornment>,
                    placeholder: 'Responsibilities',
                    value: data?.descriptionOfExperience || ''
                },
                {
                    index:index,
                    id:'nameOfReportingManager',
                    placeholder:`NameOfReportingManager`,
                    startAdornment:<InputAdornment position='start'></InputAdornment>,
                    value:data?.nameOfReportingManager
                },
                {
                    index:index,
                    id:'referenceDesignation',
                    placeholder:" ReportingManager Designation",
                    value:data?.referenceDesignation,
                },
                {
                    index:index,
                    id:'referenceMailId',
                    placeholder:"ReportingManager MailId",
                    value:data?.referenceMailId,
                },
                {
                    index:index,
                    id:"referencePhoneNo",
                    placeholder:"ReportingManager PhoneNo",
                    value:data?.referencePhoneNo
                }
            ]);
        } else {
            return [
                [
                    {
                        index: 0,
                        id: 'organization',
                        placeholder: 'Name of the Company',
                        startAdornment: <InputAdornment position="start"><CorporateFareIcon fontSize='small' /></InputAdornment>,
                        value: ''
                    },
                    {
                        index: 0,
                        id: 'industry',
                        placeholder: 'Industry',
                        startAdornment: <InputAdornment position="start"><FactoryIcon fontSize='small' /></InputAdornment>,
                        value: ''
                    },
                    {
                        index: 0,
                        id: 'location',
                        placeholder: 'Location',
                        startAdornment: <InputAdornment position="start"><AddLocationIcon fontSize='small' /></InputAdornment>,
                        value: ''
                    },
                    {
                        index: 0,
                        id: 'dateOfJoining',
                        placeholder: 'Date of joining',
                        value: ''
                    },
                    {
                        index: 0,
                        id: 'dateOfLeaving',
                        placeholder: 'Date of Leaving',
                        value: ''
                    },
                    {
                        index: 0,
                        id: 'website',
                        placeholder: 'Website',
                        startAdornment: <InputAdornment position="start"><LanguageIcon fontSize='small' /></InputAdornment>,
                        value: ''
                    },
                    {
                        index: 0,
                        id: 'role',
                        placeholder: 'Designation',
                        startAdornment: <InputAdornment position="start"><LanguageIcon fontSize='small' /></InputAdornment>,
                        value: ''
                    },
                    {
                        index: 0,
                        id: 'descriptionOfExperience',
                        placeholder: 'Responsibilities',
                        startAdornment: <InputAdornment position="start"><FactoryIcon fontSize='small' /></InputAdornment>,
                        value: ''
                    },
                    {
                        index:0,
                        id:'nameOfReportingManager',
                        placeholder:`NameOfReportingManager`,
                        startAdornment:<InputAdornment position='start'></InputAdornment>,
                        value:''
                    },{
                        index:0,
                        id:'ReportingManagerdesignation',
                        placeholder:"ReportingManager Designation",
                        value:"",
                    },
                    {
                        index:0,
                        id:'referenceMailId',
                        placeholder:"ReportingManager MailId",
                        value:'',
                    },
                    {
                        index:0,
                        id:"referencePhoneNo",
                        placeholder:"ReportingManager PhoneNo",
                        value:""
                    }
                ]];
        }
    };

    const initialFields = initializeFields();

    const [experienceGroups, setexperienceGroups] = useState<FieldConfig[][]>(initialFields);
    const history = useHistory();
    const projectInformations: IProject[] = props?.location?.state?.projectInformations;
    const profileInformation: IPrepareCV[] = props?.location?.state?.profileInformation;
    const educationInformations: IEducationalQualification[] = props?.location.state?.educationInformations;
    const skillInformation: ISkill[] = props?.location?.state?.skillInformation;


    const addFieldGroup = () => {
        // Create an array of empty fields with the same structure as initialFields
        const emptyFields: FieldConfig[] = initialFields[0].map(field => ({
            ...field,
            value: '' // Set the value to an empty string
        }));
        setexperienceGroups([emptyFields, ...experienceGroups]);
    };

    const removeFieldGroup = (index: number) => {
        const newFieldGroups = [...experienceGroups];
        newFieldGroups.splice(index, 1);
        setexperienceGroups(newFieldGroups);
    };

    const toggleAccordion = (index: number) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const handleFieldValuesChange = (index, updatedFieldValues) => {
        const updatedExperienceInformation = [...experience];
        updatedExperienceInformation[index] = updatedFieldValues;
        setExperience(updatedExperienceInformation);
       
    };

    const handleNextStep = () => {
        let value = experienceInformation;
        history.push("/Project", {
            profileInformation: profileInformation, educationInformations: educationInformations, experienceInformation: experience.length > 0 ? parseExperienceData(experience) : value, skillInformation: skillInformation, projectInformations: projectInformations
        });
    };

    const handleBackStep = () => {
        let value = experienceInformation;
        history.push("/Skills", {
            profileInformation: profileInformation, educationInformations: educationInformations, skillInformation: skillInformation, experienceInformation: experience.length > 0 ? parseExperienceData(experience) : value, projectInformations: projectInformations
        });
    };
    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={2} display={"flex"} justifyContent={"center"} alignItems={"center"} borderRight={"1px solid lightGray"} style={{ backgroundColor: "#7a64a7" }}>
                    <Stepper
                        activeStep={activeStep}
                        orientation='vertical'
                        connector={<ColorlibConnector />}>
                        {steps.map((step, index) => (
                            <Step key={step.label} sx={{ color: "white" }}>
                                <StepLabel StepIconComponent={ColorlibStepIcon} icon={step.icon}>
                                    <b style={{ color: activeStep === index ? "orange" : (index < activeStep ? "limegreen" : "#ccc"), fontSize: activeStep === index ? "18px" : "14px" }}>
                                        {step.label}
                                    </b>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid item xs={10}>
                    {activeStep === 3 && (
                        <Grid container spacing={1} ml={.2}>
                            <Grid item xs={10} pt={6}>
                                <Typography variant='body1' color="#5e35b1"><b>Experience</b></Typography>
                            </Grid>
                            <Grid item xs={2} display={"flex"} justifyContent={"flex-end"}>
                                <MLearnTooltip title="Add" placement="top">
                                    <IconButton
                                        aria-label="Add"
                                        color="primary"
                                        sx={{ mb: "2px" }}
                                        size='small'
                                        onClick={addFieldGroup}
                                        style={{ backgroundColor: "#5e35b1", color: "white" }}>
                                        <AddCircleIcon />
                                    </IconButton>
                                </MLearnTooltip>
                            </Grid>
                            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.educationContainer}>
                                <Grid container spacing={0}>
                                    {experienceGroups.map((fields, index) => (
                                        <Grid item xs={12} key={index} display={"flex"}>
                                            <Grid item xs={11.5}>
                                                <Accordion expanded={index === expandedIndex} onChange={() => toggleAccordion(index)} style={{ marginLeft: "25px" }}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon fontSize='small' />} sx={{ borderBottom: "1px solid lightgrey", backgroundColor: "beige" }}>
                                                        <Typography variant='body2' color={"chocolate"}><b>Experience Information {index + 1}</b></Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <DynamicTextFieldGroup
                                                            key={index}
                                                            index={index}
                                                            fields={fields}
                                                            value={experience[index]}
                                                            onRemove={() => removeFieldGroup(index)}
                                                            OnChange={handleFieldValuesChange} />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                            <Grid item xs={0.5} ml={2} mt={1} >
                                                <IconButton onClick={() => removeFieldGroup(index)} className={classes.JoinIconButton}>
                                                    <MLearnTooltip title="Remove" placement="top">
                                                        <DeleteOutlineIcon fontSize='small' color='error' />
                                                    </MLearnTooltip>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Scrollbars>
                            <Box pt={0.5} width={'100%'} display={'inline-flex'} justifyContent={'flex-end'} borderTop={'1px solid lightgray'}>
                                <Button variant="contained" size="small" color="success" sx={{ marginRight: "20px" }} onClick={handleBackStep}>
                                    <b>Back</b>
                                </Button>
                                <Button variant="contained" size="small" color="success" sx={{ marginRight: "20px" }} onClick={handleNextStep}>
                                    <b>Next</b>
                                </Button>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Experience;