
import React from 'react';
import Box from '@mui/material/Box';
import ApexCharts from 'apexcharts';
import { styled } from '@mui/material/styles';
 interface FeedbackChartData {
    ratings: number[];
}

interface FeedbackChartProps {
    feedbackData: FeedbackChartData;
}

const FeedbackChart: React.FC<FeedbackChartProps> = ({ feedbackData }) => {
    const StyledFeedbackChart = styled('div')({
        '& .apexcharts-legend': {
            overflow: 'hidden',
            fontSize:'18px',
            alignItems: 'center'
        },
    });
    
    const { ratings } = feedbackData;

    React.useEffect(() => {
        const options = {
            chart: {
                type: 'donut',
                height: 200,
                offsetY: 20,
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            formatter: function (w) {
                                const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                                return total;
                            },
                        },
                    },
                },
            },
            labels: ['1 Stars', '2 Stars', '3 Stars', '4 Stars', '5 Star'],
            series: ratings,
            legend: {
                show: true,
            },
            dataLabels: {
                enabled: false,
            },
        };

        const chart = new ApexCharts(document.querySelector('#chart'), options);
        chart.render();

        return () => {
            chart.destroy();
        };
    }, [ratings]);

    return (
        <StyledFeedbackChart>
            <Box sx={{ display: 'flex', alignItems: 'center',mr:'60px' }}> 
                <Box sx={{ml:'50px'}}> 
                    <div id="chart"></div>
                </Box>
            </Box>
        </StyledFeedbackChart>
    );
};

export default FeedbackChart;