import React from "react";
import useStyles from "../ProfileCardCss";
import { Chip, Grid, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import JobMockData from '../../../../_mock/Users/JobMockData.json';

interface Course {
    title: string;
    description: string;
    onViewDetails: () => void; // Add onViewDetails prop
}

const CourseCard: React.FC<Course> = (props) => {
    const { title, onViewDetails } = props;
    const handleViewDetailsClick = () => {
        onViewDetails();
    };

    const classes = useStyles();
    return (
        <Grid item xs={12} md={3}>
            <Grid container spacing={0.5} p={2} m={0} borderRadius={3} border={'1px solid rgb(238, 242, 246)'} bgcolor={'white'} className={classes.courseCard}>
                <Grid item xs={11} borderBottom={'1px dashed lightgray'}>
                    <Typography variant="body2" color="primary"><b>{title}</b></Typography>
                </Grid>
                <Grid item xs={1} borderBottom={'1px dashed lightgray'}>
                    <MoreVertIcon fontSize="small" />
                </Grid>
                <Grid item xs={12} style={{ padding: "5px 0px" }}>
                    <Typography variant="body2" color="textSecondary" style={{ marginTop: 1 }} className={classes.ellipsis}>
                       {JobMockData?.jobDescription?.text}
                    </Typography>
                </Grid>
                <Grid item xs={12} justifyContent={"flex-end"} display={"flex"} borderTop={"1px dashed lightgray"}>
                    <Typography variant="body2" color="gray" textAlign={'end'}>
                        <Chip label="View details" size="small" color="success" onClick={handleViewDetailsClick} />
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CourseCard;
