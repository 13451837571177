/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Completed from "../../images/CompleteCourse/Completed.svg";
import { useHistory, useLocation } from "react-router-dom";
import { usePost } from "../../utils/apiHelper";
import { useGlobalState } from "../../GlobalStore/globalStore";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export default function CourseCompleted() {
    const history = useHistory();
    const location = useLocation();
    const { state } = useGlobalState();
    const { courseId, courseTitle } = location.state;
    const [userName, setUserName] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = () => {

        if (!userName) {
            setError("Please enter your name");
            return;
        }

        (async () => {
            let request = {
                userId: state?.userAccessContext?.userId,
                courseId: courseId,
                userName: userName,
                courseTitle: courseTitle
            }
            await usePost("UserCourse/InsertUserNameAssignedTemplete", request);
        })();
        history.push({
            pathname: "/Certificate",
            state: { userName: userName, courseTitle: courseTitle , courseId: courseId}
        });
    };

    const handleNameChange = (e) => {
        setUserName(e.target.value);
        setError("");
    };

    return (
        <React.Fragment>
            <Grid container spacing={0} alignItems={"center"}>
                <Grid item xs={12} mt={3} ml={50} >
                    <img src={Completed} alt="Course completed " height={400} width={600} />
                </Grid>
            </Grid>
            <Grid container spacing={0} alignItems={"center"}>
                <Grid item xs={12} ml={70} mt={2}>
                    <Typography variant="body2"><b>Enter your name to display in Certificate</b></Typography>
                </Grid>
                <Grid item xs={12} ml={72} mt={2} >
                    <TextField
                        variant="outlined"
                        size="small"
                        name="userName"
                        placeholder="Enter your name"
                        value={userName}
                        onChange={handleNameChange}
                        error={!!error} 
                        helperText={error}
                    />
                    <Button variant="text" color="success" onClick={handleSubmit} endIcon={<ArrowRightAltIcon />}>Continue</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
