export interface ISubscribedUser {
  productSubscriptionId: number;
  userId: number;
  productId: number;
  courseId: number;
  subscribedDate: string;
  expiredDate: string;
  payment: number;
  discount: number;
  transactionId: string;
  paymentStatus: number;
  paymentReceiptUrl: string;
}
export interface IUser {
  userId: number;
  firstName: string;
  lastName: string;
  userName:string;
  managerEmail:string;
  phoneNumber:number;
  userRole: number;
  isSubscribed: boolean;
  isManager:boolean;
  password:string;
  confirmPassword:string;
  createdDate: string;
  modifiedDate: string;
}

export const CourseLevel = [
  { id: 1, label: "Beginner" },
  { id: 2, label: "Intermediator" },
  { id: 3, label: "Expert" },
];

export interface IError {
  errorNumber?: number;
  errorMessage?:string;
  fieldName?: string;
}

export interface IUserInformation {
  userId?: number;
  firstName?: string;
  lastName?: string;
  emailAddress?:string;
  userRole?: number;
  isManager?:boolean;
  phoneNumber?: string;
  linkedInUserName?: string;
  gitHubUserName?: string;
  aboutMe?: string;
  location?: string;
  state?: string;
}
export interface IManagerDetailOutput {
  learnerUserId: number;
  learnerEmail: string;
  managerUserId: number;
  managerEmail: string;
}
export interface RenderTree {
  Id: number;
  Email: string;
  children?: RenderTree[];
}
export interface ICoursecode {
  courseId: number;
  courseTitle: string;
  courseCode:string
}

export interface IUserDetail {
  userId: number;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
}
export interface ICourseAssign{
  courseStartDate: string
  courseEndDate: string
  firstRemainderDate: string
  SecondRemainderDate: string
  ThirdRemainderDate: string
}
export interface IImportCourseInputs {
  emailAddress?: string
  courseCode?: number
  courseStartDate?: string
  firstReminderDate?: string
  secondReminderDate?: string
  thirdReminderDate?: string
  courseEndDate?: string
}
export interface LearnerDetail {
  learnerUserId: number,
  learnerEmail: string,
}
export interface CourseData {
  courseId: string;
  courseCode: string;
  courseStatus: string;
  userEmail: string;
  managerEmail: string;
}

export interface IManagerMapping{
  learnerCode:string;
  managerEmail: string;
  learnerEmail: string;
}
export interface IUserEmail{
  checked: boolean;
  userId:number
  emailAddress: string;
}
export interface ManageUserContract {
  LearnerCode?: string;
  LearnerEmail?: string;
  ManagerEmail?: string;
}

export interface ManageUserContract {
  LearnerCode?: string;
  LearnerEmail?: string;
  ManagerEmail?: string;
}

export interface IManagerAndUserMapDetails {
  userId: number;
  courseName: string;
  fullName: string
  emailAddress: string
  status: string
}

export interface IUserDetails {
  emailAddress: string;
  firstName: string
  lastName: string
  userId: number
}

export interface IAssignCertificateUser{
  assignedTempleteId:number;
  userId: number;
  courseId: number;
  certificateId: number;
  userName: string;
}


export interface ISampleFileData {
  sampleFileId: number;
  fileName: string;
  fileUri: string;
  fileId: string;
  fileSize: number;
  fileType: string;
  containerName: string;
}

export interface IAssignUserDetail {
  firstName: string;
  lastName: string;
  emailAddress: string;
  userRole: string;
  isManageCourse: boolean;
  isManageUser: boolean; // Assuming managedLearners can be an array of any type
}
export interface IManagedLearner {
  emailAddress: string;
  userId: number;
}

export interface IPrepareCV {
  personalId: number;
  firstName: string;
  lastName: string;
  emailInformation: string;
  phoneNumber: string;
  linkedInUserName: string;
  gitHubUserName: string;
  address: string;
  careerObjective: string;
  profileImage: string;
}

export interface IEducationalQualification {
  degreeId: number;
  degreeName: string;
  university: string;
  yearOfPassing: number;
  percentage: string;
  certification: string;
  descriptionofCertification: string;
}

export interface IProject {
  projectId: number;
  projectName: string;
  projectTechnology: string;
  projectDescription: string;
}

export interface IProjectInformationProps {
  projectInformation: IProject[];
  onNextStep: () => void; 
  onBackStep: () => void;
}

export interface IExperience {
  experienceId: number;
  experience: string;
  descriptionOfExperience: string;
  organization: string;
  industry: string;
  location: string;
  website: string;
  role: string;
  dateOfJoining: Date;
  dateofLeaving: Date;
  reference: string;
  nameOfReportingManager:string;
  referenceDesignation:string;
  referenceMailId:string;
  referencePhoneNo:string;
}

export interface ISkill {
  skillId: number;
  skillName: string;
  hobbiesName: string;
}

// Define the main interface for the entire result
export interface CVResult {
  prepareCV ?: IPrepareCV[];
  educationalQualification: IEducationalQualification[];
  project: IProject[];
  experience: IExperience[];
  skill: ISkill[];
}

export interface PrepareCVInformations {
  userId: number;
  firstName: string;
  lastName: string;
  emailInformation: string;
  phoneNumber: string;
  linkedInUserName: string;
  gitHubUserName: string;
  address: string;
  careerObjective: string;
  profileImage: string;
}
export interface EducationalQualification {
  userId: number;
  degreeName: string;
  university: string;
  yearOfPassing: string;
  percentage: string;
  certification: string;
  descriptionofCertification: string;
}
export interface IExperienceProps {
  experienceInformation: IExperience[]; // Assuming personalInformation is an array of PrepareCV objects
}
export interface ProjectDetails {
  userId: number;
  projectName: string;
  projectTechnology: string;
  projectDescription: string;
}
export interface SkillDetails {
  userId: number;
  skillName: string;
  hobbiesName: string;
};

export let objective="Results-driven and highly motivated professional with a strong background in [Industry/Field]. Seeking a challenging position at [Company Name] where I can leverage my skills in [Specific Skills] to contribute to the company's growth and success. Dedicated to delivering high-quality results and continuously improving processes.";
