import React, { useEffect, useState } from "react";
import { IDynamicForm } from "../../../../model/DynamicForms";
import { Grid, Typography, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";

export const DynamicTextFieldGroup: React.FC<IDynamicForm> = (props) => {
    const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const initialValues = props.fields.reduce((acc, field) => ({ ...acc, [field.id]: field.value || '' }), {});
        setFieldValues(initialValues);
    }, [props.fields]);

    const handleFieldValueChange = (id: string, value: string) => {
        const updatedFieldValues = {
            ...fieldValues,
            [id]: value
        };
        setFieldValues(updatedFieldValues);
        props.OnChange(props.index, updatedFieldValues); // Pass index along with updatedFieldValues
    }
    
    return (
        <Grid container spacing={1}>
            {props.fields.map((field, index) => (
                <Grid key={index} item xs={6} p={2}>
                    <Typography variant="subtitle1"><b>{field.placeholder}</b></Typography>
                    {field.id === 'role' ? (
                        <Autocomplete
                            id={field.id}
                            size={'small'}
                            fullWidth
                            options={["Role1", "Role2", "Role3"]} // Add your list of roles here
                            value={fieldValues[field.id]}
                            onChange={(event, newValue) => handleFieldValueChange(field.id, newValue || '')}
                            renderInput={(params) => <TextField {...params} placeholder={field.placeholder} />}
                        />
                    )
                    : field.id === 'dateOfJoining' || field.id === 'dateOfLeaving' ? ( // Render Date text fields for "Date of Joining" and "Date of Leaving"
                        <TextField
                            id={field.id}
                            size={'small'}
                            fullWidth
                            type="date" // Set the type to "date" for date input
                            onChange={(e) => handleFieldValueChange(field.id, e.target.value)}
                            placeholder={field.placeholder}
                            name={field.name}
                            value={fieldValues[field.id]} // Use fieldValues to display the current value
                            InputProps={{
                                startAdornment: field.startAdornment,
                                endAdornment: field.endAdornment
                            }}
                        />
                    ) : (
                        <TextField
                            id={field.id}
                            size={'small'}
                            fullWidth
                            onChange={(e) => handleFieldValueChange(field.id, e.target.value)}
                            placeholder={field.placeholder}
                            name={field.name}
                            value={fieldValues[field.id]} // Use fieldValues to display the current value
                            InputProps={{
                                startAdornment: field.startAdornment,
                                endAdornment: field.endAdornment
                            }}
                        />
                    )}
                </Grid>
            ))}           
        </Grid>
    );
};
