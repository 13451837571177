/* eslint-disable @typescript-eslint/no-unused-vars */
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PercentIcon from '@mui/icons-material/Percent';
import SchoolIcon from '@mui/icons-material/School';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, InputAdornment, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Box } from '@mui/system';
import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import ColorlibConnector from '../../../../GlobalStyles/ColorlibConnector';
import MLearnTooltip from '../../../../GlobalStyles/ToolTip';
import { FieldConfig } from '../../../../model/DynamicForms';
import { IEducationalQualification, IExperience, IPrepareCV, IProject, ISkill } from '../../../../model/Users/IUser';
import useStyles from '../ProfileCardCss';
import ColorlibStepIcon from './ColorlibStepIcon';
import { DynamicTextFieldGroup } from './DynamicForms';
import { steps } from './steps';

const EducationalInformation = (props) => {
    function parseEducationalData(data: any[]): IEducationalQualification[] {
        return data?.map((item, index) => ({
            // Set the index to 0 or adjust as needed
            degreeId: 0, // Set the degreeId to an appropriate value or leave it blank if not necessary
            degreeName: item?.degreeName || "",
            university: item?.university || "",
            yearOfPassing: item?.yearOfPassing || 0,
            percentage: item?.percentage || "",
            certification: item?.certification || "",
            descriptionofCertification: item?.descriptionofCertification || "",
        }));
    }
    const classes = useStyles();
    const educationInformations: IEducationalQualification[] = parseEducationalData(props?.location.state?.educationInformations);
    const profileInformation: IPrepareCV = props?.location?.state?.profileInformation;

    const initializeFields = () => {
        if (Array.isArray(educationInformations) && educationInformations.length > 0) {
            return educationInformations.map((data, index) => [
                {
                    index: index,
                    id: 'degreeName',
                    placeholder: 'Qualification',
                    startAdornment: <InputAdornment position="start"><SchoolIcon fontSize='small' /></InputAdornment>,
                    value: data.degreeName || ''
                },
                {
                    index: index,
                    id: 'university',
                    placeholder: 'University/School',
                    startAdornment: <InputAdornment position="start"><AccountBalanceIcon fontSize='small' /></InputAdornment>,
                    value: data.university || ''
                },
                {
                    index: index,
                    id: 'yearOfPassing',
                    placeholder: 'Year of Passing',
                    endAdornment: <InputAdornment position="start"><CalendarMonthIcon fontSize='small' /></InputAdornment>,
                    value: data.yearOfPassing || 0
                },
                {
                    index: index,
                    id: 'percentage',
                    placeholder: 'Percentage',
                    startAdornment: <InputAdornment position="start"><PercentIcon fontSize='small' /></InputAdornment>,
                    value: data.percentage || ''
                },
                {
                    index: index,
                    id: 'certification',
                    placeholder: 'Certification',
                    startAdornment: <InputAdornment position="start"><EmojiEventsIcon fontSize='small' /></InputAdornment>,
                    value: data.certification || ''
                },
                {
                    index: index,
                    id: 'descriptionofCertification',
                    placeholder: 'Description of Certification',
                    startAdornment: <InputAdornment position="start"><EmojiEventsIcon fontSize='small' /></InputAdornment>,
                    value: data.descriptionofCertification || ''
                }
            ]);
        } else {
            // If no existing data, initialize with empty values
            return [
                [
                    {
                        index: 0,
                        id: 'degreeName',
                        placeholder: 'Qualification',
                        startAdornment: <InputAdornment position="start"><SchoolIcon fontSize='small' /></InputAdornment>,
                        value: ''
                    },
                    {
                        index: 0,
                        id: 'university',
                        placeholder: 'University/School',
                        startAdornment: <InputAdornment position="start"><AccountBalanceIcon fontSize='small' /></InputAdornment>,
                        value: ''
                    },
                    {
                        index: 0,
                        id: 'yearOfPassing',
                        placeholder: 'Year of Passing',
                        endAdornment: <InputAdornment position="start"><CalendarMonthIcon fontSize='small' /></InputAdornment>,
                        value: 0
                    },
                    {
                        index: 0,
                        id: 'percentage',
                        placeholder: 'Percentage',
                        startAdornment: <InputAdornment position="start"><PercentIcon fontSize='small' /></InputAdornment>,
                        value: ''
                    },
                    {
                        index: 0,
                        id: 'certification',
                        placeholder: 'Certification',
                        startAdornment: <InputAdornment position="start"><EmojiEventsIcon fontSize='small' /></InputAdornment>,
                        value: ''
                    },
                    {
                        index: 0,
                        id: 'descriptionofCertification',
                        placeholder: 'Description of Certification',
                        startAdornment: <InputAdornment position="start"><EmojiEventsIcon fontSize='small' /></InputAdornment>,
                        value: ''
                    }
                ]
            ];
        }
    };


    const initialFields = initializeFields();
    const [educationInformation, setEducationInformation] = useState([]);
    const [educationInformationFieldGroups, setEducationInformationFieldGroups] = useState<FieldConfig[][]>(initialFields);
    const [expandedIndex, setExpandedIndex] = useState<number>();
    const [activeStep, setActiveStep] = useState(1);
    const skillInformation: ISkill[] = props?.location?.state?.skillInformation;
    const experienceInformation: IExperience[] = props?.location?.state?.experienceInformation;
    const projectInformations: IProject[] = props?.location?.state?.projectInformations;


    const history = useHistory();
    const addFieldGroup = () => {
        const emptyFields: FieldConfig[] = initialFields[0].map(field => ({
            ...field,
            value: ''
        }));
        setEducationInformationFieldGroups([...educationInformationFieldGroups, emptyFields]);
        setExpandedIndex(educationInformationFieldGroups.length); // Expand the newly added accordion
    };

    const toggleAccordion = (index: number) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const removeFieldGroup = (index: number) => {
        const newFieldGroups = [...educationInformationFieldGroups];
        newFieldGroups.splice(index, 1);
        setEducationInformationFieldGroups(newFieldGroups);
        // If the removed accordion was expanded, close it
        if (index === expandedIndex) {
            setExpandedIndex(null);
        }
    };

    const handleFieldValuesChange = (index, updatedFieldValues) => {
        const updatedEducationInformation = [...educationInformation];
        updatedEducationInformation[index] = updatedFieldValues;
        setEducationInformation(updatedEducationInformation);
    };
    const handleNextStep = () => {

        let value = educationInformations;
        history.push("/Skills", {
            educationInformations: educationInformation.length > 0 ? parseEducationalData(educationInformation) : value, profileInformation: profileInformation, skillInformation: skillInformation, experienceInformation: experienceInformation,
            projectInformations: projectInformations
        });
    };
    const handleBackStep = () => {

        let value = educationInformations;
        history.push("/PersonalInformation", {
            educationInformations: educationInformation.length > 0 ? parseEducationalData(educationInformation) : value,
            profileInformation: profileInformation, skillInformation: skillInformation, experienceInformation: experienceInformation,
            projectInformations: projectInformations
        });
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={2} display={"flex"} justifyContent={"center"} alignItems={"center"} borderRight={"1px solid lightGray"} style={{ backgroundColor: "#7a64a7" }}>
                <Stepper
                    activeStep={activeStep}
                    orientation='vertical'
                    connector={<ColorlibConnector />}>
                    {steps.map((step, index) => (
                        <Step key={step.label} sx={{ color: "white" }}>
                            <StepLabel StepIconComponent={ColorlibStepIcon} icon={step.icon}>
                                <b style={{ color: activeStep === index ? "orange" : (index < activeStep ? "limegreen" : "#ccc"), fontSize: activeStep === index ? "16px" : "14px" }}>
                                    {step.label}
                                </b>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            <Grid item xs={9.7} >
                {activeStep === 1 && (
                    <Grid container spacing={2} ml={.2}>
                        <Grid item xs={10} pt={6} borderBottom={'1px dashed blue'}>
                            <Typography variant='body1' color="#5e35b1"><b>Educational Information</b></Typography>
                        </Grid>
                        <Grid item xs={2} display={"flex"} justifyContent={"flex-end"} borderBottom={'1px dashed blue'}>
                            <MLearnTooltip title="Add" placement="top">
                                <IconButton
                                    size='small'
                                    sx={{ mb: "2px" }}
                                    aria-label="Add"
                                    color="primary"
                                    onClick={addFieldGroup}
                                    style={{ backgroundColor: "#5e35b1", color: "white" }}>
                                    <AddCircleIcon />
                                </IconButton>
                            </MLearnTooltip>
                        </Grid>
                        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.educationContainer}>
                            <Grid container spacing={0}>
                                {educationInformationFieldGroups?.map((fields, index) => (
                                    <Grid item xs={12} key={index} display={"flex"}>
                                        <Grid item xs={11.5} >
                                            <Accordion expanded={index === expandedIndex} onChange={() => toggleAccordion(index)} style={{ marginLeft: "25px" }}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon fontSize='small' />} sx={{ borderBottom: "1px solid lightgrey", backgroundColor: "beige" }}>
                                                    <Typography variant='body2' color={"chocolate"}><b> EducationInformation{index + 1}</b></Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <DynamicTextFieldGroup
                                                        key={index}
                                                        fields={fields}
                                                        value={educationInformation[index]}
                                                        onRemove={() => removeFieldGroup(index)}
                                                        index={index}
                                                        OnChange={handleFieldValuesChange}
                                                    />
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                        <Grid item xs={0.5} ml={2} mt={1}>
                                            <IconButton onClick={() => removeFieldGroup(index)} className={classes.JoinIconButton}>
                                                <MLearnTooltip title="Remove" placement="top">
                                                    <DeleteOutlineIcon fontSize='small' color='error' />
                                                </MLearnTooltip>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Scrollbars>
                        <Box pt={0.5} width={'100%'} display={'inline-flex'} justifyContent={'flex-end'} borderTop={'1px solid lightgray'}>
                            <Button variant="contained" size="small" color="success" sx={{ marginRight: "20px" }} onClick={handleBackStep}>
                                <b>Back</b>
                            </Button>
                            <Button variant="contained" size="small" color="success" onClick={handleNextStep} sx={{ marginRight: "20px" }}>
                                <b>Next</b>
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default EducationalInformation;
