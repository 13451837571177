/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { Chip, Paper, Switch } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListOffTwoToneIcon from '@mui/icons-material/FilterListOffTwoTone';
import FilterListTwoToneIcon from '@mui/icons-material/FilterListTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import SupervisorAccountTwoToneIcon from '@mui/icons-material/SupervisorAccountTwoTone';
import { Alert, Backdrop, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { MoonLoader } from 'react-spinners';
import { useGlobalState } from '../../GlobalStore/globalStore';
import StyledTreeView from '../../GlobalStyles/StyledTreeview';
import MLearnTooltip from '../../GlobalStyles/ToolTip';
import useStyles from "../../components/ManageCourse/CourseCss";
import { ICourseList, IManagerUserListResult, IStatus, IUserListResult } from '../../model/Manager/IMangerCourseDate';
import { ICourseAssign, IManagerAndUserMapDetails, IManagerDetailOutput, IUserDetails, RenderTree } from '../../model/Users/IUser';
import { useFetch, usePost } from '../../utils/apiHelper';
import AssignCourse from './AssignCourse';
import AssignUser from './AssignUser';
import EditAssignCourse from './EditAssignCourse';
import ManageImportUserCourse from './ImportUserCourse';

const statusOptions: IStatus[] = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
    { label: 'Pending', value: 'pending' },
];

export default function Manager() {

    const { state } = useGlobalState();
    const defaluttreevalue: RenderTree[] = [
        {
            Id: state.userAccessContext.userId,
            Email: state.userAccessContext.emailAddress,
            children: []
        }
    ]
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [managerTree, setManagerTree] = useState<RenderTree[]>(defaluttreevalue);
    const [managerInformation, setManagerInformation] = useState<IManagerDetailOutput[]>([]);
    const [textBox, setTextBox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userListResult, setUserListResult] = useState<IUserListResult[]>([]);
    const [courseListResult, setCourseListResult] = useState<ICourseList[]>([]);
    const [managerUserListResult, setManagerUserListResult] = useState<IManagerUserListResult[]>([]);
    const [selectedUserName, setSelectedUserName] = useState<IUserDetails>(null);
    const [selectedCourseName, setSelectedCourseName] = useState<ICourseList>(null);
    const [selectManagerEmailId, setSelectManagerEmailId] = useState<IManagerUserListResult>(null);
    const [selectedStatus, setSelectedStatus] = useState<IStatus>(null);
    // feature , need the above state, i will remove later
    const [email, setEmail] = useState('');
    const [snackbar, setSnackBar] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedCourseCode, setSelectedCourseCode] = useState<any>([]);
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [userDetail, setUserDetail] = useState<IManagerAndUserMapDetails[]>([]);
    const [totalCounts, setTotalCounts] = useState<number>(0);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [selectManagerId, setSelectManagerId] = useState<number>(0);
    const [tableLoading, setTableLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedManagerId, setSelectedManagerId] = useState<number>();
    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [allOptions, setAllOptions] = useState<any[]>([]);
    const [learnerUserId, setLeanerUserId] = useState<number>(null);

    const handleOptionSelect = (event, value) => {
        if (value && !selectedOptions.find(option => option.emailAddress === value.emailAddress)) {
            setSelectedOptions([...selectedOptions, value]);
        }
        setInputValue('');
    };

    const handleSelectAll = () => {
        setSelectAll(true);
        setAllOptions(userListResult); // Set all options when switch is on
        setSelectedOptions([...selectedOptions, ...allOptions]);
    };

    const handleDeselectAll = () => {
        setSelectedOptions([]);
        setSelectAll(false);
    };
    const setDefaultMappedUsers = (Data) => {
        const filteredManagerData = Data.filter(item => item.managerUserId === state.userAccessContext.userId);
        const defaultSelectedValues: IUserListResult[] = filteredManagerData.map(option => ({
            userId: option.learnerUserId,
            emailAddress: option.learnerEmail,
            firstName: "",
            lastName: ""
        }));
        setSelectedOptions(defaultSelectedValues);
    }

    const handleDeleteChip = (index: number) => {
        if (selectAll) {
            const updatedOptions = [...allOptions];
            updatedOptions.splice(index, 1);
            setAllOptions(updatedOptions);
            setSelectedOptions(updatedOptions);
        } else {
            const updatedOptions = [...selectedOptions];
            updatedOptions.splice(index, 1);
            setSelectedOptions(updatedOptions);
        }
    };

    const handleDeleteConfirm = () => {
        setOpenDialog(false);
    };

    const handleDialogDeleteClose = () => {
        setOpenDialog(false);
    };

    let CourseDetails: ICourseAssign = {
        courseStartDate: moment(new Date())?.format("YYYY-MM-DD"),
        courseEndDate: moment(new Date())?.format("YYYY-MM-DD"),
        firstRemainderDate: '',
        SecondRemainderDate: '',
        ThirdRemainderDate: '',
    }
    const [localState, setLocalState] = useState(CourseDetails);

    useEffect(() => {
        fetchData();
        GetFilterUserdetsils();
        handleClick(state?.userAccessContext?.userId);
    }, [])

    const handleClose = () => {
        setOpen(false);
        setSelectedUsers([]);
        setSelectedCourseCode([]);
        setLocalState({
            ...localState,
            courseStartDate: '',
            courseEndDate: '',
            firstRemainderDate: '',
            SecondRemainderDate: '',
            ThirdRemainderDate: ''
        });
        setSnackBar(false);
    }
    const GetFilterUserdetsils = () => {
        (async () => {

            await useFetch<{ userListResult: IUserListResult[], managerUserListResult: IManagerUserListResult[], courseListResult: ICourseList[] }>('Users/GetManagerUserDetails').then((result) => {
                setUserListResult(result?.data?.userListResult); //all user
                setManagerUserListResult(result?.data?.managerUserListResult);
                setCourseListResult(result?.data?.courseListResult);
            })
        })().finally(() => {
        })
    }

    const fetchData = () => {
        (async () => {
            setLoading(true);
            let request = state?.userAccessContext?.userId;
            await usePost<IManagerDetailOutput[]>(`Users/GetManagerDetail?ReportingTo`, request).then((result) => {
                setManagerInformation(result.data);
                const generatedTree = buildTree(result.data);
                const updatedTree = [...defaluttreevalue];
                updatedTree[0].children = generatedTree; // Add generated tree as children of the root
                setManagerTree(updatedTree);
            }).finally(() => setLoading(false)); handleClick(state.userAccessContext.userId);
        })()
    };

    //formultate tree 
    const buildTree = (
        data: IManagerDetailOutput[],
        parentId: number | null = state.userAccessContext.userId,
        visited: Set<number> = new Set()
    ): RenderTree[] => {
        const tree: RenderTree[] = [];
        for (const item of data) {
            // Check if the current node has already been visited
            if (!visited.has(item.learnerUserId) && item.managerUserId === parentId) {
                // Mark the current node as visited
                visited.add(item.learnerUserId);
                const node: RenderTree = {
                    Id: item.learnerUserId,
                    Email: item.learnerEmail,
                    children: buildTree(data, item.learnerUserId, visited)
                };
                tree.push(node);
            }
        }
        return tree;
    };


    const handleAddSubManager = (Email: string) => {
        setTextBox(true);
        setEmail(Email);
    }
    const handleEdit = (learnerUserId) => {
        setTextBox(true)
        setDefaultMappedUsers(managerInformation);
        setSelectedManagerId(state?.userAccessContext?.userId);
        setLeanerUserId(learnerUserId);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value));
    };

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };
    const handleDialogClose = () => {
        setTextBox(false);
        fetchData();
        handleFilterClear(state.userAccessContext.userId);
        setSelectedOptions([]);
    }

    const SaveEditManager = () => {
        setLoading(true);
        const mangeLearnerMapping = selectedOptions?.map((code) => ({
            'userId': code.userId,
        }));

        (async () => {
            let request = {
                "managerId": state.userAccessContext.userId, // Assuming managerEmail holds the managerId value
                "learnerIds": mangeLearnerMapping // Use mangeLearnerMapping directly
            };
            await usePost("UserCourse/InsertManagerLearner", request).then(() => { handleDialogClose() }).finally(() => {
                setLoading(false); setSnackBar(true)

            });
        })().finally(() => setSnackbarMessage('Data Saved Successfully'));
    }


    function onUserNameSelect(e, selected) {
        setSelectedUserName(selected);
    }

    function onManagerNameSelect(event, value) {
        setSelectManagerEmailId(value);
    }

    function onCourseNameSelect(event, value) {
        setSelectedCourseName(value);
    }

    function onStatusSelect(event, value) {
        setSelectedStatus(value);
    };
    const handleClick = (nodeId: any) => {
        setSelectManagerId(nodeId);
        setTableLoading(true);
        (async () => {
            let request = {
                "managerId": nodeId,
                "searchKeyword": selectedUserName?.emailAddress,
                "reportingManager": selectManagerEmailId?.managerEmail,
                "courseList": selectedCourseName?.courseTitle
            }
            await usePost<{ managerAndUserMapResults: IManagerAndUserMapDetails[], totalCount: any }>("Users/GetManagerAndUserMapDetails", request).then((r) => {
                setUserDetail(r?.data?.managerAndUserMapResults);
                setTotalCounts(r?.data?.totalCount);
            })
        })().finally(() => {
            setTableLoading(false);
        })
    }

    const handleFilterClear = (nodeId: any) => {
        setSelectManagerId(nodeId);
        setTableLoading(true);
        (async () => {
            let request = {
                "managerId": nodeId,
                "searchKeyword": "",
                "reportingManager": "",
                "courseList": ""
            }
            await usePost<{ managerAndUserMapResults: IManagerAndUserMapDetails[], totalCount: any }>("Users/GetManagerAndUserMapDetails", request).then((r) => {
                setUserDetail(r?.data?.managerAndUserMapResults);
                setTotalCounts(r?.data?.totalCount);
            })
        })().finally(() => {
            setTableLoading(false);
            Reset();
        })
    }

    const Reset = () => {
        setSelectedCourseCode([]);
        setSelectManagerEmailId(null);
        setSelectedUserName(null);
        setSelectedCourseName(null);
        setSelectedStatus(null);
    }
    function EnhancedTableHead() {
        const headCells = [
            { id: "courseTitle", numeric: false, disablePadding: false, label: "Course Name", sortable: false },
            { id: "firstName", numeric: false, disablePadding: false, label: "Full Name", sortable: false },
            { id: "EmailAddress", numeric: false, disablePadding: false, label: "Email", sortable: false },
            { id: "courseStatus", numeric: false, disablePadding: false, label: "Course Status", sortable: false },
            { id: "Action", numeric: false, disablePadding: false, label: "Action", sortable: false },
        ];

        return (
            <TableHead className={classes.tableHead}>
                <TableRow sx={{ color: "white" }}>
                    <TableCell className={classes.orgTableCellHeader}>
                        <Checkbox size='small' className={classes.checkBoxStyle} inputProps={{ 'aria-label': 'select all desserts' }} />
                    </TableCell >
                    {headCells.map((headCell) => (
                        <TableCell className={classes.orgTableCellHeader} key={headCell.id} size="small" >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    const renderTree = (nodes: RenderTree, parentId: number | null = null, index: number) => (
        <TreeItem key={`${nodes.children}_${nodes.Id}_${index}`} nodeId={nodes.Id.toString()}
            label={
                <React.Fragment>
                    <Grid container spacing={0} p={0.5}>
                        <Grid item xs={1}>
                            <SupervisorAccountTwoToneIcon className={classes.accounticonStyle} fontSize='medium' />
                        </Grid>
                        <Grid item xs={10} >
                            <Typography variant='body2' color={'black'} mt={'5px'} ml={'10px'} onClick={() => handleClick(nodes.Id)}>
                                {nodes?.Email}
                            </Typography>
                        </Grid>
                        {parentId == null && // Check if it's not a root node
                            state.userAccessContext.userRole ? <Grid item xs={1} display={"flex"} justifyContent={"flex-end"}>
                            <MLearnTooltip title="Edit Manager" placement="top">
                                <IconButton size="small" sx={{ marginLeft: "5px" }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleEdit(nodes.Id);
                                        handleAddSubManager(nodes.Email)
                                    }}>
                                    <EditIcon color='success' fontSize='small' />
                                </IconButton>
                            </MLearnTooltip>
                        </Grid> : null}
                    </Grid>
                </React.Fragment>
            }
        >
            {Array.isArray(nodes?.children) ? nodes?.children.map((node, childIndex) => renderTree(node, nodes.Id, childIndex)) : null}
        </TreeItem>
    );

    return (
        <React.Fragment>
            {loading ? (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <MoonLoader size={50} color={"#5e35b1"} loading={loading} />
                </Backdrop>
            ) : (
                <Grid container spacing={0} bgcolor={'white'} borderRadius={2} >
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={8} className={classes.borderBottom} p={0.5} display="flex">
                            <Typography variant='h6' color={'black'} ml={1}><b>Manage Learnings</b></Typography>
                            <Typography variant='body2' color={'black'} mt={1} ml={27} className={classes.managerStyle}>
                                <b>Logined As : {managerTree[0]?.Email}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.borderBottom} p={1}>
                            {state.userAccessContext.userRole ?
                                <Grid container spacing={0} display="flex" justifyContent={'space-between'}>
                                    <ManageImportUserCourse onCreate={fetchData} />
                                    <AssignUser onCreate={fetchData} userList={userListResult} userDetail={userDetail} />
                                    <AssignCourse onCreate={fetchData} userList={userListResult} userDetail={userDetail} />
                                </Grid> : <Grid container spacing={0} display="flex" justifyContent={'flex-end'}>
                                    <AssignCourse onCreate={fetchData} userList={userListResult} userDetail={userDetail} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={3.5} borderRight={'1px solid lightgray'}>
                        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.TreeViewContainer}>
                            <Grid container spacing={1} >
                                {managerTree.length === 0 && (
                                    <Grid item xs={12} mt={20} >
                                        <Grid container spacing={0} >
                                            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                                <PersonOutlineTwoToneIcon fontSize='large' htmlColor='#5e35b1' sx={{ fontSize: 50 }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='body1' color={'#5e35b1'} ml={1} textAlign={'center'} fontWeight={600}>
                                                    Please add a manager
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid item xs={12} >
                                    <StyledTreeView aria-label="rich object" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpanded={managerTree.map(node => node.Id.toString())} defaultExpandIcon={<ChevronRightIcon />}>
                                        {managerTree?.map((node, index) => renderTree(node, null, index))}
                                    </StyledTreeView>
                                </Grid>
                            </Grid>
                        </Scrollbars>
                    </Grid>
                    <Grid item xs={8.5}>
                        <Grid container spacing={0} >
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            disablePortal
                                            size='small'
                                            id="combo-box-demo"
                                            className={classes.Filter}
                                            options={userListResult}
                                            style={{ color: 'black' }}
                                            getOptionLabel={(option) => option?.emailAddress}
                                            onChange={(e, selected) => onUserNameSelect(e, selected)}
                                            value={selectedUserName}
                                            renderTags={() => null}
                                            renderInput={(params) => <TextField {...params} label="Name" />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            disablePortal
                                            size='small'
                                            id="combo-box-demo"
                                            value={selectManagerEmailId}
                                            options={managerUserListResult}
                                            getOptionLabel={(option) => option?.managerEmail}
                                            onChange={onManagerNameSelect}
                                            className={classes.Filter}
                                            renderInput={(params) => <TextField size='small' {...params} label="Reporting Manager" />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            disablePortal
                                            size='small'
                                            id="combo-box-demo"
                                            className={classes.Filter}
                                            value={selectedCourseName}
                                            options={courseListResult}
                                            getOptionLabel={(option) => option?.courseTitle}
                                            onChange={onCourseNameSelect}
                                            renderInput={(params) => <TextField {...params} label="Course List" />}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Autocomplete
                                            disablePortal
                                            className={classes.Filter}
                                            size='small'
                                            id="combo-box-demo"
                                            value={selectedStatus}
                                            options={statusOptions || []}
                                            getOptionLabel={(option) => option?.label}
                                            onChange={onStatusSelect}
                                            renderInput={(params) => <TextField {...params} label="Status" />}
                                        />
                                    </Grid>
                                    <Grid item xs={1} display={'flex'} justifyContent={'space-around'} alignItems={'center'}>
                                        <MLearnTooltip title="Filter" placement="top">
                                            <IconButton size='small' sx={{ mt: 0.5 }} onClick={(e) => { handleClick(selectManagerId); }}>
                                                <FilterListTwoToneIcon />
                                            </IconButton>
                                        </MLearnTooltip>
                                        <MLearnTooltip title="Clear Filter" placement="top">
                                            <IconButton size='small' sx={{ mt: 0.5 }} onClick={(e) => { handleFilterClear(managerTree[0]?.Id) }} >
                                                <FilterListOffTwoToneIcon color='error' />
                                            </IconButton>
                                        </MLearnTooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <TableContainer sx={{ mt: 1 }}>
                                    {tableLoading && (
                                        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={tableLoading}>
                                            <MoonLoader size={50} color={"#5e35b1"} loading={tableLoading} />
                                        </Backdrop>
                                    )}
                                    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.orgTableContainer}>
                                        <Table aria-label="customized table" size='medium' stickyHeader>
                                            <EnhancedTableHead />
                                            <TableBody>
                                                {userDetail?.length ? (
                                                    <React.Fragment>
                                                        {tableLoading ? (
                                                            // Show skeleton loading when table is loading
                                                            Array.from({ length: 5 }).map((_, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell colSpan={6}>
                                                                        <Skeleton animation="wave" />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            <React.Fragment>
                                                                {userDetail?.map((row, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell sx={{ padding: 1 }} className={classes.orgTableCellStyle}>
                                                                            <Checkbox size='small' sx={{ padding: "5px" }} inputProps={{ 'aria-label': 'select all desserts' }} />
                                                                        </TableCell>
                                                                        <TableCell sx={{ padding: 1 }} className={classes.orgTableCellStyle} >
                                                                            {row?.courseName}
                                                                        </TableCell>
                                                                        <TableCell sx={{ padding: 1 }} className={classes.orgTableCellStyle}>
                                                                            {row?.fullName}
                                                                        </TableCell>
                                                                        <TableCell sx={{ padding: 1 }} className={classes.orgTableCellStyle}>
                                                                            {row?.emailAddress}
                                                                        </TableCell>
                                                                        <TableCell sx={{ padding: 1 }} className={classes.orgTableCellStyle} >
                                                                            {row?.status}
                                                                        </TableCell>
                                                                        <TableCell sx={{ padding: 1 }} className={classes.orgTableCellStyle}>
                                                                            <EditAssignCourse userId={row.userId} onCreate={handleFilterClear} /> &nbsp;
                                                                            <DeleteTwoToneIcon fontSize='small' color="error" />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <TableRow >
                                                            <TableCell colSpan={6} align="center" style={{ color: 'red' }}><b>No records available...</b></TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Scrollbars>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} borderTop={'1px solid lightgray'} mt={5}>
                                <TablePagination component="div" count={totalCounts} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            )}
            <div>
                <Dialog open={textBox} onClose={handleDialogClose} fullWidth PaperProps={{ style: { borderRadius: 15 } }}>
                    <DialogTitle className={classes.dialogStyles}> Edit managers </DialogTitle>
                    <CancelOutlinedIcon
                        onClick={handleDialogClose}
                        className={classes.closeButton}
                    />
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <Paper className={classes.containerpaperStyle}>
                                    {(selectAll ? allOptions : selectedOptions).map((option, index) => (
                                        <Chip
                                            key={index}
                                            label={option.emailAddress}
                                            onDelete={() => handleDeleteChip(index)}
                                            classes={{ deleteIcon: classes.chipDeleteIcon, root: classes.chip }}
                                        />
                                    ))}
                                    {((selectAll ? allOptions : selectedOptions).length === 0) && (
                                        <Grid container justifyContent="center" alignItems="center" style={{ height: '100%', color: "#5e35b1" }}>
                                            <Typography>Select Learner</Typography>
                                        </Grid>
                                    )}
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    fullWidth
                                    id="checkboxes-tags-demo"
                                    options={state.userAccessContext.userRole && state.userAccessContext.isManageCourse && state.userAccessContext.isManageUser ? userListResult : userDetail as any}
                                    getOptionLabel={(option) => option.emailAddress}
                                    value={null}  //Reset the Autocomplete Value
                                    inputValue={inputValue}
                                    onChange={handleOptionSelect}
                                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                                    getOptionSelected={(option, value) => option.emailAddress === value.emailAddress}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Assign Learner" placeholder="Favorites" />
                                    )}
                                    renderOption={(option) => {
                                        const selected = selectedOptions.some((selectedOption) => selectedOption.emailAddress === option.emailAddress);
                                        return <li className={selected ? classes.selectedOption : classes.unselectedOption}>{option.emailAddress}</li>;
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>Deselect All</Typography>
                                    <Switch
                                        defaultChecked={false}
                                        checked={selectAll}
                                        onChange={(e) => {
                                            e.target.checked ? handleSelectAll() : handleDeselectAll();
                                        }}
                                        inputProps={{ 'aria-label': 'ant design' }}
                                    />
                                    <Typography>Select All</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={SaveEditManager} color="primary" className={classes.AddbuttonStyle} startIcon={<CancelIcon />}>
                            Update
                        </Button>
                        <Button onClick={handleDialogClose} color="secondary" className={classes.ButtonStyle} startIcon={<CheckCircleIcon />}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openDialog} onClose={handleDialogDeleteClose} fullWidth PaperProps={{ style: { borderRadius: 15 } }}>
                    <DialogTitle id="alert-dialog-title" className={classes.dialogStyles}>Confirm Deletion</DialogTitle>
                    <CancelOutlinedIcon onClick={handleClose} className={classes.closeButton} />
                    <DialogContent>
                        Are you sure you want to delete this manager?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteConfirm} color="primary" className={classes.AddbuttonStyle} startIcon={<CancelIcon />}>
                            Yes
                        </Button>
                        <Button onClick={handleDialogDeleteClose} color="secondary" className={classes.ButtonStyle} startIcon={<CheckCircleIcon />} >
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Snackbar
                open={snackbar}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                color='success'
                className={classes.snackbarColor}
            >
                <Alert onClose={handleClose} severity="success" className={classes.snackbarColor}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </React.Fragment >
    );
}
