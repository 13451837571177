import { INotificationDetails } from "../model/ICourse";

export const Profilejob: { title: string; description: string }[] = [
    { title: "Full stack developer", description: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts" },
    { title: "Java developer", description: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts" },
    { title: "Software developer", description: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts" },
    { title: "Azure Senior developer", description: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts" },
    { title: "Tester", description: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts" },
    { title: "AI developer", description: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts" },
    { title: "Associate developer", description: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts" },
    { title: "UI/UX designer", description: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts" },
    { title: "Backend developer", description: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts" },
    { title: "C# Sharp developer", description: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts" },
    { title: "React Typescript", description: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts" },
    { title: "Graphic designer", description: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts" },
];
export const mockNotificationDetails: INotificationDetails[] = [
    {
      notificationMessage: "Your course on React Basics is now available.",
      createdBy: 1,
      createdDate: new Date('2024-05-15T10:30:00'),
      status: "unread",
      emailAddress: "user1@example.com"
    },
    {
      notificationMessage: "You have an upcoming deadline for the JavaScript Advanced course.",
      createdBy: 2,
      createdDate: new Date('2024-05-20T14:45:00'),
      status: "read",
      emailAddress: "user2@example.com"
    },
    {
      notificationMessage: "New updates are available for your Python Programming course.",
      createdBy: 3,
      createdDate: new Date('2024-05-25T09:00:00'),
      status: "unread",
      emailAddress: "user3@example.com"
    },
    {
      notificationMessage: "Your profile has been updated successfully.",
      createdBy: 4,
      createdDate: new Date('2024-05-28T11:15:00'),
      status: "read",
      emailAddress: "user4@example.com"
    },
    {
      notificationMessage: "Reminder: Submit your assignment for the Machine Learning course.",
      createdBy: 5,
      createdDate: new Date('2024-05-29T13:00:00'),
      status: "unread",
      emailAddress: "user5@example.com"
    }, {
      notificationMessage: "Your profile has been updated successfully.",
      createdBy: 4,
      createdDate: new Date('2024-05-28T11:15:00'),
      status: "read",
      emailAddress: "user4@example.com"
    },
    {
      notificationMessage: "Reminder: Submit your assignment for the Machine Learning course.",
      createdBy: 5,
      createdDate: new Date('2024-05-29T13:00:00'),
      status: "unread",
      emailAddress: "user5@example.com"
    }
  ];
