import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    aboutGrid: {
       transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
      '&:hover': {
        transform: 'scale(2.05) translateY(-5px)', 
       },

    },
    gridPadding:{
        padding: '10px',
        marginTop:'30px'
    },
    background:{
        backgroundColor: 'blue'
    }
  }));