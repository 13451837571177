import './App.css';
import Login2 from './pages/Login2';

function App() {
  return (
    <div>
      <Login2 />
    </div>
  );
}

export default App;
