import { Theme, createStyles, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //images
    thumbnail: {
      width: '40%',
      // height: '33%',
    },
    textcolor: {
      color: "white !important"
    },
    //end
    elipises: {
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    //filter autocomplete
    Filter: {
      '& .MuiAutocomplete-inputRoot': {
        padding: '0px !important',
      },
      width: '90% !important',
      ml: '8px !important',
      mt: '8px !important'
    },
    //dialogtitel
    dialogtitle: { backgroundColor: '#1976d2', color: 'white', justifyContent: 'center', display: 'flex' },
    div: {
      flexGrow: 1,
      padding: '0px'
    },
    close: {
      cursor: 'pointer', position: 'absolute', right: '14px', top: '14px'
    },
    container: {
      bordeRadius: '10px',
      paddingLeft: '0px !important',
      paddingTop: '0px',
      minWidth: "158px !important",
    },
    header: {
      fontSize: '18px'
    },
    imageGrid: {
      padding: '8px',
      [theme.breakpoints.down("sm")]: {
        textAlign: 'center'
      },
      [theme.breakpoints.up("sm")]: {
        textAlign: 'left'
      },
    },
    imageGrid1: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    lineBreak: {
      margin: 0,
      flexShrink: 0,
      borderWidth: 0,
      borderStyle: 'dashed',
      borderColor: 'rgba(145, 158, 171, 0.24)',
      borderBottomWidth: 'thin',
    },
    textfiled: {
      width: '100%',
      marginTop: '10px !important',
      fontSize: "10px !important"
      // backgroundColor: "rgb(248, 250, 252)"
    },
    importcourseStyle:{
      mt: 1,
      width: '40px !important', // Adjust width to make the circle smaller
      height: "33px !important",
      right: "10px !important",
      borderRadius: '8px !important', // Initial border radius for rectangle shape
      transition: 'border-radius 0.3s !important', // Smooth transition for border radius change
      '&:hover': {
          backgroundColor: "#5e35b1 !important",
          border: "2px solid black !important",
      },
    },
    DropzoneArea: {
      minHeight: '200px',
      maxHeight: '200px',
      fontSize: '12px',
      minWidth: '370px',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      backgroundColor: 'rgb(237, 231, 246) !important',
      border: '2px dashed rgba(145, 158, 171, 0.24)',
      borderRadius: '5px',
      [theme.breakpoints.up("sm")]: {
        maxWidth: 'auto',
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: 'auto',
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 'auto',
      },
    },
    DropzoneArea1: {
      minHeight: '130px !important',
      // maxHeight: '190px',
      fontSize: '12px',
      // minWidth: '550px',
      backgroundColor: '#f4f6f8 !important',
      border: '2px dashed rgba(145, 158, 171, 0.24)',
      borderRadius: '5px',
      [theme.breakpoints.up("sm")]: {
        maxWidth: 'auto',
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: 'auto',
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 'auto',
      },
    },
    DropzoneAreaText: {
      fontSize: '15px!important',
      textAlign: 'center',
      paddingTop: '25px',
      color: 'rgb(10, 10, 10)'
    },
    content: {
      padding: '25px',
      overflowY: 'hidden',
      [theme.breakpoints.up("lg")]: {
        padding: '25px',
        overflowY: 'hidden',
      },
      [theme.breakpoints.down("md")]: {
        padding: '25px',
        overflowY: 'scroll',
      },
      [theme.breakpoints.down("xs")]: {
        padding: '25px',
        overflowY: 'scroll',
      }
    },
    save: {
      background: ' green',
      borderRadius: '8px',
      fontSize: '13px',
      marginRight: '5px',
      transition: 'background - color 250ms cubic- bezier(0.4, 0, 0.2, 1) 0ms, box- shadow 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms, border - color 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover': {
        backgroundColor: ' green',

      }
    },
    cancel: {
      background: 'red',
      borderRadius: '8px',
      marginRight: '15px',
      fontSize: '13px',
      transition: 'background - color 250ms cubic- bezier(0.4, 0, 0.2, 1) 0ms, box- shadow 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms, border - color 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover': {
        backgroundColor: 'red',
      }
    },
    deleteDialogue: {
      minWidth: "500px",
    },
    tableCellHeader: {
      padding: '10px 15px !important'
    },
    tableCellStyle: {
      padding: '1px 15px !important',
      textAlign: "center",
      '& .MuiTableCell-root': {
        padding: '0px 15px!important',
      }
    },
    boxStyle: {
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      backgroundColor: "white",
      height: 'auto'
    },
    gridContainer: {
      paddingLeft: "2px",
      width: "100%"
    },
    tabPanelPadding: {
      padding: '0px 0px !important'
    },
    box: {
      background: "white",
      borderBottom: "1px solid lightgray",
      width: "100%",
      minWidth: "158px",
      borderRadious: "20px !important",
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
    padding: {
      padding: '0px !important'
    },
    tabSelected: {
      fontWeight: "bold",
      "&.Mui-selected": {
        color: "rgb(94, 53, 177) !important"
      }
    },
    searchBox: {
      // marginTop: '2px !important',
      borderRadius: "10px !important",
      border: '2px solid #5e35b1 !important',
      height: '35px !important',
      textIndent: '10px !important',
      fontSize: "12px !important",
      '& .MuiInputBase-root': {
        fontSize: "12px !important"
      },
      '& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root': {
        paddingRight: '2px'
      }
    },
    outlinedInput: {
      fontSize: "12px !important",
      color: "black !important",
      marginTop: '-2px !important',
      '&$focused $notchedOutline': {
        color: 'white !important',
        border: '0px !important'
      },
    },
    notchedOutline: {
      color: 'white !important',
      border: 'none !important',
      fontSize: "12px !important",
    },
    closeIcon: {
      padding: '3px',
      color: '#5e35b1 !important',
      '&:hover': { color: 'white !important', backgroundColor: "#5e35b1 !important" },
      borderRadius: '20px'
    },
    searchIcon: {
      padding: '3px',
      color: '#5e35b1 !important',
      '&:hover': { color: 'white !important', backgroundColor: "#5e35b1 !important" },
      borderRadius: '20px'
    },
    button: {
      backgroundColor: "rgb(30, 136, 229)", borderRadius: "10px",
      '&:hover': {
        backgroundColor: 'rgb(30, 136, 229)',

      }
    },
    borderRadius: {
      borderRadius: "15px !important"
    },
    dialogBackground: {
      backdropFilter: "blur(5px)",
      backgroundColor: "rgba(145,158,171,0.20) ! important"
    },
    dialogActionPadding: {
      padding: '15px'
    },

    /* New css design for Manage Course */
    createButton: {
      background: "#5e35b1 !important",
      fontSize: "12px !important",
      borderRadius: 2,
    },
    courseCard: {
      "&:hover": {
        border: "1px solid #007FFF !important",
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important",
      },
    },
    courseContainer: {
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 195px) !important",
        minHeight: "calc( 100vh - 195px) !important",
      },
    },
    userCourseContainer: {
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 125px) !important",
        minHeight: "calc( 100vh - 125px) !important",
      },
    },
    courseQuestion: {
      // padding: '8px !important',
      maxHeight: "calc( 100vh - 240px) !important",
      minHeight: "calc( 100vh - 240px) !important",
      // [theme.breakpoints.up('lg')]: {
      //     maxHeight: 'calc( 100vh - 270px) !important',
      //     minHeight: 'calc( 100vh - 270px) !important',
      // },
    },
    /* Add Course  */
    timelineItem: {
      flex: "0 !important",
      "& .MuiTimelineItem-missingOppositeContent:before": {
        flex: "0 !important",
      },
      "& .MuiTimelineItem-root::before": {
        content: "" /* You can modify the content property as needed */,
        flex: 1,
        padding: 0,
        /* Other styles as needed */
      },
    },
    /* Manage Topic */
    topicContainer: {
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 240px) !important",
        minHeight: "calc( 100vh - 240px) !important",
      },
    },
    //manager relation
    TreeViewContainer: {
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 140px) !important",
        minHeight: "calc( 100vh - 140px) !important",
      },
    },
    listButtonActive: {
      color: "#5e35b1 !important",
      cursor: "pointer !important",
      padding: "3px 5px!important",
      marginTop: "0px!important",
      fontWeight: "bold",
      borderRadius: "5px!Important",
      backgroundColor: "#E6E6FA!important",
    },
    listButtonInActive: {
      padding: "3px 5px !important",
      marginTop: "0px !important",
      backgroundColor: "transparent !important",
      cursor: "pointer !important",
      // borderLeft: '5px solid transparent !important',
    },
    submitButton: {
      // background: '#5e35b1 !important',
      fontSize: "12px !important",
      borderRadius: 2,
    },

    /* User Page Css */

    tabList: {
      borderBottom: "1px solid lightgray",
      "& .MuiTabs-indicator": {
        backgroundColor: "#5e35b1 !important",
      },
    },
    tabs: {
      textTransform: "none",
      minHeight: "45px !important",
      "&.Mui-selected": {
        color: "#5e35b1 !important",
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    listItemIcon: {
      minWidth: "30px !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    ListItemIcon1: {
      minWidth: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    courseTitle: {
      color: "#5e35b1",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    userCourseTitle: {
      // color: "white",
      color: '#5e35b1',
      overflow: "hidden",
      display: "-webkit-box !important",
      WebkitBoxOrient: "vertical",
      whiteSpace: 'nowrap',
      textOverflow: "ellipsis !important",
      WebkitLineClamp: 5,
      height: "80px",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    borderBottom: {
      borderBottom: "1px dashed lightgray",
    },
    ellipsis: {
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      wordWrap: "break-word",
      textOverflow: "ellipsis",
      WebkitLineClamp: 5,
      height: "80px",
    },
    ellipsisUserView: {
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      wordWrap: "break-word",
      textOverflow: "ellipsis",
      WebkitLineClamp: 5,
      height: "150px",
    },
    ellipsisUserView1: {
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      wordWrap: "break-word",
      textOverflow: "ellipsis",
      WebkitLineClamp: 2,
      height: "40px",
    },
    headingEllipsis: {
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      wordWrap: "break-word",
      textOverflow: "ellipsis",
      WebkitLineClamp: 1,
      height: "100px",
    },
    headUserEllipsis: {
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      wordWrap: "break-word",
      textOverflow: "ellipsis",
      WebkitLineClamp: 2,
      height: "50px",
      // borderTopLeftRadius: "8px",
      // borderTopRightRadius: "8px",
      alignItems: 'center'
    },
    customizedDivider: {
      justifyContent: "flex-end",
    },
    dialogClose: {
      cursor: "pointer !important",
      position: "absolute",
      right: "14px !important",
      top: "14px !important",
    },
    closeButton: {
      cursor: "pointer",
      position: "absolute",
      right: "14px",
      top: "10px",
      color: "white"
    },
    typography: {
      variant: "h5",
      textAlign: "center",
      fontFamily: "times new roman",
    },
    typography1: {
      variant: "h6",
      textAlign: "center",
      fontFamily: "times new roman",
    },
    typography2: {
      variant: "h6",
      textAlign: "center",
      fontFamily: "times new roman",
      ml: 0,
      letterSpacing: 0,
      fontSize: 15,
    },
    typography3: {
      variant: "h6",
      textAlign: "center",
      letterSpacing: 0,
      fontFamily: "times new roman",
    },
    activeCertificate: {
      justifyContent: "center !important",
      flexDirection: "column",
      backgroundColor: "#d8f9ff !important",
      "&:hover": {
        backgroundColor: "#d8f9ff !important",
      },
    },
    inActiveCertificate: {
      justifyContent: "center !important",
      flexDirection: "column",
      backgroundColor: "lightgray !important",
      "&:hover": {
        backgroundColor: "lightgray !important",
      },
    },
    CertificateTemplate: {
      justifyContent: "center !important",
      flexDirection: "column",
      backgroundColor: "transparent !important",
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
    imageBox: {
      minWidth: "calc(300px + 10vw) !important"
    },
    image: {
      width: "200px",
      marginTop: "15px",
      border: "1px solid gray",
      height: "115px"
    },
    skeleton: {
      width: "200px",
      marginTop: "15px",
      border: "1px solid gray"
    },
    iconPosition: {
      position: "absolute"
    },
    snackbarColor: {
      color: "white !important",
      backgroundColor: "green !important"
    },
    selectedTopic: {
      padding: '3px 30px 3px 15px'
    },
    dropzone: {
      minHeight: '140px !important',
      fontSize: '12px !important',
      border: '1px dashed blue !important',
      backgroundColor: '#f1f5ff !important',
      boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px !important',
      borderRadius: '5px',
    },
    filenamestyle: {
      fontWeight: 'bold',
      color: 'green',
    },
    orgTableContainer: {
      maxHeight: "690px !important",
      minHeight: "690px !important",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 290px) !important",
        minHeight: "calc( 100vh - 290px) !important",
      },
    },
    tableHead: {
      background: '#7d77c6 !important',
    },
    orgTableCellHeader: {
      color: "white !important",
      padding: '4px 15px !important',
      fontSize: '12px',
      fontWeight: 'bold',
      background: '#5e35b1 !important',
    },
    checkBoxStyle: {
      color: 'white !important',
      padding: '5px !important'
    },
    orgTableCellStyle: {
      padding: '3px 15px !important',
      '& .MuiTableCell-root': {
        padding: '3px 15px!important',
      }
    },
    verticalTreeLine: {
      borderLeft: '1px dashed #000000 !important',
      height: '100% !important',
      marginLeft: '5px !important',
    },
    //new
    paper2: {
      border: '2px solid black',
      height: 'auto',
      margin: 0
    },
    GreenColor: {
      fontSize: 12,
      marginLeft: '5px',
      color: 'green',
      minWidth: 350
    },
    clientCodeStyle: {
      fontSize: 12,
      marginLeft: '5px',
      minWidth: 350
    },
    inputFontSize: {
      fontSize: '12px !important',
      padding:"0px !important"
    },
    textBoxStyle: {
      marginTop: '13px',
      fontSize: 13,
      width: '100%',
      '& .MuiChip-labelSmall': {
        paddingLeft: '5px',
        paddingRight: '5px',
        fontSize: '13px'
      },

    },
    LabelBoxStyle: {
      margin: '6px 2px',
      marginTop: '12px'
    },
    formLabel: {
      fontWeight: 'bold',
      color: 'green',
      fontSize: 14,
    },
    boxBorder: {
      padding: '2px 8px',
      borderRadius: '5px',
      width: '98%',
      marginTop: '12px',
      border: '2px solid #3bb78f',
      boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
    },
    textBoxStyle2: {
      fontSize: 10,
      // padding: '2px',
      '& .MuiChip-labelSmall': {
        fontSize: 10
      }
    },
    valueContainer: {
      "& > :not(:last-child)": {
        marginRight: '5px'
      },
      "& > *": {
        // marginTop: '5px',
        marginBottom: '5px'
      },
      

    },
    scrollMargin: {
      '& div:first-child': {
        marginBottom: '5px',
      },
      '& div:nth-child(3) div': {
        backgroundColor: '#2377e4 !important',
      },
      '& div:nth-child(3)': {
        top: '10px !important',
        bottom: '5px !important'
      },
    },
    textField: {
      width: 240,
      alignContent: 'flex-start',
      justify: "flex-start",
      marginTop: '14px',
      '& .MuiFormControl-marginNormal': {
        marginTop: '10px'
      }
    },
    clientCodeBox: {
      width: '300%',
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px 4px 4px 4px',
        selectedTopic: {
          padding: '3px 30px 3px 15px'
        },
      }
    },
    dialogStyles: {
      display: "flex !important",
      justifyContent: "center !important",
      backgroundColor: "#5e35b1 !important",
      color: "white !important",
      padding: "9px 1px 2px 2px !important"
    },
    ButtonStyle: {
      backgroundColor: "red !important",
      color: "white !important"
    },
    AddbuttonStyle: {
      backgroundColor: "green !important",
      color: "white !important"
    },
    managerStyle: {
      backgroundColor: '#ccfcee !important',
      color: '#338b7e !important',
      borderRadius: '5px!important ',
    },
    accounticonStyle: {
      color: '#5e35b1 !important',
      marginRight: "5px !important"
    },
    chipDeleteIcon: {
      color: 'green !important',
    },
    selectedOption: {
      backgroundColor: '#ccfcee !important',
      color: 'green !important',
    },
    unselectedOption: {
      backgroundColor: 'transparent !important',
      color: 'black !important',
    },
    chip: {
      marginRight: '5px',
      marginBottom: '5px',
      backgroundColor: '#ccfcee !important',
      color: "green !important"
    },
    containerpaperStyle: {
      border: '2px solid #2196F3',
      padding: '10px',
      height: '200px',
      overflow: 'auto'
    },
    tableHeadBackground: {
      backgroundColor: "#36454F"
    },
    ButtonStyles: {
      mt: "1px !important",
      width: '40px  !important',
      height: "33px !important",
      right: "30px  !important",
      borderRadius: '8px  !important',
      transition: 'border-radius 0.3s  !important',
      '&:hover': {
        backgroundColor: "#5e35b1  !important",
        border: "2px solid black  !important",
      },
    },
    courseStyle: {
      mt: 1,
      width: '40px !important',
      height: "33px !important",
      backgroundColor: "green !important",
      borderRadius: '8px !important',
      transition: 'border-radius 0.3s !important', // Smooth transition for border radius change
      '&:hover': {
        backgroundColor: "#5e35b1 !important",
        border: "2px solid black !important",
      },
    },
    dialogBorderRadius: {
      borderRadius: "20px"
    },
    iconColor:{
      color:"red", 
      fontSize:"10px !important"
    },
    dialog:{
      width:"800px",
      borderRadius:"10px !important"
    },
    dialogTitle: {
      display: "flex !important",
      justifyContent: "center !important",
      backgroundColor: "#5e35b1 !important",
      color: "white !important",
      padding: "9px 1px 2px 2px !important"
    },
    borderTop:{
      borderTop:"1px solid lightgray"
    }
  })
);
export default useStyles;
