import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { GraphChartProps } from "../../../model/Dashborad/Dashboard";

const GraphChart: React.FC<GraphChartProps> = ({ data }) => {
    const series = [
        {
            name: 'Time Spent (hours)',
            data: data?.map(d => d.timeSpent),
        }
    ];

    const options: ApexOptions = {
        chart: {
            type: 'line',
            height: 300,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true
            },
        },
        title: {
            text: 'Daily Student Time Spent',
            align: 'left',
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#263238'
            }
        },
        xaxis: {
            categories: data.map(d => d.date),
            title: {
                text: 'Date',
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#263238'
                }
            },
            labels: {
                style: {
                    fontSize: '12px',
                    colors: ['#263238']
                }
            }
        },
        yaxis: {
            title: {
                text: 'Time Spent (hours)',
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#263238'
                }
            },
            labels: {
                style: {
                    fontSize: '12px',
                    colors: ['#263238']
                }
            },
            max: 8
        },
        tooltip: {
            y: {
                formatter: (val: number) => `${val} hours`
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#263238']
            }
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        }
    };

    return (
        <div>
            <ReactApexChart options={options} series={series} type="line" height={450} />
        </div>
    );
};
export default GraphChart;