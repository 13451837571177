import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
iconButtons: {
    marginTop: '3px !important',
    color: '#5e35b1 !important',
    backgroundColor: "#E6E6FA !important",
    '&:hover': { color: 'white !important', backgroundColor: "#5e35b1 !important" },
    borderRadius: '10px'
  },
  scrollbar: {
    minHeight: 'calc(90vh - 240px)',
    maxHeight: 'calc(90vh - 240px)'
},
AdminSessionContainer:{
  minHeight: 'calc(95vh - 610px)',
  maxHeight: 'calc(95vh - 610px)'
},
CourseRichTextEditor:{
  minHeight: 'calc(90vh - 700px)',
  maxHeight: 'calc(90vh - 700px)'
},
root: {
  width: '100%',
  backgroundColor: theme.palette.background.paper,
},
listItem: {
  border: '1px solid #ccc',
  marginBottom: theme.spacing(1),
},
paper: {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  //padding: theme.spacing(0),
},
LabContainer: {
  [theme.breakpoints.up("lg")]: {
      maxHeight: "calc( 100vh - 237px) !important",
      minHeight: "calc( 100vh - 237px) !important",
  },
},
InstructionContainer: {
  [theme.breakpoints.up("lg")]: {
      maxHeight: "calc( 80vh - 380px) !important",
      minHeight: "calc( 80vh - 380px) !important",
  },
},
stepsContainer:{
  [theme.breakpoints.up("lg")]: {
    maxHeight: "calc( 55vh - 100px) !important",
    minHeight: "calc( 55vh - 100px) !important",
},
}
}),
);

export default useStyles;
