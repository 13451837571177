/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Link } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import { usePost } from '../../utils/apiHelper';
import { baseImage } from '../Constant/Constant';
import { ICourseFile } from '../../model/course';
import useStyles from '../ManageCourse/CourseCss';
import React, { useEffect, useState } from 'react';
import { base64toBlob } from '../../utils/Base64toblob';
import { useGlobalState } from '../../GlobalStore/globalStore';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { CustomLinearProgress } from '../../GlobalStyles/CustomLinearProgress';


const getCourseLevelLabel = (id) => {
    switch (id) {
        case 1:
            return "Beginner";
        case 2:
            return "Intermediator";
        case 3:
            return "Expert";
        default:
            return "Not Selected";
    }
};

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center" p={0}>
            <Box width="100%" mr={2}>
                <CustomLinearProgress variant="determinate" {...props} color='success' />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                    <b>{`${Math.round(props.value)}%`}</b>
                </Typography>
            </Box>
        </Box>
    );
}

const CompletedCourseView = (props) => {

    const { state } = useGlobalState();
    const { userCourseList } = props;
    const classes = useStyles();
    const [imageData, setImageData] = useState([]);

    useEffect(() => {
        userCourseList.forEach(course => {
            GetCourseMediaDetail(course?.courseId);
        });
    }, [userCourseList]);

    const GetCourseMediaDetail = (courseId) => {
        (async () => {
            let requests = {
                courseId: courseId,
                courseTopicId: null,
                courseSubTopicId: null,
            };
            await usePost<ICourseFile[]>("File/GetCourseMediaDetail", requests).then(
                (r) => {
                    fetchProductImages(r.data[0]?.fileURI, r.data[0]?.courseId, r.data[0]?.fileType)
                }
            );
        })();
    };
    const HandleRetake = (courseId) => {
        (async () => {
            let request = {
                "userId": state.userAccessContext.userId,
                "courseId": courseId
            };
            await usePost('UserCourse/ResetCourseProgress', request).then(() =>
                props.callback()
            )
        })()
    }
    const fetchProductImages = (blobUri, courseId, type) => {
        (async () => {
            try {
                const request = { "pdfUri": blobUri };
                if (type !== '.mp4') {
                    const response = await usePost<any>("File/FileDownload", request);
                    const imageUrl = URL.createObjectURL(base64toBlob(baseImage + response?.data));
                    setImageData(prevImageData => [...prevImageData, { imageUrl, courseId }]);
                }
            } catch (error) {
                console.error("An error occurred while fetching product images:", error);
            }
        })();
    };

    return (
        <React.Fragment>
            <Grid container spacing={0} p={0} m={0}>
                {!userCourseList?.length ? (
                    <Typography variant="h6" color="textSecondary" align="center" sx={{ width: '100%', mt: 3 }}>
                        There is no course to display...
                    </Typography>
                ) : (userCourseList?.map((r) => (
                    <Grid item xs={12} md={3} m={0} p={2}>
                        <Grid container spacing={0} m={0} borderRadius={0} boxShadow={2} borderBottom={'5px solid #5e35b1'}>
                            <Grid item xs={12} border={'1px solid lightgray'}>
                                <Box m={0} height={'200px'}>
                                    <img
                                        src={imageData.some(image => image.courseId === r.courseId) ? imageData.find(image => image.courseId === r.courseId).imageUrl : ''}
                                        alt={r?.courseTitle}
                                        width={'100%'}
                                        height={'100%'}
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
                                <Typography variant='body2' p={"15px"} color={'#5e35b1'} className={classes.headUserEllipsis}>
                                    <Link to={`/courseView/${r.courseId}/${r.courseTitle}`} className={classes.userCourseTitle}> <b>{r?.courseTitle}</b> </Link>
                                </Typography>
                                {(state.userAccessContext.isManageCourse && state.userAccessContext.isManageUser && state.userAccessContext.userRole) ? (<Button size='small' color='error' onClick={() => { HandleRetake(r?.courseId) }}>ReTake</Button>) : null}
                            </Grid>
                            <Grid item xs={12} >
                                <Typography variant="body2" color="gray" p={"0px 15px 8px 15px"} textAlign={'justify'} className={classes.ellipsisUserView1}>
                                    {r?.courseAbstract}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} p={"5px 15px"}>
                                <Typography variant='body2' fontSize={'13px'} color={'gray'}><b>Completed Status</b></Typography>
                                <LinearProgressWithLabel value={r?.courseCompletionPercentage} />
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container spacing={1} p={"5px 15px 10px"} >
                                    <Grid item xs={8} display={'flex'} flexDirection={'row'}>
                                        <Typography variant="body2" color="gray">
                                            4.8 (13k reviews)
                                        </Typography>
                                        <StarIcon fontSize='small' htmlColor='#f5c60d' />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Chip label={getCourseLevelLabel(r?.courseLevel)} variant='filled' size='small'
                                            color={
                                                r?.courseLevel === 1 ? 'warning' :
                                                    r?.courseLevel === 2 ? 'secondary' :
                                                        r?.courseLevel === 3 ? 'primary' : 'default'
                                            } />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))
                )}
            </Grid>
        </React.Fragment >
    )
}
export default CompletedCourseView;