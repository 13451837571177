export interface GraphChartProps {
  data: { date: string; timeSpent: number }[];
}
export const data = [
  { date: "2024-07-18", timeSpent: 2 },
  { date: "2024-07-19", timeSpent: 3 },
  { date: "2024-07-20", timeSpent: 1.5 },
  { date: "2024-07-21", timeSpent: 4 },
  { date: "2024-07-22", timeSpent: 2.5 },
  { date: "2024-07-23", timeSpent: 3.2 },
  { date: "2024-07-24", timeSpent: 3.8 },
];
export const avatars = [
  { src: "https://i.pravatar.cc/300?img=1", alt: "Manager 1" },
  { src: "https://i.pravatar.cc/300?img=2", alt: "Manager 2" },
  { src: "https://i.pravatar.cc/300?img=3", alt: "Manager 3" },
  { src: "https://i.pravatar.cc/300?img=4", alt: "Manager 4" },
];
export const courseDetails = [
  {
    courseId: 1,
    courseTitle: "React for Beginners",
    scheduledUsersCount: 60,
    reminder: {
      type: "Assignment Due",
      staff: "John Doe",
      date: "2024-08-01",
    },
  },
  {
    courseId: 2,
    courseTitle: "Advanced TypeScript",
    scheduledUsersCount: 45,
    reminder: {
      type: "Project Submission",
      staff: "Jane Smith",
      date: "2024-08-05",
    },
  },
  {
    courseId: 3,
    courseTitle: "Node.js and Express",
    scheduledUsersCount: 70,
    reminder: {
      type: "Quiz",
      staff: "Alice Johnson",
      date: "2024-08-10",
    },
  },
  {
    courseId: 4,
    courseTitle: "GraphQL with Apollo",
    scheduledUsersCount: 30,
    reminder: {
      type: "Exam",
      staff: "Bob Brown",
      date: "2024-08-15",
    },
  },
  {
    courseId: 5,
    courseTitle: "Introduction to Docker",
    scheduledUsersCount: 55,
    reminder: {
      type: "Final Presentation",
      staff: "Carol White",
      date: "2024-08-20",
    },
  },
];

export const colorList = [
  {
    bgColor: "rgba(91, 228, 155, 0.2)",
    color: "rgb(0, 75, 80)",
  },
  {
    bgColor: "rgba(97, 243, 243, 0.2)",
    color: "rgb(0, 55, 104)",
  },
  {
    bgColor: "rgba(255, 214, 102, 0.2)",
    color: "#03a9f4",
  },
  {
    bgColor: "rgba(255, 171, 0, 0.2)",
    color: "#f50057",
  },
  {
    bgColor: "rgba(244, 67, 54, 0.2)",
    color: "#d32f2f",
  },
];
