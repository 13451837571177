/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { Chip, ClickAwayListener, FormLabel, Typography, } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Autocomplete, { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import CancelIcon from '@mui/icons-material/Cancel';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import { Alert, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useGlobalState } from '../../GlobalStore/globalStore';
import MLearnTooltip from '../../GlobalStyles/ToolTip';
import useStyles from "../../components/ManageCourse/CourseCss";
import { ICourseList } from '../../model/Manager/IMangerCourseDate';
import { ICourseAssign, ICoursecode, IImportCourseInputs, IUserDetail } from '../../model/Users/IUser';
import { useFetch, usePost } from '../../utils/apiHelper';

export default function AssignCourse(props) {
    const { state } = useGlobalState();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackBar] = useState(false);
    const [open, setOpen] = useState(false);
    const [courseCodeOpen, setCourseCodeOpen] = useState(false);
    const [userOpen, setUserOpen] = useState(false);
    const [courseCodeSearch, setCourseCodeSearch] = useState("");
    const [uerSearch, setUserSearch] = useState("");
    const [confirmedCourseCode, setConfirmedCourseCode] = useState<ICourseList[]>([]);
    const [confirmedUsers, setConfirmedUsers] = useState<IUserDetail[]>([]);
    const [courseCodeLoading, setCourseCodeLoading] = useState(false);
    const [selectedCourseCode, setSelectedCourseCode] = useState<any>([]);
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const admin = state.userAccessContext.userRole && state.userAccessContext.isManageCourse && state.userAccessContext.isManageUser;

    let CourseDetails: ICourseAssign = {
        courseStartDate: '',
        courseEndDate: '',
        firstRemainderDate: '',
        SecondRemainderDate: '',
        ThirdRemainderDate: '',
    }
    const [localState, setLocalState] = useState(CourseDetails);
    const [formValid, setFormValid] = useState(false);


    const handleOpen = () => {
        setOpen(true);
        if (admin) {
            setConfirmedUsers(props.userList);
        } else {
            // Assuming userId is available

            setConfirmedUsers(props.userDetail);
        }
        GetAllCourseDetails();
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedUsers([]);

        props.onCreate();
        setSelectedCourseCode([]);
        setLocalState({
            ...localState,
            courseStartDate: '',
            courseEndDate: '',
            firstRemainderDate: '',
            SecondRemainderDate: '',
            ThirdRemainderDate: ''
        });
        setSnackBar(false);
    }

    const handleCourseCodeClickAway = (e, isSearchClear: boolean) => {
        setCourseCodeOpen(false);
        if (isSearchClear) {
        }
    };

    const handleUserClickAway = (e, isSearchClear: boolean) => {
        setUserOpen(false);
        if (isSearchClear) {
        }
    };

    const handleCourseCodeSelect = (e, selected) => {
        setSelectedCourseCode(selected);
        if (selected?.length <= 0) {
        }
        let name = e.target.name;
        setLocalState({
            ...localState, [name]: e.target.value
        });
    }

    const handleUserSelect = (event, selected) => {
        setSelectedUsers(selected);
        if (selected?.length <= 0) {
        }
    }

    const handleUserSearch = (e) => {
        setUserSearch(e.target.value);
    }
    const GetAllCourseDetails = () => {
        (async () => {
            await useFetch<{ listCode: ICoursecode[] }>("Course/GetCourseCodeWithUser").then((r) => {
                setConfirmedCourseCode(r?.data?.listCode); //all course list
            })
        })()
    }



    const onDeleteCourse = (courseId: number) => () => {
        setSelectedCourseCode((value) => value.filter((v) => (v.courseId !== courseId)));
    };

    const onDeleteUser = (userId: number) => () => {
        setSelectedUsers((value) => value.filter((v) => (v.userId !== userId)));
    };

    const handleSave = async () => {
        const importCourseInputs: IImportCourseInputs[] = [];
        selectedCourseCode?.map(async (course: any) => {
            selectedUsers?.map(async (user: any) => {
                let request: IImportCourseInputs = {
                    emailAddress: user?.emailAddress,
                    courseCode: course?.courseCode,
                    courseStartDate: localState.courseStartDate,
                    firstReminderDate: localState.firstRemainderDate,
                    secondReminderDate: localState.SecondRemainderDate,
                    thirdReminderDate: localState.ThirdRemainderDate,
                    courseEndDate: localState.courseEndDate,
                };
                importCourseInputs.push(request);
            });
        })

        let request = {
            "importCourseInputs": importCourseInputs
        }
        await usePost("Course/ImportCourseSchedule", request).then(() => {
            setOpen(false);
            setLoading(true);
            setSelectedCourseCode([]);
            setSnackBar(true);
            setSnackBar(true);
        }).finally(() => { setSnackbarMessage('Course Assigned Successfully') })
    }
    useEffect(() => {
        ValidateRemainderDate()
    }, [localState]);

    const ValidateRemainderDate = () => {
        const {
            courseStartDate,
            courseEndDate,
            firstRemainderDate,
            SecondRemainderDate,
            ThirdRemainderDate
        } = localState;
    
        const allFieldsFilled = Object.values(localState).every(val => val !== '');
    
        const areDatesValid = (startDate, endDate, dates) => {
            if (!startDate || !endDate) return false;
    
            const dateObjects = dates.map(date => new Date(date));
            const [start, end, ...reminders] = [new Date(startDate), new Date(endDate), ...dateObjects];
            
            // Ensure the start date is today or later
            if (start.getTime() < new Date().setHours(0, 0, 0, 0)) return false;
            if (start >= end) return false;
    
            for (let i = 0; i < reminders.length - 1; i++) {
                if (reminders[i] >= reminders[i + 1]) return false;
            }
    
            return start <= reminders[0] && reminders[reminders.length - 1] < end;
        };
    
        const datesValid = areDatesValid(courseStartDate, courseEndDate, [
            firstRemainderDate,
            SecondRemainderDate,
            ThirdRemainderDate
        ]);
    
        setFormValid(allFieldsFilled && datesValid);
    }
    
    const handleManagerNameChange = (e) => {
        let name = e.target.name;
        setLocalState({
            ...localState, [name]: e.target.value
        });
    }
    return (
        <React.Fragment>
            <MLearnTooltip title="Assign Course" placement="top">
                <Button
                    size="small"
                    variant="contained"
                    color="success"
                    onClick={handleOpen}
                    className={classes.courseStyle}
                >
                    <MenuBookTwoToneIcon />
                </Button>
            </MLearnTooltip>
            <div>
                <Dialog
                    open={open}
                    keepMounted
                    maxWidth={'lg'}
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{ className: classes.dialog }}
                >
                    <DialogTitle boxSizing={'border-box'} className={classes.dialogTitle}><b>ASSIGN COURSE</b>
                        <CancelIcon onClick={handleClose} className={classes.closeButton} />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Box className={classes.LabelBoxStyle}>
                                    <FormLabel className={classes.formLabel}> Selected Course Code : {selectedCourseCode?.length}</FormLabel>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className={classes.LabelBoxStyle}>
                                    <FormLabel className={classes.formLabel}> Selected Users : {selectedUsers.length}</FormLabel>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <ClickAwayListener onClickAway={(e) => { handleCourseCodeClickAway(e, courseCodeSearch.length > 0) }}>
                                    <div style={{ display: 'flex' }}>
                                        <Autocomplete
                                            multiple
                                            size="small"
                                            fullWidth
                                            title='courseId'
                                            loading={courseCodeLoading}
                                            className={classes.clientCodeBox}
                                            autoComplete={false}
                                            id="courseId"
                                            options={Array.from(confirmedCourseCode)}
                                            getOptionLabel={(option) => option?.courseTitle}
                                            classes={{ paper: classes.paper2 }}
                                            disableClearable
                                            value={selectedCourseCode}
                                            open={courseCodeOpen}
                                            renderTags={() => null}
                                            onChange={(e, selected) => { handleCourseCodeSelect(e, selected); }}
                                            onOpen={() => {
                                                setCourseCodeOpen(true);
                                            }}
                                            onClose={(e: any, reason: AutocompleteCloseReason) => {
                                                if (reason === "toggleInput") {
                                                    setCourseCodeOpen(false);
                                                }
                                            }}
                                            getOptionDisabled={(option) => option?.courseId}
                                            renderOption={(option, { selected }) => (
                                                <span className={`${selected ? classes.GreenColor : classes.clientCodeStyle}`} >
                                                    {`${option?.courseCode} -- ${option?.courseTitle}`}
                                                </span>
                                            )}
                                            ListboxProps={{ style: { maxHeight: '160px' } }}
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth size='small' name='courseId'
                                                    value={courseCodeSearch} variant="outlined"
                                                    type='text' placeholder="Search Course Code"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        inputProps: {
                                                            ...params.inputProps,
                                                            maxLength: 256
                                                        },
                                                        classes: {
                                                            root: classes.inputFontSize,
                                                        },
                                                    }
                                                    }
                                                    className={classes.textBoxStyle}
                                                />
                                            )}
                                        />
                                    </div>
                                </ClickAwayListener>
                            </Grid>
                            <Grid item xs={6}>
                                <ClickAwayListener onClickAway={(e) => { handleUserClickAway(e, uerSearch.length > 0) }}>
                                    <div style={{ display: 'flex' }}>
                                        <Autocomplete
                                            multiple size="small"
                                            fullWidth
                                            loading={courseCodeLoading}
                                            className={classes.clientCodeBox}
                                            autoComplete={false}
                                            id="checkboxes-tags-demo"
                                            options={confirmedUsers}
                                            getOptionLabel={(option) => option?.emailAddress}
                                            classes={{ paper: classes.paper2 }}
                                            disableClearable
                                            value={selectedUsers}
                                            open={userOpen}
                                            renderTags={() => null}
                                            onChange={(e, selected) => { handleUserSelect(e, selected); handleManagerNameChange(e); }}
                                            onOpen={() => {
                                                setUserOpen(true);
                                            }}
                                            onClose={(e: any, reason: AutocompleteCloseReason) => {
                                                if (reason === "toggleInput") {
                                                    setUserOpen(false);
                                                }
                                            }}
                                            getOptionDisabled={(option) => option?.courseId}
                                            renderOption={(option, { selected }) => (
                                                <span className={`${selected ? classes.GreenColor : classes.clientCodeStyle}`} >
                                                    {`${option?.emailAddress}`}
                                                </span>
                                            )}
                                            ListboxProps={{ style: { maxHeight: '160px' } }
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth size="small"
                                                    name='userIduserEmailAddress'
                                                    value={courseCodeSearch} variant="outlined"
                                                    type='text' placeholder="Search Users"
                                                    onChange={(e) => { setCourseCodeSearch(e.target.value); }}
                                                    onKeyDown={event => {
                                                        if (event.key === 'Enter') { handleUserSearch(event); setUserOpen(true); }
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        inputProps: {
                                                            ...params.inputProps,
                                                            maxLength: 256
                                                        },
                                                        classes: {
                                                            root: classes.inputFontSize,
                                                        },
                                                    }
                                                    }
                                                    className={classes.textBoxStyle}
                                                />
                                            )}
                                        />
                                    </div>
                                </ClickAwayListener>
                            </Grid>
                            <Grid item xs={6}>
                                <Card className={classes.boxBorder}>
                                    <Scrollbars autoHide
                                        autoHideTimeout={1000}
                                        autoHideDuration={10}
                                        autoHeight
                                        className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                        autoHeightMin="auto"
                                        autoHeightMax={75}
                                        style={{ width: '100%' }}>
                                        <div className={`${classes.valueContainer}`} >
                                            {selectedCourseCode.length === 0 ? (<Typography variant='subtitle1' align='center' color="textPrimary">select the courseCode</Typography>) : (selectedCourseCode.map((v) => (
                                                <Chip key={v} size="small" color="secondary" label={v.courseTitle} onDelete={onDeleteCourse(v.courseId)} />
                                            ))
                                            )}                                              </div>
                                    </Scrollbars>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card className={classes.boxBorder}>
                                    <Scrollbars autoHide
                                        autoHideTimeout={1000}
                                        autoHideDuration={10}
                                        autoHeight
                                        className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                        autoHeightMin="auto"
                                        autoHeightMax={75}
                                        style={{ width: '100%' }}>
                                        <div className={`${classes.valueContainer}`} >
                                            {(selectedUsers.length === 0) ? (<Typography variant='subtitle1' align='center' color="textPrimary">select the emailAddress</Typography>)
                                                : (selectedUsers.map((v) => (
                                                    <Chip key={v} size="small" color="secondary" label={v.emailAddress} onDelete={onDeleteUser(v.userId)} />
                                                )))}
                                        </div>

                                    </Scrollbars>
                                </Card>

                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    id="GM_Startdate"
                                    size="small"
                                    fullWidth
                                    label="Start Date"
                                    name='courseStartDate'
                                    variant="outlined"
                                    type="date"
                                    value={localState.courseStartDate}
                                    onChange={(e) => handleManagerNameChange(e)}
                                    className={classes.textField}
                                    inputProps={{ max: "9999-12-31" }}
                                    InputProps={{
                                        classes: {
                                            root: classes.outlinedInput
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    id="GM_Startdate"
                                    size="small"
                                    label="End Date"
                                    variant="outlined"
                                    type="date"
                                    fullWidth
                                    name='courseEndDate'
                                    value={localState.courseEndDate}
                                    onChange={(e) => handleManagerNameChange(e)}
                                    className={classes.textField}
                                    inputProps={{ max: "9999-12-31" }}
                                    InputProps={{
                                        classes: {
                                            root: classes.outlinedInput
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} mt={1}>
                                <TextField
                                    id="GM_Startdate"
                                    size="small"
                                    fullWidth
                                    label="First Reminder Date"
                                    variant="outlined"
                                    type="date"
                                    name='firstRemainderDate'
                                    value={localState.firstRemainderDate}
                                    onChange={(e) => handleManagerNameChange(e)}
                                    className={classes.textField}
                                    inputProps={{ max: "9999-12-31" }}
                                    InputProps={{
                                        classes: {
                                            root: classes.outlinedInput
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} mt={1}>
                                <TextField
                                    id="GM_Startdate"
                                    size="small"
                                    fullWidth
                                    label="Second Reminder Date"
                                    variant="outlined"
                                    type="date"
                                    name='SecondRemainderDate'
                                    value={localState.SecondRemainderDate}
                                    onChange={(e) => handleManagerNameChange(e)}
                                    className={classes.textField}
                                    inputProps={{ max: "9999-12-31" }}
                                    InputProps={{
                                        classes: {
                                            root: classes.outlinedInput
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} mt={1}>
                                <TextField
                                    id="GM_Startdate"
                                    size="small"
                                    fullWidth
                                    label="Third Reminder Date"
                                    variant="outlined"
                                    type="date"
                                    name='ThirdRemainderDate'
                                    value={localState.ThirdRemainderDate}
                                    onChange={(e) => handleManagerNameChange(e)}
                                    className={classes.textField}
                                    inputProps={{ max: "9999-12-31" }}
                                    InputProps={{
                                        classes: {
                                            root: classes.outlinedInput
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={classes.borderTop}>
                        <Button size='small' onClick={handleSave} variant='contained' color='success' disabled={!formValid} startIcon={<CheckCircleIcon />}>SAVE</Button>
                        <Button size='small' onClick={handleClose} variant='contained' color='error' startIcon={<CancelIcon />}>CLOSE</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Snackbar
                open={snackbar}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                color='success'
                className={classes.snackbarColor}
            >
                <Alert onClose={handleClose} severity="success" className={classes.snackbarColor}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </React.Fragment >
    );
}