/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useState } from 'react';
import { Grid, TextField, Autocomplete, Typography, Button, Stepper, Step, StepLabel } from "@mui/material";
import { IEducationalQualification, IExperience, IPrepareCV, IProject, ISkill } from '../../../../model/Users/IUser';
import { Box } from '@mui/system';
import Scrollbars from 'react-custom-scrollbars';
import useStyles from '../ProfileCardCss';
import ColorlibConnector from '../../../../GlobalStyles/ColorlibConnector';
import { steps } from './steps';
import ColorlibStepIcon from './ColorlibStepIcon';
import { useHistory } from 'react-router-dom';
interface Skill {
    skillName: string;
    hobbiesName: string;
}

const Skills = (props) => {
    const classes = useStyles();
    const [inputSkill, setInputSkill] = useState<string>(''); // State to track input value for skills
    const [inputHobby, setInputHobby] = useState<string>(''); // State to track input value for hobbies
    const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]); // State to track selected skills
    const skillInformation: ISkill[] = props?.location?.state?.skillInformation;
    const [options, setOptions] = useState<Skill[]>(skillInformation || []);
    const [selectedHobbies, setSelectedHobbies] = useState<Skill[]>([]); // State to track selected hobbies
    const [activeStep, setActiveStep] = useState(2);
    const history = useHistory();
    const experienceInformation: IExperience[] = props?.location?.state?.experienceInformation;
    const projectInformations: IProject[] = props?.location?.state?.projectInformations;
    const profileInformation: IPrepareCV[] = props?.location?.state?.profileInformation;
    const educationInformations: IEducationalQualification[] = props?.location.state?.educationInformations;
    const handleSkillInputChange = (event: ChangeEvent<{}>, value: string) => {
        setInputSkill(value); // Update input value for skills
    };

    const handleHobbyInputChange = (event: ChangeEvent<{}>, value: string) => {
        setInputHobby(value); // Update input value for hobbies
    };


    const handleSkillKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && inputSkill.trim() !== '') {
            const newSkill: Skill = { skillName: inputSkill.trim(), hobbiesName: '' };
            setSelectedSkills([...selectedSkills, newSkill]);
            setOptions([...options, newSkill]);
            setInputSkill('');
        }
    };

    const handleHobbyKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && inputHobby.trim() !== '') {
            const newHobby: Skill = { skillName: '', hobbiesName: inputHobby.trim() };
            setSelectedHobbies([...selectedHobbies, newHobby]);
            setOptions([...options, newHobby]);
            setInputHobby('');
        }
    };
    const updatedSkillInformation = [
        ...skillInformation,
        ...selectedSkills.map(skill => ({ skillName: skill.skillName, hobbiesName: '' })),
        ...selectedHobbies.map(hobby => ({ skillName: '', hobbiesName: hobby.hobbiesName }))
    ];

    const handleNextStep = () => {
        history.push("/Experience", {
            profileInformation: profileInformation, educationInformations: educationInformations,
            skillInformation: updatedSkillInformation, experienceInformation: experienceInformation, projectInformations: projectInformations
        });
    };

    const handleBackStep = () => {
        history.push("/EducationalInformation", {
            profileInformation: profileInformation, educationInformations: educationInformations,
            skillInformation: skillInformation, experienceInformation: experienceInformation, projectInformations: projectInformations
        });
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={2} display={"flex"} justifyContent={"center"} alignItems={"center"} borderRight={"1px solid lightGray"} style={{ backgroundColor: "#7a64a7" }}>
                <Stepper
                    activeStep={activeStep}
                    orientation='vertical'
                    connector={<ColorlibConnector />}
                >
                    {steps.map((step, index) => (
                        <Step key={step.label} sx={{ color: "white" }}>
                            <StepLabel StepIconComponent={ColorlibStepIcon} icon={step.icon}>
                                <b style={{ color: activeStep === index ? "orange" : (index < activeStep ? "limegreen" : "#ccc"), fontSize: activeStep === index ? "18px" : "14px" }}>
                                    {step.label}
                                </b>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            <Grid item xs={10}>
                {activeStep === 2 && (
                    <Grid container spacing={1} ml={0}>
                        <Grid item xs={12} pt={6} borderBottom={'1px dashed blue'}>
                            <Typography variant='body1' color="#5e35b1"><b>Skills</b></Typography>
                        </Grid>
                        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.educationContainer}>
                            <Grid container spacing={0}>
                                {/* Future Work */}
                                {/* <Grid item xs={12}>
                                    <Paper className={classes.containerpaperStyle}>
                                        {chip.map((chip, index) => (
                                            <Chip key={index} label={chip} style={{ margin: '4px' }} />
                                        ))}
                                    </Paper>
                                </Grid> */}
                                <Grid item xs={12} marginLeft={"20px"}>
                                    <Typography variant='body1'><b>Skill</b></Typography>
                                    <Autocomplete
                                        multiple
                                        id="size-small-outlined-multi"
                                        size="small"
                                        options={options}
                                        getOptionLabel={(option) => option?.skillName || ''}
                                        value={selectedSkills}
                                        onChange={(event, newValue) => setSelectedSkills(newValue as Skill[])}
                                        inputValue={inputSkill}
                                        onInputChange={handleSkillInputChange}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Enter your Skills..." onKeyDown={handleSkillKeyDown} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} marginLeft={"25px"}>
                                    <Typography variant='body1'><b>Hobbies</b></Typography>
                                    <Autocomplete
                                        multiple
                                        id="size-small-outlined-multi"
                                        size="small"
                                        options={options}
                                        getOptionLabel={(option) => option?.hobbiesName || ''}
                                        value={selectedHobbies}
                                        onChange={(event, newValue) => setSelectedHobbies(newValue as Skill[])}
                                        inputValue={inputHobby}
                                        onInputChange={handleHobbyInputChange}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Enter your Hobbies..." onKeyDown={handleHobbyKeyDown} />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Scrollbars>
                        <Box pt={0.5} width={'100%'} display={'inline-flex'} justifyContent={'flex-end'} borderTop={'1px solid lightgray'}>
                            <Button variant="contained" size="small" color="success" sx={{ marginRight: "20px" }} onClick={handleBackStep}>
                                Back
                            </Button>
                            <Button variant="contained" size="small" color="success" sx={{ marginRight: "20px" }} onClick={handleNextStep}>
                                <b>Next</b>
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default Skills;

