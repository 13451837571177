import React from "react";
import { IconButton } from "@material-ui/core";
import { Grid } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import useStyles from "./TopicContentCss";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";

export default function Prompt({ onPromptChange, value }: any) {
  const classes = useStyles();
  const [prompt, setPrompt] = useState("");

  useEffect(() => {
    setPrompt(value);
  }, [value]);

  const handlePromptInputChange = (e: any) => {
    const newPrompt = e.target.value;
    setPrompt(newPrompt);
    onPromptChange(newPrompt);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.display}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={prompt}
          placeholder="Prompt"

          onChange={handlePromptInputChange}
          InputProps={{
            endAdornment: (
              <IconButton size="small">
                <SendIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </React.Fragment>
  );
}
