//React
import React from "react";
//Mui components
import Grid from '@mui/material/Grid';
//Components
import AddLab from '../../ManageLab/AddLab';

export default function LabView() {
    return (
        <React.Fragment >
            <Grid item xs={12} display={'flex'} >
                <Grid item xs={7} ml={1} mt={1} sx={{ minHeight: 'calc(100vh - 189px)' }} color={'lightgray'} borderRight={'1px dashed gray'}>
                    <Grid item xs={12}>
                        <iframe
                            id="muiIframe"
                            src="https://codesandbox.io/embed/material-ui-example-2i7rh?fontsize=14&hidenavigation=1&theme=dark&view=preview"
                            style={{
                                width: "99%",
                                minHeight: "calc(100vh - 155px)",
                                border: 1,
                                borderRadius: "4px",
                                overflow: "hidden"
                            }}
                            allowFullScreen
                            title="Material-UI Example"
                            allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb"
                            sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={5} m={1} mt={0} sx={{ minHeight: 'calc(100vh - 188px)' }}>         
                            <Grid item xs={12}>
                                <AddLab />
                            </Grid>       
                </Grid>
            </Grid>
        </React.Fragment>
    );
}