/* eslint-disable react-hooks/rules-of-hooks */
import { DialogActions, DialogContent, DialogTitle, } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SchoolSharpIcon from '@mui/icons-material/SchoolSharp';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { Button, Chip, Dialog, Grid, IconButton, Paper, Stack, Switch, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import MLearnTooltip from '../../GlobalStyles/ToolTip';
import { ICoursecode } from "../../model/Users/IUser";
import { useFetch, usePost } from "../../utils/apiHelper";
import useStyles from '../UsersDetails/UserCss';
const EditAssignCourse = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [courseListResult, setCourseListResult] = useState<ICoursecode[]>([]);
    const [selectedOptionCourse, setSelectedOptionCourse] = useState<ICoursecode[]>([]);
    const [courseInputValue, setCourseInputValue] = useState<string>('');
    const [selectAllCourse, setSelectAllCourse] = useState<boolean>(false);
    const [allCourseOptions, setAllCourseOptions] = useState<ICoursecode[]>();

    const handleClickOpen = () => {
        setOpen(true);
        GetAllCourseDetails();

        GetManagerUserPermissionDetail();
    };

    const handleClose = () => {
        setOpen(false);
    };
    const GetAllCourseDetails = () => {
        (async () => {
            await useFetch<{ listCode: ICoursecode[] }>("Course/GetCourseCodeWithUser").then((r) => {
                setCourseListResult(r?.data?.listCode); //all course list
            })
        })()
    }
    const handleDefaultMappedCourse = (Data) => {
        const defaultSelectedValues: ICoursecode[] = Data.map(option => ({
            courseId: option.courseId,
            courseTitle: option.courseTitle,
            courseCode: option.courseCode
        }));
        setSelectedOptionCourse(defaultSelectedValues);
    }
    const GetManagerUserPermissionDetail = () => {
        let request = {
            'UserId': props.userId
        };
        (async () => {
            await usePost<{ courses: ICoursecode[] }>(`Users/GetManageUserDetail?UserId=${props.userId}`, request).then((response) => {

                handleDefaultMappedCourse(response.data?.courses);  //default course detais

            });
        })()
    }

    const handleSubmit = async () => {
        const CourseIds = selectedOptionCourse?.map((code) => ({
            'CourseIds': code.courseId,
        }));

        (async () => {
            let request = {
                "UserId": props.userId, // Assuming managerEmail holds the managerId value
                "CourseIds": CourseIds // Use mangeLearnerMapping directly
            };
            await usePost("UserCourse/UnAssignCourse", request).then(() => {
                props.onCreate()
                setOpen(false)
            });
        })()
    };
    const handleSelectAllCourse = () => {
        setSelectAllCourse(true);
        setAllCourseOptions(courseListResult); // Set all options when switch is on
        setSelectedOptionCourse([...selectedOptionCourse, ...allCourseOptions]);
    };

    const handleDeselectAllCourse = () => {
        setSelectedOptionCourse([]);
        setSelectAllCourse(false);
    };

    const handleDeleteCourseChip = (index: number) => {
        if (selectAllCourse) {
            const updatedOptions = [...allCourseOptions];
            updatedOptions.splice(index, 1);
            setAllCourseOptions(updatedOptions);
            setSelectedOptionCourse(updatedOptions);
        } else {
            const updatedOptions = [...selectedOptionCourse];
            updatedOptions.splice(index, 1);
            setSelectedOptionCourse(updatedOptions);
        }
    };
    const handleCourseOptionSelect = (event, value) => {
        if (value && !selectedOptionCourse.find(option => option.courseTitle === value.courseTitle)) {
            setSelectedOptionCourse([...selectedOptionCourse, value]);
        }
        setCourseInputValue('');
    }

    return (
        <React.Fragment>
            <MLearnTooltip title="UnAssign Course" placement="top">
                <IconButton size="small" sx={{ marginLeft: "5px" }} onClick={handleClickOpen}>
                    <UnarchiveIcon color='success' />
                </IconButton>
            </MLearnTooltip>
            <Dialog open={open} onClose={handleClose} fullWidth PaperProps={{ style: { borderRadius: 15 } }}>
                <DialogTitle className={classes.dialogStyles}> UN-Assign Course </DialogTitle>
                <CancelOutlinedIcon
                    onClick={handleClose}
                    className={classes.closeButton}
                />
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Paper className={classes.containerpaperStyle}>
                                {selectedOptionCourse?.map((option, index) => (
                                    <Chip
                                        key={index}
                                        label={option?.courseTitle}
                                        onDelete={() => handleDeleteCourseChip(index)}
                                        color="primary"
                                        size="small"
                                        sx={{ m: '3px' }}
                                    />
                                ))}
                                {selectedOptionCourse.length === 0 && (
                                    <Grid container justifyContent="center" alignItems="center" flexDirection="column" color="#5e35b1">
                                        <Typography variant="subtitle1" textAlign="center">
                                            <b>Select Course</b>
                                        </Typography>
                                        <SchoolSharpIcon fontSize="large" />
                                    </Grid>
                                )}
                            </Paper>

                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                size='small'
                                fullWidth
                                id="checkboxes-tags-demo"
                                getOptionLabel={(option) => option?.courseTitle}
                                value={null} //Reset the Autocomplete Value
                                inputValue={courseInputValue}
                                className={classes.autoCompleteStyle}
                                onChange={handleCourseOptionSelect}
                                onInputChange={(event, newInputValue) => setCourseInputValue(newInputValue)}
                                getOptionSelected={(option, value) => option?.courseTitle === value?.courseTitle}
                                renderInput={(params) => (
                                    <TextField {...params} label="Assign Course" placeholder="Favorites" />
                                )} options={courseListResult}
                                renderOption={(option) => {
                                    const selected = selectedOptionCourse?.some((selectedOption) => selectedOption?.courseTitle === option?.courseTitle);
                                    return <li className={selected ? classes.selectedOption : classes.unselectedOption}>{option?.courseTitle}</li>;
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Deselect All</Typography>
                                <Switch
                                    id="selected course"
                                    defaultChecked={false}
                                    checked={selectAllCourse}
                                    onChange={(e) => {
                                        e.target?.checked ? handleSelectAllCourse() : handleDeselectAllCourse();
                                    }}
                                    inputProps={{ 'aria-describedby': 'switch-description' }}
                                />
                                <Typography>Select All</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary" className={classes.AddbuttonStyle} startIcon={<CancelIcon />}>
                        Update
                    </Button>
                    <Button onClick={handleClose} color="secondary" className={classes.ButtonStyle} startIcon={<CheckCircleIcon />}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};
export default EditAssignCourse;
