/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
// eslint-disable-next-line react-hooks/exhaustive-deps
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, FormControlLabel, Grid, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { ITopicDetailResultContract, TopicContentProps } from "../../../model/course";
import { usePost } from "../../../utils/apiHelper";
import { Attachments } from "./TopicContent/Attachments";
import Prompt from "./TopicContent/ChatPrompt";
import CustomizedDividers from "./TopicContent/CustomizedDividers";
import useStyles from "./TopicContent/TopicContentCss";
import UrlLinkView from "./TopicContent/UrlView";
import VideoView from "./TopicContent/VideoView";
import { useGlobalState } from "../../../GlobalStore/globalStore";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';



const TopicContent: React.FC<TopicContentProps> = (props) => {

  const { state } = useGlobalState();
  const initailaValue: ITopicDetailResultContract = {
    topicId: 0,
    parentTopicId: 0,
    courseId: 0,
    topicName: "",
    topicDescrition: "",
    topicObjective: "",
    topicVideoURL: "",
    topicSearchURL: "",
    topicPrompt: "",
    stipulatedTime: "",
    isAssignmentRequired: false
  };

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const { topicId, courseId, SubTopicId, flag, onCallBack } = props;
  const [getTopicDetails, setGetTopicDetails] = useState<ITopicDetailResultContract>();
  const [file, setFile] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState(getTopicDetails?.stipulatedTime);
  const [description, setDescription] = useState<string>("");



  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = () => {
    (async () => {
      let request = {
        "topicId": flag ? topicId : SubTopicId,
        "isTopicSelected": flag
      };
      await usePost<{ topicDetailResultContracts: ITopicDetailResultContract[] }>("Course/GetTopicInformation", request).then((response) => {
        setGetTopicDetails(response.data.topicDetailResultContracts[0])
      })
    })()
  }

  useEffect(() => {
    fetchData();
  }, [props]);

  const handleCustomizedDividersChange= (newContent: string) => {
    setGetTopicDetails(prevState => ({
      ...prevState,
      topicDescrition: newContent
    }));
  };

  const handleCustomizedDividersChange1 = (newContent: string) => {
    setGetTopicDetails(prevState => ({
      ...prevState,
      topicObjective: newContent
    }));
  };

  const handleVideoIdChange = (newVideoId: string) => {
    setGetTopicDetails(prevState => ({
      ...prevState,
      topicVideoURL: newVideoId
    }));
  };


  const handleUrlChange = (newUrlId: string) => {
    setGetTopicDetails(prevState => ({
      ...prevState,
      topicSearchURL: newUrlId
    }));
  };

  const handlePromptChange = (newPrompt: string) => {
    setGetTopicDetails(prevState => ({
      ...prevState,
      topicPrompt: newPrompt
    }));
  };

  const handleFileChange = (newFile: File) => {
    setFile(newFile);
  };

  const openConfirmation = () => {
    setConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setConfirmationOpen(false);
  };

  const handleCheckBoxChange = (event) => {
    const newValue = event.target.checked;
    setGetTopicDetails(prevState => ({
      ...prevState,
      isAssignmentRequired: newValue
    }));
  };

  const handleSubmit = () => {
    if (flag) {
      (async () => {
        let TopicData = {
          courseTopicId: getTopicDetails?.topicId,
          courseId: courseId,
          courseTopicName: getTopicDetails?.topicName,
          courseTopicDescrition: getTopicDetails.topicDescrition,
          courseTopicObjective: getTopicDetails.topicObjective,
          courseTopicVideoURL: getTopicDetails.topicVideoURL,
          courseTopicSearchURL: getTopicDetails.topicSearchURL,
          courseTopicPrompt: getTopicDetails.topicPrompt,
          isAssignmentRequired: getTopicDetails?.isAssignmentRequired,
          modifiedBy: state.userAccessContext.userId.toString(),

        };
        await usePost("Course/SaveCourseTopicDetail", TopicData).then(() => {
          setGetTopicDetails(initailaValue);
          setSnackbarMessage('Content Added Successfully');
          setOpen(true);
          openConfirmation();
          closeConfirmation();
        }).finally(() => onCallBack());
      })()
    } else {
      (async () => {

        let SubTopicData = {
          courseSubTopicId: getTopicDetails?.topicId,
          courseTopicId: getTopicDetails?.parentTopicId,
          courseId: courseId,
          courseSubTopicName: getTopicDetails?.topicName,
          CourseSubTopicDescription: getTopicDetails.topicDescrition,
          courseSubTopicObjective: getTopicDetails.topicObjective,
          courseSubTopicVideoURL: getTopicDetails.topicVideoURL,
          courseSubTopicSearchURL: getTopicDetails.topicSearchURL,
          courseSubTopicPrompt: getTopicDetails.topicPrompt,
          stipulatedTime: selectedTime,
          isAssignmentRequired: getTopicDetails?.isAssignmentRequired,
          modifiedBy: state.userAccessContext.userId,
        };
        await usePost("Course/CreateSubTopic", SubTopicData).then(() => {
          setGetTopicDetails(initailaValue);
          setSnackbarMessage('Content Added Successfully');
          setSelectedTime('');
          setOpen(true);
          openConfirmation();
          closeConfirmation();
        }).finally(() => onCallBack())
      })()
    }
  };

  const handleChange = (event: any) => {
    setGetTopicDetails({
      ...getTopicDetails,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={0} padding={0.1} borderBottom="1px solid lightgray">
        <Grid item xs={6} padding={0}>
          <Typography variant="body2" padding={1} marginTop={"5px"} color={"black"} >
            <b>Topic Name : {getTopicDetails?.topicName}</b>
          </Typography>
        </Grid>
        {/* Future enhancement */}
        {/* <Grid item xs={2} display={"flex"} justifyContent={"flex-end"}> */}
        {/* <TextField size="small" label="mm:ss" /> */}
        {/*  //feature enhancement 
   <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoItem sx={{ margin: 0 }}>
      <TimePicker 
        views={['minutes', 'seconds']} 
        format="mm:ss"  
        value={defaultTime} // Set the default time value here
        onChange={handleTimeChange} // Handle time change and update state
        sx={{ height: "30px" }} 
      />
    </DemoItem>
  </LocalizationProvider> */}
        {/* </Grid> */}
        <Grid item xs={6} padding={0.9} display={"flex"} justifyContent={"flex-end"}>
          <Button variant="contained" size="small" color="success" onClick={openConfirmation} sx={{ marginRight: "8px" }} className={classes.submitButton} >
            <b>Submit</b>
          </Button>
        </Grid>
      </Grid>
      <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.courseQuestion}>
        <Box p={1}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body2" border={"1px solid lightgray"} padding={"5px"} >
                <b>Description</b>
              </Typography>
              <Box className={classes.textEditor}>
                <CustomizedDividers onChange={handleCustomizedDividersChange} value={getTopicDetails?.topicDescrition??""} minHeight="150px"  minWidth={570}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" border={"1px solid lightgray"} padding={"5px"} >
                <b>Objective</b>
              </Typography>
              <Box className={classes.textEditor}>
                <CustomizedDividers onChange={handleCustomizedDividersChange1} value={getTopicDetails?.topicObjective ?? ""} minHeight="150px" minWidth={570}  />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <VideoView onVideoIdChange={handleVideoIdChange} value={getTopicDetails?.topicVideoURL} flag={flag} topicId={flag ? getTopicDetails?.topicId : getTopicDetails?.parentTopicId} subTopicId={flag ? null : getTopicDetails?.topicId} courseId={courseId} />
            </Grid>
            <Grid item xs={3}>
              <UrlLinkView onUrlChange={handleUrlChange} value={getTopicDetails?.topicSearchURL} />
            </Grid>
            <Grid item xs={3}>
              <Attachments onAttachmentChange={handleFileChange} courseId={courseId} flag={flag} topicId={flag ? getTopicDetails?.topicId : getTopicDetails?.parentTopicId} subTopicId={flag ? null : getTopicDetails?.topicId} />
            </Grid>
            <Grid item xs={3}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Is Assignment Required"
                  name="isAssignmentRequired"
                  onChange={handleCheckBoxChange}
                  checked={getTopicDetails?.isAssignmentRequired || false}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Prompt
                onPromptChange={handlePromptChange} value={getTopicDetails?.topicPrompt??""} onChange={(e) => { handleChange(e); }} />
            </Grid>
          </Grid>
        </Box>
      </Scrollbars>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        color='success'
        className={classes.snackbarColor}
      >
        <Alert onClose={handleClose} severity="success" className={classes.snackbarColor}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={confirmationOpen} onClose={closeConfirmation} fullWidth PaperProps={{ style: { borderRadius: 15 } }} classes={{ paper: classes.deleteDialogue }}>
        <DialogTitle id="alert-dialog-title" className={classes.dialogStyles}>
          <b>{"Confirm"}</b>
          <CancelOutlinedIcon
            onClick={closeConfirmation}
            className={classes.closeButton}
          />
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to submit?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="success" variant="contained" size="small" startIcon={<CheckCircleIcon />}>
            Yes
          </Button>
          <Button onClick={closeConfirmation} color="error" variant="contained" size="small" startIcon={<CancelIcon />}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment >
  );
}
export default TopicContent;