/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { IWeekCourseProgress } from '../../model/IDashboardCount';





interface LineChartProps {
  weeklyProgress: IWeekCourseProgress[];
}

const LineChart: React.FC<LineChartProps> = ({ weeklyProgress }) => {
  const [courseCounts, setCourseCounts] = useState<number[]>([]);
  const [userCounts, setUserCounts] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    if (Array.isArray(weeklyProgress)) {
      setCourseCounts(weeklyProgress.map(course => course.courseCount));
      setUserCounts(weeklyProgress.map(course => course.learnerCount));
      setLabels(weeklyProgress.map(course => course.weekLabel));
    }
  }, [weeklyProgress]);
  const series = [
    {
      name: 'Learner Count',
      type: 'column',
      data: userCounts
    },
    {
      name: 'Course Count',
      type: 'line',
      data: courseCounts
    }
  ];

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'line'
    },
    stroke: {
      width: [0, 4]
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    },
    labels: labels,
    xaxis: {
      type: 'category'
    },
    yaxis: [
      {
        title: {
          text: 'Learner Count'
        }
      },
      {
        opposite: true,
        title: {
          text: 'Course Count'
        }
      }
    ],
    colors: ['#c7d2fe', '#6366f1']
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="line" height={357} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default LineChart;
